import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { BodySize, ElementSize } from "src/app/types/ui/layout.types";
import { setBodySize, setIsDarkTheme, setMainSize, toggleSidebar } from "src/app/store/features/ui/layout/ui.layout.actions";
import { forgetSession } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
	bodySize: createReducer({ width: 0, height: 0 } as BodySize)
		.handleAction([ setBodySize ], (state, action) => action.payload),
	mainSize: createReducer({ width: 0, height: 0 } as ElementSize)
		.handleAction([ setMainSize ], (_, action) => action.payload),
	isSidebarOpen: createReducer(true)
		.handleAction([ toggleSidebar ], (_, action) => action.payload)
		.handleAction([ forgetSession ], () => true),
	isDarkTheme: createReducer(false)
		.handleAction([ setIsDarkTheme ], (_, action) => action.payload),
});

export default reducer;
