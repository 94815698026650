/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import SingleSkeletonAvailability from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Skeleton/SingleSkeletonAvailability.component";

type Props = {
	roomsNumber: number
	roomAvailabilitiesNumber: number
}

function SkeletonAvailabilities(props: Props) {

	const {
		roomsNumber,
		roomAvailabilitiesNumber,
	} = props;

	return (
		<>
			{
				new Array(roomsNumber).fill("").map((_, roomIndex) =>
					<div className="flex gap-1 overflow-hidden" key={ roomIndex }>
						{
							new Array(roomAvailabilitiesNumber).fill("").map((_, availabilityIndex) =>
								<SingleSkeletonAvailability key={ availabilityIndex }/>
							)
						}
					</div>
				)
			}
		</>
	);
}

export default SkeletonAvailabilities;