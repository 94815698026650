/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import classNames from "classnames";
import { Link } from "react-router-dom";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { CSSProperties, isValidElement, MouseEvent } from "react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Image, Nullable } from "src/app/types/util.types";
import Avatar from "src/app/components/Utils/Avatar.component";

export type ModelCellProps<T> = {
	model: T
	link?: Nullable<string>
	className?: string
	onClick?: () => void
	style?: CSSProperties
	size?: "sm" | "lg"
	customRender?: (model: T) => ReactNode | string
} & (T extends { image: Nullable<Image> } ? { image: Nullable<Image>, fallbackImage: ReactNode } : {});


export const hasFallbackImage = (obj: any): obj is { fallbackImage: ReactNode } => {
	return typeof obj === "object" && "fallbackImage" in obj && isValidElement(obj.fallbackImage);
}

export const hasImage = (obj: any): obj is { image: Nullable<Image> } => {
	return (
		typeof obj === "object" &&
		"image" in obj &&
		typeof obj.image === "object" &&
		typeof obj.image?.full === "string" &&
		typeof obj.image?.thumb === "string" &&
		typeof obj.image?.icon === "string"
	);
}

function ModelCell<T extends { id: string | number, name: string }>(props: ModelCellProps<T>) {

	const {
		link,
		className,
		onClick,
		style,
		model,
		size = "lg",
		customRender,
	} = props;

	const { getLink } = useUserScopeNavigate();

	const _onClick = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		if (isNull(onClick)) return;
		onClick();
	}

	const outerClassName = classNames(
		"border border-gray-300 rounded group/model-cell-wrapper max-h-full",
		{ "hover:underline": (isNotNull(link) && isNull(onClick) || (isNotNull(link) && isNotNull(onClick))) },
		{ "p-2 h-[40px]": size === "lg" },
		{ "p-1": size === "sm" },
		className,
	)

	const innerClassName = classNames(
		"flex items-center gap-2 whitespace-nowrap max-h-full",
		"[&>span]:text-ellipsis [&>span]:overflow-hidden [&>span]:whitespace-nowrap [&>span]:group-hover/model-cell-wrapper:underline",
		"text-ellipsis overflow-hidden whitespace-nowrap group-hover/model-cell-wrapper:underline",
		{ "[&>div>div>img]:min-w-[30px]": size === "lg" },
		{ "[&>div>div>img]:min-w-[20px] [&>div>div>img]:w-[20px]": size === "sm" },
		{ "text-xs": size === "sm" },
	)

	const _children =
		<>
			{
				hasImage(props) &&
					isNotNull(props.image) &&
						isNotNull(props.image) ?
						<Avatar
							alt={ `${ model.name }-cell-avatar` }
							img={ props.image.thumb }
							placeholderInitials={ model.name[ 0 ] }
							size="sm"
							className="h-full [&>div]:h-full [&>div>div]:h-full [&>div>img]:!h-full [&>div>img]:object-cover"
						/> :
						hasFallbackImage(props) && props.fallbackImage
			}
			<span>
				{ isNotNull(customRender) ? customRender(model) : model.name }
			</span>
		</>

	if (isNull(link)) {
		return (
			<div
				className={ classNames(outerClassName, innerClassName) }
				style={ style ?? undefined }
				onClick={ _onClick }
			>
				{ _children }
			</div>
		)
	}

	if (isNotNull(link) && isNull(onClick)) {
		return (
			<div
				className={ outerClassName }
				onClick={ e => e.stopPropagation() }
			>
				<Link
					to={ getLink(link) }
					style={ style ?? undefined }
					className={ innerClassName }
				>
					{ _children }
				</Link>
			</div>
		);
	}

	if (isNotNull(link) && isNotNull(onClick)) {
		return (
			<div
				onClick={ _onClick }
				className={ outerClassName }
			>
				<Link
					to={ getLink(link) }
					className={ innerClassName }
					style={ style ?? undefined }
				>
					{ _children }
				</Link>
			</div>
		);
	}

	return (
		<div
			className={ classNames(outerClassName, innerClassName) }
			style={ style ?? undefined }
		>
			{ _children }
		</div>
	)
}

export default ModelCell;