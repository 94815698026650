/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedDiscountCode } from "src/app/types/api/discountCode.types";
import { createDiscountCodeAsync, deleteDiscountCodeByIdAsync, fetchDiscountCodeByIdAsync, fetchDiscountCodesAsync, updateDiscountCodeAsync } from "src/app/store/features/discountCode/discountCode.actions";

export const fetchDiscountCodeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchDiscountCodesAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/admin/discount-codes`,
				method: "GET",
				withScope: true,
				onSuccess: fetchDiscountCodesAsync.success,
				onFailure: fetchDiscountCodesAsync.failure,
			}),
		),
	);

export const fetchDiscountCodeByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchDiscountCodeByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/discount-codes/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedDiscountCode>) => fetchDiscountCodeByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchDiscountCodeByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const createDiscountCodeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createDiscountCodeAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/admin/discount-codes",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createDiscountCodeAsync.success,
				onFailure: createDiscountCodeAsync.failure,
			}),
		),
	);

export const updateDiscountCodeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateDiscountCodeAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/discount-codes/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateDiscountCodeAsync.success,
				onFailure: updateDiscountCodeAsync.failure,
			}),
		),
	);

export const deleteDiscountCodeByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteDiscountCodeByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/discount-codes/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteDiscountCodeByIdAsync.success,
				onFailure: deleteDiscountCodeByIdAsync.failure,
			}),
		),
	);
