import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { isAuthorized } from "src/app/store/features/user/user.selectors";

type Props =
	ReturnType<typeof mapStateToProps>;

function PrivateRoute(props: Props) {

	const {
		isAuthorized,
	} = props;

	if (isAuthorized) {
		return <Outlet/>;
	} else {
		return <Navigate to="/panel/login"/>;
	}
}

const mapStateToProps = (state: RootState) => ({
	isAuthorized: isAuthorized(state),
});

export default connect(mapStateToProps)(PrivateRoute);
