import { RouteObject } from "react-router/dist/lib/context";
import CategoryListView from "src/app/views/Category/CategoryList.view";

const categoryRoutes: RouteObject = {
    path: "categories",
    children: [
        {
            index: true,
            element: <CategoryListView/>,
        }
    ],
};

export default categoryRoutes;
