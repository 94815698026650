/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import classNames from "classnames";
import { HiOutlineTrash } from "react-icons/hi";
import { CartItem, CartItemType } from "src/app/types/api/reservation.types";
import { Nullable } from "src/app/types/util.types";
import { isNotNull } from "src/app/utils/typeguards";
import Avatar from "src/app/components/Utils/Avatar.component";
import PlaceholderImage from "src/app/components/PurchasingProcess/util/PlaceholderImage.component";
import React from "react";
import { FormProduct } from "src/app/types/api/product.types";

type Props = {
	cartItem: CartItem & { type: CartItemType.PRODUCT, product: FormProduct }
	className?: string
	handleProductChange: (productId: number, number: Nullable<number>) => void
}

function CartItemProduct(props: Props ) {

	const {
		cartItem,
		className = "",
		handleProductChange,
	} = props;

	const onCountChange = (value: number) => {
		if (cartItem.type !== CartItemType.PRODUCT) return;
		handleProductChange(cartItem.product.id, value);
	}

	const handleProductDelete = () => {
		if (cartItem.type !== CartItemType.PRODUCT) return;
		handleProductChange(cartItem.product.id, null)
	}

	return (
		<div className={ classNames(
			"flex gap-5 border-[1px] border-gray-200 p-3",
			"flex-col",
			"xs:flex-row",
			className,
		) }>
			<div className={ classNames(
				"flex items-center gap-2",
				"flex-row w-full justify-between",
				"xs:flex-col xs:w-auto xs:justify-start",
			) }>
				<div className={ classNames(
					"flex justify-center",
					"h-16 w-16 min-h-[64px] min-w-[64px]",
					"xs:h-24 xs:w-24 xs:min-h-[96px] xs:min-w-[96px]",
				) }>
					{
						isNotNull(cartItem?.image)
							?
							<Avatar
								img={ cartItem?.image?.thumb }
								className="[&>div>img]:h-16 [&>div>img]:min-h-[64px] [&>div>img]:w-16 [&>div>img]:min-w-[64px]"
							/>
							:
							<PlaceholderImage className="h-full w-full"/>
					}
				</div>
				<div className="flex rounded-md bg-myPrimary-orange-500 text-white text-xs justify-between items-center max-w-[78px] min-w-[78px]">
					<div className="cursor-pointer pr-4 py-1 pl-2" onClick={ () => onCountChange(-1) }>
						-
					</div>
					<div className="mx-1">
						{ cartItem.product.count }
					</div>
					<div className="cursor-pointer pl-4 py-1 pr-2" onClick={ () => onCountChange(+1) }>
						+
					</div>
				</div>
			</div>
			<div className="flex flex-col">
				<div className={ classNames(
					"uppercase font-[900]",
					"text-xs",
					"xs:text-sm",
					"sm:text-base",
				) }>
					{ cartItem.title }
				</div>
				<ul>
					{
						cartItem.bulletPoints.map((bulletPoint, index) =>
							<li
								key={ index }
								className="text-xs text-myPrimary-purple-500 font-light"
							>
								{ bulletPoint }
							</li>
						)
					}
				</ul>
			</div>
			<div className={ classNames(
				"flex h-full justify-between items-end",
				"flex-row ml-0",
				"xs:flex-col xs:ml-auto",
			) }>
				<div className="font-extrabold whitespace-nowrap">
					{ `${ cartItem.price } ZŁ` }
				</div>
				{
					cartItem.type === CartItemType.PRODUCT &&
					<div
						className="font-light text-sm text-myPrimary-purple-500 flex gap-1 items-center hover:underline cursor-pointer"
						onClick={ handleProductDelete }
					>
						<span>
							Usuń
						</span>
						<HiOutlineTrash className="h-5 w-5"/>
					</div>
				}
			</div>
		</div>
	);
}

export default CartItemProduct;
