/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import useReservationBreadcrumbHeaderHeight from "src/app/utils/hooks/useReservationBreadcrumbHeaderHeight";
import parrotImageOrange from "src/assets/images/kakadu-parrot-orange.svg";
import classNames from "classnames";
import { Button } from "flowbite-react";

function PurchasingProcessConfirmationContainer() {

	const { height: headerHeight } = useReservationBreadcrumbHeaderHeight();

	return (
		<div
			className={ classNames(
				"flex items-center gap-10",
				"flex-col-reverse justify-end",
				"lg:flex-row lg:justify-center",
			) }
			style={ { minHeight: `calc(100vh - ${ headerHeight }px - 32px)` } }
		>
			<div className={ classNames(
				"flex flex-col gap-5",
				"text-center",
				"lg:text-right",
			) }>
				<div className={ classNames(
					"uppercase text-myPrimary-orange-500 font-[900]",
					"text-xl",
					"xs:text-2xl",
					"sm:text-3xl",
				) }>
					DZIĘKUJEMY ZA DOKONANIE REZERWACJI!
				</div>
				<div className={ classNames(
					"text-sm",
					"sm:text-base",
				) }>
					Odnotowaliśmy Twoją płatność i zarejestrowaliśmy Twoją rezerwacje. W wiadomości e-mail otrzymasz potwierdzenie rezerwacji oraz dokumenty sprzedaży
				</div>
				<Button
					color="orange-full"
					href="/"
					className="self-center lg:self-end"
				>
					Wróć do formularza rezerwacji
				</Button>
			</div>
			<img
				src={ parrotImageOrange }
				alt="KAKADU"
				className={ classNames(
					"h-[30vh] min-h-[100px]",
					"lg:h-[500px] lg:w-3/5",
				) }
			/>
		</div>
	);
}

export default PurchasingProcessConfirmationContainer;
