/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { CSSProperties } from "react";
import DatePicker from "src/app/components/Form/DatePicker.component";
import { createFormField } from "src/app/utils/forms";
import { DateTime } from "luxon";
import { Button } from "flowbite-react";
import { HiCalendar } from "react-icons/hi";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import PurchasingProcessSmallButton from "src/app/components/PurchasingProcess/util/PurchasingProcessSmallButton.component";
import { LocaleFromISO } from "src/app/utils/luxon";
import classNames from "classnames";
import { MAX_FUTURE_MONTHS } from "src/app/utils/constants/constants";

type Props = {
	onDateChange: (date: DateTime) => void
	date: DateTime
	className?: string
	style?: CSSProperties
}

function DatePickerHeader(props: Props) {

	const {
		onDateChange,
		date,
		className,
		style,
	} = props;

	const disableNextButton = date.hasSame(DateTime.now().plus({ months: MAX_FUTURE_MONTHS }), "day");
	const isToday = date.hasSame(DateTime.now(), "day");

	const onPrevClick = () => {
		onDateChange(date.minus({ days: 1 }));
	};

	const onNextClick = () => {
		if (disableNextButton) return;
		onDateChange(date.plus({ days: 1 }));
	};

	return (
		<div
			className={ classNames("flex gap-4 items-center z-20", className) }
			style={ style }
		>
			<DatePicker
				formItem={ createFormField(date.toJSDate()) }
				onChange={ date => onDateChange(DateTime.fromJSDate(date)) }
				onBlur={ () => undefined }
				showTimeInput={ false }
				maxDate={ DateTime.now().plus({ months: MAX_FUTURE_MONTHS }).toJSDate() }
				closeOnChange={ true }
				customInput={
					<Button
						color="orange-outline"
						size="xs"
					>
						<HiCalendar className="text-base mr-2"/>
						data
					</Button>
				}
			/>
			<SlArrowLeft
				onClick={ onPrevClick }
				className={ classNames(
					"text-lg",
					"text-myPrimary-orange-500 cursor-pointer",
					// { "text-gray-300": disablePrevButton },
				) }
			/>
			<SlArrowRight
				onClick={ onNextClick }
				className={ classNames(
					"text-lg",
					{ "text-myPrimary-orange-500 cursor-pointer": !disableNextButton },
					{ "text-gray-300": disableNextButton },
				) }
			/>
			<span className="text-myPrimary-orange-500 text-sm">
				{ date.toLocaleString(DateTime.DATE_HUGE) }
			</span>
			<PurchasingProcessSmallButton
				color={ isToday ? "orange-full" : "orange-outline" }
				onClick={ () => onDateChange(LocaleFromISO(DateTime.now().toISO())) }
			>
				<span>Dziś</span>
			</PurchasingProcessSmallButton>
		</div>
	);
}

export default DatePickerHeader;
