/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { SimpleRoom } from "src/app/types/api/room.types";
import { Nullable } from "src/app/types/util.types";
import { AvailabilityState, FormAvailability, VenueAvailabilities } from "src/app/types/api/reservation.types";
import useReducerForm from "src/app/utils/hooks/useReducerForm";
import { purchasingProcessFormActions } from "src/app/store/features/form/form.actions";
import { isNull } from "src/app/utils/typeguards";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";

export type ReservingData = {
	date: string
	room: SimpleRoom
	startTime: string
	endTime: string
	availabilities: FormAvailability[]
}

function useReservingData(): Nullable<ReservingData> {

	const form = useReducerForm<PurchasingProcessReducerForm>(
		"purchasingProcess",
		purchasingProcessFormActions,
		() => null,
	);

	return form.form.dateRoomAvailabilities.value.reduce<Nullable<ReservingData>>((prev, current) => {
		const roomAvailabilities: Nullable<VenueAvailabilities<FormAvailability>> = current.roomAvailabilities.reduce<Nullable<VenueAvailabilities<FormAvailability>>>((prevInner, currentInner) => {
			if (!currentInner.availabilities.some(availability => availability.state === AvailabilityState.RESERVING)) return prevInner;
			return {
				...currentInner,
				availabilities: currentInner.availabilities.filter(availability => availability.state === AvailabilityState.RESERVING)
			};
		}, null);

		if (isNull(roomAvailabilities)) return prev;
		return {
			date: current.date,
			room: roomAvailabilities.room,
			startTime: roomAvailabilities.availabilities[ 0 ].startTime,
			endTime: roomAvailabilities.availabilities[ roomAvailabilities.availabilities.length - 1 ].endTime,
			availabilities: roomAvailabilities.availabilities,
		};
	}, null);
}

export default useReservingData;