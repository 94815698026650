/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Image, Nullable } from "src/app/types/util.types";
import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";
import { Category } from "src/app/types/api/category.types";

export enum ProductAbility {
	VIEW = "product.view",
	UPDATE = "product.update",
	DELETE = "product.delete",
}

export type SimpleProduct = {
	id: number
	name: string
	description: Nullable<string>
	price: string
	vatRate: number
	categories: Category[]

	image: Nullable<Image>

	organizationId: string
	organization: Nullable<SimpleOrganization>

	createdAt: string
	updatedAt: string
	meta: {
		abilities: ProductAbility[]
	}
}

export type FormProduct = SimpleProduct & {
	count: number
}

export enum ProductCategory {
	SEASONAL = "SEASONAL",
	MENU = "MENU",
	ALCOHOL = "ALCOHOL",
	SNACKS = "SNACKS",
	PARTY = "PARTY",
	OTHERS = "OTHERS",
}

export type Product =
	SimpleProduct
	& {}

export type DetailedProduct =
	Product
	& {
		organization: Organization
	}

export type ReservationProduct =
	Product
	& {
		quantity: number
	}

export type CreateProductPayload = {
	name: string
	description: string
	price: string
	vatRate: number
	avatar: Nullable<File>
	categories: Category[]
}

export type UpdateProductPayload = {
	id: number
	name?: string
	description?: string
	price?: string
	vatRate?: number
	avatar: Nullable<File>
	categories: Category[]
}
