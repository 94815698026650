/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useEffect, useState } from "react";
import { RootState } from "src/app/store/root.reducer";
import { useSelector } from "react-redux";
import { breakpoints } from "src/app/utils/constants/constants";

export const useReservationBreadcrumbHeaderHeight = () => {

	const bodyWidth = useSelector((state: RootState) => state.ui.layout.bodySize.width);

	const [ height, setHeight ] = useState(175);

	useEffect(() => {
		if (bodyWidth > breakpoints.LG) setHeight(175);
		else if (bodyWidth > breakpoints.MD) setHeight(130);
		else setHeight(110);
	}, [ bodyWidth ]);


	return { height, setHeight };
}

export default useReservationBreadcrumbHeaderHeight;

