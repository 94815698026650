/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { CreateVenuePayload, DetailedVenue, UpdateVenuePayload, Venue } from "src/app/types/api/venue.types";
import { FetchVenueAvailabilitiesPayload, VenueAvailabilities } from "src/app/types/api/reservation.types";

export const fetchAdminVenuesAsync = createNetworkAction<undefined, SuccessPayload<Venue[]>>("VENUE__FETCH_ADMIN_VENUES");
export const fetchVenuesAsync = createNetworkAction<undefined, SuccessPayload<Venue[]>>("VENUE__FETCH_VENUES");
export const fetchVenueByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedVenue>, FailurePayloadWithId>("VENUE__FETCH_VENUE_BY_ID");
export const createVenueAsync = createNetworkAction<CreateVenuePayload, SuccessPayload<DetailedVenue>>("VENUE__CREATE_VENUE");
export const updateVenueAsync = createNetworkAction<UpdateVenuePayload, SuccessPayload<DetailedVenue>>("VENUE__UPDATE_VENUE");
export const deleteVenueByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("VENUE__DELETE_VENUE");
export const fetchVenueAvailabilitiesAsync = createNetworkAction<FetchVenueAvailabilitiesPayload, SuccessPayloadWithId<VenueAvailabilities[]>, FailurePayloadWithId>("RESERVATION__FETCH_VENUE_AVAILABILITIES");
export const fetchAdminVenueAvailabilitiesAsync = createNetworkAction<FetchVenueAvailabilitiesPayload, SuccessPayloadWithId<VenueAvailabilities[]>, FailurePayloadWithId>("VENUE__FETCH_ADMIN_VENUE_AVAILABILITIES");
