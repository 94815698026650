/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import logo_short from "src/assets/images/logo_short.png";

type Props = {
	className?: string
}

function LogoShortImage(props: Props) {

	const {
		className = "",
	} = props;

	return (
		<img
			src={ logo_short }
			alt="Logo"
			className={ className }
		/>
	);
}

export default LogoShortImage;
