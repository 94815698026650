/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CreateVenuePayload, UpdateVenuePayload } from "src/app/types/api/venue.types";
import { FetchVenueAvailabilitiesPayload } from "src/app/types/api/reservation.types";

export const uiCreateVenue = createAction("UI_VENUE__CREATE_VENUE")<CreateVenuePayload>();
export const uiUpdateVenue = createAction("UI_VENUE__UPDATE_VENUE")<UpdateVenuePayload>();
export const uiDeleteVenue = createAction("UI_VENUE__DELETE_VENUE")<number>();
export const uiFetchVenueAvailabilities = createAction("UI_VENUE__FETCH_AVAILABILITIES")<FetchVenueAvailabilitiesPayload>();
export const uiFetchVenueAvailabilitiesDebounce = createAction("UI_VENUE__FETCH_AVAILABILITIES_DEBOUNCE")<FetchVenueAvailabilitiesPayload>();
export const uiFetchAdminVenueAvailabilities = createAction("UI_VENUE__FETCH_ADMIN_AVAILABILITIES")<FetchVenueAvailabilitiesPayload>();
export const uiFetchVenues = createAction("UI_VENUE__FETCH_VENUES")<undefined>();
