/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiCreateDiscountCode, uiDeleteDiscountCode, uiUpdateDiscountCode } from "src/app/store/features/ui/discountCode/ui.discountCode.actions";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { createDiscountCodeAsync, deleteDiscountCodeByIdAsync, updateDiscountCodeAsync } from "src/app/store/features/discountCode/discountCode.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";

export const uiCreateDiscountCodeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateDiscountCode)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_DISCOUNT_CODE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createDiscountCodeAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createDiscountCodeAsync.success, action) || isActionOf(createDiscountCodeAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createDiscountCodeAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Poprawnie utworzono kod rabatowy!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateDiscountCodeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateDiscountCode)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_DISCOUNT_CODE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateDiscountCodeAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateDiscountCodeAsync.success, action) || isActionOf(updateDiscountCodeAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateDiscountCodeAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie edytowano kod rabatowy!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteDiscountCodeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteDiscountCode)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_DISCOUNT_CODE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteDiscountCodeByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteDiscountCodeByIdAsync.success, action) || isActionOf(deleteDiscountCodeByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteDiscountCodeByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto kod rabatowy!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);
