import React, { PropsWithChildren } from "react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { FormItemError } from "src/app/types/ui/form.types";
import { Button, Label, LabelProps } from "flowbite-react";
import { DropzoneOptions, FileWithPath, useDropzone } from "react-dropzone";
import classNames from "classnames";
import { HiDocumentAdd } from "react-icons/hi";

type Props = {
	className?: string
	labelProps?: LabelProps
	label?: string
	buttonText: string
	onChange: (file: FileWithPath) => void
	name: string // Friendly name for HTML Semantic
	error?: FormItemError
	options?: Omit<DropzoneOptions, "onDrop">
}

function FileInput(props: PropsWithChildren<Props>) {
	const {
		className,
		labelProps,
		label,
		buttonText,
		onChange,
		name,
		error,
		options,
	} = props;

	const _onDrop = (acceptedFiles: FileWithPath[]) => {
		const file = acceptedFiles[ 0 ];

		if (isNull(file)) return;

		onChange(file);
	};

	const {
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop: _onDrop,
		maxSize: 100 * 1024 * 1024, // 100 MB
		maxFiles: 1,
		...options,
	});

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-y-0.5",
					className,
				)
			}
		>
			{
				isNotNull(label) &&
                <Label
                    htmlFor={ name }
					{ ...labelProps }
                >
					{ label }
                </Label>
			}
			<div
				{
					...getRootProps({
						className: classNames(
							"flex items-center px-4 py-6 border-2 rounded-lg border-dashed border-gray-400 cursor-pointer transition duration-100 ease-in-out bg-transparent",
							{ "border-green-600": isDragAccept },
							{ "border-red-600": isDragReject },
						),
					})
				}
			>
				<input name={ name } { ...getInputProps() } />
				<HiDocumentAdd className="w-10 h-10 text-gray-400"/>
				<div className="flex flex-col ml-3 gap-1">
					<div className="text-sm dark:text-white">Prześlij plik</div>
					<div className="text-xs text-gray-400">Maks. wielkość 100 MB</div>
				</div>
				<Button color="light" className="ml-auto">
					{ buttonText }
				</Button>
			</div>
			{
				isNotNull(error) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ error }
                </div>
			}
		</div>
	);
}

export default (FileInput);
