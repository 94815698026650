/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { lazy } from "react";
import { RouteObject } from "react-router/dist/lib/context";

const ReservationListView = lazy(() => import("src/app/views/Reservation/ReservationList.view"));

const orderRoutes: RouteObject = {
	path: "orders",
	element: <ReservationListView/>,
};

export default orderRoutes;

