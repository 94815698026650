/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import SkeletonRooms from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Skeleton/SkeletonDesktopRooms.component";
import RoomPreview from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/PurchasingProcessRoomPreview.component";
import SkeletonAvailabilities from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Skeleton/SkeletonDesktopAvailabilities.component";
import { getCurrentDateRoomAvailabilities, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import AvailabilityComponent from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Availability/Availability.component";
import React, { useLayoutEffect, useState } from "react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { FormHookReturnType } from "src/app/types/ui/form.types";
import { RESERVATION_SKELETON_ROOM_AVAILABILITIES_NUMBER, RESERVATION_SKELETON_ROOM_NUMBER } from "src/app/utils/constants/constants";
import classNames from "classnames";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

type Props = {
	showSkeleton: boolean
	form: FormHookReturnType<PurchasingProcessReducerForm>
}

function AvailabilitiesDesktop(props: Props) {

	const {
		showSkeleton,
		form: {
			form: {
				venue,
				dateRoomAvailabilities,
				date,
			},
		},
	} = props;

	const [ isTimelineScrolled, toggleTimelineScrolled ] = useState({
		left: false,
		right: true,
	});

	const availabilitiesWrapper = document.getElementById("availabilities-wrapper");

	const handleAvailabilitiesWrapperScroll = (scrollLeft: number, scrollWidth: number, clientWidth: number) => {
		if (scrollLeft > 0) {
			toggleTimelineScrolled(prevState => ({ ...prevState, left: true }));
		} else {
			toggleTimelineScrolled(prevState => ({ ...prevState, left: false }));
		}
		if (scrollLeft < scrollWidth - clientWidth) {
			toggleTimelineScrolled(prevState => ({ ...prevState, right: true }));
		} else {
			toggleTimelineScrolled(prevState => ({ ...prevState, right: false }));
		}
	};

	const scrollAvailabilitiesWrapper = (scrollValue: number) => {
		if (isNull(availabilitiesWrapper)) return;
		availabilitiesWrapper.scroll(availabilitiesWrapper.scrollLeft + scrollValue, 0);
	};

	useLayoutEffect(() => {
		const availabilitiesWrapper = document.querySelector("#availabilities-wrapper");
		if (isNull(availabilitiesWrapper)) return;

		const hasScrollbar = availabilitiesWrapper.scrollWidth > availabilitiesWrapper.clientWidth;
		toggleTimelineScrolled({
			left: false,
			right: hasScrollbar,
		});
	}, [ showSkeleton ]);

	const header = document.getElementById("purchase-header");
	const chooseDate = document.getElementById("choose-date");
	const iconHeight = 48;

	return (
		<div
			id="main-wrapper"
			className={
				classNames(
					"gap-5 relative",
					"hidden",
					"lg:flex",
				)
			}
		>
			{
				isTimelineScrolled.right &&
				/* Blur-right */
                <div
                    onClick={ () => scrollAvailabilitiesWrapper(480) }
                    className="w-28 h-full bg-gradient-to-l from-white cursor-pointer absolute top-0 right-[-7px] z-10 flex items-center justify-center"
                >
                    <SlArrowRight
                        className="text-5xl text-myPrimary-orange-500 fixed right-0 transition"
                        style={ {
							top:
								Math.max((header?.clientHeight ?? 0) - window.scrollY, 0) +
								(chooseDate?.clientHeight ?? 0) +
								(
									(
										document.body.clientHeight -
										Math.max((header?.clientHeight ?? 0) - window.scrollY, 0) -
										(chooseDate?.clientHeight ?? 0) -
										iconHeight
									) / 2
								),
						} }
                    />
                </div>
			}
			<div
				id="rooms-wrapper"
				className="flex flex-col gap-4 relative"
			>
				{
					isTimelineScrolled.left &&
					/* Blur-left */
                    <div
                        onClick={ () => scrollAvailabilitiesWrapper(-480) }
                        className="w-28 h-full bg-gradient-to-r from-white cursor-pointer absolute top-0 left-[calc(100%+7px)] z-10 flex items-center justify-center"
                    >
                        <SlArrowLeft
                            className="text-5xl text-myPrimary-orange-500 fixed left-[400px] transition"
                            style={ {
								top:
									Math.max((header?.clientHeight ?? 0) - window.scrollY, 0) +
									(chooseDate?.clientHeight ?? 0) +
									(
										(
											document.body.clientHeight -
											Math.max((header?.clientHeight ?? 0) - window.scrollY, 0) -
											(chooseDate?.clientHeight ?? 0) -
											iconHeight
										) / 2
									),
						} }
                        />
                    </div>
				}
				{
					showSkeleton ? <SkeletonRooms roomsNumber={ RESERVATION_SKELETON_ROOM_NUMBER }/> :
						venue.value?.rooms
							 ?.sort((a, b) => a.maxPeople - b.maxPeople)
							 ?.filter(room => {
								 const roomWithAvailabilities = getCurrentDateRoomAvailabilities(dateRoomAvailabilities.value, date.value)
									 ?.find(({ roomId, availabilities }) =>
										 roomId === room.id && availabilities.length > 0,
									 );

								 return isNotNull(roomWithAvailabilities);
							 })
							 ?.map(room =>
								 <RoomPreview
									 key={ room.id }
									 room={ room }
								 />,
							 )
				}
			</div>
			<div
				id="availabilities-wrapper"
				className="flex flex-col gap-4 overflow-x-auto scroll-smooth"
				onScroll={ ({ currentTarget: { scrollLeft, scrollWidth, clientWidth } }) => handleAvailabilitiesWrapperScroll(scrollLeft, scrollWidth, clientWidth) }
			>
				{
					showSkeleton
						?
						<SkeletonAvailabilities
							roomsNumber={ RESERVATION_SKELETON_ROOM_NUMBER }
							roomAvailabilitiesNumber={ RESERVATION_SKELETON_ROOM_AVAILABILITIES_NUMBER }
						/>
						:
						venue.value?.rooms
							 ?.sort((a, b) => a.maxPeople - b.maxPeople)
							 .map(room => {
								 const roomWithAvailabilities = getCurrentDateRoomAvailabilities(dateRoomAvailabilities.value, date.value)
									 ?.find(({ roomId, availabilities }) =>
										 roomId === room.id && availabilities.length > 0,
									 );
								 if (isNull(roomWithAvailabilities)) return null;

								 return (
									 <div
										 key={ room.id }
										 className="flex gap-1"
									 >
										 {
											 roomWithAvailabilities.availabilities.map((availability, index) =>
												 <AvailabilityComponent
													 key={ index }
													 form={ props.form }
													 availability={ availability }
													 room={ room }
													 idPrefix="desktop"
												 />,
											 )
										 }
									 </div>
								 );
							 })
				}
			</div>
		</div>
	);
}

export default AvailabilitiesDesktop;
