import { DataState, ErrorState, LoadingState } from "src/app/types/redux.types";
import { useEffect } from "react";
import { isNotNull, isString } from "src/app/utils/typeguards";
import { GridLoader } from "react-spinners";
import ErrorWrapper from "src/app/components/Message/ErrorWrapper.component";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { SnackbarMessageType } from "src/app/types/ui/message.types";
import { RootState } from "src/app/store/root.reducer";
import { useSelector } from "react-redux";
import { StrategyProps } from "src/app/types/ui/caching.types";

function CachedThenFreshStrategy<T>(props: StrategyProps<T, (data: T, isLoading: boolean) => JSX.Element>) {

	const {
		request,
		unmount,
		state,
		children,
		useEffectDependency,
		withoutLoading = false,
	} = props;

	const { bodySize } = useSelector((state: RootState) => state.ui.layout);

	useEffect(() => {
		request();

		return () => {
			isNotNull(unmount) && unmount();
		};
	}, [ useEffectDependency ]);

	if (state.dataState === DataState.PRESENT) {
		if (state.errorState === ErrorState.NOT_PRESENT) {
			return children(state.data, state.loadingState === LoadingState.LOADING);
		} else {
			return (
				<div className="flex flex-col gap-4">
					<SnackbarComponent
						className="shadow"
						type={ SnackbarMessageType.WARNING }
						message="Prezentowane dane mogą być nieaktualne."
					/>
					{ children(state.data, state.loadingState === LoadingState.LOADING) }
				</div>
			);
		}
	} else {
		if (state.errorState === ErrorState.PRESENT) {
			const error = state.errors[ 0 ];
			const errorMessage = isString(error.message) ? error.message : (error.message?.message ?? "Unknown error");
			return (
				<ErrorWrapper
					codeName={ error.codeName }
					message={ errorMessage }
					buttonText="Spróbuj ponownie"
					onButtonClick={ () => request() }
					isLoading={ state.loadingState === LoadingState.LOADING }
				/>
			);
		} else if (!withoutLoading) {
			return (
				<div className="w-full h-full flex items-center justify-center">
					<GridLoader size={ bodySize.width > 1000 ? 30 : 20 } color="#EC5600"/>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default CachedThenFreshStrategy;
