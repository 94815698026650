/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import classNames from "classnames";
import { AdminReservationPayloadProduct } from "src/app/utils/constants/roomReservation.form";
import { HiMinus, HiPlus } from "react-icons/hi";
import React from "react";

type Props = {
	formProduct:  AdminReservationPayloadProduct
	onProductPlus: (productId: number) => void
	onProductMinus: (productId: number) => void
}

function CounterCell(props: Props) {

	const {
		formProduct: {
			quantity,
			id: productId,
		},
		onProductPlus,
		onProductMinus,
	} = props;

	return (
		<div className="flex justify-center items-center gap-1">
			<div
				className={ classNames(
					"p-1 bg-red-500 rounded flex items-center justify-center h-max cursor-pointer",
					{ "pointer-events-none opacity-50": quantity === 0 }
				) }
				onClick={ () => onProductMinus(productId) }
			>
				<HiMinus className="w-5 h-5 fill-white"/>
			</div>
			<div className="p-2 rounded  border-myPrimary-purple-500">
				{ quantity }
			</div>
			<div
				className="p-1 bg-green-500 rounded flex items-center justify-center h-max cursor-pointer"
				onClick={ () => onProductPlus(productId) }
			>
				<HiPlus className="w-5 h-5 fill-white"/>
			</div>
		</div>
	);
}

export default CounterCell;

