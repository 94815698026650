/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField } from "src/app/utils/forms";
import { useEffect } from "react";
import useForm from "src/app/utils/hooks/useForm";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { Category, UpdateCategoryPayload } from "src/app/types/api/category.types";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	category: Category
	onUpdate: (payload: UpdateCategoryPayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type UpdateCategoryForm = {
	name: string
}

const validator: FormValidator<UpdateCategoryForm> = {
	name: (name, optional) => validateField("Nazwa", name, optional),
};

function UpdateCategoryModal(props: Props) {

	const {
		isOpen,
		handleClose,
		category,
		onUpdate,
		isUpdating,
	} = props;

	const _handleSubmit = (formValues: UpdateCategoryForm) => {
		onUpdate({ ...formValues, id: category.id });
		handleClose();
	};

	const initialState = {
		name: createFormField(category.name),
	}

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(initialState, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isUpdating) setForm(initialState);
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Edytuj kategorie
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<Input
						formItem={ form.name }
						label="Nazwa"
						name="category-name"
						inputProps={ {
							type: "text",
							onChange: e => handleChange("name", e.target.value),
							onBlur: () => handleBlur("name"),
						} }
					/>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						Zapisz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, { category }: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableId: category.id, loadableType: LoadableType.UPDATE_CATEGORY }),
});

export default connect(mapStateToProps)(UpdateCategoryModal);
