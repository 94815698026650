import { DetailedDiscountCode } from "src/app/types/api/discountCode.types";

type Props = {
    discountCode: DetailedDiscountCode
};

function DiscountCodeProfileContainer(props: Props) {
    const {
        discountCode,
    } = props;

    return (
        <div>My ID: { discountCode.id }</div>
    );
}

export default (DiscountCodeProfileContainer);