import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { getAdminRoomWithVenuesWithRoomsWithProductsWithDiscountCodes } from "src/app/store/features/room/room.selectors";
import { fetchAdminRoomsAsync, fetchRoomByIdAsync } from "src/app/store/features/room/room.actions";
import RoomProfileContainer from "src/app/containers/Room/RoomProfile.container";
import { useParams } from "react-router-dom";
import { fetchAdminVenuesAsync } from "src/app/store/features/venue/venue.actions";
import { uiAddTimeWindowToWeekday, uiCopyTimeRangeFromOtherRoom, uiCopyTimeRangeFromOtherWeekday, uiDeleteTimeWindow, uiEditTimeWindow, uiUpdateRoom } from "src/app/store/features/ui/room/ui.room.actions";
import { fetchDiscountCodesAsync } from "src/app/store/features/discountCode/discountCode.actions";
import { fetchProductsAsync } from "src/app/store/features/product/product.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function RoomProfileView(props: Props) {
	const {
		getRoom,
		fetchRoomById,
		fetchVenues,
		fetchAdminRooms,
		fetchDiscountCodes,
		fetchProducts,
		updateRoom,
		copyTimeRangeFromOtherRoom,
		copyTimeRangeFromOtherWeekday,
		addTimeWindowToWeekday,
		editTimeWindow,
		deleteTimeWindow,
	} = props;

	const { roomId = "0" } = useParams();

	const _handleRequest = () => {
		fetchRoomById(+roomId);
		fetchVenues();
		fetchAdminRooms();
		fetchDiscountCodes();
		fetchProducts();
	};

	return (
		<ForceFreshStrategy
			request={ _handleRequest }
			state={ getRoom(+roomId) }
		>
			{
				({ room, venues, rooms, products, discountCodes }) =>
					<RoomProfileContainer
						room={ room }
						venues={ venues }
						rooms={ rooms }
						products={ products }
						discountCodes={ discountCodes }
						onUpdateBasicInformation={ updateRoom }
						onCreateTimeWindow={ addTimeWindowToWeekday }
						onCopyTimeWindow={ copyTimeRangeFromOtherWeekday }
						onCopyTimeWindowsFromOtherRoom={ copyTimeRangeFromOtherRoom }
						onEditTimeWindow={ editTimeWindow }
						onDeleteTimeWindow={ deleteTimeWindow }
					/>
			}
		</ForceFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	getRoom: (id: number) => getAdminRoomWithVenuesWithRoomsWithProductsWithDiscountCodes(state, id),
});

const mapDispatchToProps = {
	fetchRoomById: fetchRoomByIdAsync.request,
	fetchVenues: fetchAdminVenuesAsync.request,
	fetchAdminRooms: fetchAdminRoomsAsync.request,
	fetchDiscountCodes: fetchDiscountCodesAsync.request,
	fetchProducts: fetchProductsAsync.request,
	updateRoom: uiUpdateRoom,
	copyTimeRangeFromOtherRoom: uiCopyTimeRangeFromOtherRoom,
	copyTimeRangeFromOtherWeekday: uiCopyTimeRangeFromOtherWeekday,
	addTimeWindowToWeekday: uiAddTimeWindowToWeekday,
	editTimeWindow: uiEditTimeWindow,
	deleteTimeWindow: uiDeleteTimeWindow,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomProfileView);
