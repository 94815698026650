import { RouteObject } from "react-router/dist/lib/context";
import DiscountCodeListView from "src/app/views/DiscountCode/DiscountCodeList.view";
import DiscountCodeProfileView from "src/app/views/DiscountCode/DiscountCodeProfile.view";
import DiscountCodeCreateView from "src/app/views/DiscountCode/DiscountCodeCreate.view";

const discountCodeRoutes: RouteObject = {
	path: "discount-codes",
	children: [
		{
			index: true,
			element: <DiscountCodeListView/>,
		}, {
			path: "create",
			element: <DiscountCodeCreateView/>,
		}, {
			path: ":discountCodeId",
			element: <DiscountCodeProfileView/>,
		}
	],
};

export default discountCodeRoutes;
