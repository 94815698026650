import { Nullable } from "src/app/types/util.types";
import { Form, FormItem, FormItemError, FormValidator } from "src/app/types/ui/form.types";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import moment from "moment";

export const createFormField = <T>(value: T, { disabled = false, optional = false, success = false } = {}): FormItem<T> =>
	({
		value: value,
		initialValue: value,
		touched: false,
		error: null,
		success: success,
		disabled: disabled,
		optional: optional,
	});

export const validateForm = <T>(form: Form<T>, validator: FormValidator<T>) => {
	for (const prop in form) {
		const item = form[ prop ];
		if (!item.disabled) {
			const error = validator[ prop ](item.value, item.optional, form);
			form[ prop ] = {
				...form[ prop ],
				error: error,
				success: error === null,
			};
		}
	}
	return { ...form };
};

export const isFormValid = <T>(form: Form<T>) => {
	for (const prop in form) {
		if (form[ prop ].error) {
			return false;
		}
	}
	return true;
};

export const getAllFormValues = <T>(form: Form<T>): T => {
	const values: T = {} as T;
	for (const prop in form) {
		values[ prop ] = form[ prop ].value;
	}
	return values;
};

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const phoneRegex = /^\+[1-9]\d{6,14}$/;

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 150;

export const validateField = (fieldName: string, value: string, optional = false): FormItemError => {
	if (isEmptyString(value.trim()) && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}
	return null;
};

export const validateNullableField = (fieldName: string, value: Nullable<any>, optional = false): FormItemError => {
	if (isNull(value) && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}

	return null;
};

export const validateJSON = (fieldName: string, value: string, optional = false): FormItemError => {
	if (isEmptyString(value.trim()) && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}

	try {
		JSON.parse(value);
	} catch (e) {
		if (!isEmptyString(value.trim())) {
			return `${ fieldName } ma nie poprawną strukturę`;
		}
	}

	return null;
};

export const validateNumberField = (fieldName: string, value: number | string, optional = false, from = 0, to?: number): FormItemError => {
	const numberValue = +value;
	if ((isNull(value) || isEmptyString(value)) && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}
	if (isNaN(numberValue)) {
		return `${ fieldName } jest niepoprawny`;
	}
	if (numberValue < from) {
		return `${ fieldName } musi być większe lub równe niż ${ from }`;
	}
	if (isNotNull(to) && numberValue > to) {
		return `${ fieldName } musi być mniejsze lub równe niż ${ to }`;
	}
	return null;
};

export const validateDate = (fieldName: string, value: Nullable<Date>, optional = false): FormItemError => {
	if (isNull(value) && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}

	if (!moment(value).isValid()) {
		return `${ fieldName } jest niepoprawna`;
	}

	return null;
};

export const validateFutureDate = (fieldName: string, value: Nullable<Date>, optional = false): FormItemError => {
	if (isNull(value) && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}

	if (!moment(value).isValid()) {
		return `${ fieldName } jest niepoprawna`;
	}

	if (moment(value).isBefore(moment().add(1, "minute"), "minute")) {
		return `${ fieldName } jest przeszła`;
	}

	return null;
};

export const validateArrayField = (fieldName: string, array: any[], optional = false, length = 0): FormItemError => {
	if (array.length === 0 && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}
	if (array.length < length && !optional) {
		return `${ fieldName } musi się składać z min ${ length } element${ length > 1 ? "ów" : "u" }`;
	}
	return null;
};

export const validateEmail = (email: string, optional = false): FormItemError => {
	if (!isEmptyString(email.trim()) && !emailRegex.test(email)) {
		return "Podaj poprawny adres mailowy";
	}
	if (isEmptyString(email.trim()) && !optional) {
		return "Uzupełnij email";
	}
	return null;
};

export const validatePhone = (phone: string, optional = false): FormItemError => {
	if (!isEmptyString(phone.trim()) && !phoneRegex.test(phone)) {
		return "Podaj poprawny nr telefonu";
	}
	if (isEmptyString(phone.trim()) && !optional) {
		return `Uzupełnij nr telefonu`;
	}

	const PHONE_MIN_LENGTH = 5;
	const PHONE_MAX_LENGTH = 20;

	if (!isEmptyString(phone) && phone.length < PHONE_MIN_LENGTH) {
		return `Nr telefonu musi składać się z minimum ${ PHONE_MIN_LENGTH } cyfr`;
	}
	if (!isEmptyString(phone) && phone.length > PHONE_MAX_LENGTH) {
		return `Nr telefonu może mieć maksymalnie ${ PHONE_MAX_LENGTH } cyfr`;
	}

	return null;
};

export const validatePassword = (password: string, optional = false): FormItemError => {
	if (!isEmptyString(password) && password.length < PASSWORD_MIN_LENGTH) {
		return `Hasło musi się składać z minimum ${ PASSWORD_MIN_LENGTH } znaków`;
	}
	if (!isEmptyString(password) && password.length > PASSWORD_MAX_LENGTH) {
		return `Hasło może mieć maksymalnie ${ PASSWORD_MAX_LENGTH } znaków`;
	}
	return validateField("Hasło", password, optional);
};

export const validateStrongPassword = (password: string, optional = false): FormItemError => {
	const errors: string[] = [];
	if (!isEmptyString(password) && password.length < PASSWORD_MIN_LENGTH) {
		errors.push(`Hasło musi się składać z mimimum ${ PASSWORD_MIN_LENGTH } znaków`);
	}
	if (!isEmptyString(password) && password.trim().length < PASSWORD_MIN_LENGTH) {
		errors.push(`Niepoprawna struktura hasła`);
	}
	if (!isEmptyString(password) && password.length > PASSWORD_MAX_LENGTH) {
		errors.push(`Hasło może mieć maksymalnie ${ PASSWORD_MAX_LENGTH } znaków`);
	}
	if (!isEmptyString(password.trim()) && !/[A-Z]/.test(password)) {
		errors.push("Hasło musi się składać z jednej wielkiej litery");
	}
	if (!isEmptyString(password.trim()) && !/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password)) {
		errors.push("Hasło musi się składać z jednego specjalnego znaku");
	}
	if (!isEmptyString(password.trim()) && !/\d/.test(password)) {
		errors.push("Hasło musi się składać z minimum jednej cyfry");
	}

	if (errors.length > 1) {
		return errors;
	} else if (errors.length === 1) {
		return errors[ 0 ];
	}

	return validateField("Hasło", password, optional);
};

export const comparePasswords = (password: string, passwordConfirmed: string): FormItemError => {
	if (password !== passwordConfirmed) {
		return "Hasła nie są takie same";
	}
	return null;
};

enum PhotoFormats {
	JPEG = "image/jpeg",
	PNG = "image/png"
}

export const validatePhoto = (fieldName: string, file: Nullable<File>, optional = false, maxSizeInMegaBytes = 10): FormItemError => {
	if (isNull(file) && !optional) {
		return `Uzupełnij ${ fieldName }`;
	}

	if (isNotNull(file) && !Object.values<string>(PhotoFormats).includes(file.type)) {
		return "Niepoprawny format pliku";
	}

	if (isNotNull(file) && file.size > maxSizeInMegaBytes * 1024 * 1024) {
		return `${ fieldName } może mieć maksymalnie ${ maxSizeInMegaBytes } MB`;
	}

	return null;
};

export const compareStrings = (fieldName: string, firstString: string, secondString: string, ignoreCase = true): FormItemError => {
	if ((ignoreCase && firstString !== secondString) || (!ignoreCase && firstString.toLowerCase() !== secondString.toLowerCase())) {
		return `Niepoprawna ${ fieldName.toLowerCase() }`;
	}

	return null;
};
