/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Image, Nullable, WeekDay } from "src/app/types/util.types";
import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";
import { Venue } from "src/app/types/api/venue.types";

export enum RoomAbility {
	VIEW = "room.view",
	UPDATE = "room.update",
	DELETE = "room.delete",
}

export type SimpleRoom = {
	id: number
	name: string
	description: Nullable<string>
	maxPeople: number

	image: Nullable<Image>

	venueId: number
	organizationId: string
	organization: Nullable<SimpleOrganization>

	timeRanges: RoomTimeRange[]

	createdAt: string
	updatedAt: string
	meta: {
		abilities: RoomAbility[]
	}
}

export type Room =
	SimpleRoom
	& {
		venue: Nullable<Venue>
	}

export type DetailedRoom =
	Room
	& {
		organization: Organization
	};

export type RoomTimeRange = { // RoomTimeRangeViewModel::standardResource
	id: number
	weekday: WeekDay
	startHour: string
	endHour: string
	price: number
	createdAt: string
	updatedAt: string
}

export type CreateRoomPayload = {
	venueId: number
	name: string
	description?: string
	location: string
	maxPeople: number
	avatar: Nullable<File>
}

export type UpdateRoomPayload = {
	id: number
	venueId?: number
	name?: string
	description?: string
	location?: string
	maxPeople?: number
	avatar?: Nullable<File>
}

export type UpdateRoomTimeRangesPayload = {
	id: number
	timeRangeId?: number // loading record
	weekDay?: WeekDay // loading record
	timeRanges: {
		weekday: WeekDay
		startHour: string
		endHour: string
		price: number
	}[]
}

export type CopyTimeRangesFromOtherRoomPayload = {
	id: number
	roomToCopyId: number
}
