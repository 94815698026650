import { FormItem } from "src/app/types/ui/form.types";
import { Label, LabelProps } from "flowbite-react";
import Input, { PhoneInputProps } from "react-phone-input-2";
import classNames from "classnames";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";

type Props = {
	className?: string
	formItem: FormItem<string>
	onChange: (phone: string, formattedPhone: string) => void
	onBlur: () => void
	name: string // Friendly name for HTML Semantic
	phoneInputProps?: PhoneInputProps
	labelProps?: LabelProps
	label?: string | ReactNode
	displayErrorMessage?: boolean
};

function PhoneInput(props: Props) {

	const {
		className,
		formItem,
		onChange,
		onBlur,
		name,
		labelProps,
		label,
		displayErrorMessage = true,
		phoneInputProps,
	} = props;

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-y-0.5",
					className,
				)
			}
		>
			{
				isNotNull(label) &&
                <Label
                    htmlFor={ name }
					{ ...labelProps }
                >
					{ label }
                </Label>
			}
			<Input
				{ ...phoneInputProps }
				inputProps={ {
					name: name,
				} }
				value={ formItem.value }
				specialLabel=""
				placeholder=""
				onChange={ (value, _1, _2, formattedValue) => {
					const phone = isEmptyString(value) ? "" : `+${ value }`;
					onChange(phone, formattedValue);
				} }
				onBlur={ onBlur }
				disabled={ formItem.disabled }
				inputClass={
					classNames(
						"block !w-full !h-[unset] border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500 !rounded-lg p-2.5 !text-sm !tracking-normal",
						{ "!bg-red-50 dark:!bg-red-100 !text-red-900": isNotNull(formItem.error) }, // Standard
						{ "[&:not(:focus)]:!border-red-500 dark:[&:not(:focus)]:!border-red-400": isNotNull(formItem.error) }, // Unfocused
						{ "focus:!ring-1 focus:!border-red-500 focus:!ring-red-500 focus:dark:!border-red-500 focus:dark:!ring-red-500": isNotNull(formItem.error) }, // Focused

					)
				}
				buttonClass={
					classNames(
						"!rounded-lg !border-none !m-[1px] !bg-transparent [&>div]:!bg-transparent [&>div]:!rounded-lg",
						{ "dark:[&_div.arrow]:!border-t-white dark:[&_div.arrow]:!border-b-white": isNull(formItem.error) },
						{ "[&_div.arrow]:!border-t-black [&_div.arrow]:!border-b-black": isNotNull(formItem.error) },
					)
				}
				dropdownClass="!max-h-[240px] !rounded-lg !bg-gray-50 dark:!bg-gray-700 !border !border-gray-300 dark:!border-gray-600 !drop-shadow-lg !shadow-none dark:[&>li:not(.highlight)]:!text-white dark:[&>li:not(.highlight)>span.dial-code]:!text-gray-400 hover:[&>li:not(.highlight)]:!bg-primary-100 hover:dark:[&>li:not(.highlight)]:!text-black dark:[&>li:not(.highlight):hover>span.dial-code]:!text-gray-500 [&>li.highlight]:!bg-primary-500 [&>li.highlight]:!text-white [&>li.highlight>span.dial-code]:!text-gray-700"
			/>
			{
				(isNotNull(formItem.error) && displayErrorMessage) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ formItem.error }
                </div>
			}
		</div>
	);
}

export default (PhoneInput);
