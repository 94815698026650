/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import PurchasingProcessProcessingContainer from "src/app/containers/PurchasingProcess/PurchasingProcessProcessing.container";

function PurchasingProcessProcessingView() {

	return (
		<PurchasingProcessProcessingContainer/>
	);
}

export default PurchasingProcessProcessingView;
