import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { debounceTime, filter, map, mergeMap, switchMap, take, withLatestFrom } from "rxjs/operators";
import { uiAuthMe, uiConfirmTwoFactorAuthentication, uiCreateUser, uiDeleteUser, uiDisableTwoFactorAuthentication, uiEnableTwoFactorAuthentication, uiFetchPaginatedUsers, uiFetchUserScopes, uiForgotPassword, uiLogin, uiResetPassword, uiScopeAuthMe, uiSetUserOrganizationScope, uiUpdateUser, uiUpdateUserPassword, uiUpdateUserStatus } from "src/app/store/features/ui/user/ui.user.actions";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType } from "src/app/types/ui/loading.types";
import { authMeAsync, confirmTwoFactorAuthenticationAsync, createUserAsync, deleteUserAsync, disableTwoFactorAuthenticationAsync, enableTwoFactorAuthenticationAsync, fetchPaginatedUsersAsync, fetchUserScopesAsync, forgetSession, forgotPasswordAsync, loginAsync, logoutAsync, resetPasswordAsync, scopeAuthMeAsync, updateUserAsync, updateUserPasswordAsync, updateUserStatusAsync } from "src/app/store/features/user/user.actions";
import { addMessage, displayToast, removeMessagesFromView } from "src/app/store/features/message/message.actions";
import { SnackbarMessageType, ToastType, View } from "src/app/types/ui/message.types";
import { push } from "redux-first-history";
import { getDefaultView } from "src/app/utils/constants/constants";
import { ErrorCodeName } from "src/app/types/api/api.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import moment from "moment";
import { loginFormActions } from "src/app/store/features/form/form.actions";
import { authLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.reducer";
import { DataState, LoadingState } from "src/app/types/redux.types";
import { getBoundaryPageIndexes, isDifferentPaginationOptions } from "src/app/utils/helpers";
import { empty } from "src/app/store/features/misc/misc.actions";
import { Nullable } from "src/app/types/util.types";
import { matchPath } from "react-router-dom";

export const uiLoginEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiLogin)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.LOGIN })),
				of(addLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
				of(loginAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(loginAsync.success, action) || isActionOf(loginAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(loginAsync.success, responseAction)) {
							return merge(
								of(loginFormActions.resetForm()),
								of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
								of(uiFetchUserScopes()),
							);
						} else if (isActionOf(loginAsync.failure, responseAction)) {
							if (responseAction.payload.errors.some(error => error.httpStatus === 428 && error.codeName === ErrorCodeName.UNAUTHENTICATED_MISSING_2FA_OTP)) {
								return merge(
									of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
									of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
									of(push("/panel/login/2fa")),
								);
							} else if (responseAction.payload.errors.some(error => error.httpStatus === 429 && error.codeName === ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS)) {
								const error = responseAction.payload.errors.find(error => error.httpStatus === 429 && error.codeName === ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS);
								if (isNull(error)) {
									return merge(
										of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
										of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
									);
								}

								const date = moment().add(error.availableInSeconds, "s").format("HH:mm:ss");
								return merge(
									of(addMessage({
										view: View.LOGIN,
										type: SnackbarMessageType.ERROR,
										message: `Przekroczyłeś maksymalna ilość prób logowania. Ze względów bezpieczeństwa musisz poczekać ${ error.availableInSeconds } sekund (${ date }) przed kolejną próbą`,
									})),
									of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
									of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
								);
							}
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
							);
						} else {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.LOGIN })),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
							);
						}
					}),
				),
			),
		),
	);

export const uiAuthMeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiAuthMe)),
		switchMap(_ =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
				of(authMeAsync.request()),
				action$.pipe(
					filter(action => isActionOf(authMeAsync.success, action) || isActionOf(authMeAsync.failure, action)),
					take(1),
					mergeMap(action => {
						if (isActionOf(authMeAsync.success, action)) {
							return merge(
								of(removeLoadingRecord(authLoadingRecord)),
								of(uiFetchUserScopes()),
							);
						} else {
							return merge(
								of(removeLoadingRecord(authLoadingRecord)),
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
								of(addMessage({ view: View.LOGIN, type: SnackbarMessageType.ERROR, message: "Twoja sesja wygasła" })),
								of(forgetSession()),
							);
						}
					}),
				),
			),
		),
	);

export const uiSetUserOrganizationScopeEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiSetUserOrganizationScope)),
		mergeMap(action => {
			if (action.payload.organization.id !== state$.value.user.userScope?.organization?.id) {
				return merge(
					of(uiScopeAuthMe()),
					of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie przełaczono do ${ action.payload.organization.name }` })),
				);
			}
			return of(uiScopeAuthMe());
		}),
	);

export const uiScopeAuthMeEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiScopeAuthMe)),
		switchMap(() => {
			return concat(
				of(scopeAuthMeAsync.request()),
				action$.pipe(
					filter(isActionOf(scopeAuthMeAsync.failure)),
					take(1),
					withLatestFrom(state$),
					mergeMap(([ _, state ]) => {
						if (isNull(state.user.userScope) || state.user.userOrganizationScopes.dataState === DataState.NOT_PRESENT) return of(forgetSession());
						const userScope = state.user.userScope;
						const organizationScope = state.user.userOrganizationScopes.data[ 0 ];
						if (isNull(organizationScope) || organizationScope.id === userScope.organization.id) return of(forgetSession());

						return of(uiSetUserOrganizationScope({ organization: organizationScope }));
					}),
				),
			);
		}),
	);

export const uiForgotPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiForgotPassword)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.FORGOT_PASSWORD })),
				of(removeMessagesFromView(View.FORGOT_PASSWORD)),
				of(forgotPasswordAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(forgotPasswordAsync.success, action) || isActionOf(forgotPasswordAsync.failure, action)),
					take(1),
					mergeMap(action => {
						if (isActionOf(forgotPasswordAsync.success, action)) {
							return merge(
								of(push("/panel/forgot-password/message")),
								of(removeLoadingRecord({ loadableType: LoadableType.FORGOT_PASSWORD })),
								of(addMessage({
									view: View.FORGOT_PASSWORD_MESSAGE,
									type: SnackbarMessageType.SUCCESS,
									message: "Wiadomość została wysłana na podany adres mailowy. Sprawdź swoją skrzynke i kliknij w link aby kontynuować",
								})),
							);
						} else if (isActionOf(forgotPasswordAsync.failure, action)) {
							if (action.payload.errors.some(error => error.httpStatus === 429 && error.codeName === ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS)) {
								const error = action.payload.errors.find(error => error.httpStatus === 429 && error.codeName === ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS);
								if (isNull(error)) return of(removeLoadingRecord({ loadableType: LoadableType.LOGIN }));

								const date = moment().add(error.availableInSeconds, "s").format("HH:mm:ss");
								return merge(
									of(addMessage({
										view: View.FORGOT_PASSWORD,
										type: SnackbarMessageType.ERROR,
										message: `Przekroczyłeś maksymalna ilość prób logowania. Ze względów bezpieczeństwa musisz poczekać ${ error.availableInSeconds } sekund (${ date }) przed kolejną próbą`,
									})),
									of(removeLoadingRecord({ loadableType: LoadableType.FORGOT_PASSWORD })),
								);
							}
						}
						return of(removeLoadingRecord({ loadableType: LoadableType.FORGOT_PASSWORD }));
					}),
				),
			),
		),
	);

export const uiResetPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiResetPassword)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.RESET_PASSWORD })),
				of(resetPasswordAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(resetPasswordAsync.success, action) || isActionOf(resetPasswordAsync.failure, action)),
					take(1),
					mergeMap(action => {
						if (isActionOf(resetPasswordAsync.success, action)) {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.RESET_PASSWORD })),
								of(addMessage({
									view: View.LOGIN,
									type: SnackbarMessageType.SUCCESS,
									message: "Gotowe! Możesz się zalogować przy pomocy swojego nowego hasła",
								})),
								of(push("/panel/login")),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.RESET_PASSWORD }));
						}
					}),
				),
			),
		),
	);

export const uiFetchUserScopesEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiFetchUserScopes)),
		mergeMap(_ =>
			concat(
				of(fetchUserScopesAsync.request()),
				action$.pipe(
					filter(action => isActionOf(fetchUserScopesAsync.success, action) || isActionOf(fetchUserScopesAsync.failure, action)),
					take(1),
					withLatestFrom(state$),
					mergeMap(([ action, state ]) => {
						if (isActionOf(fetchUserScopesAsync.success, action)) {
							let urlOrganizationId: Nullable<string>;
							let accessingUrl: Nullable<string>;
							if (isNotNull(state.router.location)) {
								const organizationMatch = matchPath("/panel/organization/:scopeOrganizationId/*", state.router.location.pathname);
								if (isNotNull(organizationMatch)) {
									urlOrganizationId = organizationMatch.params.scopeOrganizationId;

									accessingUrl = organizationMatch.pathname.replace(organizationMatch.pathnameBase, "");
								}
							}

							if (isNull(state.user.userScope) && state.user.loggedUser.dataState === DataState.PRESENT) { /* User doesn't have persisted scopes, find and set userScope */
								const loggedUser = state.user.loggedUser.data;
								const organizationScopes = action.payload.data;
								const organization = organizationScopes.find(organization => organization.id === loggedUser.organization.id) ?? organizationScopes[ 0 ];

								if (isNull(organization)) {
									return merge(
										of(forgetSession()),
										of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
										of(displayToast({ type: ToastType.WARNING, content: "Organizacja jest niedostępna" })),
									);
								}
								return merge(
									of(uiSetUserOrganizationScope({ organization })),
									of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
									of(push(`${ getDefaultView(organization.id) }${ state.router.location?.search ?? "" }`)),
								);
							} else if (isNotNull(state.user.userScope) && isNotNull(urlOrganizationId) && state.user.loggedUser.dataState === DataState.PRESENT) { /* Try to set organizationId from url */
								const userScope = state.user.userScope;
								const loggedUser = state.user.loggedUser.data;
								const organizationScopes = action.payload.data;
								const organization = organizationScopes.find(organization => organization.id === urlOrganizationId);

								if (isNull(organization)) { /* Org from url is no present from /scopes - replace it with accessible one */
									const accessibleScopeOrg = organizationScopes.find(organization => organization.id === userScope.organization.id) ?? organizationScopes.find(organization => organization.id === loggedUser.organization.id);
									if (isNull(accessibleScopeOrg)) { // (Url) AND (logged user) org doesnt exist in userScopes -> logout
										return merge(
											of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
											of(forgetSession()),
										);
									} else { // We got org so we can redirect user to this path with persist old path
										const urlPrefix = `/panel/organization/${ accessibleScopeOrg.id }`;
										return merge(
											of(push({
												pathname: isNotNull(accessingUrl) ? `${ urlPrefix }${ accessingUrl }` : getDefaultView(accessibleScopeOrg.id),
												search: state.router.location?.search,
											})),
											of(uiSetUserOrganizationScope({ organization: accessibleScopeOrg })),
											of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
											of(displayToast({ type: ToastType.WARNING, content: "Organizacja jest niedostępna" })),
										);
									}
								}

								return merge(
									of(uiSetUserOrganizationScope({ organization })),
									of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
								);
							}
							return of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES }));
						} else {
							return merge(
								of(removeLoadingRecord({ loadableType: LoadableType.FETCH_USER_SCOPES })),
								of(forgetSession()),
							);
						}
					}),
				),
			),
		),
	);

export const uiLogoutSuccessEpic: RootEpic = action$ =>
	action$.pipe(
		filter(action => isActionOf(logoutAsync.success, action) || isActionOf(forgetSession, action)),
		map(_ => {
			// abortController.abort();
			return empty();
		}),
	);

export const uiFetchPaginatedUsersEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiFetchPaginatedUsers)),
		debounceTime(300),
		switchMap(action => {
			// Check if page exist or already loading ->
			// if no = proceed
			// if yes = fetch boundary pages
			const paginatedCasesReducer = state$.value.user.paginatedUsers;

			const isRequestingPageLoadedOrLoading = paginatedCasesReducer.pages.some(page =>
				(
					page.pageIndex === action.payload.pageIndex &&
					(
						page.data.dataState === DataState.PRESENT ||
						(
							page.data.dataState === DataState.NOT_PRESENT &&
							page.data.loadingState === LoadingState.LOADING
						)
					)
				),
			);

			if (
				isRequestingPageLoadedOrLoading &&
				!isDifferentPaginationOptions(
					{ actualPageSize: paginatedCasesReducer.meta.actualPageSize, newPageSize: action.payload.pageSize },
					{ actualSearch: paginatedCasesReducer.meta.actualSearch, newSearch: action.payload.search },
					{ actualSort: paginatedCasesReducer.meta.actualSort, newSort: action.payload.sort },
					{ actualFilters: paginatedCasesReducer.meta.actualFilters, newFilters: action.payload.filters },
				)
			) {
				const maxPageIndex = Math.floor(paginatedCasesReducer.meta.totalCount / action.payload.pageSize);
				const fetchBoundaryPagesRequests =
					getBoundaryPageIndexes(paginatedCasesReducer.pages, action.payload.pageIndex, { maxPageIndex })
						.map(pageIndex =>
							of(fetchPaginatedUsersAsync.request({
								...action.payload,
								pageIndex,
								isBoundaryPage: true,
							})),
						);
				return merge(
					...fetchBoundaryPagesRequests,
				);
			}

			return concat(
				of(fetchPaginatedUsersAsync.request(action.payload)),
				action$.pipe(
					filter(responseAction =>
						(
							isActionOf(fetchPaginatedUsersAsync.success, responseAction) &&
							!isDifferentPaginationOptions(
								{ actualPageSize: action.payload.pageSize, newPageSize: responseAction.payload.id.pageSize },
								{ actualSearch: action.payload.search, newSearch: responseAction.payload.id.search },
								{ actualSort: action.payload.sort, newSort: responseAction.payload.id.sort },
								{ actualFilters: action.payload.filters, newFilters: responseAction.payload.id.filters },
							)
						),
					),
					withLatestFrom(state$),
					mergeMap(([ responseAction, state ]) => {
						if (isActionOf(fetchPaginatedUsersAsync.success, responseAction)) {
							// if fetched page is actual page from reducer meta (with isDiffPagOptions)
							// calculate boundaries of fetched page
							// check if that boundaries are DataState.PRESENT and LoadingState.NOT_LOADING
							// fetch unfetched pages
							const paginatedCasesReducer = state.user.paginatedUsers;
							if (
								!isDifferentPaginationOptions(
									{ actualPageSize: paginatedCasesReducer.meta.actualPageSize, newPageSize: responseAction.payload.id.pageSize },
									{ actualSearch: paginatedCasesReducer.meta.actualSearch, newSearch: responseAction.payload.id.search },
									{ actualSort: paginatedCasesReducer.meta.actualSort, newSort: responseAction.payload.id.sort },
									{ actualFilters: paginatedCasesReducer.meta.actualFilters, newFilters: responseAction.payload.id.filters },
								) &&
								paginatedCasesReducer.meta.actualPageIndex === responseAction.payload.id.pageIndex
							) {
								const maxPageIndex = Math.floor((responseAction.payload.meta?.totalCount ?? responseAction.payload.data.length) / responseAction.payload.id.pageSize);
								const fetchBoundaryPagesRequests =
									getBoundaryPageIndexes(paginatedCasesReducer.pages, responseAction.payload.id.pageIndex, { maxPageIndex })
										.map(pageIndex =>
											of(fetchPaginatedUsersAsync.request({
												...action.payload,
												pageIndex,
												isBoundaryPage: true,
											})),
										);

								return merge(
									...fetchBoundaryPagesRequests,
								);
							}
						}

						return of(empty());
					}),
				),
			);
		}),
	);

/*export const uiHandleDeleteCaseFromPaginationEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(deleteUserAsync.success)),
		mergeMap(() => {
			const paginatedCasesReducer = state$.value.user.paginatedUsers;

			const actualPageIndex = paginatedCasesReducer.meta.actualPageIndex;
			const maxPageIndex = Math.floor(paginatedCasesReducer.meta.totalCount / paginatedCasesReducer.meta.actualPageSize);

			const fetchPageRequestPayload: FetchUsersRequestPayload = {
				pageIndex: actualPageIndex,
				pageSize: paginatedCasesReducer.meta.actualPageSize,
				sort: paginatedCasesReducer.meta.actualSort,
				isBoundaryPage: false,
				filters: paginatedCasesReducer.meta.actualFilters,
				search: paginatedCasesReducer.meta.actualSearch
			};

			const fetchBoundaryPageRequests =
				getBoundaryPageIndexes(paginatedCasesReducer.pages, actualPageIndex, { maxPageIndex })
					.filter(pageIndex => pageIndex > actualPageIndex)
					.map(pageIndex =>
						of(fetchPaginatedUsersAsync.request({
							...fetchPageRequestPayload,
							pageIndex,
							isBoundaryPage: true
						}))
					);

			return merge(
				of(fetchPaginatedUsersAsync.request({
					...fetchPageRequestPayload,
					pageIndex: actualPageIndex,
					isBoundaryPage: false
				})),
				...fetchBoundaryPageRequests
			);
		})
	);*/

export const uiCreateUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateUser)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableType: LoadableType.CREATE_USER })),
				of(createUserAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createUserAsync.success, action) || isActionOf(createUserAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createUserAsync.success, responseAction)) {
							return merge(
								of(push(`/panel/organization/${ responseAction.payload.data.organization.id }/users`)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie utworzono użytkownika!" })),
								of(removeLoadingRecord({ loadableType: LoadableType.CREATE_USER })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.CREATE_USER }));
						}
					}),
				),
			),
		),
	);

export const uiUpdateUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateUser)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER })),
				of(updateUserAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateUserAsync.success, action) || isActionOf(updateUserAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateUserAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano użytkownika!" })),
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER }));
						}
					}),
				),
			),
		),
	);

export const uiUpdateUserStatusEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateUserStatus)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER_STATUS })),
				of(updateUserStatusAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateUserStatusAsync.success, action) || isActionOf(updateUserStatusAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateUserStatusAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano status użytkownika!" })),
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER_STATUS })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER_STATUS }));
						}
					}),
				),
			),
		),
	);

export const uiUpdateUserPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateUserPassword)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER_PASSWORD })),
				of(updateUserPasswordAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateUserPasswordAsync.success, action) || isActionOf(updateUserPasswordAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateUserPasswordAsync.success, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.SUCCESS, content: "Hasło zostało zmienione!" })),
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER_PASSWORD })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.UPDATE_USER_PASSWORD }));
						}
					}),
				),
			),
		),
	);

export const uiDeleteUserEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteUser)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.DELETE_USER })),
				of(deleteUserAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteUserAsync.success, action) || isActionOf(deleteUserAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteUserAsync.success, responseAction)) {
							return merge(
								of(push("/panel/users")),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto użytkownika!" })),
								of(removeLoadingRecord({ loadableType: LoadableType.DELETE_USER, loadableId: action.payload })),
							);
						} else {
							return of(removeLoadingRecord({ loadableType: LoadableType.DELETE_USER, loadableId: action.payload }));
						}
					}),
				),
			),
		),
	);

export const uiEnableTwoFactorAuthenticationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiEnableTwoFactorAuthentication)),
		mergeMap(action =>
			merge(
				of(addLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.DISPLAY_USER_TWO_FA_MODAL })),
				of(enableTwoFactorAuthenticationAsync.request(action.payload)),
			),
		),
	);

export const uiConfirmTwoFactorAuthenticationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiConfirmTwoFactorAuthentication)),
		switchMap(action =>
			concat(
				of(removeMessagesFromView(View.TWO_FACTOR_AUTHENTICATION_MODAL)),
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.CONFIRM_USER_TWO_FA })),
				of(confirmTwoFactorAuthenticationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(confirmTwoFactorAuthenticationAsync.success, action) || isActionOf(confirmTwoFactorAuthenticationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(confirmTwoFactorAuthenticationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.DISPLAY_USER_TWO_FA_MODAL })),
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.CONFIRM_USER_TWO_FA })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie ustawiono weryfikację dwuskładnikową!" })),
							);
						} else {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.CONFIRM_USER_TWO_FA })),
								of(addMessage({
									view: View.TWO_FACTOR_AUTHENTICATION_MODAL,
									type: SnackbarMessageType.ERROR,
									message: "Kod weryfikacyjny jest niepoprawny",
								})),
							);
						}
					}),
				),
			),
		),
	);

export const uiDisableTwoFactorAuthenticationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDisableTwoFactorAuthentication)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.DISABLE_USER_TWO_FA })),
				of(disableTwoFactorAuthenticationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(disableTwoFactorAuthenticationAsync.success, action) || isActionOf(disableTwoFactorAuthenticationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(disableTwoFactorAuthenticationAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.DISABLE_USER_TWO_FA })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie wyłączono weryfikacje dwuskładnikową!" })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload, loadableType: LoadableType.DISABLE_USER_TWO_FA }));
						}
					}),
				),
			),
		),
	);
