/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useEffect, useRef } from "react";
import { ReservationStatus } from "src/app/types/api/reservation.types";
import ReservationStatusBadge from "src/app/components/Room/Reservation/ReservationStatusBadge.component";
import useMousePosition from "src/app/utils/hooks/useMousePosition";
import { isNull } from "src/app/utils/typeguards";

type Props = {
	toggleOpen: (isOpen: boolean) => void
	onChange: (status: ReservationStatus) => void
}

function ChooseReservationStatusInner(props: Props) {

	const {
		toggleOpen,
		onChange,
	} = props;

	const mousePosition = useMousePosition();

	const mouseOutHandlerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (
			isNull(mouseOutHandlerRef.current) ||
			isNull(mousePosition.x) ||
			isNull(mousePosition.y)
		) return;
		const mouseOutHandlerX = mouseOutHandlerRef.current.getBoundingClientRect().x;
		const mouseOutHandlerHeight = mouseOutHandlerRef.current.getBoundingClientRect().height;
		const mouseOutHandlerY = mouseOutHandlerRef.current.getBoundingClientRect().y;
		const mouseOutHandlerWidth = mouseOutHandlerRef.current.getBoundingClientRect().width;
		if (
			mouseOutHandlerX > mousePosition.x ||
			mouseOutHandlerY > mousePosition.y ||
			mouseOutHandlerX + mouseOutHandlerWidth < mousePosition.x ||
			mouseOutHandlerY + mouseOutHandlerHeight < mousePosition.y
		) toggleOpen(false);
	}, [ mousePosition.x, mousePosition.y ]);


	return (
		<div className="flex flex-col gap-2 absolute cursor-default left-0 top-[24px] p-2 bg-white border border-gray-400 rounded z-40">
			{/* MouseOutHandler */}
			<div
				ref={ mouseOutHandlerRef }
				className="absolute top-[-24px] left-0 w-full h-[calc(100%+24px)] bg-transparent"
			></div>
			{
				Object.values(ReservationStatus).map(status =>
					<ReservationStatusBadge
						key={ status }
						status={ status }
						mode="normal"
						className="cursor-pointer"
						onClick={ () => {
							onChange(status);
							toggleOpen(false);
						} }
					/>
				)
			}
		</div>
	);
}

export default ChooseReservationStatusInner;