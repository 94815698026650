/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { CSSProperties } from "react";
import classNames from "classnames";
import moment from "moment";
import { Button } from "flowbite-react";
import { AdminAvailability, Reservation } from "src/app/types/api/reservation.types";
import { Nullable } from "src/app/types/util.types";

type Props = {
	availability: AdminAvailability
	onEditClick: (reservationId: Nullable<number>) => void
	onReserveClick: (availability: AdminAvailability) => void
	currentDateReservation: Nullable<Reservation>
	style?: CSSProperties
	color?: string
}

function AdminSingleAvailability(props: Props) {

	const {
		availability,
		onEditClick,
		onReserveClick,
		currentDateReservation,
		style,
		color,
	} = props;

	return (
		<div
			id={ `${ availability.startTime }-${ availability.endTime }` }
			className={ classNames(
				"flex flex-col min-w-max bg-opacity-90 gap-2 py-8 items-center justify-center rounded-2xl border-[1px] transition-all duration-200 px-4 overflow-hidden relative",
				{ "border-myPrimary-orange-500": !availability.isReserved },
				{ "bg-myPrimary-gray-100": availability.isReserved },
			) }
			style={ {
				...style,
				backgroundColor: color,
			} }
		>
			<span className="text-xs text-mySecondary-purple-500">
				{ `${ moment(availability.startTime).format("HH:mm") } - ${ moment(availability.endTime).format("HH:mm") }` }
			</span>
			<span className="text-xl font-extrabold transition-all duration-200">
				{ `${ availability.price } zł` }
			</span>
			{
				availability.isReserved ?
					<Button
						className="text-myPrimary-orange-500 !transition-all !duration-200"
						onClick={ () => onEditClick(currentDateReservation?.id ?? null) }
						color="purple-full"
						size="xs"
					>
						Edytuj
					</Button>
					:
					<Button
						className="text-myPrimary-orange-500 !transition-all !duration-200"
						onClick={ () => onReserveClick(availability) }
						color="orange-full"
						size="xs"
					>
						Rezerwuj
					</Button>
			}
		</div>
	);
}

export default AdminSingleAvailability;
