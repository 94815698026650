import { FunctionComponent, SVGProps } from "react";

export type Nullable<T> =
	T
	| null
	| undefined;

export type EnumDictionary<T extends string | symbol | number, U> = {
	[K in T]: U;
};

export type SVG = FunctionComponent<SVGProps<SVGSVGElement>>

export type ArrayElement<ArrayType extends readonly unknown[]> =
	ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type Enum = any;

export type SidebarItem =
	{
		icon: SVG
		title: string
		hidden?: boolean
		path?: string
		childrens?: {
			icon?: SVG
			title: string
			path: string
			hidden?: boolean
		}[]
	};

export type Image = {
	full: string // 500x500
	thumb: string // 200x200
	icon: string // 40x40
}

export type SelectOption<T> = {
	value: T
	label: string
	disabled?: boolean
};

export type StringsOnly<T> = T extends string ? T : never;

export type UseParamsPayload = {
	userId?: string
	companyId?: string
	venueId?: string
	organizationId?: string
	roomId?: string
	productId?: string
	discountCodeId?: string
	reservationId?: string
	categoryId?: string
	// Breadcrumbs type plop - don't remove
}

export type ModalConfig<T> = {
	isOpen: boolean
	value?: Nullable<T>
}


export type Address = {
	street?: Nullable<string>
	houseNumber?: Nullable<string>
	flatNumber?: Nullable<string>
	postCode?: Nullable<string>
	city?: Nullable<string>
	country?: Nullable<string>
}

export type TimeWindowType = {
	startTime: string
	endTime: string
	price: number
	isReserved: boolean
}

export enum DayTime {
	MORNING = "MORNING",
	AFTERNOON = "AFTERNOON",
	EVENING = "EVENING",
}

export enum WeekDay {
	MONDAY = "MONDAY",
	TUESDAY = "TUESDAY",
	WEDNESDAY = "WEDNESDAY",
	THURSDAY = "THURSDAY",
	FRIDAY = "FRIDAY",
	SATURDAY = "SATURDAY",
	SUNDAY = "SUNDAY",
}

export enum DayHour {
	ONE = "1:00",
	TWO = "2:00",
	THREE = "3:00",
	FOUR = "4:00",
	FIVE = "5:00",
	SIX = "6:00",
	SEVEN = "7:00",
	EIGHT = "8:00",
	NINE = "9:00",
	TEN = "10:00",
	ELEVEN = "11:00",
	TWELVE = "12:00",
	THIRTEEN = "13:00",
	FOURTEEN = "14:00",
	FIFTEEN = "15:00",
	SIXTEEN = "16:00",
	SEVENTEEN = "17:00",
	EIGHTEEN = "18:00",
	NINETEEN = "19:00",
	TWENTY = "20:00",
	TWENTY_ONE = "21:00",
	TWENTY_TWO = "22:00",
	TWENTY_THREE = "23:00",
	TWENTY_FOUR = "24:00",
}

export type SortCompare<T> = {
	data: T
}

export enum PaymentMethod {
	CASH = "CASH",
	TRANSFER = "TRANSFER",
}
