import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { PurchasingProcessOutletContext } from "src/app/hoc/layouts/PurchasingProcess.layout";
import { useEffect } from "react";
import { PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { purchasingProcessFormStepRouteDictionary } from "src/app/utils/constants/dictionaries";
import PurchasingProcessChooseAvailabilitiesContainer from "src/app/containers/PurchasingProcess/PurchasingProcessChooseAvailabilities.container";

function ChooseAvailabilitiesView() {

	const {
		form,
		fetchAvailabilities,
		venues,
		isAtLeastOneAvailabilityChosen,
	} = useOutletContext<PurchasingProcessOutletContext>();

	const {
		form: {
			venue,
		},
		handleChange,
	} = form;

	const { venueId } = useParams();

	const routerLocation = useLocation();

	useEffect(() => {
		const paramVenueId = isNotNull(venueId) ? Number(venueId) : null;
		if (isNull(venue.value) && isNotNull(paramVenueId)) {
			const localVenue = venues.find(venue => venue.id === paramVenueId);
			if (isNull(localVenue)) return;
			handleChange("venue", localVenue);
		}
		if (
			isNull(venue.value) ||
			routerLocation.pathname !== purchasingProcessFormStepRouteDictionary(venue.value.id)[ PurchasingProcessFormStep.AVAILABILITIES ]
		) return;
		handleChange("step", PurchasingProcessFormStep.AVAILABILITIES);
		handleChange("hasConfirmAgeInformationModal", false);
	}, []);

	return (
		<PurchasingProcessChooseAvailabilitiesContainer
			form={ form }
			fetchAvailabilities={ fetchAvailabilities }
			venues={ venues }
			isAtLeastOneAvailabilityChosen={ isAtLeastOneAvailabilityChosen }
		/>
	);
}

export default ChooseAvailabilitiesView;
