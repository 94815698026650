/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CopyTimeRangesFromOtherRoomPayload, CreateRoomPayload, UpdateRoomPayload, UpdateRoomTimeRangesPayload } from "src/app/types/api/room.types";

export const uiCreateRoom = createAction("UI_ROOM__CREATE_ROOM")<CreateRoomPayload>();
export const uiUpdateRoom = createAction("UI_ROOM__UPDATE_ROOM")<UpdateRoomPayload>();
export const uiCopyTimeRangeFromOtherRoom = createAction("UI_ROOM__COPY_TIME_RANGE_FROM_OTHER_ROOM")<CopyTimeRangesFromOtherRoomPayload>();
export const uiCopyTimeRangeFromOtherWeekday = createAction("UI_ROOM__COPY_TIME_RANGE_FROM_OTHER_WEEKDAY")<UpdateRoomTimeRangesPayload>();
export const uiAddTimeWindowToWeekday = createAction("UI_ROOM__ADD_TIME_WINDOW_TO_WEEKDAY")<UpdateRoomTimeRangesPayload>();
export const uiEditTimeWindow = createAction("UI_ROOM__EDIT_TIME_WINDOW")<UpdateRoomTimeRangesPayload>();
export const uiDeleteTimeWindow = createAction("UI_ROOM__DELETE_TIME_WINDOW")<UpdateRoomTimeRangesPayload>();
export const uiDeleteRoom = createAction("UI_ROOM__DELETE_ROOM")<number>();
