import { RouteObject } from "react-router/dist/lib/context";
import { ReservationRoute } from "src/app/types/api/reservation.types";
import PurchasingProcessBreadcrumbsLayout from "src/app/hoc/layouts/PurchasingProcessBreadcrumbs.layout";
import ChooseAvailabilitiesView from "src/app/views/PurchasingProcess/PurchasingProcessChooseAvailabilities.view";
import AdditionsView from "src/app/views/PurchasingProcess/PurchasingProcessAdditions.view";
import PaymentView from "src/app/views/PurchasingProcess/PurchasingProcessPayment.view";

const purchasingProcessRoutes: RouteObject[] = [
	{
		path: ReservationRoute.AVAILABILITIES,
		element: <ChooseAvailabilitiesView/>,
	},
	{
		element: <PurchasingProcessBreadcrumbsLayout/>,
		children: [
			{
				path: ReservationRoute.ADDITIONS,
				element: <AdditionsView/>,
			},
			{
				path: ReservationRoute.PAYMENT,
				element: <PaymentView/>,
			},
		],
	},
];

export default purchasingProcessRoutes;
