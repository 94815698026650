/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { AdminAvailability, DetailedReservation, Reservation } from "src/app/types/api/reservation.types";
import { calculateAdminRoomReservationPriceAsync, calculateRoomReservationPriceAsync, createAdminReservationAsync, createReservationAsync, deleteReservationByIdAsync, fetchAdminReservationsByDateAsync, fetchAdminRoomAvailabilitiesAsync, fetchReservationByIdAsync, fetchReservationsAsync, fetchRoomAvailabilitiesAsync, generateReservationInvoiceAsync, sendReservationInvoiceAsync, updateReservationAsync, updateReservationStatusAsync } from "src/app/store/features/reservation/reservation.actions";

export const fetchReservationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchReservationsAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/admin/reservations`,
				method: "GET",
				withScope: true,
				onSuccess: fetchReservationsAsync.success,
				onFailure: fetchReservationsAsync.failure,
			}),
		),
	);

export const fetchReservationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchReservationByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedReservation>) => fetchReservationByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchReservationByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const fetchAdminReservationByDateEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchAdminReservationsByDateAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations?filter[date]=${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<Reservation[]>) => fetchAdminReservationsByDateAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchAdminReservationsByDateAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const fetchRoomAvailabilitiesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchRoomAvailabilitiesAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/rooms/${ action.payload.roomId }/availabilities/${ action.payload.date }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<AdminAvailability[]>) => fetchRoomAvailabilitiesAsync.success({ ...payload, id: `${ action.payload.roomId }-${ action.payload.date }` }),
				onFailure: (payload: FailurePayload) => fetchRoomAvailabilitiesAsync.failure({ ...payload, id: `${ action.payload.roomId }-${ action.payload.date }` }),
			}),
		),
	);

export const fetchAdminRoomAvailabilitiesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchAdminRoomAvailabilitiesAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/rooms/${ action.payload.roomId }/availabilities/${ action.payload.date }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<AdminAvailability[]>) => fetchAdminRoomAvailabilitiesAsync.success({ ...payload, id: `${ action.payload.roomId }-${ action.payload.date }` }),
				onFailure: (payload: FailurePayload) => fetchAdminRoomAvailabilitiesAsync.failure({ ...payload, id: `${ action.payload.roomId }-${ action.payload.date }` }),
			}),
		),
	);

export const calculateAdminRoomReservationPriceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(calculateAdminRoomReservationPriceAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations/calculate-price`,
				method: "POST",
				withScope: true,
				data: action.payload,
				onSuccess: calculateAdminRoomReservationPriceAsync.success,
				onFailure: calculateAdminRoomReservationPriceAsync.failure,
			}),
		),
	);

export const calculateRoomReservationPriceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(calculateRoomReservationPriceAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/reservations/calculate-price`,
				method: "POST",
				withScope: true,
				data: action.payload,
				onSuccess: calculateRoomReservationPriceAsync.success,
				onFailure: calculateRoomReservationPriceAsync.failure,
			}),
		),
	);

export const createReservationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createReservationAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/reservations",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createReservationAsync.success,
				onFailure: createReservationAsync.failure,
			}),
		),
	);

export const createAdminReservationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createAdminReservationAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/admin/reservations",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createAdminReservationAsync.success,
				onFailure: createAdminReservationAsync.failure,
			}),
		),
	);

export const updateReservationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateReservationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateReservationAsync.success,
				onFailure: updateReservationAsync.failure,
			}),
		),
	);

export const deleteReservationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteReservationByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteReservationByIdAsync.success,
				onFailure: deleteReservationByIdAsync.failure,
			}),
		),
	);

export const updateReservationStatusEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateReservationStatusAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations/${ action.payload.reservationId }/status`,
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: updateReservationStatusAsync.success,
				onFailure: updateReservationStatusAsync.failure,
			}),
		),
	);

export const sendReservationInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(sendReservationInvoiceAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations/${ action.payload }/send-invoice-email`,
				method: "POST",
				withScope: true,
				onSuccess: sendReservationInvoiceAsync.success,
				onFailure: sendReservationInvoiceAsync.failure,
			}),
		),
	);

export const generateReservationInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(generateReservationInvoiceAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/reservations/${ action.payload }/generate-invoice`,
				method: "GET",
				withScope: true,
				onSuccess: generateReservationInvoiceAsync.success,
				onFailure: generateReservationInvoiceAsync.failure,
			}),
		),
	);
