/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { DetailedProduct, Product } from "src/app/types/api/product.types";
import { deleteProductByIdAsync, fetchAdminProductsAsync, fetchProductByIdAsync, fetchProductsAsync, updateProductAsync } from "src/app/store/features/product/product.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
    adminProducts: createReducer(initialStateReducer as StateReducer<Product[]>)
        .handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchAdminProductsAsync.request, fetchAdminProductsAsync.success, fetchAdminProductsAsync.failure ], handleBasicActions(fetchAdminProductsAsync))
		.handleAction([ deleteProductByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		}),
    products: createReducer(initialStateReducer as StateReducer<Product[]>)
		.handleAction([ fetchProductsAsync.request, fetchProductsAsync.success, fetchProductsAsync.failure ], handleBasicActions(fetchProductsAsync)),
	singleProduct: createReducer([] as ArrayStateReducer<DetailedProduct>)
        .handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchProductByIdAsync.request, fetchProductByIdAsync.success, fetchProductByIdAsync.failure ], handleBasicActionsForArray(fetchProductByIdAsync))
		.handleAction([ updateProductAsync.success ],
			(state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: action.payload.data,
						},
					}),
				)),
});

export default reducer;
