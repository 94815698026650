/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validateNumberField } from "src/app/utils/forms";
import { isEmptyString, isNotNull } from "src/app/utils/typeguards";
import useForm from "src/app/utils/hooks/useForm";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { DetailedRoom } from "src/app/types/api/room.types";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { connect } from "react-redux";
import { dayHourToStartAndEndHourString } from "src/app/utils/constants/dictionaries";
import { compareReservationTime } from "src/app/utils/helpers";
import { DAY_START_HOUR } from "src/app/utils/constants/constants";
import { LoadableType } from "src/app/types/ui/loading.types";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	room: DetailedRoom
	onCreate: (values: CreateTimeWindowForm) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

export type CreateTimeWindowForm = {
	startTime: string
	endTime: string
	price: string
}

const validator: FormValidator<CreateTimeWindowForm> = {
	startTime: (startTime, optional, form) => {
		const endTime = isEmptyString(form.endTime.value) ? null : form.endTime.value;
		if (isNotNull(endTime) && !compareReservationTime(startTime, form.endTime.value)) {
			return "Błędny zakres";
		}
		return validateField("Czas od", startTime, optional);
	},
	endTime: (endTime, optional, form) => {
		const startTime = isEmptyString(form.startTime.value) ? null : form.startTime.value;
		if (isNotNull(startTime) && !compareReservationTime(form.startTime.value, endTime)) {
			return "Błędny zakres";
		}
		return validateField("Czas do", endTime, optional);
	},
	price: (price, optional) => validateNumberField("Cena", price, optional),
};

function CreateTimeWindowModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onCreate,
		isCreating,
	} = props;

	const _handleSubmit = (values: CreateTimeWindowForm) => {
		onCreate(values);
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		startTime: createFormField(""),
		endTime: createFormField(""),
		price: createFormField(""),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isCreating) resetForm();
	}, [ isOpen, isCreating ]);

	return (
		<Modal
			show={ (isOpen || isCreating) }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Dodaj okno czasowe
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						<div className="text-sm">
							{ `Doba rozpoczyna się o ${ dayHourToStartAndEndHourString[ DAY_START_HOUR ].start } i kończy o ${ dayHourToStartAndEndHourString[ DAY_START_HOUR ].end }` }
						</div>
						<div className="grid grid-cols-2 gap-2">
							<div>
								<Input
									formItem={ form.startTime }
									label="Czas od"
									name="startTime"
									inputProps={ {
										type: "time",
										onChange: (e) => handleChange("startTime", e.target.value),
										onBlur: () => {
											handleBlur("startTime");
											form.endTime.touched && handleBlur("endTime");
										},
									} }
								/>
							</div>
							<div>
								<Input
									formItem={ form.endTime }
									label="Czas do"
									name="endTime"
									inputProps={ {
										type: "time",
										onChange: (e) => handleChange("endTime", e.target.value),
										onBlur: () => {
											form.startTime.touched && handleBlur("startTime");
											handleBlur("endTime");
										},
									} }
								/>
							</div>
						</div>
						<Input
							formItem={ form.price }
							label="Cena"
							name="price"
							inputProps={ {
								type: "number",
								onChange: (e) => handleChange("price", e.target.value),
								onBlur: () => handleBlur("price"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isCreating }
					>
						Stwórz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isCreating: didLoadingRecordExist(state, { loadableId: props.room.id, loadableType: LoadableType.ADD_TIME_WINDOW_TO_WEEKDAY }),
});

export default connect(mapStateToProps)(CreateTimeWindowModal);
