import { Outlet } from "react-router-dom";

function PurchasingResultLayout() {

	return (
		<>
			<main className="w-full">
				<Outlet context={ {} }/>
			</main>

		</>
	);
}

export default PurchasingResultLayout;
