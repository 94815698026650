/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormItem } from "src/app/types/ui/form.types";
import { Checkbox, Label } from "flowbite-react";
import React from "react";
import classNames from "classnames";
import { isNotNull, isNull } from "src/app/utils/typeguards";

type Props = {
	formItem: FormItem<boolean>
	label: string | ReactNode
	name: string
	onChange: () => void
	displayErrorMessage?: boolean
	role?: string
	className?: string
	labelClassName?: string
}

function CheckboxComponent(props: Props) {

	const {
		formItem,
		label,
		name,
		onChange,
		displayErrorMessage = false,
		role,
		className = "",
		labelClassName = "",
	} = props;

	const _getErrorMessage = () => {
		if (isNull(formItem.error)) return null;

		if (displayErrorMessage) {
			if (formItem.error instanceof Array) {
				return (
					<ul className="text-red-600 text-sm">
						{
							formItem.error.map((err, i) =>
								<li key={ i } className="mb-1">{ err }</li>,
							)
						}
					</ul>
				);
			} else {
				return formItem.error;
			}
		} else {
			return " ";
		}
	};

	return (
		<div
			className={ classNames("flex flex-col gap-y-0.5", className) }
			role={ role }
		>
			<div
				className="flex items-center gap-x-1"
				onClick={ onChange }
			>
				<Checkbox
					name={ name }
					checked={ formItem.value }
					onChange={ () => undefined }
					className={ classNames(
						{ "border-red-500 bg-red-100": isNotNull(formItem.error) },
					) }
				/>
				<Label
					htmlFor={ name }
					className={ classNames(
						{ "!text-red-600 !font-medium": isNotNull(formItem.error) },
						labelClassName,
					) }
				>
					{ label }
				</Label>
			</div>
			{
				isNotNull(formItem.error) &&
				<span
					className="font-medium"
					role="input-error"
				>
					{ _getErrorMessage() }
				</span>
			}
		</div>
	);
}

export default CheckboxComponent;