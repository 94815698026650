/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button, Card } from "flowbite-react";
import { HiClipboardCopy } from "react-icons/hi";
import { CopyTimeRangesFromOtherRoomPayload, DetailedRoom, Room, RoomTimeRange, UpdateRoomTimeRangesPayload } from "src/app/types/api/room.types";
import { ModalConfig, WeekDay } from "src/app/types/util.types";
import TimeWindowWeekPreview from "src/app/components/Room/TimeWindow/TimeWindowWeekPreview.component";
import { useState } from "react";
import classNames from "classnames";
import CreateTimeWindowModal, { CreateTimeWindowForm } from "src/app/components/Room/TimeWindow/CreateTimeWindowModal.component";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import EditTimeWindowModal, { EditTimeWindowForm } from "src/app/components/Room/TimeWindow/EditTimeWindowModal.component";
import CopyTimeRangesFromOtherRoomModal from "src/app/components/Room/TimeWindow/CopyTimeRangesFromOtherRoomModal.component";
import { LocaleFromISO } from "src/app/utils/luxon";

type Props = {
	room: DetailedRoom
	rooms: Room[]
	onCreateTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
	onEditTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
	onCopyTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
	onCopyTimeWindowsFromOtherRoom: (payload: CopyTimeRangesFromOtherRoomPayload) => void
	onDeleteTimeWindow: (payload: UpdateRoomTimeRangesPayload) => void
};

function RoomTimeWindows(props: Props) {

	const {
		room,
		rooms,
		onCreateTimeWindow,
		onEditTimeWindow,
		onCopyTimeWindow,
		onCopyTimeWindowsFromOtherRoom,
		onDeleteTimeWindow,
	} = props;

	const [ createModalConfig, setCreateModalConfig ] = useState<ModalConfig<WeekDay>>({ isOpen: false });
	const [ isCopyModalOpen, toggleCopyModal ] = useState(false);
	const [ editTimeWindowModalConfig, setTimeWindowModalConfig ] = useState<ModalConfig<RoomTimeRange>>({ isOpen: false });

	const _handleCreateTimeWindow = (weekday: WeekDay, values: CreateTimeWindowForm) => {
		onCreateTimeWindow({
			id: room.id,
			timeRanges: [
				...room.timeRanges.map(timeRange => ({
					weekday: timeRange.weekday,
					startHour: LocaleFromISO(timeRange.startHour).toFormat("HH:mm"),
					endHour: LocaleFromISO(timeRange.endHour).toFormat("HH:mm"),
					price: timeRange.price,
				})),
				{
					weekday,
					startHour: values.startTime,
					endHour: values.endTime,
					price: +values.price,
				},
			],
		});
	};

	const _handleEditTimeWindow = (values: EditTimeWindowForm) => {
		if (isNull(editTimeWindowModalConfig.value)) return;

		const timeRangeId = editTimeWindowModalConfig.value.id;

		onEditTimeWindow({
			id: room.id,
			timeRangeId,
			timeRanges: room.timeRanges.map(timeRange => {
				if (timeRange.id !== timeRangeId) {
					return {
						weekday: timeRange.weekday,
						startHour: LocaleFromISO(timeRange.startHour).toFormat("HH:mm"),
						endHour: LocaleFromISO(timeRange.endHour).toFormat("HH:mm"),
						price: timeRange.price,
					};
				}

				return {
					weekday: timeRange.weekday,
					startHour: values.startTime,
					endHour: values.endTime,
					price: +values.price,
				};
			}),
		});
	};

	const _handleCopyTimeWindowFromOtherWeekday = (sourceWeekday: WeekDay, destinationWeekday: WeekDay) => {
		onCopyTimeWindow({
			id: room.id,
			weekDay: destinationWeekday,
			timeRanges: [
				...room.timeRanges
					   .filter(timeRange => timeRange.weekday !== destinationWeekday)
					   .map(timeRange => ({
						   weekday: timeRange.weekday,
						   startHour: LocaleFromISO(timeRange.startHour).toFormat("HH:mm"),
						   endHour: LocaleFromISO(timeRange.endHour).toFormat("HH:mm"),
						   price: timeRange.price,
					   })),
				...room.timeRanges
					   .filter(timeRange => timeRange.weekday === sourceWeekday)
					   .map(timeRange => ({
						   weekday: destinationWeekday,
						   startHour: LocaleFromISO(timeRange.startHour).toFormat("HH:mm"),
						   endHour: LocaleFromISO(timeRange.endHour).toFormat("HH:mm"),
						   price: timeRange.price,
					   })),
			],
		});
	};

	const _handleDeleteTimeWindow = (timeWindowId: number) => {
		onDeleteTimeWindow({
			id: room.id,
			timeRangeId: timeWindowId,
			timeRanges: room.timeRanges
							.filter(timeRange => timeRange.id !== timeWindowId)
							.map(timeRange => ({
								weekday: timeRange.weekday,
								startHour: LocaleFromISO(timeRange.startHour).toFormat("HH:mm"),
								endHour: LocaleFromISO(timeRange.endHour).toFormat("HH:mm"),
								price: timeRange.price,
							})),
		});
	};

	return (
		<>
			<Card>
				<div className="flex flex-col gap-2.5">
					<div className="flex items-center justify-between pb-2.5 mb-2.5 border-b border-gray-200 dark:border-dark-borderColor">
						<h2 className="text-xl font-bold">Okna czasowe</h2>
						<div className="flex gap-1">
							<Button
								color="gray-outline"
								onClick={ () => toggleCopyModal(true) }
							>
								<HiClipboardCopy className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
								Kopiuj z innego pokoju
							</Button>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-4">
					{
						Object
							.values(WeekDay)
							.map((weekDay, index, arr) =>
								<div
									key={ weekDay }
									className={ classNames({ "border-b border-gray-200 pb-4": index !== arr.length - 1 }) }
								>
									<TimeWindowWeekPreview
										weekDay={ weekDay }
										timeRanges={ room.timeRanges.filter(timeRange => timeRange.weekday === weekDay) }
										onOpenCreateModal={ weekDay => setCreateModalConfig({ isOpen: true, value: weekDay }) }
										onEditTimeWindow={ timeRange => setTimeWindowModalConfig({ isOpen: true, value: timeRange }) }
										onCopyTimeWindowFromOtherWeekday={ _handleCopyTimeWindowFromOtherWeekday }
										onDeleteTimeWindow={ _handleDeleteTimeWindow }
									/>
								</div>
							)
					}
				</div>
			</Card>
			<CreateTimeWindowModal
				room={ room }
				isOpen={ createModalConfig.isOpen }
				handleClose={ () => setCreateModalConfig({ isOpen: false }) }
				onCreate={ values => isNotNull(createModalConfig.value) && _handleCreateTimeWindow(createModalConfig.value, values) }
			/>
			<EditTimeWindowModal
				isOpen={ editTimeWindowModalConfig.isOpen }
				handleClose={ () => setTimeWindowModalConfig(prevState => ({ ...prevState, isOpen: false })) }
				timeWindow={ editTimeWindowModalConfig.value }
				onEdit={ _handleEditTimeWindow }
			/>
			<CopyTimeRangesFromOtherRoomModal
				isOpen={ isCopyModalOpen }
				handleClose={ () => toggleCopyModal(false) }
				room={ room }
				rooms={ rooms }
				handleSave={ roomToCopyId => onCopyTimeWindowsFromOtherRoom({ id: room.id, roomToCopyId }) }
			/>
		</>
	);
}

export default (RoomTimeWindows);
