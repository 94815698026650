/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DetailedRoom, UpdateRoomPayload } from "src/app/types/api/room.types";
import { Button, Card } from "flowbite-react";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { canUpdateRoom } from "src/app/utils/abilities";
import { useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import { Venue } from "src/app/types/api/venue.types";
import EditRoomBasicInformationModal from "src/app/components/Room/EditRoomBasicInformationModal.component";
import ModelCell from "src/app/components/Utils/ModelCell.component";
import { isNotNull } from "src/app/utils/typeguards";

type Props = {
	room: DetailedRoom
	venues: Venue[]
	onUpdate: (payload: UpdateRoomPayload) => void
};

function RoomInformation(props: Props) {

	const {
		room,
		venues,
		onUpdate,
	} = props;

	const [ isEditModalVisible, toggleEditModal ] = useState(false);

	return (
		<>
			<div className="grid grid-cols-2 gap-6">
				<div>
					<Card>
						<div className="flex flex-col gap-2.5">
							<div className="flex items-center justify-between pb-2.5 mb-2.5 border-b border-gray-200 dark:border-dark-borderColor">
								<h2 className="text-xl font-bold">Podstawowe informacje</h2>
								<div className="flex gap-1">
									{
										canUpdateRoom(room) &&
                                        <Button
                                            color="transparent"
                                            onClick={ () => toggleEditModal(true) }
                                        >
                                            <HiPencilAlt className="h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
                                        </Button>
									}
								</div>
							</div>
							<LabelValue
								label="Nazwa"
								value={ room.name }
							/>
							<LabelValue
								label="Lokal"
								value={
									isNotNull(room.venue) ?
									<ModelCell
										model={ room.venue }
										link={ `/venues/${ room.venue.id }` }
										className="max-w-max"
									/> : "Brak"
								}
							/>
							<LabelValue
								label="Opis"
								value={ room.description ?? "" }
							/>
							<LabelValue
								label="Ilość osób"
								value={ room.maxPeople }
							/>
						</div>
					</Card>
				</div>
			</div>
			<EditRoomBasicInformationModal
				isOpen={ isEditModalVisible }
				handleClose={ () => toggleEditModal(false) }
				venues={ venues }
				room={ room }
				onUpdate={ onUpdate }
			/>
		</>
	);
}

export default (RoomInformation);
