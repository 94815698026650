/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DetailedVenue, UpdateVenuePayload } from "src/app/types/api/venue.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { isEmptyString } from "src/app/utils/typeguards";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	onUpdate: (payload: UpdateVenuePayload) => void
	venue: DetailedVenue
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type UpdateVenueForm = {
	name: string
	country: string
	city: string
	street: string
	houseNumber: string
	flatNumber: string
	postCode: string
}

const validator: FormValidator<UpdateVenueForm> = {
	name: (name, optional) => validateField("Nazwa", name, optional),
	country: (country, optional) => validateField("Kraj", country, optional),
	city: (city, optional) => validateField("Miasto", city, optional),
	street: (street, optional) => validateField("Ulica", street, optional),
	houseNumber: (houseNumber, optional) => validateField("Nr domu", houseNumber, optional),
	flatNumber: (flatNumber, optional) => validateField("Nr mieszkania", flatNumber, optional),
	postCode: (postCode, optional) => validateField("Kod pocztowy", postCode, optional),
};

function UpdateVenueModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onUpdate,
		isUpdating,
		venue,
	} = props;

	const _handleSubmit = (values: UpdateVenueForm) => {
		onUpdate({
			...values,
			id: venue.id,
			country: isEmptyString(values.country) ? undefined : values.country,
			flatNumber: isEmptyString(values.flatNumber) ? undefined : values.flatNumber,
		});
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		name: createFormField(venue.name),
		country: createFormField(venue.country ?? "", { optional: true }) ?? "",
		city: createFormField(venue.city ?? ""),
		street: createFormField(venue.street ?? ""),
		houseNumber: createFormField(venue.houseNumber ?? ""),
		flatNumber: createFormField(venue.flatNumber ?? "", { optional: true }),
		postCode: createFormField(venue.postCode ?? ""),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isUpdating) {
			resetForm();
		}
	}, [ isOpen, isUpdating ]);

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Edytuj lokal
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div>
						<Input
							className="mb-4"
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.country }
							label="Kraj"
							name="country"
							inputProps={ {
								type: "text",
								placeholder: "Polska",
								onChange: (e) => handleChange("country", e.target.value),
								onBlur: () => handleBlur("country"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.city }
							label="Miasto"
							name="city"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("city", e.target.value),
								onBlur: () => handleBlur("city"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.street }
							label="Ulica"
							name="street"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("street", e.target.value),
								onBlur: () => handleBlur("street"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.houseNumber }
							label="Nr domu"
							name="houseNumber"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("houseNumber", e.target.value),
								onBlur: () => handleBlur("houseNumber"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.flatNumber }
							label="Nr mieszkania"
							name="flatNumber"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("flatNumber", e.target.value),
								onBlur: () => handleBlur("flatNumber"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.postCode }
							label="Kod pocztowy"
							name="postCode"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("postCode", e.target.value),
								onBlur: () => handleBlur("postCode"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						Zapisz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, { venue }: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableId: venue.id, loadableType: LoadableType.UPDATE_VENUE }),
});

export default connect(mapStateToProps)(UpdateVenueModal);
