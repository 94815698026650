/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import logo from "src/assets/images/logo.png";

//todo: Refactor component to import .svg instead of .png/.jpg
type Props = {
	className?: string
}

function LogoImage(props: Props) {

	const {
		className = "",
	} = props;

	return (
		<img
			src={ logo }
			alt="Logo"
			className={ className }
		/>
	);
}

export default LogoImage;
