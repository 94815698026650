/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import ReservationBreadcrumbsHeader from "src/app/components/PurchasingProcess/util/Header/ReservationBreadcrumbsHeader.component";
import { Navigate, Outlet } from "react-router-dom";
import { purchasingProcessFormStepRouteDictionary } from "src/app/utils/constants/dictionaries";
import { Cart, PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { Venue } from "src/app/types/api/venue.types";
import { Nullable } from "src/app/types/util.types";
import classNames from "classnames";
import { useEffect } from "react";
import { Category } from "src/app/types/api/category.types";

type Props = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	venues: Venue[]
	cart: Cart
	categories: Category[]
	handleProductChange: (productId: number, number: Nullable<number>) => void
	isAtLeastOneAvailabilityChosen: boolean
}

function PurchasingProcessBreadcrumbsLayoutInner(props: Props) {

	const {
		form,
		venues,
		cart,
		categories,
		handleProductChange,
		isAtLeastOneAvailabilityChosen,
	} = props;

	useEffect(() => {
		window.scroll(0, 0);
	}, [ form.form.step.value ]);
	
	if (!isAtLeastOneAvailabilityChosen && form.form.step.value !== PurchasingProcessFormStep.CONFIRMATION && form.form.isFormSubmitted.value) {
		const venueId = form.form.venue.value?.id
		return <Navigate to={ purchasingProcessFormStepRouteDictionary(venueId)[ PurchasingProcessFormStep.AVAILABILITIES ] }/>;
	}

	return (
		<div className="bg-myPrimary-gray-300 min-h-screen h-full">
			<div className="flex flex-col h-full bg-myPrimary-gray-300">
				<ReservationBreadcrumbsHeader form={ form }/>
				<div className={ classNames(
					"py-4 h-full",
					"px-4",
					"lg:px-14",
				) }>
					<div className="max-w-[1440px] mx-auto">
						<Outlet context={ {
							form,
							venues,
							isAtLeastOneAvailabilityChosen,
							cart,
							handleProductChange,
							categories,
						} }/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PurchasingProcessBreadcrumbsLayoutInner;
