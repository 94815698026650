/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Image, Nullable } from "src/app/types/util.types";
import { SimpleUser } from "src/app/types/api/user.types";

export enum OrganizationAbility {
	VIEW = "organization.view",
	UPDATE = "organization.update",
	DELETE = "organization.delete",
}

export enum OrganizationStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE"
}

export type SimpleOrganization = {
	id: string
	name: string

	status: OrganizationStatus
	image: Nullable<Image>
	userCount: Nullable<number>

	createdAt: string
	updatedAt: string
	meta: {
		abilities: OrganizationAbility[]
	}
}

export type Organization =
	SimpleOrganization
	& {}

export type DetailedOrganization =
	Organization
	& {}

export type CreateOrganizationPayload = {}

export type UpdateOrganizationPayload = {
	id: string
	users: Nullable<SimpleUser[]>
}
