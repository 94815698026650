import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { StateReducer } from "src/app/types/redux.types";
import { DetailedDiscountCode } from "src/app/types/api/discountCode.types";
import { initialStateReducer } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";

const singleDiscountCodesSelector = (state: RootState) => state.discountCode.singleDiscountCode;

export const getDiscountCodeById = createSelector(
    [
        singleDiscountCodesSelector,
        (_, discountCodeId: number) => discountCodeId,
    ],
    (singleDiscountCodes, discountCodeId): StateReducer<DetailedDiscountCode> => {
        const discountCode = singleDiscountCodes.find(discountCode => discountCode.id === discountCodeId);
        if (isNotNull(discountCode)) {
            return discountCode.reducer;
        } else {
            return initialStateReducer as StateReducer<DetailedDiscountCode>;
        }
    },
);
