/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { isNotNull } from "src/app/utils/typeguards";
import { Link } from "react-router-dom";

type Props = {
	label: ReactNode
	value: ReactNode
	link?: string
};

function LabelValue(props: Props) {

	const {
		label,
		value,
		link,
	} = props;

	return (
		<div>
			<div className="text-gray-600 text-xs sm:text-sm dark:text-dark-textGray">
				{ label }
			</div>
			{
				isNotNull(link)
				?
				<Link
					to={ link }
					target="_blank"
					className="text-sm sm:text-base font-bold break-words hover:underline"
				>
					{ value }
				</Link>
				:
				<div className="text-sm sm:text-base font-bold break-words">
					{ value }
				</div>
			}
		</div>
	);
}

export default (LabelValue);
