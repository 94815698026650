/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import PurchasingResultBreadcrumbsLayoutInner from "src/app/components/PurchasingProcess/util/PurchasingResultBreadcrumbsLayoutInner.component";

function PurchasingResultBreadcrumbsLayout() {

	return (
		<PurchasingResultBreadcrumbsLayoutInner/>
	);
}

export default PurchasingResultBreadcrumbsLayout;
