/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Product } from "src/app/types/api/product.types";
import { SimpleOrganization } from "src/app/types/api/organization.types";

export enum CategoryAbility {
    VIEW = "category.view",
    UPDATE = "category.update",
    DELETE = "category.delete",
    CREATE = "category.create",
}

export type SimpleCategory = {
    id: number
    name: string,
    meta: {
        abilities: CategoryAbility[]
    }
}

export type Category =
    SimpleCategory
    & {}

export type DetailedCategory =
    Category
    & {
        organizationId: string,
        organization: SimpleOrganization,
        createdAt: string,
        updatedAt: string,
        products: Product[],
    }

export type CreateCategoryPayload = {
    name: string
}

export type UpdateCategoryPayload = {
    id: number
    name?: string
}
