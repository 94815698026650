/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Room, SimpleRoom } from "src/app/types/api/room.types";
import { Button } from "flowbite-react";
import React, { useRef, useState } from "react";
import { DetailedVenue, Venue } from "src/app/types/api/venue.types";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";
import { LocaleFromISO } from "src/app/utils/luxon";
import AdminSingleAvailability from "src/app/components/Room/AdminSingleAvailability.component";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import UpdateReservationModal from "src/app/components/Room/Reservation/UpdateReservationModal/UpdateReservationModal.component";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { AdminAvailability, CalculateReservationPricePayload, CreateReservationPayload, Reservation, UpdateReservationPayload } from "src/app/types/api/reservation.types";
import { DateTime } from "luxon";
import { ModalConfig, Nullable } from "src/app/types/util.types";
import RoomPreviewAdmin from "src/app/components/Room/RoomPreviewAdmin.component";
import { RootState } from "src/app/store/root.reducer";
import { getAdminVenueAvailabilities } from "src/app/store/features/venue/venue.selectors";
import { connect } from "react-redux";
import CreateReservationModal from "src/app/components/Room/Reservation/CreateReservationModal/CreateReservationModal.component";
import { uiFetchAdminVenueAvailabilities } from "src/app/store/features/ui/venue/ui.venue.actions";
import DatePickerHeader from "src/app/components/Utils/DatePickerHeader.component";
import { GridLoader } from "react-spinners";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { reservationColorsArr } from "src/app/utils/constants/constants";
import { uiFetchAdminRoomAvailabilities, uiFetchReservationById } from "src/app/store/features/ui/reservation/ui.reservation.actions";

type ComponentProps = {
	venue: DetailedVenue
	products: Product[]
	discountCodes: DiscountCode[]
	rooms: Room[]
	venues: Venue[]
	updateReservation: (payload: UpdateReservationPayload) => void
	createReservation: (payload: CreateReservationPayload) => void
	calculateReservationPrice: (payload: CalculateReservationPricePayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

const ROW_HEIGHT = 150;
const COL_WIDTH = 120;

function VenueProfileAvailabilities(props: Props) {

	const {
		venue: {
			id: venueId,
			rooms: venueRooms,
		},
		products,
		discountCodes,
		rooms,
		venues,
		updateReservation,
		createReservation,
		getVenueAvailabilities,
		isUpdating,
		isCreating,
		fetchVenueAvailabilities,
		isFetchingReservation,
		isFetchingRoomAvailabilities,
		fetchAdminRoomAvailabilities,
		fetchReservation,
	} = props;

	const gridRef = useRef<HTMLDivElement>(null);
	const [ createReservationButtonDisabled, setCreateReservationButtonDisabled ] = useState(false);
	const [ createReservationModalConfig, setCreateReservationModalConfig ] = useState<ModalConfig<{ availability: Nullable<AdminAvailability>, room: SimpleRoom }>>({ isOpen: false, value: null });
	const [ updateReservationModalConfig, setUpdateReservationModalConfig ] = useState<ModalConfig<{ reservationId: number, room: SimpleRoom, startDate: string }>>({ isOpen: false, value: null });
	const [ date, setDate ] = useState<DateTime>(LocaleFromISO(DateTime.now().toISO()));

	const getReservationColor = (availabilities: AdminAvailability[], availabilityReservations: Reservation[]) => {
		const currentDateReservations = availabilities.reduce<Reservation[]>((prev, current) => {
			const filteredReservations = current.reservations.filter(reservation => (
				LocaleFromISO(reservation.startDate).toLocaleString(DateTime.DATE_SHORT) === date.toLocaleString(DateTime.DATE_SHORT) &&
				isNull(prev.find((({ id }) => id === reservation.id)))
			));
			return [ ...prev, ...filteredReservations ];
		}, []);

		const mappedReservations = currentDateReservations.map((reservation, index) => ({
			reservationId: reservation.id,
			color: reservationColorsArr[ index % 10 ],
		}));

		return mappedReservations.find(({ reservationId }) => availabilityReservations.find(reservation => reservation.id === reservationId))?.color;
	};

	const updateReservationModalRequest = ({ room, reservationId, startDate }: { room: SimpleRoom, reservationId: Nullable<number>, startDate: string }) => {
		if (isNull(reservationId)) return;
		setUpdateReservationModalConfig({ isOpen: true, value: { reservationId, room, startDate } });
		fetchReservation(reservationId);
		fetchAdminRoomAvailabilities({ roomId: room.id ?? 0, date: date.toFormat("yyyy-MM-dd") });
	};

	return (
		<div className="flex flex-col gap-4">

			<div className="flex gap-2 items-center justify-between">
				<DatePickerHeader
					onDateChange={ setDate }
					date={ date }
				/>
				<div>
					<Button
						size="sm"
						color="primary"
						onClick={ () => setCreateReservationModalConfig({ isOpen: true, value: null }) }
						disabled={ createReservationButtonDisabled }
					>
						Dodaj rezerwacje
					</Button>
				</div>
			</div>
			<div className="relative">
				{
					(
						isUpdating ||
						isCreating ||
						isFetchingRoomAvailabilities ||
						isFetchingReservation(updateReservationModalConfig.value?.reservationId ?? -1)
					) &&
                    <div
                        className="absolute top-0 left-0 w-full h-full backdrop-blur-[2px] flex justify-center pt-20"
                        style={ { zIndex: 15 } }
                    >
                        <GridLoader size={ 30 } color="#eb5600"/>
                    </div>
				}
				<CachedThenFreshStrategy
					request={ () => fetchVenueAvailabilities({ venueId, date: date.toFormat("yyyy-MM-dd") }) }
					state={ getVenueAvailabilities(venueId, date.toFormat("yyyy-MM-dd")) }
					useEffectDependency={ date.toFormat("yyyy-MM-dd") }
				>
					{
						venueAvailabilities => {
							if (isNull(venueRooms) || venueAvailabilities.length === 0) {
								setCreateReservationButtonDisabled(true);
								return (
									<div className="text-4xl font-[900] text-myPrimary-purple-400 w-full text-center mt-4">
										Brak pokoi
									</div>
								);
							}
							setCreateReservationButtonDisabled(venueAvailabilities.every(({ availabilities }) => availabilities.length === 0));
							return (
								<>
									<div className="flex gap-2">
										<div className="flex flex-col gap-2">
											{
												venueRooms.map(room =>
													<RoomPreviewAdmin
														key={ room.id }
														disableClick={ createReservationButtonDisabled || venueAvailabilities.find(({ roomId }) => roomId === room.id)?.availabilities.length === 0 }
														onClick={ () => setCreateReservationModalConfig({ isOpen: true, value: { availability: null, room: room } }) }
														room={ room }
														width={ 250 }
														height={ ROW_HEIGHT }
													/>,
												)
											}
										</div>
										<div
											ref={ gridRef }
											className="flex flex-col gap-2 overflow-x-auto"
										>
											{
												venueRooms.map(room => {
													const venueAvailability = venueAvailabilities.find(({ roomId }) => roomId === room.id);
													if (isNull(venueAvailability) || venueAvailability.availabilities.length === 0) {
														return (
															<div
																key={ room.id }
																className="flex items-center text-2xl font-[900] text-myPrimary-purple-400 relative"
																style={ {
																	height: ROW_HEIGHT,
																	width: gridRef.current?.scrollWidth ?? "auto",
																} }
															>
																<div className="sticky whitespace-nowrap left-0">
																	Brak dostępności
																</div>
															</div>
														);
													}
													return (
														<div
															key={ room.id }
															className="flex gap-2"
														>
															{
																venueAvailability.availabilities.map(availability => {
																	// const currentDateReservation = availability.reservations.find(reservation => {
																	// 	const reservationStartDate = LocaleFromISO(reservation.startDate);
																	// 	return (
																	// 		reservationStartDate.year === date.year &&
																	// 		reservationStartDate.month === date.month &&
																	// 		reservationStartDate.day === date.day
																	// 	)
																	// });
																	const currentDateReservation = availability.reservations[ 0 ];

																	return (
																		<AdminSingleAvailability
																			key={ `availability-${ availability.startTime }-${ availability.endTime }-${ availability.price }` }
																			availability={ availability }
																			onReserveClick={ availability => setCreateReservationModalConfig({ isOpen: true, value: { availability, room } }) }
																			onEditClick={ reservationId => updateReservationModalRequest({ room, reservationId, startDate: availability.startTime }) }
																			currentDateReservation={ currentDateReservation }
																			color={ getReservationColor(venueAvailability.availabilities, availability.reservations) }
																			style={ {
																				height: ROW_HEIGHT,
																				width: COL_WIDTH,
																				minWidth: COL_WIDTH,
																				maxWidth: COL_WIDTH,
																			} }
																		/>
																	);
																})
															}
														</div>
													);
												})
											}
										</div>
									</div>
									<CreateReservationModal
										date={ date }
										isOpen={ createReservationModalConfig.isOpen }
										handleClose={ () => setCreateReservationModalConfig({ isOpen: false, value: null }) }
										discountCodes={ discountCodes }
										products={ products }
										availability={ createReservationModalConfig.value?.availability ?? undefined }
										onCreate={ createReservation }
										room={ createReservationModalConfig.value?.room ?? undefined }
										venue={ props.venue }
										venues={ venues }
										rooms={ rooms }
									/>
									{
										(
											isNotNull(updateReservationModalConfig.value) &&
											!isFetchingReservation(updateReservationModalConfig.value.reservationId)
										) &&
                                        <UpdateReservationModal
                                            date={ LocaleFromISO(updateReservationModalConfig.value.startDate) }
                                            isOpen={ updateReservationModalConfig.isOpen }
                                            handleClose={ () => setUpdateReservationModalConfig({ isOpen: false }) }
                                            room={ updateReservationModalConfig.value.room }
                                            discountCodes={ discountCodes }
                                            products={ products }
                                            reservationId={ updateReservationModalConfig.value.reservationId }
                                            onUpdate={ updateReservation }
                                            disableRequest={ true }
                                        />
									}
								</>
							);
						}
					}
				</CachedThenFreshStrategy>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isUpdating: didLoadingRecordExist(state, { loadableType: LoadableType.UPDATE_RESERVATION }),
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_RESERVATION }),
	getVenueAvailabilities: (venueId: number, date: string) => getAdminVenueAvailabilities(state, venueId, date),
	isFetchingReservation: (reservationId: number) => didLoadingRecordExist(state, { loadableId: reservationId, loadableType: LoadableType.FETCH_RESERVATION_BY_ID }),
	isFetchingRoomAvailabilities: didLoadingRecordExist(state, { loadableType: LoadableType.FETCH_ADMIN_ROOM_AVAILABILITIES }),
});

const mapDispatchToProps = {
	fetchAdminRoomAvailabilities: uiFetchAdminRoomAvailabilities,
	fetchReservation: uiFetchReservationById,
	fetchVenueAvailabilities: uiFetchAdminVenueAvailabilities,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueProfileAvailabilities);
