/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import PurchasingProcessConfirmationContainer from "src/app/containers/PurchasingProcess/PurchasingProcessConfirmation.container";

function PurchasingProcessConfirmationView() {

	return (
		<PurchasingProcessConfirmationContainer/>
	);
}

export default PurchasingProcessConfirmationView;
