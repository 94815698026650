/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { Button } from "flowbite-react";
import { useState } from "react";
import { Nullable } from "src/app/types/util.types";
import PurchasingProcessProduct from "src/app/components/PurchasingProcess/Additionals/PurchasingProcessProduct.component";
import ReservationCart from "src/app/components/PurchasingProcess/util/Cart/ReservationCart.component";
import { Cart, PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { ProductCategory } from "src/app/types/api/product.types";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { BeatLoader } from "react-spinners";
import classNames from "classnames";
import { Category } from "src/app/types/api/category.types";

type ComponentProps = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	cart: Cart
	handleProductChange: (productId: number, number: Nullable<number>) => void
	categories: Category[]
}

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

function PurchasingProcessAdditionsContainer(props: Props) {

	const form = props.form;

	const {
		form: {
			form: {
				products,
				price,
			},
			handleChange,
		},
		cart,
		handleProductChange,
		isCalculatingPrice,
		categories,
	} = props;

	const [ currentCategory, setCurrentCategory ] = useState<Nullable<Category>>(null);

	const filteredProducts =
		products.value.filter(product => {
			if (isNull(currentCategory)) return true;
			return product.categories.some(({ id }) => id === currentCategory.id);
		})

	return (
		<div className={ classNames(
			"flex gap-10 w-full h-full",
			"flex-col",
			"lg:flex-row",
		) }>
			<div className={ classNames(
				"flex flex-col gap-5 h-max",
				"w-full",
				"lg:w-1/2",
				"xl:w-3/5",
			) }>
				<div className="flex flex-col gap-2">
					<div className={ classNames(
						"text-myPrimary-purple-500",
						"text-sm font-normal",
						"sm:text-base sm:font-light",
					) }>
						Przygotujcie pokój na Wasze przybycie!
					</div>
					<div className="gap-2 flex flex-wrap">
						<Button
							color={ isNull(currentCategory) ? "purple-full" : "purple-outline" }
							onClick={ () => setCurrentCategory(null) }
							size="xs"
						>
							<span>Wszystkie</span>
						</Button>
						{
							categories.map(category =>
								<Button
									key={ category.id }
									color={ category.id === currentCategory?.id ? "purple-full" : "purple-outline" }
									onClick={ () => setCurrentCategory(category.id === currentCategory?.id ? null : category) }
									size="xs"
								>
									<span>{ category.name }</span>
								</Button>
							)
						}
					</div>
				</div>
				<div className={ classNames(
					"gap-4",
					"flex overflow-x-auto pb-2 scrollbar- scrollbar-thumb-myPrimary-orange-500 scrollbar-thumb-rounded-lg scrollbar-track-white scrollbar-thin",
					"lg:grid-cols-3 lg:grid lg:overflow-x-visible lg:pb-0",
					"xl:grid-cols-4",
				) }>
					{
						Object.values(ProductCategory).length === 0
							?
							<div className={ classNames(
								"font-bold text-myPrimary-purple-500 col-span-4",
								"text-xl",
								"sm:text-2xl",
							) }>
								Brak produktow
							</div>
							:
							(filteredProducts.length === 0 && isNotNull(currentCategory))
								?
								<div className={ classNames(
									"font-bold text-myPrimary-purple-500 col-span-4",
									"text-xl",
									"sm:text-2xl",
								) }>
									{ `Brak produktów w kategorii "${ currentCategory.name }"` }
								</div>
								:
								filteredProducts.map(product =>
									<PurchasingProcessProduct
										key={ product.id }
										product={ product }
										form={ form }
										handleProductChange={ handleProductChange }
									/>
								)
					}
				</div>
			</div>
			<ReservationCart
				title="TWOJE ZAMÓWIENIE"
				cart={ cart }
				handleProductChange={ handleProductChange }
				className={ classNames(
					"!w-full",
					"lg:!w-1/2",
					"xl:!w-2/5",
				) }
			>
				<Button
					color="orange-full"
					size="xl"
					disabled={ isCalculatingPrice }
					className="mx-auto w-4/5 [&>span]:justify-between [&>span]:w-full [&>span]:text-lg rounded-xl mt-3"
					onClick={ () => handleChange("step", PurchasingProcessFormStep.PAYMENT) }
				>
					{
						isCalculatingPrice
						?
						<div className="mx-auto">
							<BeatLoader size={ 10 } color="white"/>
						</div>
						:
							<div className={ classNames(
								"flex items-center gap-2 mx-auto",
								"flex-col",
								"xs:flex-row",
							) }>
								<span className="text-sm xs:text-base">
									Potwierdź i przejdź dalej
								</span>
								<span className={ classNames(
									"font-extrabold",
									"text-base",
									"xs:text-xl",
								) }>
									{ `${ price.value } ZŁ` }
								</span>
							</div>
					}
				</Button>
			</ReservationCart>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCalculatingPrice: didLoadingRecordExist(state, { loadableType: LoadableType.CALCULATE_RESERVATION_PRICE }),
});

export default connect(mapStateToProps)(PurchasingProcessAdditionsContainer);
