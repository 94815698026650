/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validateNumberField } from "src/app/utils/forms";
import { isEmptyString, isNotNull } from "src/app/utils/typeguards";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { RoomTimeRange } from "src/app/types/api/room.types";
import { Nullable } from "src/app/types/util.types";
import { dayHourToStartAndEndHourString, weekDayDictionary } from "src/app/utils/constants/dictionaries";
import { compareReservationTime } from "src/app/utils/helpers";
import { DAY_START_HOUR } from "src/app/utils/constants/constants";

type Props = {
	isOpen: boolean
	handleClose: () => void
	timeWindow: Nullable<RoomTimeRange>
	onEdit: (values: EditTimeWindowForm) => void
};

export type EditTimeWindowForm = {
	startTime: string
	endTime: string
	price: string
}

const validator: FormValidator<EditTimeWindowForm> = {
	startTime: (startTime, optional, form) => {
		const endTime = isEmptyString(form.endTime.value) ? null : form.endTime.value;
		if (isNotNull(endTime) && !compareReservationTime(startTime, form.endTime.value)) {
			return "Błędny zakres";
		}
		return validateField("Czas od", startTime, optional);
	},
	endTime: (endTime, optional, form) => {
		const startTime = isEmptyString(form.startTime.value) ? null : form.startTime.value;
		if (isNotNull(startTime) && !compareReservationTime(form.startTime.value, endTime)) {
			return "Błędny zakres";
		}
		return validateField("Czas do", endTime, optional);
	},
	price: (price, optional) => validateNumberField("Cena", price, optional),
};

function EditTimeWindowModal(props: Props) {

	const {
		isOpen,
		handleClose,
		timeWindow,
		onEdit,
	} = props;

	const _handleSubmit = (values: EditTimeWindowForm) => {
		onEdit(values);
		handleClose();
	};

	useEffect(() => {
		setForm(_getInitialState());
	}, [ timeWindow ]);

	const _getInitialState = () => ({
		startTime: createFormField(timeWindow?.startHour ?? ""),
		endTime: createFormField(timeWindow?.endHour ?? ""),
		price: createFormField(timeWindow?.price?.toString() ?? ""),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen) setForm(_getInitialState());
	}, [ isOpen ]);

	return (
		<Modal
			show={ isOpen }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ isOpen ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Edytuj okno czasowe
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						{
							isNotNull(timeWindow) &&
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
								{ `${ weekDayDictionary[ timeWindow.weekday ] } ${ timeWindow.startHour } - ${ timeWindow.endHour }` }
                            </p>
						}
						<div className="text-sm">
							{ `Doba rozpoczyna się o ${ dayHourToStartAndEndHourString[ DAY_START_HOUR ].start } i kończy o ${ dayHourToStartAndEndHourString[ DAY_START_HOUR ].end }` }
						</div>
						<div className="grid grid-cols-2 gap-2">
							<div>
								<Input
									formItem={ form.startTime }
									label="Czas od"
									name="startTime"
									inputProps={ {
										type: "time",
										onChange: (e) => handleChange("startTime", e.target.value),
										onBlur: () => {
											handleBlur("startTime");
											form.endTime.touched && handleBlur("endTime");
										},
									} }
								/>
							</div>
							<div>
								<Input
									formItem={ form.endTime }
									label="Czas do"
									name="endTime"
									inputProps={ {
										type: "time",
										onChange: (e) => handleChange("endTime", e.target.value),
										onBlur: () => {
											form.startTime.touched && handleBlur("startTime");
											handleBlur("endTime");
										},
									} }
								/>
							</div>
						</div>
						<Input
							formItem={ form.price }
							label="Cena"
							name="price"
							inputProps={ {
								type: "number",
								onChange: (e) => handleChange("price", e.target.value),
								onBlur: () => handleBlur("price"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
					>
						Zapisz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

export default (EditTimeWindowModal);
