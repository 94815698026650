import { DataState, FetchPaginatedDataBasicRequest, FetchPaginatedDataBasicRequestFilters, LoadingState, PaginationStateReducer, PaginationStateReducerMeta } from "src/app/types/redux.types";
import { ArrayElement, Nullable } from "src/app/types/util.types";
import { MUIDataTableOptions, MUIDataTableState, MUISortOptions } from "mui-datatables";
import { TABLE_DEFAULT_CELL_HEIGHT, TABLE_FOOTER_HEIGHT, TABLE_THEAD_HEIGHT, TABLE_TITLE_HEIGHT } from "src/app/components/Utils/Table.component";
import { useEffect, useState } from "react";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "src/app/utils/constants/constants";
import { isEmptyString, isNotNull } from "src/app/utils/typeguards";
import { getFiltersFromUrl, isDifferentPaginationOptions } from "src/app/utils/helpers";
import { GridLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { DataTableAction, TableURLParamsKey } from "src/app/types/ui/table.types";

type Props<T, S extends Nullable<string>, F extends FetchPaginatedDataBasicRequestFilters> = {
	request: (payload: FetchPaginatedDataBasicRequest & { filters: F, sort: S }) => void
	onChangePaginationMeta: (meta: Partial<PaginationStateReducerMeta<S, F>>) => void
	unmount: () => void
	children: (data: T, tableConfiguration: PaginationTableConfiguration, isLoading: boolean) => JSX.Element
	state: PaginationStateReducer<T>
	sortMapper: (sort: Partial<MUISortOptions>) => S
	filterMapper: (filters: string[][]) => F
	useEffectDependency?: string
	wrapperHeight: number
	cellHeight?: number
	withoutLoading?: boolean
	usePersist?: boolean
	tablePersistPrefix?: string
};

export type PaginationTableConfiguration = {
	tableOptions: MUIDataTableOptions
	filters?: string[][]
}

type PaginationTableState = {
	pageIndex: number
	pageSize: number
	previousPageIndexes: number[]
	sort: Partial<MUISortOptions>
	search: Nullable<string>
	filters: string[][] // [ColumnIndex][FilterValueIndex]
}

function PaginationStrategy<T, S extends Nullable<string>, F extends FetchPaginatedDataBasicRequestFilters>(props: Props<T, S, F>) {

	const {
		request,
		onChangePaginationMeta,
		unmount,
		children,
		state,
		useEffectDependency,
		sortMapper,
		filterMapper,
		wrapperHeight,
		cellHeight = TABLE_DEFAULT_CELL_HEIGHT,
		withoutLoading = false,
		usePersist = false,
		tablePersistPrefix = "table",
	} = props;

	const navigate = useNavigate();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);

	const pageIndexKey = `${ tablePersistPrefix }${ TableURLParamsKey.PAGE_INDEX }`;
	const pageSizeKey = `${ tablePersistPrefix }${ TableURLParamsKey.PAGE_SIZE }`;
	const searchKey = `${ tablePersistPrefix }${ TableURLParamsKey.SEARCH }`;
	const sortNameKey = `${ tablePersistPrefix }${ TableURLParamsKey.SORT_NAME }`;
	const sortDirectionKey = `${ tablePersistPrefix }${ TableURLParamsKey.SORT_DIRECTION }`;
	const filtersKey = `${ tablePersistPrefix }${ TableURLParamsKey.FILTER }`;

	const pageIndex = urlParams.get(pageIndexKey);
	const pageSize = urlParams.get(pageSizeKey);
	const search = urlParams.get(searchKey);
	const sortName = urlParams.get(sortNameKey);
	const sortDirection = urlParams.get(sortDirectionKey);
	const filters = urlParams.getAll(filtersKey);

	const _getPageSize = () => {
		const pageSize = Math.floor((wrapperHeight - TABLE_TITLE_HEIGHT - TABLE_THEAD_HEIGHT - TABLE_FOOTER_HEIGHT) / cellHeight);
		if (pageSize < 1) {
			return DEFAULT_PAGINATION_PAGE_SIZE;
		}

		return pageSize;
	};

	const _navigate = (pageIndex: number, pageSize: number, search: Nullable<string>, sort: Partial<MUISortOptions>, filters: string[][]) => {
		if (!usePersist) return;

		urlParams.delete(searchKey);
		urlParams.delete(sortNameKey);
		urlParams.delete(sortDirectionKey);
		urlParams.delete(filtersKey);

		urlParams.set(pageIndexKey, pageIndex.toString());
		urlParams.set(pageSizeKey, pageSize.toString());

		if (isNotNull(search) && !isEmptyString(search)) urlParams.set(searchKey, search);

		if (isNotNull(sortMapper(sort))) {
			urlParams.set(sortNameKey, sort.name!);
			urlParams.set(sortDirectionKey, sort.direction!);
		}

		if (filters.some(filter => filter.length > 0)) {
			filters.forEach((filter, filterIndex) => {
				if (filter.length > 0) {
					filter.forEach(singleFilter => {
						urlParams.append(filtersKey, `${ filterIndex }${ TableURLParamsKey.FILTER_SEPARATOR }${ singleFilter }`);
					});
				}
			});
		}

		if (isNotNull(pagination)) {
			navigate({
				search: urlParams.toString(),
			}, {
				replace: true,
			});
		}
	};

	const [ pagination, setPagination ] = useState<PaginationTableState>({
		pageIndex: isNotNull(pageIndex) ? +pageIndex : state.meta.actualPageIndex,
		previousPageIndexes: [],
		pageSize: isNotNull(pageSize) ? +pageSize : _getPageSize(),
		search,
		sort: {
			name: sortName ?? undefined,
			direction: sortDirection as MUISortOptions["direction"] ?? undefined,
		},
		filters: getFiltersFromUrl(filters),
	});

	useEffect(() => {
		// Re-Set in strategy initial meta information
		// Then send request to not step in first case
		onChangePaginationMeta({
			actualPageIndex: pagination.pageIndex,
			actualPageSize: pagination.pageSize,
			actualSearch: pagination.search,
			actualSort: sortMapper(pagination.sort),
			actualFilters: filterMapper(pagination.filters),
		});

		request({
			...pagination,
			sort: sortMapper(pagination.sort),
			filters: filterMapper(pagination.filters),
			isBoundaryPage: false,
		});

		return () => {
			unmount();
		};
	}, [ useEffectDependency ]);

	useEffect(() => {
		_navigate(pagination.pageIndex, pagination.pageSize, pagination.search, pagination.sort, pagination.filters);
	}, [ pagination ]);

	const actualPage = state.pages.find(page => page.pageIndex === state.meta.actualPageIndex);

	const previousPage: Nullable<ArrayElement<PaginationStateReducer<T>["pages"]>> =
		pagination.previousPageIndexes.reduce<Nullable<ArrayElement<PaginationStateReducer<T>["pages"]>>>(
			(prev, pageIndex) => {
				if (isNotNull(prev)) return prev;
				const currentPage = state.pages.find(page => page.pageIndex === pageIndex);

				if (isNotNull(currentPage) && currentPage.data.dataState === DataState.PRESENT) return currentPage;

				return prev;
			},
			null,
		);

	const tableOptions: MUIDataTableOptions = {
		serverSide: true,
		onTableInit: (action: string, tableState: MUIDataTableState) => {
			setPagination(prevState => ({
				...prevState,
				search: tableState.searchText,
				sort: tableState.sortOrder,
				filters: tableState.filterList,
			}));
		},
		onTableChange: (action: string, tableState: MUIDataTableState) => {
			switch (action) {
				case DataTableAction.PAGE_CHANGE:
					if (!isDifferentPaginationOptions(
						{ actualPageSize: pagination.pageSize, newPageSize: tableState.rowsPerPage },
						{ actualSearch: pagination.search, newSearch: tableState.searchText },
						{ actualSort: sortMapper(pagination.sort), newSort: sortMapper(tableState.sortOrder) },
						{ actualFilters: filterMapper(pagination.filters), newFilters: filterMapper(tableState.filterList) },
					)) {
						const isPreviousLoaded = state.pages.some(page => page.pageIndex === pagination.pageIndex && page.data.dataState === DataState.PRESENT);
						const newState: PaginationTableState = {
							...pagination,
							pageIndex: tableState.page,
							previousPageIndexes: isPreviousLoaded ? [ pagination.pageIndex, ...pagination.previousPageIndexes ] : pagination.previousPageIndexes,
						};
						request({
							...newState,
							sort: sortMapper(newState.sort),
							filters: filterMapper(newState.filters),
							isBoundaryPage: false,
						});
						onChangePaginationMeta({
							actualPageIndex: tableState.page,
						});
						setPagination(newState);
					}
					break;
				case DataTableAction.FILTER_CHANGE:
				case DataTableAction.RESET_FILTERS:
				case DataTableAction.SORT:
				case DataTableAction.SEARCH:
				case DataTableAction.CHANGE_ROWS_PER_PAGE:
				case DataTableAction.PROPS_UPDATE:
					if (
						isDifferentPaginationOptions(
							{ actualPageSize: pagination.pageSize, newPageSize: tableState.rowsPerPage },
							{ actualSearch: pagination.search, newSearch: tableState.searchText },
							{ actualSort: sortMapper(pagination.sort), newSort: sortMapper(tableState.sortOrder) },
							{ actualFilters: filterMapper(pagination.filters), newFilters: filterMapper(tableState.filterList) },
						)
					) { // Restarting table state to first page
						const newState: PaginationTableState = {
							pageSize: tableState.rowsPerPage,
							sort: tableState.sortOrder,
							filters: tableState.filterList,
							search: tableState.searchText,
							pageIndex: 0,
							previousPageIndexes: [],
						};
						request({
							...newState,
							sort: sortMapper(newState.sort),
							filters: filterMapper(newState.filters),
							isBoundaryPage: false,
						});
						setPagination(newState);
					}
					break;
			}
		},
		searchText: pagination.search ?? undefined,
		sortOrder:
			(isNotNull(pagination.sort.name) && isNotNull(pagination.sort.direction))
				?
				{
					name: pagination.sort.name,
					direction: pagination.sort.direction,
				}
				:
				undefined,
		page: state.meta.actualPageIndex,
		count: state.meta.totalCount,
		rowsPerPage: pagination.pageSize,
		onChangeRowsPerPage: (pageSize: number) => {
			const newState = {
				...pagination,
				pageSize,
				pageIndex: 0,
				previousPageIndexes: [],
			};
			request({
				...newState,
				sort: sortMapper(newState.sort),
				filters: filterMapper(newState.filters),
				isBoundaryPage: false,
			});
			setPagination(newState);
		},
		rowsPerPageOptions: pagination.pageSize ? Array.from(new Set([ 10, 25, 50, pagination.pageSize ])).sort((a, b) => a - b) : undefined,
		jumpToPage: true,
	};

	const tableConfiguration = {
		tableOptions,
		filters: usePersist ? pagination.filters : undefined,
	};

	if (isNotNull(actualPage) && actualPage.data.dataState === DataState.PRESENT) {
		return children(actualPage.data.data, tableConfiguration, actualPage.data.loadingState === LoadingState.LOADING); // We don't refetch already fetched pages
	} else if (isNotNull(previousPage) && previousPage.data.dataState === DataState.PRESENT) {
		return children(previousPage.data.data, tableConfiguration, true);
	} else if (!withoutLoading) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<GridLoader size={ 30 } color="#EC5600"/>
			</div>
		);
	} else {
		return null;
	}
}

export default (PaginationStrategy);
