/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { DetailedVenue, Venue } from "src/app/types/api/venue.types";
import { deleteVenueByIdAsync, fetchAdminVenueAvailabilitiesAsync, fetchAdminVenuesAsync, fetchVenueAvailabilitiesAsync, fetchVenueByIdAsync, fetchVenuesAsync, updateVenueAsync } from "src/app/store/features/venue/venue.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";
import { VenueAvailabilities } from "src/app/types/api/reservation.types";

const reducer = combineReducers({
	adminVenues: createReducer(initialStateReducer as StateReducer<Venue[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchAdminVenuesAsync.request, fetchAdminVenuesAsync.success, fetchAdminVenuesAsync.failure ], handleBasicActions(fetchAdminVenuesAsync))
		.handleAction([ deleteVenueByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		}),
	venueAvailabilities: createReducer([] as ArrayStateReducer<VenueAvailabilities[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchVenueAvailabilitiesAsync.request, fetchVenueAvailabilitiesAsync.success, fetchVenueAvailabilitiesAsync.failure ], handleBasicActionsForArray(fetchVenueAvailabilitiesAsync, requestPayload => `${ requestPayload.venueId }-${ requestPayload.date }`)),
	adminVenueAvailabilities: createReducer([] as ArrayStateReducer<VenueAvailabilities[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction(
			[
				fetchAdminVenueAvailabilitiesAsync.request,
				fetchAdminVenueAvailabilitiesAsync.success,
				fetchAdminVenueAvailabilitiesAsync.failure
			],
			handleBasicActionsForArray(
				fetchAdminVenueAvailabilitiesAsync,
					requestPayload => `${ requestPayload.venueId }-${ requestPayload.date }`
			)
		),
	venues: createReducer(initialStateReducer as StateReducer<Venue[]>)
		.handleAction([ fetchVenuesAsync.request, fetchVenuesAsync.success, fetchVenuesAsync.failure ], handleBasicActions(fetchVenuesAsync)),
	singleVenue: createReducer([] as ArrayStateReducer<DetailedVenue>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchVenueByIdAsync.request, fetchVenueByIdAsync.success, fetchVenueByIdAsync.failure ], handleBasicActionsForArray(fetchVenueByIdAsync))
		.handleAction([ updateVenueAsync.success ],
			(state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: action.payload.data,
						},
					}),
				)),
});

export default reducer;
