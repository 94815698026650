import { ErrorCodeName } from "src/app/types/api/api.types";
import Error from "src/assets/images/error/error.png";
import { Button } from "flowbite-react";
import { errorCodeNameDictionary } from "src/app/utils/constants/dictionaries";

type Props = {
	codeName: ErrorCodeName
	message?: string
	buttonText: string
	onButtonClick: () => void
	isLoading?: boolean
};

function ErrorWrapper(props: Props) {

	const {
		codeName,
		message,
		buttonText,
		onButtonClick,
		isLoading,
	} = props;

	return (
		<div className="w-full h-full flex flex-col items-center justify-center">
			<img alt="" src={ Error } className="w-80 h-80 object-contain mb-4"/>
			<h1 className="mb-6 text-2xl font-bold dark:text-white md:text-5xl">
				{ errorCodeNameDictionary[ codeName ] }
			</h1>
			<p className="mb-6 w-4/5 text-center text-lg text-gray-500 dark:text-gray-300">
				{ message }
			</p>
			<Button
				onClick={ onButtonClick }
				isProcessing={ isLoading }
			>
				{ buttonText }
			</Button>
		</div>
	);
}

export default (ErrorWrapper);
