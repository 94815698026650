/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceOrPushObject, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { AdminAvailability, DetailedReservation, Reservation } from "src/app/types/api/reservation.types";
import { createAdminReservationAsync, deleteReservationByIdAsync, fetchAdminReservationsByDateAsync, fetchAdminRoomAvailabilitiesAsync, fetchReservationByIdAsync, fetchReservationsAsync, generateReservationInvoiceAsync, updateReservationAsync, updateReservationStatusAsync } from "src/app/store/features/reservation/reservation.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";
import { LocaleFromISO } from "src/app/utils/luxon";
import { DAY_START_HOUR } from "src/app/utils/constants/constants";

const reducer = combineReducers({
	reservations: createReducer(initialStateReducer as StateReducer<Reservation[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchReservationsAsync.request, fetchReservationsAsync.success, fetchReservationsAsync.failure ], handleBasicActions(fetchReservationsAsync))
		.handleAction([ deleteReservationByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		})
		.handleAction([ createAdminReservationAsync.success, updateReservationAsync.success, updateReservationStatusAsync.success, generateReservationInvoiceAsync.success ], (state, action) => {
			if (state.dataState === DataState.NOT_PRESENT) return state;

			return {
				...state,
				data: replaceOrPushObject(state.data, action.payload.data, (a, b) => a.id === b.id),
			};
		}),
	adminDateReservations: createReducer([] as ArrayStateReducer<Reservation[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction(
			[
				fetchAdminReservationsByDateAsync.request,
				fetchAdminReservationsByDateAsync.success,
				fetchAdminReservationsByDateAsync.failure,
			],
			handleBasicActionsForArray(
				fetchAdminReservationsByDateAsync,
				requestPayload => requestPayload,
			),
		)
		.handleAction([ updateReservationAsync.success, updateReservationStatusAsync.success ], (state, action) => state.map(stateItem => {
			const startDateHour = LocaleFromISO(action.payload.data.startDate).hour;
			const startDate = (startDateHour >= 0 && startDateHour < DAY_START_HOUR) ? LocaleFromISO(action.payload.data.startDate).minus({ day: 1 }) : LocaleFromISO(action.payload.data.startDate);
			if (
				stateItem.id !== startDate.toFormat("yyyy-MM-dd") ||
				stateItem.reducer.dataState !== DataState.PRESENT
			) {
				return stateItem;
			}

			return {
				...stateItem,
				reducer: {
					...stateItem.reducer,
					data: stateItem.reducer.data.map(reservation => {
						if (reservation.id !== action.payload.data.id) return reservation;
						return action.payload.data;
					}),
				},
			};
		}))
		.handleAction([ createAdminReservationAsync.success ], (state, action) => state.map(stateItem => {
			const startDateHour = LocaleFromISO(action.payload.data.startDate).hour;
			const startDate = (startDateHour >= 0 && startDateHour < DAY_START_HOUR) ? LocaleFromISO(action.payload.data.startDate).minus({ day: 1 }) : LocaleFromISO(action.payload.data.startDate);
			if (
				stateItem.id !== startDate.toFormat("yyyy-MM-dd") ||
				stateItem.reducer.dataState !== DataState.PRESENT
			) {
				return stateItem;
			}

			return {
				...stateItem,
				reducer: {
					...stateItem.reducer,
					data: [ ...stateItem.reducer.data, action.payload.data ],
				},
			};
		}))
		.handleAction([ deleteReservationByIdAsync.success ], (state, action) => state.map(stateItem => {
			if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

			return {
				...stateItem,
				reducer: {
					...stateItem.reducer,
					data: stateItem.reducer.data.filter(reservation => reservation.id !== action.payload.data.id),
				},
			};
		})),
	singleReservation: createReducer([] as ArrayStateReducer<DetailedReservation>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchReservationByIdAsync.request, fetchReservationByIdAsync.success, fetchReservationByIdAsync.failure ], handleBasicActionsForArray(fetchReservationByIdAsync))
		.handleAction([ updateReservationAsync.success, generateReservationInvoiceAsync.success ],
			(state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: action.payload.data,
						},
					}),
				)),
	adminRoomAvailabilities: createReducer([] as ArrayStateReducer<AdminAvailability[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction(
			[
				fetchAdminRoomAvailabilitiesAsync.request,
				fetchAdminRoomAvailabilitiesAsync.success,
				fetchAdminRoomAvailabilitiesAsync.failure,
			],
			handleBasicActionsForArray(
				fetchAdminRoomAvailabilitiesAsync,
				requestPayload => `${ requestPayload.roomId }-${ requestPayload.date }`,
			),
		)
		.handleAction([ deleteReservationByIdAsync.success ], (state, action) =>
			state.map(stateItem => {
				if (stateItem.reducer.dataState !== DataState.PRESENT) return stateItem;

				return {
					...stateItem,
					reducer: {
						...stateItem.reducer,
						data: stateItem.reducer.data.map(availability => {
							if (availability.reservations.every(reservation => reservation.id !== action.payload.data.id)) return availability;

							return {
								...availability,
								isReserved: false,
								reservations: [],
							};
						}),
					},
				};
			}),
		),
});

export default reducer;
