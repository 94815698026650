/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createAction } from "typesafe-actions";
import { UserScope } from "src/app/types/ui/user.types";
import { CalculatePurchasingProcessPricePayload, CalculateReservationPricePayload, CreateReservationPayload, FetchRoomAvailabilitiesPayload, UpdateReservationPayload, UpdateReservationStatusPayload } from "src/app/types/api/reservation.types";

export const uiFetchRoomAvailabilities = createAction("UI_RESERVATION__FETCH_ROOM_AVAILABILITIES")<FetchRoomAvailabilitiesPayload>();
export const uiCalculateAdminRoomReservationPrice = createAction("UI_RESERVATION__CALCULATE_ADMIN_ROOM_RESERVATION_PRICE")<CalculateReservationPricePayload>();
export const uiCreateReservation = createAction("UI_RESERVATION__CREATE_RESERVATION")<CreateReservationPayload>();
export const uiCreateAdminReservation = createAction("UI_RESERVATION__CREATE_ADMIN_RESERVATION")<CreateReservationPayload>();
export const uiUpdateReservation = createAction("UI_RESERVATION__UPDATE_RESERVATION")<UpdateReservationPayload>();
export const uiDeleteReservation = createAction("UI_RESERVATION__DELETE_RESERVATION")<number>();
export const uiReservationInit = createAction("UI_RESERVATION__INIT")<UserScope>();
export const uiCalculateReservationPrice = createAction("UI_RESERVATION__CALCULATE_RESERVATION_PRICE")<CalculatePurchasingProcessPricePayload>();
export const uiCalculateReservationProductsPrice = createAction("UI_RESERVATION__CALCULATE_RESERVATION_PRODUCTS_PRICE")<CalculatePurchasingProcessPricePayload>();
export const uiApplyDiscountCode = createAction("UI_RESERVATION__DISCOUNT_CODE")<CalculatePurchasingProcessPricePayload>();
export const uiFetchAdminRoomAvailabilities = createAction("UI_RESERVATION__FETCH_ADMIN_ROOM_AVAILABILITIES")<FetchRoomAvailabilitiesPayload>();
export const uiFetchAdminRoomAvailabilitiesDebounce = createAction("UI_RESERVATION__FETCH_ADMIN_ROOM_AVAILABILITIES_DEBOUNCE")<FetchRoomAvailabilitiesPayload>();
export const uiFetchAdminReservationsByDate = createAction("UI_RESERVATION__FETCH_ADMIN_RESERVATIONS_BY_DATE")<string>();
export const uiUpdateReservationStatus = createAction("UI_RESERVATION__UPDATE_RESERVATION_STATUS")<UpdateReservationStatusPayload>();
export const uiFetchReservationById = createAction("UI_RESERVATION__FETCH_RESERVATION_BY_ID")<number>();
export const uiSendReservationInvoice = createAction("UI_RESERVATION__SEND_RESERVATION_INVOICE")<number>();
export const uiGenerateReservationInvoice = createAction("UI_RESERVATION__GENERATE_RESERVATION_INVOICE")<number>();
