/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

function SingleSkeletonAvailability() {
	return (
		<div className="h-40 w-40 min-w-[160px] flex flex-col gap-6 items-center justify-center rounded-2xl border border-gray-200 animate-pulse">

			<div className="bg-gray-200 py-1.5 w-1/2 rounded-full"></div>
			<div className="bg-gray-200 py-1.5 w-1/2 rounded-full"></div>

			{/* button */}
			<div className="py-1 px-2 text-sm text-gray-300 bg-gray-200 rounded-lg">
				Rezerwuj
			</div>
		</div>
	);
}

export default SingleSkeletonAvailability;
