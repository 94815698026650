/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useLocation, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { PurchasingProcessBreadcrumbOutletContext } from "src/app/hoc/layouts/PurchasingProcessBreadcrumbs.layout";
import { isNull } from "src/app/utils/typeguards";
import { purchasingProcessFormStepRouteDictionary } from "src/app/utils/constants/dictionaries";
import PurchasingProcessAdditionsContainer from "src/app/containers/PurchasingProcess/PurchasingProcessAdditions.container";
import PurchasingProcessAgeModal from "src/app/views/PurchasingProcess/PurchasingProcessAgeModal.component";

function PurchasingProcessAdditionsView() {

	const {
		form,
		cart,
		categories,
		handleProductChange,
	} = useOutletContext<PurchasingProcessBreadcrumbOutletContext>();

	const {
		form: {
			venue,
		},
		handleChange,
	} = form;

	const routerLocation = useLocation();
	const [ isAgeModalOpen, toggleAgeModal ] = useState(!form.form.hasConfirmAgeInformationModal.value);

	useEffect(() => {
		if (isNull(venue.value)) return;
		if (routerLocation.pathname !== purchasingProcessFormStepRouteDictionary(venue.value.id)[ PurchasingProcessFormStep.ADDITIONS ]) return;
		handleChange("step", PurchasingProcessFormStep.ADDITIONS);
	}, []);

	return (
		<>
			<PurchasingProcessAdditionsContainer
				form={ form }
				cart={ cart }
				handleProductChange={ handleProductChange }
				categories={ categories }
			/>
			<PurchasingProcessAgeModal
				isOpen={ isAgeModalOpen }
				handleClose={ () => {
					toggleAgeModal(false);
					form.handleChange("hasConfirmAgeInformationModal", true);
				} }
			/>
		</>
	);
}

export default PurchasingProcessAdditionsView;
