/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Badge, Popover } from "flowbite-react";
import { CSSProperties } from "react";
import classNames from "classnames";
import ModelCell, { hasFallbackImage, hasImage, ModelCellProps } from "src/app/components/Utils/ModelCell.component";
import { Image, Nullable } from "src/app/types/util.types";
import { isNotNull } from "src/app/utils/typeguards";

type ExtendedType = { id: number | string, name: string };

type Props<T extends ExtendedType> = {
	list: T[]
	count: number
	getLink: Nullable<(id: T["id"]) => string>
	size?: "sm" | "lg"
	customRender?: ModelCellProps<T>["customRender"]
	className?: string
	style?: CSSProperties
	mainItemsWrapperStyle?: CSSProperties
} & (T extends { image: Nullable<Image> } ? { fallbackImage: ReactNode } : {});

function ModelListPopover<T extends ExtendedType>(props: Props<T>) {

	const {
		list,
		count,
		getLink,
		size = "lg",
		customRender,
		className,
		style,
		mainItemsWrapperStyle,
	} = props;

	return (
		<div
			style={ style }
			className={ classNames("flex items-center gap-2 max-w-max", className) }
		>
			<div style={ mainItemsWrapperStyle }>
				{
					list.length !== 0 &&
					list.map((model, index) =>
						index < count &&
                        <ModelCell
                            key={ model.id }
                            model={ list[ 0 ] }
                            link={ isNotNull(getLink) ? getLink(model.id) : null }
                            size={ size }
                            customRender={ customRender }
							image={ hasImage(model) ? model.image : null }
							fallbackImage={ hasFallbackImage(props) ? props.fallbackImage : null }
                        />
					)
				}
			</div>
			<Popover
				trigger="hover"
				placement="top-end"
				className="max-w-max z-50"
				content={
					<div className="flex flex-col items-start gap-2 bg-white p-2">
						{
							list.map((model, index) =>
								index >= count &&
                                <ModelCell
                                    key={ model.id }
									size={ size }
                                    model={ model }
                                    className="w-full"
                                    link={ isNotNull(getLink) ? getLink(model.id) : null }
                                    customRender={ customRender }
                                    image={ hasImage(model) ? model.image : null }
                                    fallbackImage={ hasFallbackImage(props) ? props.fallbackImage : null }
                                />
							)
						}
					</div>
				}
			>
				<div className="flex items-center gap-2 max-w-max">
					{
						list.length > count &&
                        <Badge color={ list.length !== 0 ? "success" : "warning" }>
							<span style={ { fontSize: (size === "sm" ? 12 : (size === "lg" ? 14 : 14)) } }>
								{ list.length !== 0 ? `+${ list.length - count }` : "Brak" }
							</span>
                        </Badge>
					}
				</div>
			</Popover>
		</div>
	);
}

export default ModelListPopover;