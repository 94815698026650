import { Form, FormActions, FormHookReturnType, FormItem, FormItemError, FormReducerKeys, ReducerForm } from "src/app/types/ui/form.types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { FormEvent, useContext, useEffect, useState } from "react";
import { isNotNull } from "src/app/utils/typeguards";
import { getAllFormValues, isFormValid, validateForm } from "src/app/utils/forms";
import { CallbackPromptContext } from "src/app/hoc/providers/CallbackPrompt.provider";

function useReducerForm<T>(
	formReducerName: FormReducerKeys,
	formActions: FormActions<T>,
	handleSubmit: (form: T) => void,
): FormHookReturnType<T> {
	const {
		form,
		validator,
		comparator,
	} = useSelector((state: RootState) => state.form[ formReducerName ] as ReducerForm<T>);
	const dispatch = useDispatch();

	const [ isSubmitted, toggleSubmitted ] = useState(false);

	const _handleSetForm = (form: Form<T>) => {
		dispatch(formActions.setForm({ form: form }));
	};

	const _handleChange = (prop: keyof T, value: any) => {
		dispatch(formActions.handleChange({ prop, value }));
	};

	const _handleBlur = (prop: keyof T) => {
		dispatch(formActions.handleBlur({ prop }));
	};

	const _handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
		isNotNull(e) && e.preventDefault();
		const newForm = validateForm(form, validator);
		_handleSetForm(newForm);
		if (isFormValid(newForm)) {
			toggleSubmitted(true);
			handleSubmit(getAllFormValues(newForm));
		}
	};

	const _toggleDisable = (prop: keyof T, isDisabled: boolean) => {
		dispatch(formActions.toggleDisable({ prop, isDisabled }));
	};

	const _setError = (prop: keyof T, error: FormItemError) => {
		dispatch(formActions.setError({ prop, error }));
	};

	const _resetForm = () => {
		dispatch(formActions.resetForm());
	};

	const _isFormEdited = () =>
		Object
			.entries<FormItem<any>>(form)
			.some(([ key, entry ]) => {
				const typedKey = key as keyof T;
				const comparatorFunction = comparator[ typedKey ] ?? ((a, b) => a === b);
				return !comparatorFunction(entry.value, entry.initialValue);
			});

	useEffect(() => {
		if (_isFormEdited() && isSubmitted) {
			toggleSubmitted(false);
		}
	}, [ _isFormEdited() ]);

	const callbackPromptContext = useContext(CallbackPromptContext);

	useEffect(() => {
		if (isNotNull(callbackPromptContext?.toggleComponentEdited)) {
			callbackPromptContext.toggleComponentEdited(Object.keys(form).join(), !isSubmitted && _isFormEdited());
		}
	}, [ !isSubmitted && _isFormEdited() ]);

	return {
		form,
		setForm: _handleSetForm,
		handleChange: _handleChange,
		handleBlur: _handleBlur,
		handleSubmit: _handleSubmit,
		toggleDisable: _toggleDisable,
		setError: _setError,
		resetForm: _resetForm,
		isFormEdited: !isSubmitted && _isFormEdited(),
		// Solution for redirecting after submit
		// and not working when backend returns error
		// (then frontend will allow to redirect to another page without alert)
	};
}

export default useReducerForm;
