/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import ReservationErrorBoundary from "src/app/hoc/boundaries/ReservationError.boundary";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { purchasingProcessFormStepRouteDictionary } from "src/app/utils/constants/dictionaries";
import { SimpleVenue } from "src/app/types/api/venue.types";
import { SimpleProduct } from "src/app/types/api/product.types";
import { Cart, CartItem, CartItemType, PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { Nullable } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import useReservingData from "src/app/utils/hooks/useReservingData";
import { Category } from "src/app/types/api/category.types";

type Props = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	fetchAvailabilities: (venue: number, date: string) => void
	venues: SimpleVenue[]
	products: SimpleProduct[]
	categories: Category[]
	isAtLeastOneAvailabilityChosen: boolean
}

function PurchasingProcessLayoutInner(props: Props) {

	const form = props.form;

	const {
		form: {
			form: {
				step,
				venue,
				products: formProducts,
			},
		},
		venues,
		products,
		fetchAvailabilities,
		categories,
		isAtLeastOneAvailabilityChosen,
	} = props;

	const reservingData = useReservingData();

	const { venueId } = useParams();

	const navigate = useNavigate();

	const routeLocation = useLocation();

	useEffect(() => {
		if (step.value === PurchasingProcessFormStep.AVAILABILITIES) {
			const localVenueId = venue.value?.id ?? isNotNull(venueId) ? Number(venueId) : null;
			if (isNull(localVenueId)) return;
			navigate(purchasingProcessFormStepRouteDictionary(localVenueId)[ PurchasingProcessFormStep.AVAILABILITIES ]);
			return;
		}
		if (isNull(venue.value)) return;
		if (routeLocation.pathname !== purchasingProcessFormStepRouteDictionary(venue.value.id)[ step.value ]) {
			navigate(purchasingProcessFormStepRouteDictionary(venue.value.id)[ step.value ]);
		}
	}, [ step.value ]);

	const cartAvailabilities: Nullable<CartItem> = isNull(reservingData) ? null : {
		title: "SESJA KARAOKE",
		type: CartItemType.SESSION,
		date: reservingData.date,
		startTime: reservingData.startTime,
		endTime: reservingData.endTime,
		price: reservingData.availabilities.reduce<number>((availabilityPrev, availabilityCurrent) => availabilityPrev + availabilityCurrent.price , 0),
		image: reservingData.room.image,
		bulletPoints: [
			`max ${ reservingData.room.maxPeople } osób`,
			`Pokój ${ reservingData.room.name }`,
			`${ venue.value?.name }`,
		],
	}

	const cartProducts: CartItem[] = formProducts.value.reduce<CartItem[]>((prev, current) => {
		if (current.count === 0) return prev;
		return [ ...prev, {
			title: current.name,
			type: CartItemType.PRODUCT,
			price: Number((Number(current.price) * current.count).toFixed(2)), //formatting to decimal number
			image: current?.image,
			product: current,
			bulletPoints: [ current.description ?? "" ],
		} ]
	}, []);

	const cart: Cart = isNotNull(cartAvailabilities) ? [ ...cartProducts, cartAvailabilities ] : cartProducts

	return (
		<main className="w-full">
			<ReservationErrorBoundary>
				<Outlet context={ {
					form,
					cart,
					fetchAvailabilities,
					venues,
					products,
					categories,
					isAtLeastOneAvailabilityChosen,
				} }/>
			</ReservationErrorBoundary>
		</main>
	);
}

export default PurchasingProcessLayoutInner;
