import { RouteObject } from "react-router/dist/lib/context";
import VenueListView from "src/app/views/Venue/VenueList.view";
import VenueProfileView from "src/app/views/Venue/VenueProfile.view";
import VenueCreateView from "src/app/views/Venue/VenueCreate.view";

const venueRoutes: RouteObject = {
	path: "venues",
	children: [
		{
			index: true,
			element: <VenueListView/>,
		}, {
			path: "create",
			element: <VenueCreateView/>,
		}, {
			path: ":venueId",
			element: <VenueProfileView/>,
		},
	],
};

export default venueRoutes;
