import { FormItem } from "src/app/types/ui/form.types";
import { Nullable } from "src/app/types/util.types";
import { Label, LabelProps, TextInput, TextInputProps } from "flowbite-react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { RefAttributes } from "react";
import classNames from "classnames";

type Props = {
	className?: string
	formItem: FormItem<Nullable<string | number>>
	name: string // Friendly name for HTML Semantic
	inputProps?: TextInputProps & RefAttributes<HTMLInputElement>
	labelProps?: LabelProps
	label?: string | ReactNode
	displayErrorMessage?: boolean
	role?: string
}

export const INPUT_EXTERNAL_ICON_CLASSNAMES = "h-5 w-5 text-gray-500 dark:text-gray-400";

function Input(props: Props) {
	const {
		className,
		formItem,
		name,
		inputProps = {},
		labelProps = {},
		label,
		displayErrorMessage = true,
		role,
	} = props;

	const _getErrorMessage = () => {
		if (isNull(formItem.error)) return null;

		if (displayErrorMessage) {
			if (formItem.error instanceof Array) {
				return (
					<ul className="form__error--list">
						{
							formItem.error.map((err, i) =>
								<li key={ i } className="mb-1">{ err }</li>,
							)
						}
					</ul>
				);
			} else {
				return formItem.error;
			}
		} else {
			return " ";
		}
	};

	return (
		<div
			className={
				classNames(
					"flex flex-col gap-y-0.5",
					className,
				)
			}
			role={ role }
		>
			{
				isNotNull(label) &&
                <Label
                    htmlFor={ name }
					{ ...labelProps }
                >
					{ label }
                </Label>
			}
			<TextInput
				{ ...inputProps }
				name={ name }
				color={ isNotNull(formItem.error) ? "failure" : undefined }
				helperText={ isNotNull(formItem.error) ? <><span className="font-medium" role="input-error">{ _getErrorMessage() }</span></> : undefined }
				disabled={ formItem.disabled || inputProps.disabled }
				value={ isNotNull(formItem.value) ? formItem.value : undefined }
				className={ classNames(inputProps?.className) }
			/>
		</div>
	);
}

export default (Input);
