/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CSSProperties, useState } from "react";
import { ReservationStatus } from "src/app/types/api/reservation.types";
import ReservationStatusBadge from "src/app/components/Room/Reservation/ReservationStatusBadge.component";
import classNames from "classnames";
import ChooseReservationStatusInner from "src/app/components/Form/ChooseReservationStatus/ChooseReservationStatusInner.component";

type Props = {
	status: ReservationStatus
	onChange: (status: ReservationStatus) => void
	isProcessing?: boolean
	className?: string
	style?: CSSProperties
	onMouseEnter?: () => void
}

function ChooseReservationStatus(props: Props) {

	const {
		status,
		onChange,
		isProcessing = false,
		className,
		style,
		onMouseEnter,
	} = props;

	const [ isOpen, toggleOpen ] = useState(false);

	return (

		<div
			className={ classNames("relative", className) }
			style={ style }
			onMouseEnter={ onMouseEnter }
		>
			<ReservationStatusBadge
				status={ status }
				mode="dropdown"
				isOpen={ isOpen }
				onClick={ () => toggleOpen(isOpen => !isOpen) }
				className="z-30"
				isProcessing={ isProcessing }
			/>
			{
				isOpen &&
                <ChooseReservationStatusInner
					onChange={ onChange }
					toggleOpen={ toggleOpen }
				/>
			}
		</div>
	);
}

export default ChooseReservationStatus;

