/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DetailedRoom } from "src/app/types/api/room.types";
import Avatar from "src/app/components/Utils/Avatar.component";
import { getInitials } from "src/app/utils/ui";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		room: DetailedRoom
	};

function RoomHeader(props: Props) {

	const {
		room,
		bodySize: {
			width: bodyWidth,
		},
	} = props;

	return (
		<div className="flex gap-6 items-center">
			<Avatar
				className="hidden sm:block [&_span]:text-3xl lg:[&_span]:text-5xl [&_span]:leading-none"
				alt={ `${ room.name }-avatar` }
				img={ room.image?.thumb }
				placeholderInitials={ getInitials(room.name) }
				size={ bodyWidth < 1024 ? "lg" : "xl" }
			/>
			<div className="w-full flex flex-wrap gap-4 justify-between">
				<div className="flex flex-col gap-2.5">
					<div className="flex flex-col gap-2.5">
						<div className="flex items-center gap-4">
							<Avatar
								className="sm:hidden [&_span]:text-xl [&_span]:leading-none"
								alt={ `${ room.name }-avatar` }
								img={ room.image?.thumb }
								placeholderInitials={ getInitials(room.name) }
								size="md"
							/>
							<div className="flex flex-col gap-2">
								<h2 className="text-xl sm:text-2xl font-semibold">
									{ room.name }
								</h2>
								<div className="text-sm">
									<span>{ "Utworzono " }</span>
									<Moment fromNow>
										{ room.createdAt }
									</Moment>
									{
										!moment(room.updatedAt).isSame(room.createdAt) &&
                                        <>
											{ ` (zaaktualizowano ` }
                                            <Moment fromNow>
												{ room.updatedAt }
                                            </Moment>
											{ `)` }
                                        </>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodySize: state.ui.layout.bodySize,
});

export default connect(mapStateToProps)(RoomHeader);
