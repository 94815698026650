import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchAdminRoomsAsync } from "src/app/store/features/room/room.actions";
import RoomListContainer from "src/app/containers/Room/RoomList.container";
import { uiCreateRoom, uiDeleteRoom } from "src/app/store/features/ui/room/ui.room.actions";
import { getRoomsWithVenues } from "src/app/store/features/room/room.selectors";
import { fetchAdminVenuesAsync } from "src/app/store/features/venue/venue.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function RoomListView(props: Props) {
	const {
		roomsWithVenues,
		fetchRooms,
		fetchVenues,
		createRoom,
		deleteRoom,
	} = props;

	const _handleRequest = () => {
		fetchRooms();
		fetchVenues();
	};

	return (
		<CachedThenFreshStrategy
			request={ _handleRequest }
			state={ roomsWithVenues }
		>
			{
				({ rooms, venues }) =>
					<RoomListContainer
						rooms={ rooms }
						venues={ venues }
						onCreate={ createRoom }
						onDelete={ deleteRoom }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	roomsWithVenues: getRoomsWithVenues(state),
});

const mapDispatchToProps = {
	fetchRooms: fetchAdminRoomsAsync.request,
	fetchVenues: fetchAdminVenuesAsync.request,
	createRoom: uiCreateRoom,
	deleteRoom: uiDeleteRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomListView);
