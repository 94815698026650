import { useEffect, useState } from "react";
import { FormValidator } from "src/app/types/ui/form.types";
import { compareStrings, createFormField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Button, Modal } from "flowbite-react";
import SnackbarComponent from "src/app/components/Message/Snackbar.component";
import { SnackbarMessageType } from "src/app/types/ui/message.types";
import Input from "src/app/components/Form/Input.component";

type Props = {
	title: string
	warning: string | ReactNode
	isOpen: boolean
	handleClose: () => void
	confirmWord?: string
	onConfirm: () => void,
	isLoading?: boolean
	confirmText?: string
};

type ConfirmForm = {
	confirmWord: string
};

function ConfirmModal(props: Props) {
	const {
		title,
		warning,
		confirmWord,
		onConfirm,
		isLoading = false,
		confirmText = "Potwierdź",
		handleClose,
		isOpen,
	} = props;

	useEffect(() => {
		if (props.isOpen) {
			resetForm();
		}
	}, [ props.isOpen ]);

	const validator: FormValidator<ConfirmForm> = {
		confirmWord: (confirmWord) => compareStrings("Provided word", confirmWord, props.confirmWord ?? "", false),
	};

	const _handleSubmit = () => {
		if (isNotNull(props.isLoading) && !props.isLoading) toggleShouldBeOpen(true);
		onConfirm();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		confirmWord: createFormField("", { disabled: isNull(props.confirmWord) }),
	}, validator, _handleSubmit);

	const [ shouldBeOpen, toggleShouldBeOpen ] = useState(false);

	useEffect(() => {
		if (isNotNull(props.isLoading) && !props.isLoading) toggleShouldBeOpen(false);
	}, [ props.isLoading ]);

	return (
		<Modal
			show={ isOpen || shouldBeOpen }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || shouldBeOpen) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				{ title }
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body>
					<div className="space-y-6">
						<SnackbarComponent
							type={ SnackbarMessageType.WARNING }
							message={ warning }
						/>
						{
							isNotNull(confirmWord) &&
                            <Input
                                className="mb-4"
                                formItem={ form.confirmWord }
                                label={ `Wpisz "${ confirmWord }" aby kontynuować` }
                                name="confirmWord"
                                inputProps={ {
									type: "text",
									autoFocus: true,
									onChange: (e) => handleChange("confirmWord", e.target.value),
									onBlur: () => handleBlur("confirmWord"),
								} }
                            />
						}
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-end border-none pt-0">
					<Button onClick={ handleClose } color="gray">
						Anuluj
					</Button>
					<Button
						// color="primary"
						isProcessing={ isLoading }
						disabled={ isNotNull(confirmWord) && confirmWord !== form.confirmWord.value }
						type="submit"
					>
						{ confirmText }
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

export default (ConfirmModal);
