import { DetailedProduct, UpdateProductPayload } from "src/app/types/api/product.types";
import ProductHeader from "src/app/components/Product/ProductHeader.component";
import { TbListDetails } from "react-icons/tb";
import QueryParamTabs from "src/app/components/Utils/QueryParamTabs.component";
import ProductInformation from "src/app/components/Product/ProductInformation.component";

type Props = {
	product: DetailedProduct
	onUpdate: (payload: UpdateProductPayload) => void
};

export enum ProductProfileTabs {
	OVERVIEW = "overview"
}

function ProductProfileContainer(props: Props) {
	const {
		product,
		onUpdate,
	} = props;

	return (
		<div className="flex flex-col gap-4">
			<ProductHeader
				product={ product }
			/>
			<QueryParamTabs
				style="underline"
				items={ {
					[ ProductProfileTabs.OVERVIEW ]: {
						name: "Informacje",
						icon: TbListDetails,
						component: (
							<ProductInformation
								product={ product }
								onUpdate={ onUpdate }
							/>
						),
					},
				} }
			/>
		</div>
	);
}

export default (ProductProfileContainer);
