/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Moment from "react-moment";
import moment from "moment";
import { DetailedVenue } from "src/app/types/api/venue.types";

type Props = {
	venue: DetailedVenue
};

function VenueHeader(props: Props) {

	const {
		venue,
	} = props;

	return (
		<div className="flex gap-6 items-center">
			<div className="w-full flex flex-wrap gap-4 justify-between">
				<div className="flex flex-col gap-2.5">
					<div className="flex flex-col gap-2.5">
						<div className="flex items-center gap-4">
							<div className="flex flex-col gap-2">
								<h2 className="text-xl sm:text-2xl font-semibold">
									{ venue.name }
								</h2>
								<div className="text-sm">
									<span>{ "Utworzono " }</span>
									<Moment fromNow>
										{ venue.createdAt }
									</Moment>
									{
										!moment(venue.updatedAt).isSame(venue.createdAt) &&
                                        <>
											{ ` (zaaktualizowano ` }
                                            <Moment fromNow>
												{ venue.updatedAt }
                                            </Moment>
											{ `)` }
                                        </>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VenueHeader;
