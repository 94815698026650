/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useOutletContext } from "react-router-dom";
import PurchasingProcessBreadcrumbsLayoutInner from "src/app/components/PurchasingProcess/util/PurchasingProcessBreadcrumbsLayoutInner.component";
import { FormHookReturnType } from "src/app/types/ui/form.types";
import { mapFormToCalculatePricePayload, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { Cart } from "src/app/types/api/reservation.types";
import { Venue } from "src/app/types/api/venue.types";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { addLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { FormProduct } from "src/app/types/api/product.types";
import { isNull } from "src/app/utils/typeguards";
import { uiCalculateReservationProductsPrice } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { Nullable } from "src/app/types/util.types";
import { PurchasingProcessOutletContext } from "src/app/hoc/layouts/PurchasingProcess.layout";
import useReservingData from "src/app/utils/hooks/useReservingData";
import { Category } from "src/app/types/api/category.types";

export type PurchasingProcessBreadcrumbOutletContext = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	venues: Venue[]
	cart: Cart
	categories: Category[]
	fetchAvailabilities: (venueId: number, date: string) => void
	handleProductChange: (productId: number, number: Nullable<number>) => void
	handleCalculatePrice: () => void
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps

function PurchasingProcessBreadcrumbsLayout(props: Props) {

	const {
		calculateProductsPrice,
		addLoadingRecord,
	} = props;

	const {
		form,
		venues,
		cart,
		categories,
		isAtLeastOneAvailabilityChosen,
	} = useOutletContext<PurchasingProcessOutletContext>();

	const reservingData = useReservingData();

	const handleProductChange = (productId: number, number: Nullable<number>) => {
		const mapProducts = (products: FormProduct[]) => products.map(product => {
			if (product.id !== productId) return product;
			return {
				...product,
				count: isNull(number) ? 0 : product.count + number,
			}
		})

		const mappedCalculatePricePayload = mapFormToCalculatePricePayload({
			...form.form,
			products: {
				...form.form.products,
				value: mapProducts(form.form.products.value),
			}
		}, reservingData);
		if (isNull(mappedCalculatePricePayload)) return;

		form.handleChange("products", mapProducts(form.form.products.value));
		addLoadingRecord({ loadableType: LoadableType.CALCULATE_RESERVATION_PRICE })
		calculateProductsPrice(mappedCalculatePricePayload);
	};

	return (
		<PurchasingProcessBreadcrumbsLayoutInner
			form={ form }
			venues={ venues }
			cart={ cart }
			handleProductChange={ handleProductChange }
			categories={ categories }
			isAtLeastOneAvailabilityChosen={ isAtLeastOneAvailabilityChosen }
		/>
	);
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	calculateProductsPrice: uiCalculateReservationProductsPrice,
	addLoadingRecord: addLoadingRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingProcessBreadcrumbsLayout);
