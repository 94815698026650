/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { HiInformationCircle, HiOutlinePlusCircle } from "react-icons/hi";
import { Tooltip } from "flowbite-react";
import React from "react";
import { FormProduct } from "src/app/types/api/product.types";
import PurchasingProcessSmallButton from "src/app/components/PurchasingProcess/util/PurchasingProcessSmallButton.component";
import { Nullable } from "src/app/types/util.types";
import classNames from "classnames";
import Avatar from "src/app/components/Utils/Avatar.component";
import { isNotNull } from "src/app/utils/typeguards";
import PlaceholderImage from "src/app/components/PurchasingProcess/util/PlaceholderImage.component";

type Props = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	product: FormProduct
	handleProductChange: (productId: number, number: Nullable<number>) => void
	className?: string
}

function PurchasingProcessProduct(props: Props) {

	const {
		product: {
			id: productId,
			name,
			description,
			price,
			count,
			image,
		},
		handleProductChange,
		className = "",
	} = props;

	const onProductAdd = () => handleProductChange(productId, 1);

	return (
		<div className={ classNames(
			"flex flex-col items-center justify-between",
			"!h-auto w-[100px] min-w-[100px] max-w-[100px] [&>div>#product-image]:max-w-[100px]",
			"sm:w-[150px] sm:min-w-[150px] sm:max-w-[150px] sm:[&>div>#product-image]:max-w-[150px]",
			"lg:!h-full lg:[&>div>#product-image]:min-w-0 lg:w-auto lg:min-w-0 lg:[&>div>#product-image]:max-w-auto",
			className
		) }>
			<div className="flex flex-col items-center">
				<div
					id="product-image"
					className="w-full rounded-2xl object-cover aspect-square overflow-hidden"
				>
					{
						isNotNull(image)
							?
							<Avatar
								img={ image?.thumb }
								className="[&>div>img]:h-full [&>div>img]:w-full"
							/>
							:
							<PlaceholderImage/>
					}
				</div>
				<div className={ classNames(
					"flex items-center w-full justify-between mb-3 text-myPrimary-purple-500 mt-2",
					"flex-col-reverse",
					"lg:flex-row",
				) }>
					<span className={ classNames(
						"uppercase font-bold",
						"text-xs",
						"lg:text-sm",
					) }>
						{ name }
					</span>
					<div className={ classNames(
						"ml-auto",
						"lg:ml-0",
					) }>
						<Tooltip
							className={ classNames("max-w-[80vw] md:max-w-[500px]") }
							content={
								<span className="text-xs">
									{ description }
								</span>
							}
						>
							<HiInformationCircle className="h-5 w-5"/>
						</Tooltip>
					</div>
				</div>
			</div>
			<PurchasingProcessSmallButton
				onClick={ onProductAdd }
				className={ classNames(
					"[&>span]:p-[2px] max-h-[26px]",
					"sm:[&>span]:px-1 sm:[&>span]:py-1 sm:max-h-[unset]",
				) }
			>
				<span className="text-xs">
					{ `${ price } zł` }
				</span>
				<div className="hidden sm:block mx-2">|</div>
				<div className="hidden sm:block">
					{ `dodaj ${ count > 0 ? `(${ count })` : "" }` }
				</div>
				<div className="flex sm:hidden ml-2 rounded-full items-center justify-center text-[10px] aspect-square">
					<HiOutlinePlusCircle className="h-4 w-4"/>
				</div>
			</PurchasingProcessSmallButton>
		</div>
	);
}

export default PurchasingProcessProduct;
