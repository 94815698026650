/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useLocation, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import { PurchasingProcessFormStep } from "src/app/types/api/reservation.types";
import { PurchasingProcessBreadcrumbOutletContext } from "src/app/hoc/layouts/PurchasingProcessBreadcrumbs.layout";
import { isNull } from "src/app/utils/typeguards";
import { purchasingProcessFormStepRouteDictionary } from "src/app/utils/constants/dictionaries";
import PurchasingProcessPaymentContainer from "src/app/containers/PurchasingProcess/PurchasingProcessPayment.container";

function PurchasingProcessPaymentView() {

	const {
		form,
		cart,
		handleProductChange,
	} = useOutletContext<PurchasingProcessBreadcrumbOutletContext>()

	const {
		form: {
			venue,
		},
		handleChange,
	} = form;

	const routerLocation = useLocation();

	useEffect(() => {
		if (isNull(venue.value)) return;
		if (routerLocation.pathname !== purchasingProcessFormStepRouteDictionary(venue.value.id)[ PurchasingProcessFormStep.PAYMENT ]) return;
		handleChange("step", PurchasingProcessFormStep.PAYMENT);
	}, []);

	return (
		<PurchasingProcessPaymentContainer
			form={ form }
			cart={ cart }
			handleProductChange={ handleProductChange }
		/>
	);
}

export default PurchasingProcessPaymentView;
