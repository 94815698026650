/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceOrPushObject, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { DetailedDiscountCode, DiscountCode } from "src/app/types/api/discountCode.types";
import { createDiscountCodeAsync, deleteDiscountCodeByIdAsync, fetchDiscountCodeByIdAsync, fetchDiscountCodesAsync, updateDiscountCodeAsync } from "src/app/store/features/discountCode/discountCode.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
	discountCodes: createReducer(initialStateReducer as StateReducer<DiscountCode[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchDiscountCodesAsync.request, fetchDiscountCodesAsync.success, fetchDiscountCodesAsync.failure ], handleBasicActions(fetchDiscountCodesAsync))
		.handleAction([ updateDiscountCodeAsync.success, createDiscountCodeAsync.success ], (state, action) => {
			if (state.dataState !== DataState.PRESENT) return state;
			return {
				...state,
				data: replaceOrPushObject(state.data, action.payload.data, (a, b) => a.id === b.id),
			}
		})
		.handleAction([ deleteDiscountCodeByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		}),
	singleDiscountCode: createReducer([] as ArrayStateReducer<DetailedDiscountCode>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchDiscountCodeByIdAsync.request, fetchDiscountCodeByIdAsync.success, fetchDiscountCodeByIdAsync.failure ], handleBasicActionsForArray(fetchDiscountCodeByIdAsync))
		.handleAction([ updateDiscountCodeAsync.success ],
			(state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: action.payload.data,
						},
					}),
				)),
});

export default reducer;
