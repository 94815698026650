/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Room, SimpleRoom } from "src/app/types/api/room.types";
import { FormProduct, ReservationProduct } from "src/app/types/api/product.types";
import { ClientDiscountCode, DiscountCode, DiscountCodeType } from "src/app/types/api/discountCode.types";
import { Image, Nullable } from "src/app/types/util.types";
import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";
import { AdminReservationPayloadProduct } from "src/app/utils/constants/roomReservation.form";
import { SimpleUser } from "src/app/types/api/user.types";
import { Payment } from "src/app/types/api/payment.types";

export enum ReservationAbility {
	VIEW = "reservation.view",
	UPDATE = "reservation.update",
	DELETE = "reservation.delete",
	DOWNLOAD = "reservation.downloadInvoice",
	SEND = "reservation.sendInvoiceEmail",
	GENERATE = "reservation.generateInvoice"
}

export enum ReservationStatus {
	NEW = "NEW",
	PAID = "PAID",
	UNPAID = "UNPAID",
	CANCELLED = "CANCELLED",
	ENDED = "ENDED",
}

export type SimpleReservation =
	{
		id: number

		roomId: number
		room: Room

		startDate: string
		endDate: string

		products: Nullable<ReservationProduct[]>
		people: number

		name: string
		surname: string
		email: string
		phone: string

		reservingPerson: Nullable<string>
		comment: Nullable<string>

		status: ReservationStatus

		price: string
		discountedPrice: string
		discountCodeId: Nullable<number>
		discountCode: Nullable<ClientDiscountCode>

		isMarketingConsent: boolean

		organizationId: string
		organization: Nullable<SimpleOrganization>

		createdByUserId: Nullable<number>
		modifiedByUserId: Nullable<number>

		invoice: string
		isInvoiceSent: boolean

		createdAt: string
		updatedAt: string

		meta: {
			abilities: ReservationAbility[]
		}
	}
	& ({
		   isForCompany: true
		   companyName: string
		   companyNip: string
		   companyAddress: string
		   companyPostCode: string
		   companyCity: string
	   } | {
		   isForCompany: false
	   })

export type Reservation =
	SimpleReservation
	& {
		payment: Nullable<Payment>
		createdByUser: Nullable<SimpleUser>
		modifiedByUser: Nullable<SimpleUser>
	}

export type DetailedReservation =
	Reservation
	& {
		organization: Organization
		discountCode: Nullable<DiscountCode>
	};

export type ReservationPrice = {
	price: string
	discountedPrice: Nullable<string>
	discountCode: Nullable<ClientDiscountCode>
}

export type VenueAvailabilities<T = AdminAvailability> = {
	roomId: number
	room: SimpleRoom
	availabilities: T[]
}

export enum PurchasingProcessFormStep {
	AVAILABILITIES = "AVAILABILITIES",
	ADDITIONS = "ADDITIONS",
	PAYMENT = "PAYMENT",
	CONFIRMATION = "CONFIRMATION",
	FAILED = "FAILED",
	PROCESSING = "PROCESSING",
}

export enum ReservationRoute {
	AVAILABILITIES = ":venueId?",
	ADDITIONS = ":venueId?/additions",
	PAYMENT = ":venueId?/payment",
	CONFIRMATION = ":venueId?/confirmation",
	FAILED = ":venueId?/failed",
	PROCESSING = ":venueId?/processing",
}

export type PublicAvailability = {
	startTime: string
	endTime: string
	price: number
	isReserved: boolean
}

export type AdminAvailability =
	PublicAvailability
	& {
		reservations: Reservation[]
	};

export type AvailabilitiesTimeReduce = {
	isFinalArray: boolean
	availabilities: AdminAvailability[]
}

export type CalculateReservationPricePayload = {
	reservationId?: number
	roomId: number
	startDate: string
	endDate: string
	products?: {
		id: number
		quantity: number
	}[]
	discountCode?: string
}

export type CalculatePurchasingProcessPricePayload =
	CalculateReservationPricePayload
	& {
		maxPeople?: number
		nextStep?: PurchasingProcessFormStep,
		debounceTime?: number
	}

export type FetchVenueAvailabilitiesPayload = {
	venueId: number
	date: string // yyyy-MM-DD
}

export type FetchRoomAvailabilitiesPayload = {
	roomId: number
	date: string // yyyy-MM-DD
	loadableId?: string | number
}

export type UpdateReservationStatusPayload = {
	reservationId: number
	status: ReservationStatus
}

export type CreateReservationPayload =
	{
		date: string,
		roomId: number
		startDate: string
		endDate: string
		people: number
		name: string
		surname: string
		email: string
		phone: string
		reservingPerson: string
		comment: string
		isMarketingConsent: boolean
		products?: AdminReservationPayloadProduct[]
		discountCode?: string
	}
	& ({
		   isForCompany: false
	   } | {
		   isForCompany: true
		   companyName: string
		   companyNip: string
		   companyAddress: string
		   companyPostCode: string
		   companyCity: string
	   })

export type UpdateReservationPayload =
	{
		id: number
		dateToRefresh?: string // frontend field

		roomId?: number
		startDate?: string
		endDate?: string

		people?: number

		name?: string
		surname?: string
		email?: string
		phone?: string
		isMarketingConsent?: boolean
		reservingPerson?: string
		comment?: string
		status?: ReservationStatus

		discountCode?: string
		products?: AdminReservationPayloadProduct[]
	}
	& ({
		   isForCompany?: false
	   } | {
		   isForCompany?: true
		   companyName?: string
		   companyNip?: string
		   companyAddress?: string
		   companyPostCode?: string
		   companyCity?: string
	   })

export type DateRoomAvailabilities<T = FormAvailability> = {
	date: string
	roomAvailabilities: VenueAvailabilities<T>[]
}

export enum CartItemType {
	SESSION = "SESSION",
	PRODUCT = "PRODUCT"
}

export type CartItem =
	{
		title: string
		price: number
		bulletPoints: string[]
		image: Nullable<Image>
	}
	& ({
		   type: CartItemType.PRODUCT
		   product: FormProduct
	   } | {
		   type: CartItemType.SESSION
		   date: string
		   startTime: string
		   endTime: string
	   });

export type Cart = CartItem[]

export type FormAvailability =
	AdminAvailability
	& {
		localId: number
		state: AvailabilityState
	}

export enum AvailabilityState {
	OPEN = "OPEN",
	RESERVED = "RESERVED",
	RESERVING = "RESERVING",
	EXTENDABLE = "EXTENDABLE",
	PAST = "PAST",
}

export type DiscountCodePayload =
	{ amount: string, type: DiscountCodeType.AMOUNT }
	| { percentage: number, type: DiscountCodeType.PERCENTAGE }

export type ReservationDiscountCode =
	{ value: Nullable<string> }
	& DiscountCodePayload;
