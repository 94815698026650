import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { getProductById } from "src/app/store/features/product/product.selectors";
import { fetchProductByIdAsync } from "src/app/store/features/product/product.actions";
import ProductProfileContainer from "src/app/containers/Product/ProductProfile.container";
import { useParams } from "react-router-dom";
import { uiUpdateProduct } from "src/app/store/features/ui/product/ui.product.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function ProductProfileView(props: Props) {
	const {
		getProduct,
		fetchProductById,
		updateProduct,
	} = props;

	const { productId = "0" } = useParams();

	return (
		<ForceFreshStrategy
			request={ () => fetchProductById(+productId) }
			state={ getProduct(+productId) }
		>
			{
				product =>
					<ProductProfileContainer
						product={ product }
						onUpdate={ updateProduct }
					/>
			}
		</ForceFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	getProduct: (id: number) => getProductById(state, id),
});

const mapDispatchToProps = {
	fetchProductById: fetchProductByIdAsync.request,
	updateProduct: uiUpdateProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductProfileView);
