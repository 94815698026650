/* File where we type function with return type X is Y - our custom type guards */
import { Nullable } from "src/app/types/util.types";
import { DataState, StateReducer } from "src/app/types/redux.types";

export const isNotNull = <T>(object: Nullable<T>): object is T => object != null;

export const isNull = <T>(object: Nullable<T>): object is null | undefined => object == null;

export const isString = (object: any): object is string => typeof object === "string";

export const isEmptyString = (object: any): object is "" => typeof object === "string" && object.trim() === "";

export const isNumber = (object: any): object is number => typeof object === "number";

export const isObject = (object: any): object is object => typeof object === "object";

export const isArray = (object: any): object is any[] => Array.isArray(object);

export const isDataPresentInReducer = <T>(reducer: StateReducer<T>): reducer is StateReducer<T> & { data: T, fetchedAt: string } => {
	return reducer.dataState === DataState.PRESENT && isNotNull(reducer.data) && isNotNull(reducer.fetchedAt);
}
