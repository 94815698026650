import { lazy } from "react";
import { RouteObject } from "react-router/dist/lib/context";

const CalendarView = lazy(() => import("src/app/views/Calendar/Calendar.view"));

const calendarRoutes: RouteObject = {
	index: true,
	element: <CalendarView/>,
};

export default calendarRoutes;
