import { useEffect } from "react";
import { DataState, ErrorState, LoadingState } from "src/app/types/redux.types";
import { isNotNull, isString } from "src/app/utils/typeguards";
import { GridLoader } from "react-spinners";
import ErrorWrapper from "src/app/components/Message/ErrorWrapper.component";
import { StrategyProps } from "src/app/types/ui/caching.types";

function ForceFreshStrategy<T>(props: StrategyProps<T, (data: T) => JSX.Element>) {

	const {
		request,
		unmount,
		state,
		children,
		useEffectDependency,
		withoutLoading = false,
	} = props;

	useEffect(() => {
		request();

		return () => {
			isNotNull(unmount) && unmount();
		};
	}, [ useEffectDependency ]);

	if (state.errorState === ErrorState.PRESENT) {
		const error = state.errors[ 0 ];
		const errorMessage = isString(error.message) ? error.message : (error.message?.message ?? "Unknown error");
		return (
			<ErrorWrapper
				codeName={ error.codeName }
				message={ errorMessage }
				buttonText="Spróbuj ponownie"
				onButtonClick={ () => request() }
				isLoading={ state.loadingState === LoadingState.LOADING }
			/>
		);
	} else if (state.dataState === DataState.PRESENT && state.loadingState === LoadingState.NOT_LOADING) {
		return children(state.data);
	} else if (!withoutLoading) {
		return (
			<div className="w-full h-full flex items-center justify-center">
				<GridLoader size={ 30 } color="#EC5600"/>
			</div>
		);
	} else {
		return null;
	}
}

export default (ForceFreshStrategy);
