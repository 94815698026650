/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button } from "flowbite-react";
import { EnumDictionary } from "src/app/types/util.types";
import { HiChevronRight, HiOutlineX } from "react-icons/hi";
import classNames from "classnames";
import { AvailabilityState } from "src/app/types/api/reservation.types";

type Props = {
	state: LocalAvailabilityState
	className?: string
	onButtonClick: () => void
	disabled?: boolean
}

export type LocalAvailabilityState = AvailabilityState.OPEN | AvailabilityState.RESERVING | AvailabilityState.EXTENDABLE | AvailabilityState.PAST;

function AvailabilityButton(props: Props) {

	const {
		state,
		onButtonClick,
		className = "",
		disabled = false,
	} = props;

	const availabilityButtonDictionary: EnumDictionary<LocalAvailabilityState, { icon: ReactNode, name: string, color: string }> = {
		[ AvailabilityState.OPEN ]: {
			icon: null,
			name: "Rezerwuj",
			color: "orange-outline",
		},
		[ AvailabilityState.RESERVING ]: {
			icon: <HiOutlineX className="text-base mr-1"/>,
			name: "Usuń",
			color: "purple-full",
		},
		[ AvailabilityState.EXTENDABLE ]: {
			icon: <HiChevronRight className="text-base mr-1"/>,
			name: "Przedłuż",
			color: "orange-full",
		},
		[ AvailabilityState.PAST ]: {
			icon: null,
			name: "Przeszła",
			color: "gray-outline",
		},
	}


	return (
		<Button
			className={ classNames("text-myPrimary-orange-500 !transition-all !duration-200", className) }
			onClick={ onButtonClick }
			color={ availabilityButtonDictionary[ state ].color }
			size="xs"
			disabled={ disabled }
		>
			<span>{ availabilityButtonDictionary[ state ]?.icon ?? <></> }</span>
			<span>{ availabilityButtonDictionary[ state ].name }</span>
		</Button>
	);
}

export default AvailabilityButton;
