/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { DetailedRoom, Room } from "src/app/types/api/room.types";
import { copyRoomTimeRangesFromOtherRoomAsync, deleteRoomByIdAsync, fetchAdminRoomsAsync, fetchRoomByIdAsync, fetchRoomsAsync, updateRoomAsync, updateRoomTimeRangesAsync } from "src/app/store/features/room/room.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
	rooms: createReducer(initialStateReducer as StateReducer<Room[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchRoomsAsync.request, fetchRoomsAsync.success, fetchRoomsAsync.failure ], handleBasicActions(fetchRoomsAsync)),
	adminRooms: createReducer(initialStateReducer as StateReducer<Room[]>)
		.handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchAdminRoomsAsync.request, fetchAdminRoomsAsync.success, fetchAdminRoomsAsync.failure ], handleBasicActions(fetchAdminRoomsAsync))
		.handleAction([ deleteRoomByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		}),
	singleRoom: createReducer([] as ArrayStateReducer<DetailedRoom>)
		.handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchRoomByIdAsync.request, fetchRoomByIdAsync.success, fetchRoomByIdAsync.failure ], handleBasicActionsForArray(fetchRoomByIdAsync))
		.handleAction([ updateRoomAsync.success, updateRoomTimeRangesAsync.success, copyRoomTimeRangesFromOtherRoomAsync.success ],
			(state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: action.payload.data,
						},
					}),
				)),
});

export default reducer;
