import { Nullable, SelectOption } from "src/app/types/util.types";
import { FormItem } from "src/app/types/ui/form.types";
import Select, { components, GroupBase, OptionProps, ValueContainerProps } from "react-select";
import classNames from "classnames";
import { isArray, isEmptyString, isNotNull } from "src/app/utils/typeguards";
import { PublicBaseSelectProps } from "react-select/base";
import { getVenueSelectClassNames, getVenueSelectStyles } from "src/app/utils/ui";
import { SimpleVenue } from "src/app/types/api/venue.types";

type Props = {
	className?: string
	venues: SimpleVenue[]
	formItem: FormItem<Nullable<SimpleVenue>>
	onChange: (venue: Nullable<SimpleVenue>) => void
	isLoading?: boolean
};

const _mapVenuesToOption = (venue: SimpleVenue): SelectOption<SimpleVenue> => ({
	value: venue,
	label: venue.name,
});

function PurchasingProcessVenueSelect(props: Props) {

	const {
		className,
		formItem,
		onChange,
		venues,
		isLoading,
	} = props;

	const _getSelectOptions = (): SelectOption<SimpleVenue>[] =>
		venues.map(_mapVenuesToOption);

	const selectProps: Partial<PublicBaseSelectProps<SelectOption<SimpleVenue>, false, GroupBase<SelectOption<SimpleVenue>>>> = {
		styles: getVenueSelectStyles<SimpleVenue, false>(240, 55),
		classNames: getVenueSelectClassNames<SimpleVenue, false>(formItem, false),
		isClearable: false,
		isSearchable: true,
		isLoading: isLoading,
		placeholder: "Wybierz lokalizację...",
		value: isNotNull(formItem.value) ? _mapVenuesToOption(formItem.value) : null,
		onChange: value => onChange(value?.value ?? null),
		components: {
			Option: props =>
				<OptionComponent { ...props  }>
					{ props.children }
				</OptionComponent>,
			ValueContainer: props =>
				<ValueContainerComponent{ ...props }>
					{ props.children }
				</ValueContainerComponent>,
		},
		isDisabled: formItem.disabled,
		menuPlacement: "bottom",
		isMulti: false,
		options: _getSelectOptions(),
		noOptionsMessage: () => (
			<div className="flex items-center gap-2">
				No options
			</div>
		),
	};

	return (
		<div className={ classNames(className, "flex flex-col gap-y-0.5") }>
			<Select { ...selectProps }/>
			{
				isNotNull(formItem.error) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ formItem.error }
                </div>
			}
		</div>
	);
}

type OptionComponentProps =
	OptionProps<SelectOption<SimpleVenue>, false>
	& { truncate?: number };

const OptionComponent = (props: OptionComponentProps) => {

	const {
		data: {
			value: venue,
		},
	} = props;

	return (
		<components.Option { ...props }>
			<div className="flex items-center gap-2">
				{ venue.name }
			</div>
		</components.Option>
	);
};

type ValueContainerComponentProps =
	ValueContainerProps<SelectOption<SimpleVenue>, false>
	& { truncate?: number };

const ValueContainerComponent = (props: ValueContainerComponentProps) => {

	const {
		getValue,
		selectProps: {
			inputValue,
		},
	} = props;

	const selectedValues = getValue();

	if (selectedValues.length > 0) {
		const venue = selectedValues[ 0 ].value;
		return (
			<components.ValueContainer
				{ ...props }
				className={ classNames(props.className, "flex") }
			>
				{
					isEmptyString(inputValue) &&
                    <div className="flex items-center gap-2">
						{ venue.name }
                    </div>
				}

				{ /* children[ 1 ] = Search input */ }
				{
					isArray(props.children)
						?
						props.children[ 1 ]
						:
						props.children
				}
			</components.ValueContainer>
		);
	} else {
		return (
			<components.ValueContainer { ...props }>
				{ props.children }
			</components.ValueContainer>
		);
	}
};

export default (PurchasingProcessVenueSelect);
