import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { uiCreateRoom } from "src/app/store/features/ui/room/ui.room.actions";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function RoomCreateView(props: Props) {
    const {
        createRoom,
    } = props;

    return (
        <div></div>
    );
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
    createRoom: uiCreateRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomCreateView);