import { RouteObject } from "react-router/dist/lib/context";
import ProductListView from "src/app/views/Product/ProductList.view";
import ProductProfileView from "src/app/views/Product/ProductProfile.view";
import ProductCreateView from "src/app/views/Product/ProductCreate.view";

const productRoutes: RouteObject = {
	path: "products",
	children: [
		{
			index: true,
			element: <ProductListView/>,
		}, {
			path: "create",
			element: <ProductCreateView/>,
		}, {
			path: ":productId",
			element: <ProductProfileView/>,
		},
	],
};

export default productRoutes;
