/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useEffect, useState } from "react";
import { Nullable } from "src/app/types/util.types";

const useMousePosition = () => {

	const [ mousePosition, setMousePosition ] = useState<{
		x: Nullable<number>
		y: Nullable<number>
	}>({ x: null, y: null });

	useEffect(() => {
		const updateMousePosition = (e: globalThis.MouseEvent) => {
			setMousePosition({ x: e.clientX, y: e.clientY });
		};
		window.addEventListener('mousemove', updateMousePosition);
		return () => {
			window.removeEventListener('mousemove', updateMousePosition);
		};
	}, []);

	return mousePosition;
};
export default useMousePosition;