/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map, mergeMap } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedRoom } from "src/app/types/api/room.types";
import { copyRoomTimeRangesFromOtherRoomAsync, createRoomAsync, deleteRoomByIdAsync, fetchAdminRoomsAsync, fetchRoomByIdAsync, fetchRoomsAsync, updateRoomAsync, updateRoomTimeRangesAsync } from "src/app/store/features/room/room.actions";
import { isNotNull } from "src/app/utils/typeguards";
import { from, of } from "rxjs";

export const fetchRoomsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchRoomsAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/rooms`,
				method: "GET",
				withScope: true,
				onSuccess: fetchRoomsAsync.success,
				onFailure: fetchRoomsAsync.failure,
			}),
		),
	);
export const fetchAdminRoomsEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchAdminRoomsAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/admin/rooms`,
				method: "GET",
				withScope: true,
				onSuccess: fetchAdminRoomsAsync.success,
				onFailure: fetchAdminRoomsAsync.failure,
			}),
		),
	);

export const fetchRoomByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchRoomByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/rooms/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedRoom>) => fetchRoomByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchRoomByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const createRoomEpic: RootEpic = (action$, _, services) =>
	action$.pipe(
		filter(isActionOf(createRoomAsync.request)),
		mergeMap(action =>
			isNotNull(action.payload.avatar)
				?
				from(services.file.parseFile(action.payload.avatar))
					.pipe(
						map(parsedFile =>
							apiAsync.request({
								url: "/admin/rooms",
								method: "POST",
								data: {
									...action.payload,
									avatar: parsedFile,
								},
								withScope: true,
								onSuccess: createRoomAsync.success,
								onFailure: createRoomAsync.failure,
							}),
						),
					)
				:
				of(apiAsync.request({
					url: "/admin/rooms",
					method: "POST",
					data: action.payload,
					withScope: true,
					onSuccess: createRoomAsync.success,
					onFailure: createRoomAsync.failure,
				})),
		),
	);

export const updateRoomEpic: RootEpic = (action$, _, services) =>
	action$.pipe(
		filter(isActionOf(updateRoomAsync.request)),
		mergeMap(action =>
			isNotNull(action.payload.avatar)
				?
				from(services.file.parseFile(action.payload.avatar))
					.pipe(
						map(parsedFile =>
							apiAsync.request({
								url: `/admin/rooms/${ action.payload.id }`,
								method: "PUT",
								data: {
									...action.payload,
									avatar: parsedFile,
								},
								withScope: true,
								onSuccess: updateRoomAsync.success,
								onFailure: updateRoomAsync.failure,
							}),
						),
					)
				:
				of(apiAsync.request({
					url: `/admin/rooms/${ action.payload.id }`,
					method: "PUT",
					data: action.payload,
					withScope: true,
					onSuccess: updateRoomAsync.success,
					onFailure: updateRoomAsync.failure,
				})),
		),
	);

export const updateRoomTimeRangesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateRoomTimeRangesAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/rooms/${ action.payload.id }/time-range`,
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: updateRoomTimeRangesAsync.success,
				onFailure: updateRoomTimeRangesAsync.failure,
			}),
		),
	);

export const copyRoomTimeRangesFromOtherRoomEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(copyRoomTimeRangesFromOtherRoomAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/rooms/${ action.payload.id }/copy-time-range`,
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: copyRoomTimeRangesFromOtherRoomAsync.success,
				onFailure: copyRoomTimeRangesFromOtherRoomAsync.failure,
			}),
		),
	);

export const deleteRoomByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteRoomByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/rooms/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteRoomByIdAsync.success,
				onFailure: deleteRoomByIdAsync.failure,
			}),
		),
	);
