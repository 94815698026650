/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Outlet } from "react-router-dom";
import classNames from "classnames";
import React from "react";
import ReservationResultBreadcrumbsHeader from "src/app/components/PurchasingProcess/util/Header/ReservationResultBreadcrumbsHeader.component";

function PurchasingResultBreadcrumbsLayoutInner() {
	return (
		<div className="bg-myPrimary-gray-300 min-h-screen h-full">
			<div className="flex flex-col h-full bg-myPrimary-gray-300">
				<ReservationResultBreadcrumbsHeader/>
				<div className={ classNames(
					"py-4 h-full",
					"px-4",
					"lg:px-14",
				) }>
					<div className="max-w-[1440px] mx-auto">
						<Outlet/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PurchasingResultBreadcrumbsLayoutInner;
