/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button, Tooltip } from "flowbite-react";
import { MouseEvent } from "react";
import { HiOutlineTrash } from "react-icons/hi";

type Props = {
	ability: boolean
	onDelete: (e: MouseEvent<HTMLButtonElement>) => void
}

function DeleteCell(props: Props) {

	const {
		ability,
		onDelete,
	} = props;

	return (
		<div className="flex gap-2 items-center sm:justify-end">
			{
				ability &&
                <Button
                    onClick={ (e: MouseEvent<HTMLButtonElement>) => {
						e.stopPropagation();
						onDelete(e);
					} }
                    color="gray-outline"
                    size="sm"
                    className="p-0 [&>span]:p-2"
                >
					<Tooltip content="Usuń">
                        <HiOutlineTrash className="w-5 h-5"/>
					</Tooltip>
                </Button>
			}
		</div>
	);
}

export default DeleteCell;
