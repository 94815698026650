import { Organization } from "src/app/types/api/organization.types";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { Nullable } from "src/app/types/util.types";
import { isNotNull } from "src/app/utils/typeguards";
import { canViewOrganization } from "src/app/utils/abilities";
import { useNavigate } from "react-router-dom";

type Props = {
    organizations: Organization[]
};

function OrganizationListContainer(props: Props) {
    const {
        organizations,
    } = props;

    const navigate = useNavigate();

    const organizationColumns: MUIDataTableColumn[] = [
        {
            name: "ID",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (organization: Organization) =>
                    <div className="d-flex align-items-center gap-1">
                        { organization.id }
                    </div>,
                sortCompare: order => (a, b) => {
                    if (order === "asc") {
                        return (a.data.id.toString()).localeCompare(b.data.id.toString());
                    } else {
                        return (b.data.id.toString()).localeCompare(a.data.id.toString());
                    }
                },
            },
        },
    ];

    const tableOptions: MUIDataTableOptions = {
        onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
            const clickedOrganization: Nullable<Organization> = props.organizations[ rowMeta.dataIndex ];
            if (isNotNull(clickedOrganization) && canViewOrganization(clickedOrganization)) {
                navigate(`/panel/organizations/${ clickedOrganization.id }`);
            }
        },
    };

    return (
        <Table
            title="Organizacje"
            columns={ organizationColumns }
            options={ tableOptions }
            data={ organizations }
        />
    );
}

export default (OrganizationListContainer);
