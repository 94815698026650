/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { HiOutlinePhotograph } from "react-icons/hi";
import classNames from "classnames";
import { CSSProperties } from "react";

type Props = {
	className?: string
	style?: CSSProperties
}

function PlaceholderImage({ className, style }: Props) {
	return (
		<div
			className={ classNames("h-full w-full bg-gray-200 flex items-center justify-center", className) }
			style={ style }
		>
			<HiOutlinePhotograph className="h-1/2 w-1/2 text-gray-300"/>
		</div>
	);
}

export default PlaceholderImage;
