/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiCreateVenue, uiDeleteVenue, uiFetchAdminVenueAvailabilities, uiFetchVenueAvailabilities, uiFetchVenueAvailabilitiesDebounce, uiUpdateVenue } from "src/app/store/features/ui/venue/ui.venue.actions";
import { debounceTime, filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { createVenueAsync, deleteVenueByIdAsync, fetchAdminVenueAvailabilitiesAsync, fetchVenueAvailabilitiesAsync, updateVenueAsync } from "src/app/store/features/venue/venue.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { scopedPush } from "src/app/store/features/misc/misc.actions";
import { getAvailabilityId } from "src/app/utils/helpers";
import { DateTime } from "luxon";
import { getCurrentDateRoomAvailabilities, mapPersistedAvailabilities } from "src/app/utils/constants/purchasingProcess.form";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { purchasingProcessFormActions } from "src/app/store/features/form/form.actions";
import { LocaleFromISO } from "src/app/utils/luxon";
import { AvailabilityState, DateRoomAvailabilities, FormAvailability, PurchasingProcessFormStep } from "src/app/types/api/reservation.types";

export const uiCreateVenueEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateVenue)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_VENUE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createVenueAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createVenueAsync.success, action) || isActionOf(createVenueAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createVenueAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie utworzono lokal!" })),
								of(scopedPush(`/venues/${ responseAction.payload.data.id }`)),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateVenueEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateVenue)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_VENUE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateVenueAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateVenueAsync.success, action) || isActionOf(updateVenueAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateVenueAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano lokal!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteVenueEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteVenue)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_VENUE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteVenueByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteVenueByIdAsync.success, action) || isActionOf(deleteVenueByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteVenueByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto lokal!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiFetchVenueAvailabilitiesDebounceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchVenueAvailabilitiesDebounce)),
		debounceTime(400),
		map(action => uiFetchVenueAvailabilities(action.payload)),
	);

export const uiFetchVenueAvailabilitiesEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiFetchVenueAvailabilities)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: getAvailabilityId(action.payload.venueId, DateTime.fromISO(action.payload.date).toISODate() ?? ""), loadableType: LoadableType.FETCH_AVAILABILITIES };
			const initialLoadLoadingRecord: LoadingRecord = { loadableType: LoadableType.INITIAL_LOAD_FETCH_AVAILABILITIES };
			return concat(
				of(fetchVenueAvailabilitiesAsync.request(action.payload)),
				// ...[ of(fetchVenueAvailabilitiesAsync.request(action.payload)), of(fetchVenueAvailabilitiesAsync.request({ ...action.payload, date: DateTime.fromISO(action.payload.date).plus({ days: 1 }).toISODate() ?? "" })) ], //todo: that is how to make many requests dynamically
				action$.pipe(
					filter(responseAction => {
						const id = getAvailabilityId(action.payload.venueId, action.payload.date);
						return (
							(isActionOf(fetchVenueAvailabilitiesAsync.success, responseAction) && responseAction.payload.id === id) ||
							(isActionOf(fetchVenueAvailabilitiesAsync.failure, responseAction) && responseAction.payload.id === id)
						);
					}),
					take(1), //todo: length of of(fetchAvailabilitiesAsync.request...) array
					mergeMap(responseAction => {
						if (isActionOf(fetchVenueAvailabilitiesAsync.failure, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.ERROR, content: "Niepowodzenie przy pobieraniu dostępności" })),
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(initialLoadLoadingRecord)),
							);
						} else if (isActionOf(fetchVenueAvailabilitiesAsync.success, responseAction)) {
							const persistedForm = state$.value.form.purchasingProcess.form;
							const dateRoomAvailabilities = getCurrentDateRoomAvailabilities(persistedForm.dateRoomAvailabilities.value, action.payload.date);
							const mappedPersistedAvailabilities = mapPersistedAvailabilities(persistedForm, responseAction.payload.data, action.payload.date);

							//delete past days from persisted-form
							const filteredDateRoomAvailabilities = persistedForm.dateRoomAvailabilities.value.filter(({ date }) => {
								const diff = LocaleFromISO(date).diff(DateTime.now(), "days").toObject().days;
								return isNotNull(diff) && Math.ceil(diff) >= 0;
							});

							// There is no dateRoomAvailabilities with matching date in the form-redux-store
							if (isNull(dateRoomAvailabilities)) {

								// With re fetching
								if (isNotNull(mappedPersistedAvailabilities.reFetchDate)) {
									return merge(
										of(purchasingProcessFormActions.handleChange({
											prop: "dateRoomAvailabilities",
											value: [ ...filteredDateRoomAvailabilities, { date: action.payload.date, roomAvailabilities: mappedPersistedAvailabilities.roomAvailabilities } ],
										})),
										of(removeLoadingRecord(loadingRecord)),
										of(removeLoadingRecord(initialLoadLoadingRecord)),
										of(uiFetchVenueAvailabilities({ ...action.payload, date: LocaleFromISO(mappedPersistedAvailabilities.reFetchDate).toFormat("yyyy-MM-dd") })),
									);
								}

								// Without re fetching
								return merge(
									of(purchasingProcessFormActions.handleChange({
										prop: "dateRoomAvailabilities",
										value: [ ...filteredDateRoomAvailabilities, { date: action.payload.date, roomAvailabilities: mappedPersistedAvailabilities.roomAvailabilities } ],
									})),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(initialLoadLoadingRecord)),
								);
							}

							const mappedDateRoomAvailabilities: DateRoomAvailabilities<FormAvailability>[] = filteredDateRoomAvailabilities.map(dateRoomAvailability => {
								if (DateTime.fromISO(dateRoomAvailability.date).valueOf() !== DateTime.fromISO(action.payload.date).valueOf()) return dateRoomAvailability;
								return {
									...dateRoomAvailability,
									roomAvailabilities: mappedPersistedAvailabilities.roomAvailabilities,
								};
							});

							// Re fetching availabilities
							if (isNotNull(mappedPersistedAvailabilities.reFetchDate)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({ prop: "dateRoomAvailabilities", value: mappedDateRoomAvailabilities })),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(initialLoadLoadingRecord)),
									of(uiFetchVenueAvailabilities({ ...action.payload, date: LocaleFromISO(mappedPersistedAvailabilities.reFetchDate).toFormat("yyyy-MM-dd") })),
								);
							}

							// User had RESERVING in persisted form and at least one availability on the block became RESERVED
							if (mappedPersistedAvailabilities.deleteBlock) {
								return merge(
									of(purchasingProcessFormActions.handleChange({ prop: "dateRoomAvailabilities", value: mappedDateRoomAvailabilities })),
									of(purchasingProcessFormActions.handleChange({ prop: "step", value: PurchasingProcessFormStep.AVAILABILITIES })),
									of(displayToast({ type: ToastType.ERROR, content: "Rezerwacja jest już niedostępna." })),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(initialLoadLoadingRecord)),
								);
							}

							if ( // State where there is no reservation => we need to fallback to first step
								mappedPersistedAvailabilities.roomAvailabilities.every(roomAvailability =>
									roomAvailability.availabilities.every(availability => availability.state !== AvailabilityState.RESERVING),
								)
							) {
								return merge(
									of(purchasingProcessFormActions.handleChange({ prop: "dateRoomAvailabilities", value: mappedDateRoomAvailabilities })),
									of(purchasingProcessFormActions.handleChange({ prop: "step", value: PurchasingProcessFormStep.AVAILABILITIES })),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(initialLoadLoadingRecord)),
								);
							}

							// There is dateRoomAvailability with matching date in the redux-form-store and we replace its data
							return merge(
								of(purchasingProcessFormActions.handleChange({ prop: "dateRoomAvailabilities", value: mappedDateRoomAvailabilities })),
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(initialLoadLoadingRecord)),
							);
							// fallback
						} else {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(initialLoadLoadingRecord)),
							);
						}
					}),
				),
			);
		}),
	);

export const uiFetchAdminVenueAvailabilitiesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchAdminVenueAvailabilities)),
		debounceTime(500),
		map(action => fetchAdminVenueAvailabilitiesAsync.request(action.payload)),
	);
