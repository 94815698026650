/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DetailedProduct, UpdateProductPayload } from "src/app/types/api/product.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { createFormField, validateField, validateNumberField, validatePhoto } from "src/app/utils/forms";
import { Nullable } from "src/app/types/util.types";
import { FormValidator } from "src/app/types/ui/form.types";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { isNotNull } from "src/app/utils/typeguards";
import { Button, Modal } from "flowbite-react";
import Avatar from "src/app/components/Utils/Avatar.component";
import { HiX } from "react-icons/hi";
import FileInput from "src/app/components/Form/FileInput.component";
import Input from "src/app/components/Form/Input.component";
import { getInitials } from "src/app/utils/ui";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import { Category } from "src/app/types/api/category.types";
import CategoryMultiSelect from "src/app/components/Form/CategoryMultiSelect.component";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	product: DetailedProduct
	onUpdate: (payload: UpdateProductPayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type EditProductBasicInformationForm = {
	avatar: Nullable<File>
	name: string
	description: string
	price: string
	vatRate: string
	categories: Category[]
};

const validator: FormValidator<EditProductBasicInformationForm> = {
	avatar: (avatar, optional) => validatePhoto("Awatar", avatar, optional),
	name: (name, optional) => validateField("Nazwa", name, optional),
	description: (description, optional) => validateField("Opis", description, optional),
	price: (price, optional) => validateNumberField("Cena", price, optional),
	vatRate: (vatRate, optional) => validateNumberField("Stawka VAT", vatRate, optional, 0, 100),
	categories: () => null,
};

function EditProductBasicInformationModal(props: Props) {

	const {
		isOpen,
		handleClose,
		product,
		onUpdate,
		isUpdating,
	} = props;

	const _handleSubmit = (values: EditProductBasicInformationForm) => {
		onUpdate({
			id: product.id,
			...values,
			vatRate: +values.vatRate,
		});
		handleClose();
	};

	const _getInitialState = () => ({
		avatar: createFormField(undefined, { optional: true }),
		name: createFormField(product.name),
		description: createFormField(product.description ?? "", { optional: true }),
		price: createFormField(product.price),
		vatRate: createFormField(product.vatRate.toString()),
		categories: createFormField(product.categories),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [
		product.name,
		product.description,
		product.price,
		product.vatRate,
		product.image,
	]);

	useEffect(() => {
		if (!isOpen && !isUpdating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	const _getProductAvatar = () => {
		if (isNotNull(form.avatar.value)) return URL.createObjectURL(form.avatar.value);

		if (isNotNull(product.image) && form.avatar.value === undefined) return product.image.thumb;

		return undefined;
	};

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Edytuj informacje o produkcie
			</Modal.Header>
			<form
				onSubmit={ handleSubmit }
				className="[&>div]:overflow-visible"
			>
				<Modal.Body>
					<div className="space-y-3">
						<>
							<div className="flex flex-col sm:flex-row gap-4 items-end">
								<div className="relative">
									<Avatar
										className="w-20 h-20 [&_svg]:w-20 [&_svg]:h-20"
										img={
											isNotNull(_getProductAvatar())
												?
												_getProductAvatar()
												:
												undefined
										}
										placeholderInitials={ getInitials(form.name.value) }
										size="lg"
									/>
									{
										isNotNull(_getProductAvatar()) &&
                                        <HiX
                                            className="cursor-pointer text-white absolute -top-2.5 -right-2.5 p-1 rounded-full w-6 h-6 bg-red-500 transition duration-25 ease-in-out hover:bg-red-600 hover:text-gray-100"
                                            onClick={ () => handleChange("avatar", null) }
                                        />
									}
									{
										form.avatar.value !== form.avatar.initialValue &&
                                        <HiMiniArrowUturnLeft
                                            className="cursor-pointer text-white absolute -top-2.5 -left-2.5 p-1 rounded-full w-6 h-6 bg-yellow-400 transition duration-25 ease-in-out hover:bg-yellow-500 hover:text-gray-100"
                                            onClick={ () => handleChange("avatar", undefined) }
                                        />
									}
								</div>
								<FileInput
									label="Awatar"
									buttonText="Prześlij awatar"
									name="avatar"
									className="[&>div]:py-4 flex-grow"
									onChange={ file => {
										handleChange("avatar", file);
										handleBlur("avatar");
									} }
									options={ {
										accept: {
											"image/jpeg": [],
											"image/png": [],
										},
									} }
								/>
							</div>
							{
								isNotNull(form.avatar.error) &&
                                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
									{ form.avatar.error }
                                </div>
							}
						</>
						<Input
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							formItem={ form.description }
							label="Opis"
							name="description"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("description", e.target.value),
								onBlur: () => handleBlur("description"),
							} }
						/>
						<Input
							formItem={ form.price }
							label="Cena"
							name="price"
							inputProps={ {
								type: "number",
								rightIcon: () => <span className="pr-5">zł</span>,
								onChange: (e) => handleChange("price", e.target.value),
								onBlur: () => handleBlur("price"),
							} }
						/>
						<Input
							formItem={ form.vatRate }
							label="Stawka VAT"
							name="vatRate"
							inputProps={ {
								type: "number",
								rightIcon: () => <span className="pr-5">%</span>,
								onChange: (e) => handleChange("vatRate", e.target.value),
								onBlur: () => handleBlur("vatRate"),
							} }
						/>
						<CategoryMultiSelect
							label="Kategorie"
							formItem={ form.categories }
							onChange={ categories => handleChange("categories", categories) }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						Zapisz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableType: LoadableType.UPDATE_PRODUCT, loadableId: props.product.id }),
});

export default connect(mapStateToProps)(EditProductBasicInformationModal);
