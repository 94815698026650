/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { Category, CreateCategoryPayload, DetailedCategory, UpdateCategoryPayload } from "src/app/types/api/category.types";

export const fetchAdminCategoriesAsync = createNetworkAction<undefined, SuccessPayload<Category[]>>("CATEGORY__FETCH_ADMIN_CATEGORIES");
export const fetchCategoriesAsync = createNetworkAction<undefined, SuccessPayload<Category[]>>("CATEGORY__FETCH_CATEGORIES");
export const fetchCategoryByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedCategory>, FailurePayloadWithId>("CATEGORY__FETCH_CATEGORY_BY_ID");
export const createCategoryAsync = createNetworkAction<CreateCategoryPayload, SuccessPayload<DetailedCategory>>("CATEGORY__CREATE_CATEGORY");
export const updateCategoryAsync = createNetworkAction<UpdateCategoryPayload, SuccessPayload<DetailedCategory>>("CATEGORY__UPDATE_CATEGORY");
export const deleteCategoryByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("CATEGORY__DELETE_CATEGORY");
