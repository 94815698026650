/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { getCurrentDateRoomAvailabilities, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { SimpleRoom } from "src/app/types/api/room.types";
import RoomPreview from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/PurchasingProcessRoomPreview.component";
import AvailabilityComponent from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Availability/Availability.component";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Collapse } from "react-collapse";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { breakpoints } from "src/app/utils/constants/constants";
import { AvailabilityState } from "src/app/types/api/reservation.types";
import { Nullable } from "src/app/types/util.types";
import { isNull } from "src/app/utils/typeguards";

type ComponentProps = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	room: SimpleRoom
	rowsCollapse: { roomId: number, isCollapsed: boolean }[] | undefined
	toggleRowCollapse: (roomId: number, isCollapsed: boolean) => void
}

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

function AvailabilitiesMobileRow(props: Props) {

	const {
		form: {
			form: {
				date,
				dateRoomAvailabilities,
			},
		},
		room,
		bodyWidth,
		rowsCollapse,
		toggleRowCollapse,
	} = props;

	useEffect(() => {
		if (bodyWidth > breakpoints.SM) {
			toggleRowCollapse(room.id, true);
		} else {
			toggleRowCollapse(room.id, false);
		}
	}, [ bodyWidth ]);

	const isCollapsed = Boolean(rowsCollapse?.find(row => row.roomId === room.id)?.isCollapsed);

	const [ isTimelineScrolled, toggleTimelineScrolled ] = useState({
		left: false,
		right: true,
	});

	const handleAvailabilitiesWrapperScroll = (scrollLeft: number, scrollWidth: number, clientWidth: number) => {
		if (scrollLeft > 0) {
			toggleTimelineScrolled(prevState => ({ ...prevState, left: true }));
		} else {
			toggleTimelineScrolled(prevState => ({ ...prevState, left: false }));
		}
		if (scrollLeft < scrollWidth - clientWidth) {
			toggleTimelineScrolled(prevState => ({ ...prevState, right: true }));
		} else {
			toggleTimelineScrolled(prevState => ({ ...prevState, right: false }));
		}
	};

	const activeRoomId = getCurrentDateRoomAvailabilities(dateRoomAvailabilities.value, date.value)?.reduce<Nullable<number>>((prev, current) => {
		if (!current.availabilities.some(availability => availability.state === AvailabilityState.RESERVING)) return prev;
		return current.room.id;
	}, null);

	const roomWithAvailabilities = getCurrentDateRoomAvailabilities(dateRoomAvailabilities.value, date.value)
		?.find(({ roomId, availabilities }) =>
			roomId === room.id && availabilities.length > 0,
		);

	if (isNull(roomWithAvailabilities)) return null;

	return (
		<div className="flex flex-col gap-2 overflow-hidden">
			<RoomPreview
				key={ room.id }
				room={ room }
				className={ classNames(
					"!w-full !min-w-0",
					"cursor-pointer",
					"sm:cursor-default",
				) }
				onClick={ () => bodyWidth < breakpoints.SM && toggleRowCollapse(room.id, !isCollapsed) }
				active={ room.id === activeRoomId && bodyWidth < breakpoints.SM }
			/>
			<Collapse isOpened={ isCollapsed }>
				<div className="relative">
					{
						isTimelineScrolled.right &&
						/* Blur-right */
                        <div className="w-14 h-full bg-gradient-to-l from-white absolute top-0 right-[-7px] pointer-events-none z-10"></div>
					}
					{
						isTimelineScrolled.left &&
						/* Blur-left */
                        <div className="w-14 h-full bg-gradient-to-r from-white absolute top-0 left-[-7px] pointer-events-none z-10"></div>
					}
					<div
						className={ classNames(
							"flex gap-1",
							"flex-col items-center",
							"sm:flex-row sm:overflow-x-auto sm:items-start",
						) }
						onScroll={ ({ currentTarget: { scrollLeft, scrollWidth, clientWidth } }) => handleAvailabilitiesWrapperScroll(scrollLeft, scrollWidth, clientWidth) }
					>
						{
							roomWithAvailabilities.availabilities.map((availability, index) =>
								<AvailabilityComponent
									key={ index }
									form={ props.form }
									availability={ availability }
									room={ room }
									idPrefix="mobile"
									className={ classNames(
										"!w-4/5",
										"md:w-40",
									) }
								/>,
							)
						}
					</div>
				</div>
			</Collapse>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodyWidth: state.ui.layout.bodySize.width,
});

export default connect(mapStateToProps)(AvailabilitiesMobileRow);
