/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { CopyTimeRangesFromOtherRoomPayload, CreateRoomPayload, DetailedRoom, Room, UpdateRoomPayload, UpdateRoomTimeRangesPayload } from "src/app/types/api/room.types";

export const fetchRoomsAsync = createNetworkAction<undefined, SuccessPayload<Room[]>>("ROOM__FETCH_ROOMS");
export const fetchAdminRoomsAsync = createNetworkAction<undefined, SuccessPayload<Room[]>>("ROOM__FETCH_ADMIN_ROOMS");
export const fetchRoomByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedRoom>, FailurePayloadWithId>("ROOM__FETCH_ROOM_BY_ID");
export const createRoomAsync = createNetworkAction<CreateRoomPayload, SuccessPayload<DetailedRoom>>("ROOM__CREATE_ROOM");
export const updateRoomAsync = createNetworkAction<UpdateRoomPayload, SuccessPayload<DetailedRoom>>("ROOM__UPDATE_ROOM");
export const updateRoomTimeRangesAsync = createNetworkAction<UpdateRoomTimeRangesPayload, SuccessPayload<DetailedRoom>>("ROOM__UPDATE_ROOM_TIME_RANGES");
export const copyRoomTimeRangesFromOtherRoomAsync = createNetworkAction<CopyTimeRangesFromOtherRoomPayload, SuccessPayload<DetailedRoom>>("ROOM__COPY_ROOM_TIME_RANGES_FROM_OTHER_ROOM");
export const deleteRoomByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("ROOM__DELETE_ROOM");
