/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, StateReducer } from "src/app/types/redux.types";
import { deleteObject, handleBasicActions, handleBasicActionsForArray, initialStateReducer, replaceOrPushObject, replaceStateReducer } from "src/app/utils/redux";
import moment from "moment";
import { Category, DetailedCategory } from "src/app/types/api/category.types";
import { createCategoryAsync, deleteCategoryByIdAsync, fetchAdminCategoriesAsync, fetchCategoriesAsync, fetchCategoryByIdAsync, updateCategoryAsync } from "src/app/store/features/category/category.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";

const reducer = combineReducers({
    adminCategories: createReducer(initialStateReducer as StateReducer<Category[]>)
        .handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchAdminCategoriesAsync.request, fetchAdminCategoriesAsync.success, fetchAdminCategoriesAsync.failure ], handleBasicActions(fetchAdminCategoriesAsync))
		.handleAction([ deleteCategoryByIdAsync.success ], (state, action) => {
			if (state.dataState === DataState.PRESENT) {
				return {
					...state,
					data: deleteObject(state.data, (a) => a.id === action.payload.data.id),
				};
			} else {
				return state;
			}
		})
		.handleAction([ createCategoryAsync.success, updateCategoryAsync.success ], (state, action) => {
			if (state.dataState === DataState.NOT_PRESENT) return state;

			return {
				...state,
				data: replaceOrPushObject(state.data, action.payload.data, (a, b) => a.id === b.id),
			};
		}),
    categories: createReducer(initialStateReducer as StateReducer<Category[]>)
        .handleAction([ forgetSession, logoutAsync.success ], () => initialStateReducer)
		.handleAction([ fetchCategoriesAsync.request, fetchCategoriesAsync.success, fetchCategoriesAsync.failure ], handleBasicActions(fetchCategoriesAsync)),
	singleCategory: createReducer([] as ArrayStateReducer<DetailedCategory>)
        .handleAction([ forgetSession, logoutAsync.success ], () => [])
		.handleAction([ fetchCategoryByIdAsync.request, fetchCategoryByIdAsync.success, fetchCategoryByIdAsync.failure ], handleBasicActionsForArray(fetchCategoryByIdAsync))
		.handleAction([ updateCategoryAsync.success ],
			(state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: action.payload.data,
						},
					}),
				)),
});

export default reducer;
