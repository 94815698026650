/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormItem } from "src/app/types/ui/form.types";
import { Category } from "src/app/types/api/category.types";
import CategorySelect from "src/app/components/Form/CategorySelect.component";
import { HiX } from "react-icons/hi";
import { Label } from "flowbite-react";
import { isNotNull } from "src/app/utils/typeguards";
import useForm from "src/app/utils/hooks/useForm";
import { createFormField } from "src/app/utils/forms";
import { Nullable } from "src/app/types/util.types";

type Props = {
	label?: string
	formItem: FormItem<Category[]>
	onChange: (categories: Category[]) => void
}

function CategoryMultiSelect(props: Props) {

	const {
		label,
		formItem,
		onChange,
	} = props;

	const handleCategoryDelete = (categoryId: number) => onChange(formItem.value.filter(category => category.id !== categoryId));

	const innerForm = useForm<{ category: Nullable<Category> }>({
		category: createFormField(null)
	}, { category: () => null }, () => null);

	return (
		<div className="flex flex-col gap-2">
			{
				isNotNull(label) &&
                <Label>
					{ label }
                </Label>
			}
			<div className="flex gap-2 flex-wrap">
				{
					formItem.value.map(({ name, id }) =>
						<div
							key={ id }
							className="flex items-center gap-2 p-2 border border-gray-300 rounded"
						>
							<span className="text-sm">
								{ name }
							</span>
							<HiX
								className="h-4 w-4 cursor-pointer"
								onClick={ () => handleCategoryDelete(id) }
							/>
						</div>
					)
				}
			</div>
			<CategorySelect
				onChange={ category => isNotNull(category) && onChange([ ...formItem.value, category ]) }
				excludeCategoryIds={ formItem.value.map(({ id }) => id) }
				formItem={ innerForm.form.category }
			/>
		</div>
	);
}

export default CategoryMultiSelect;
