/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CreateDiscountCodePayload, UpdateDiscountCodePayload } from "src/app/types/api/discountCode.types";

export const uiCreateDiscountCode = createAction("UI_DISCOUNT_CODE__CREATE_DISCOUNT_CODE")<CreateDiscountCodePayload>();
export const uiUpdateDiscountCode = createAction("UI_DISCOUNT_CODE__UPDATE_DISCOUNT_CODE")<UpdateDiscountCodePayload>();
export const uiDeleteDiscountCode = createAction("UI_DISCOUNT_CODE__DELETE_DISCOUNT_CODE")<number>();