/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { PropsWithChildren } from "react";
import { Button } from "flowbite-react";
import classNames from "classnames";

type Props = {
	onClick: () => void
	isLoading?: boolean
	className?: string
	disabled?: boolean
	color?: string
}

function PurchasingProcessSmallButton(props: PropsWithChildren<Props>) {

	const {
		onClick,
		isLoading = false,
		children,
		className,
		disabled = false,
		color = "orange-full"
	} = props;

	const _onClick = () => {
		if (isLoading) return;
		onClick();
	}

	return (
		<Button
			color={ color }
			onClick={ _onClick }
			className={ classNames("[&>span>span>span>svg]:!h-[14px]", className) }
			size="xs"
			isProcessing={ isLoading }
			disabled={ disabled }
		>
			{ children }
		</Button>
	);
}

export default PurchasingProcessSmallButton;