/*
<<<<<<< HEAD
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { RootEpic } from "src/app/store/root.epic";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { uiCreateProduct, uiDeleteProduct, uiUpdateProduct } from "src/app/store/features/ui/product/ui.product.actions";
import { createProductAsync, deleteProductByIdAsync, updateProductAsync } from "src/app/store/features/product/product.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { scopedPush } from "src/app/store/features/misc/misc.actions";

export const uiCreateProductEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateProduct)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_PRODUCT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createProductAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createProductAsync.success, action) || isActionOf(createProductAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createProductAsync.success, responseAction)) {
							return merge(
								of(scopedPush(`/products/${ responseAction.payload.data.id }`)),
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie utworzono produkt!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateProductEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateProduct)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_PRODUCT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateProductAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateProductAsync.success, action) || isActionOf(updateProductAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateProductAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano produkt!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteProductEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteProduct)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_PRODUCT };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteProductByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteProductByIdAsync.success, action) || isActionOf(deleteProductByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteProductByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto produkt!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);
