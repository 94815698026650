/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { Nullable, SortCompare } from "src/app/types/util.types";
import { Product } from "src/app/types/api/product.types";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import Avatar from "src/app/components/Utils/Avatar.component";
import { canViewProduct } from "src/app/utils/abilities";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import Table from "src/app/components/Utils/Table.component";
import ModelListPopover from "src/app/components/Utils/ModelListPopover.component";
import { getAllCategoriesFromModel } from "src/app/utils/helpers";
import { Category } from "src/app/types/api/category.types";

type Props = {
	products: Product[]
	disableClick?: boolean
	actionsCell?: (product: Product) => ReactNode
	customToolbar?: ReactNode
	rowsPerPage?: number
}

function ProductList(props: Props) {

	const {
		products,
		disableClick,
		actionsCell,
		customToolbar,
		rowsPerPage = 12,
	} = props;

	const { navigate } = useUserScopeNavigate();

	const productColumns: MUIDataTableColumn[] = [
		{
			name: "Nazwa",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (product: Product) =>
					<div className="flex items-center gap-2">
						<Avatar
							alt={ `${ product.name }-avatar` }
							img={ product.image?.thumb }
							placeholderInitials={ product.name[ 0 ] }
							size="sm"
						/>
						<span>
							{ product.name }
						</span>
					</div>,
				sortCompare: order => (a: SortCompare<Product>, b: SortCompare<Product>) => {
					if (order === "asc") {
						return a.data.name.localeCompare(b.data.name);
					} else {
						return b.data.name.localeCompare(a.data.name);
					}
				},
			},
		}, {
			name: "Cena",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (product: Product) => `${ product.price } zł`,
				sortCompare: order => (a: SortCompare<Product>, b: SortCompare<Product>) => {
					if (order === "asc") {
						return +a.data.price - +b.data.price;
					} else {
						return +b.data.price - +a.data.price;
					}
				},
			},
		}, {
			name: "Stawka VAT",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (product: Product) => `${ product.vatRate }%`,
				sortCompare: order => (a: SortCompare<Product>, b: SortCompare<Product>) => {
					if (order === "asc") {
						return +a.data.vatRate - +b.data.vatRate;
					} else {
						return +b.data.vatRate - +a.data.vatRate;
					}
				},
			},
		}, {
			name: "Kategoria",
			options: {
				filter: true,
				filterOptions: {
					names: getAllCategoriesFromModel(products),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const categoryNames: string[] = isNotNull(row[ 0 ].categories) ? (row[ 0 ].categories as Category[]).map(({ name }) => name.toLowerCase()) : [];
						return isNotNull(row) && !filters.some(filterElement => categoryNames.some(categoryName => filterElement.toLowerCase() === categoryName));
					},
				},
				sort: true,
				customBodyRender: ({ categories }: Product) =>
					<ModelListPopover
						list={ categories }
						count={ 1 }
						getLink={ disableClick ? null : id => `/categories/${ id }` }
					/>
				,
				sortCompare: order => (a: SortCompare<Product>, b: SortCompare<Product>) => {
					if (order === "asc") {
						return +a.data.vatRate - +b.data.vatRate;
					} else {
						return +b.data.vatRate - +a.data.vatRate;
					}
				},
			},
		}, {
			name: "Akcje",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (product: Product) => isNotNull(actionsCell) && actionsCell(product),
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
			if (disableClick) return;
			const clickedProduct: Nullable<Product> = products[ rowMeta.dataIndex ];
			if (isNotNull(clickedProduct) && canViewProduct(clickedProduct)) {
				navigate(`/products/${ clickedProduct.id }`);
			}
		},
		customSearch: (searchQuery: string, currentRow: Product[]) => {
			searchQuery = searchQuery.toLowerCase();
			const row = currentRow[ 0 ];
			const name = row.name;
			const price = row.price;
			const vatRate = row.vatRate.toString();

			if (name.toLowerCase().includes(searchQuery)) {
				return true;
			} else if (price.toLowerCase().includes(searchQuery)) {
				return true;
			} else if (vatRate.toLowerCase().includes(searchQuery)) {
				return true;
			}
			return false;
		},
		customToolbar: () => customToolbar,
		rowsPerPage: rowsPerPage
	};

	return (
		<Table
			title="Produkty"
			columns={ productColumns }
			options={ tableOptions }
			data={ products }
		/>
	);
}

export default ProductList;
