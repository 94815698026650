import { from, merge, of } from "rxjs";
import { catchError, filter, map, mergeMap, tap } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { RootEpic } from "src/app/store/root.epic";
import { getType, isActionOf } from "typesafe-actions";
import { ApiFailure, ApiSuccess, Error, ErrorCodeName } from "src/app/types/api/api.types";
import { RootAction } from "src/app/store/root.actions";
import { getErrorMessage } from "src/app/utils/helpers";
import { authMeAsync, forgetSession } from "src/app/store/features/user/user.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { displayToast } from "src/app/store/features/message/message.actions";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import store from "src/app/store/index";
import { sendErrorAsync } from "src/app/store/features/misc/misc.actions";
import { KAKADU_ORG } from "src/app/utils/constants/constants";

export const apiAsyncEpic: RootEpic = (action$, state$, api) =>
	action$.pipe(
		filter(isActionOf(apiAsync.request)),
		mergeMap(action => {
				if (action.payload.withScope && isNull(state$.value.user.userScope)) {
					store.dispatch(sendErrorAsync.request({
						message: `Scoped endpoint without user scope in user.reducer. SENDING HARDCODED KAKADU ORG ID (${ KAKADU_ORG })`,
						action: action,
						state: state$.value,
					}));
				}
				let url: string;
				if (action.payload.withScope) {
					const orgUrl = state$.value.user.userScope?.organization?.id ?? KAKADU_ORG;
					url = `/scope/${ orgUrl }${ action.payload.url }`;
				} else {
					url = action.payload.url;
				}
				return from(
					api.axios.request({
						url,
						method: action.payload.method,
						data: action.payload.data,
						headers: isNotNull(state$.value.misc.impersonateUserId)
							?
							{ "X-Impersonate": state$.value.misc.impersonateUserId }
							:
							undefined,
					}),
				).pipe(
					mergeMap(response => {
						if (!response.data.success) {
							const errors = response.data.errors;
							let isAuthMeFail = false;
							try {
								isAuthMeFail = getType(action.payload.onFailure) === getType(authMeAsync.failure);
							} catch (e) {
								console.log(e);
								console.error("Error during comparing failure actions");
							}
							if (isAuthMeFail) {
								return merge(
									of(forgetSession()),
									of(apiAsync.failure({ data: response.data, onFailure: action.payload.onFailure, withoutNotification: true })),
								);
							} else if (errors.some(error => error.httpStatus === 401)) {
								return merge(
									of(forgetSession()),
									of(apiAsync.failure({ data: response.data, onFailure: action.payload.onFailure })),
								);
							} else if (
								errors.some(error =>
									(error.httpStatus === 403 && error.codeName === ErrorCodeName.UNAUTHENTICATED_USER_EMAIL_NOT_CONFIRMED) ||
									(error.httpStatus === 428 && error.codeName === ErrorCodeName.UNAUTHENTICATED_MISSING_2FA_OTP) ||
									(error.httpStatus === 422 && error.codeName === ErrorCodeName.ROOM_TIME_RANGES_UNAVAILABLE),
								)
							) {
								return of(apiAsync.failure({ data: response.data, onFailure: action.payload.onFailure, withoutNotification: true }));
							} else {
								return of(apiAsync.failure({ data: response.data, onFailure: action.payload.onFailure }));
							}
						} else {
							return of(apiAsync.success({ data: response.data, onSuccess: action.payload.onSuccess }));
						}
					}),
					catchError(error => {
						if (error.message === "canceled") {
							return merge(
								of(apiAsync.failure({
										data: {
											success: false,
											errors: [ {
												httpStatus: 502,
												codeName: ErrorCodeName.GENERIC,
												message: "Unknown error occurred",
											} ],
										},
										onFailure: action.payload.onFailure,
										withoutNotification: true,
									}),
								),
								of(forgetSession()),
							);
						}

						return of(apiAsync.failure({
								data: {
									success: false,
									errors: [ {
										httpStatus: 502,
										codeName: ErrorCodeName.GENERIC,
										message: "Unknown error occurred",
									} ],
								},
								onFailure: action.payload.onFailure,
								withoutNotification: getType(action.payload.onFailure) === getType(authMeAsync.failure),
							}),
						);
					}),
				);
			},
		),
	);

export const apiAsyncSuccessEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(apiAsync.success)),
		tap(console.log),
		map((action: { payload: Required<ApiSuccess<any>> }) => (action.payload.onSuccess(action.payload.data) as RootAction)),
	);

export const apiAsyncFailureEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(apiAsync.failure)),
		tap(console.log),
		mergeMap((action: { payload: Required<ApiFailure<any>> }) => {
			if (action.payload.withoutNotification) {
				return of(action.payload.onFailure(action.payload.data) as RootAction);
			} else {
				const errors: string[] = action.payload.data.errors.map((error: Error) => getErrorMessage(error));
				return merge(
					of(action.payload.onFailure(action.payload.data) as RootAction),
					of(displayToast({ type: ToastType.ERROR, content: errors.join(" ") })),
				);
			}
		}),
	);
