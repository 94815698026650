/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { CreateProductPayload, DetailedProduct, Product, UpdateProductPayload } from "src/app/types/api/product.types";

export const fetchAdminProductsAsync = createNetworkAction<undefined, SuccessPayload<Product[]>>("PRODUCT__FETCH_ADMIN_PRODUCTS");
export const fetchProductsAsync = createNetworkAction<undefined, SuccessPayload<Product[]>>("PRODUCT__FETCH_PRODUCTS");
export const fetchProductByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedProduct>, FailurePayloadWithId>("PRODUCT__FETCH_PRODUCT_BY_ID");
export const createProductAsync = createNetworkAction<CreateProductPayload, SuccessPayload<DetailedProduct>>("PRODUCT__CREATE_PRODUCT");
export const updateProductAsync = createNetworkAction<UpdateProductPayload, SuccessPayload<DetailedProduct>>("PRODUCT__UPDATE_PRODUCT");
export const deleteProductByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("PRODUCT__DELETE_PRODUCT");
