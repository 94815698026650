import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchAdminVenuesAsync } from "src/app/store/features/venue/venue.actions";
import VenueListContainer from "src/app/containers/Venue/VenueList.container";
import { uiCreateVenue, uiDeleteVenue } from "src/app/store/features/ui/venue/ui.venue.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function VenueListView(props: Props) {
	const {
		adminVenues,
		fetchVenues,
		createVenue,
		deleteVenue,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ fetchVenues }
			state={ adminVenues }
		>
			{
				venues =>
					<VenueListContainer
						venues={ venues }
						onCreate={ createVenue }
						onDelete={ deleteVenue }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	adminVenues: state.venue.adminVenues,
});

const mapDispatchToProps = {
	fetchVenues: fetchAdminVenuesAsync.request,
	createVenue: uiCreateVenue,
	deleteVenue: uiDeleteVenue,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueListView);
