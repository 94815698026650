/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import React, { useState } from "react";
import AvailabilitiesMobileRow from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/AvailabilitiesMobileRow.component";
import classNames from "classnames";
import SkeletonMobile from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Skeleton/SkeletonMobile.component";
import { breakpoints } from "src/app/utils/constants/constants";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";

type ComponentProps = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	showSkeleton: boolean
}

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

function AvailabilitiesMobile(props: Props) {

	const {
		form: {
			form: {
				venue,
			},
		},
		showSkeleton,
		bodyWidth,
	} = props;

	const [ rowsCollapse, setRowsCollapse ] = useState(venue.value?.rooms?.map(room => ({
		roomId: room.id,
		isCollapsed: bodyWidth > breakpoints.SM,
	})));

	const toggleRowCollapse = (roomId: number, isCollapsed: boolean) => {
		setRowsCollapse(prevState => prevState?.map(row => {
			if (row.roomId !== roomId) {
				if (isCollapsed) return { ...row, isCollapsed: bodyWidth > breakpoints.SM };
				return row;
			}
			return { ...row, isCollapsed };
		}));
	};

	return (
		<div className={ classNames(
			"flex-col gap-3",
			"flex",
			"lg:hidden",
		) }>
			{
				showSkeleton ? <SkeletonMobile/> :
					venue.value?.rooms
						 ?.sort((a, b) => a.maxPeople - b.maxPeople)
						 ?.map(room =>
							 <AvailabilitiesMobileRow
								 key={ room.id }
								 form={ props.form }
								 room={ room }
								 rowsCollapse={ rowsCollapse }
								 toggleRowCollapse={ toggleRowCollapse }
							 />,
						 )
			}
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodyWidth: state.ui.layout.bodySize.width,
});

export default connect(mapStateToProps)(AvailabilitiesMobile);
