/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DetailedRoom, UpdateRoomPayload } from "src/app/types/api/room.types";
import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { Nullable } from "src/app/types/util.types";
import { createFormField, validateField, validateNumberField, validatePhoto } from "src/app/utils/forms";
import { FormValidator } from "src/app/types/ui/form.types";
import { Venue } from "src/app/types/api/venue.types";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Button, Modal } from "flowbite-react";
import { getInitials } from "src/app/utils/ui";
import { HiX } from "react-icons/hi";
import Avatar from "src/app/components/Utils/Avatar.component";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import FileInput from "src/app/components/Form/FileInput.component";
import Input from "src/app/components/Form/Input.component";
import Select from "src/app/components/Form/Select.component";

type ComponentProps = {
	isOpen: boolean
	handleClose: () => void
	room: DetailedRoom
	venues: Venue[]
	onUpdate: (payload: UpdateRoomPayload) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type EditRoomBasicInformationForm = {
	name: string
	description: string
	maxPeople: string
	avatar: Nullable<File>
	venueId: number
}

const validator: FormValidator<EditRoomBasicInformationForm> = {
	name: (name, optional) => validateField("Nazwa", name, optional),
	description: (description, optional) => validateField("Opis", description, optional),
	maxPeople: (maxPeople, optional) => validateNumberField("Ilość osób", maxPeople, optional, 1),
	avatar: (avatar, optional) => validatePhoto("Awatar", avatar, optional),
	venueId: () => null,
};

function EditRoomBasicInformationModal(props: Props) {

	const {
		isOpen,
		handleClose,
		room,
		venues,
		onUpdate,
		isUpdating,
	} = props;

	const _handleSubmit = (values: EditRoomBasicInformationForm) => {
		onUpdate({
			...values,
			id: room.id,
			maxPeople: +values.maxPeople,
		});
		handleClose();
	};

	const _getInitialState = () => ({
		name: createFormField(room.name),
		description: createFormField(room.description ?? "", { optional: true }),
		maxPeople: createFormField(room.maxPeople.toString()),
		avatar: createFormField(undefined, { optional: true }),
		venueId: createFormField(room.venueId),
	});

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		setForm,
	} = useForm(_getInitialState(), validator, _handleSubmit);

	useEffect(() => {
		setForm(_getInitialState());
	}, [
		room.name,
		room.description,
		room.maxPeople,
		room.image,
		room.venueId,
	]);

	useEffect(() => {
		if (!isOpen && !isUpdating) {
			setForm(_getInitialState());
		}
	}, [ isOpen, isUpdating ]);

	const _getRoomAvatar = () => {
		if (isNotNull(form.avatar.value)) return URL.createObjectURL(form.avatar.value);

		if (isNotNull(room.image) && form.avatar.value === undefined) return room.image.thumb;

		return undefined;
	};

	return (
		<Modal
			show={ isOpen || isUpdating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isUpdating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Edytuj informacje o pokoju
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body>
					<div className="space-y-3">
						<>
							<div className="flex flex-col sm:flex-row gap-4 items-end">
								<div className="relative">
									<Avatar
										className="w-20 h-20 [&_svg]:w-20 [&_svg]:h-20"
										img={
											isNotNull(_getRoomAvatar())
												?
												_getRoomAvatar()
												:
												undefined
										}
										placeholderInitials={ getInitials(form.name.value) }
										size="lg"
									/>
									{
										isNotNull(_getRoomAvatar()) &&
                                        <HiX
                                            className="cursor-pointer text-white absolute -top-2.5 -right-2.5 p-1 rounded-full w-6 h-6 bg-red-500 transition duration-25 ease-in-out hover:bg-red-600 hover:text-gray-100"
                                            onClick={ () => handleChange("avatar", null) }
                                        />
									}
									{
										form.avatar.value !== form.avatar.initialValue &&
                                        <HiMiniArrowUturnLeft
                                            className="cursor-pointer text-white absolute -top-2.5 -left-2.5 p-1 rounded-full w-6 h-6 bg-yellow-400 transition duration-25 ease-in-out hover:bg-yellow-500 hover:text-gray-100"
                                            onClick={ () => handleChange("avatar", undefined) }
                                        />
									}
								</div>
								<FileInput
									label="Awatar"
									buttonText="Prześlij awatar"
									name="avatar"
									className="[&>div]:py-4 flex-grow"
									onChange={ file => {
										handleChange("avatar", file);
										handleBlur("avatar");
									} }
									options={ {
										accept: {
											"image/jpeg": [],
											"image/png": [],
										},
									} }
								/>
							</div>
							{
								isNotNull(form.avatar.error) &&
                                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
									{ form.avatar.error }
                                </div>
							}
						</>
						<Input
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Select
							label="Lokal"
							options={
								venues.map(venue => ({
									value: venue.id,
									label: venue.name,
								}))
							}
							formItem={ form.venueId }
							onChange={ option => {
								if (isNull(option)) return;

								handleChange("venueId", option.value);
								handleBlur("venueId");
							} }
							isSearchable={ false }
							isClearable={ false }
						/>
						<Input
							formItem={ form.description }
							label="Opis"
							name="description"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("description", e.target.value),
								onBlur: () => handleBlur("description"),
							} }
						/>
						<Input
							formItem={ form.maxPeople }
							label="Ilość osób"
							name="maxPeople"
							inputProps={ {
								type: "number",
								onChange: (e) => handleChange("maxPeople", e.target.value),
								onBlur: () => handleBlur("maxPeople"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isUpdating }
					>
						Zapisz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isUpdating: didLoadingRecordExist(state, { loadableType: LoadableType.UPDATE_ROOM, loadableId: props.room.id }),
});

export default connect(mapStateToProps)(EditRoomBasicInformationModal);
