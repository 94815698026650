import { RootEpic } from "src/app/store/root.epic";
import { debounceTime, filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { applicationStart, empty } from "src/app/store/features/misc/misc.actions";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import store from "src/app/store/index";
import { setBodySize, setIsDarkTheme, setMainSize, startListeningMainSize, throttleBodySize, throttleMainSize } from "src/app/store/features/ui/layout/ui.layout.actions";
import { Nullable } from "src/app/types/util.types";
import { LoggedUser } from "src/app/types/api/user.types";
import { uiAuthMe } from "src/app/store/features/ui/user/ui.user.actions";
import { forgetSession, logoutAsync } from "src/app/store/features/user/user.actions";

export const startListeningBodySizeEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(applicationStart)),
		map(_ => {
			const body = document.querySelector("body");

			if (isNotNull(body)) {
				const bodyResizeObserver = new ResizeObserver(function (entries) {
					let rect = entries[ 0 ].contentRect;
					let width = rect.width;
					let height = rect.height;
					store.dispatch(throttleBodySize({ width, height }));
				});

				const bodyMutationObserver = new MutationObserver(function (mutations) {
					mutations.forEach(function (mutation) {
						const elementAdded = Array.from(mutation.addedNodes).some(
							element => element.contains(document.querySelector("main")),
						);

						if (elementAdded) {
							store.dispatch(startListeningMainSize());
						}
					});
				});

				bodyResizeObserver.observe(body);
				bodyMutationObserver.observe(body, { childList: true, subtree: true, attributes: true });
			}

			const html = document.querySelector("html");

			if (isNotNull(html) && state$.value.ui.layout.isDarkTheme) {
				html.classList.add("dark");
			}

			return empty();
		}),
	);

const mainResizeObserver = new ResizeObserver(function (entries) {
	let rect = entries[ 0 ].contentRect;
	let width = rect.width;
	let height = rect.height;
	store.dispatch(throttleMainSize({ width, height }));
});

export const startListeningMainSizeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(startListeningMainSize)),
		map(() => {
			const main = document.querySelector("main");
			if (isNotNull(main)) {
				mainResizeObserver.disconnect();
				mainResizeObserver.observe(main);
			}
			return empty();
		}),
	);

export const stopListeningMainSizeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(action => isActionOf(logoutAsync.success, action) || isActionOf(forgetSession, action)),
		map(() => {
			mainResizeObserver.disconnect();
			return empty();
		}),
	);

export const throttleMainSizeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(throttleMainSize)),
		debounceTime(250),
		map(action => setMainSize(action.payload)),
	);

export const setIsDarkThemeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(setIsDarkTheme)),
		map(action => {
			const html = document.querySelector("html");
			if (isNull(html)) return empty();

			if (action.payload) {
				html.classList.add("dark");
			} else {
				html.classList.remove("dark");
			}

			return empty();
		}),
	);

export const throttleBodySizeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(throttleBodySize)),
		debounceTime(250),
		map(action => setBodySize(action.payload)),
	);

export const startListeningLocalStorageChangesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(applicationStart)),
		map(_ => {
			window.addEventListener("storage", (e) => {
				if (isNotNull(e.key) && e.key === "persist:auth" && isNotNull(e.oldValue) && isNotNull(e.newValue)) {
					try {
						const loggedUser: Nullable<LoggedUser> = JSON.parse(JSON.parse(e.oldValue)?.loggedUser);
						const newLoggedUser: Nullable<LoggedUser> = JSON.parse(JSON.parse(e.newValue)?.loggedUser);
						if (isNotNull(newLoggedUser) && loggedUser?.id !== newLoggedUser?.id) {
							store.dispatch(uiAuthMe());
						}
					} catch (e) {
						console.error("Exception: Received invalid json during parsing local storage.");
					}
				}
			});

			return empty();
		}),
	);
