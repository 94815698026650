/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { HiClipboardCopy, HiPlus } from "react-icons/hi";
import { WeekDay } from "src/app/types/util.types";
import { weekDayDictionary } from "src/app/utils/constants/dictionaries";
import { RoomTimeRange } from "src/app/types/api/room.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import Dropdown, { DropdownItem } from "src/app/components/Utils/Dropdown.component";
import TimeWindow from "src/app/components/Room/TimeWindow/TimeWindow.component";
import { Button } from "flowbite-react";
import { getFormattedHour } from "src/app/utils/helpers";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		weekDay: WeekDay
		timeRanges: RoomTimeRange[]
		onOpenCreateModal: (weekday: WeekDay) => void
		onEditTimeWindow: (timeWindow: RoomTimeRange) => void
		onCopyTimeWindowFromOtherWeekday: (sourceWeekday: WeekDay, destinationWeekday: WeekDay) => void
		onDeleteTimeWindow: (timeWindowId: number) => void
	};

function TimeWindowWeekPreview(props: Props) {

	const {
		weekDay,
		timeRanges,
		onOpenCreateModal,
		onEditTimeWindow,
		onCopyTimeWindowFromOtherWeekday,
		onDeleteTimeWindow,
		isCopying,
	} = props;

	const sortedTimeRanges = timeRanges.sort((a, b) => {
		const aNumberStartHour = Number(a.startHour.split(":")[ 0 ]);
		const bNumberStartHour = Number(b.startHour.split(":")[ 0 ]);
		return getFormattedHour(aNumberStartHour) - getFormattedHour(bNumberStartHour);
	})

	return (
		<div className="flex flex-col gap-2">
			<div className="flex gap-2 justify-between">
				<h2 className="text-lg font-semibold">{ weekDayDictionary[ weekDay ] }</h2>
				<div className="flex gap-2">
					<Button
						color="orange-outline"
						size="sm"
						className="[&>span]:!py-[5px]"
						onClick={ () => onOpenCreateModal(weekDay) }
					>
						<HiPlus className="mr-2 h-4 w-3 text-white group-hover:text-white transition"/>
						Dodaj
					</Button>
					<Dropdown
						size="sm"
						color="gray-outline"
						isProcessing={ isCopying(weekDay) }
						label={
							<>
								<HiClipboardCopy className="mr-2 h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
								Kopiuj z innego dnia
							</>
						}
					>
						{
							Object
								.values(WeekDay)
								.filter(day => day !== weekDay)
								.map(day =>
									<DropdownItem
										key={ day }
										onClick={ () => onCopyTimeWindowFromOtherWeekday(day, weekDay) }
									>
										{ weekDayDictionary[ day ] }
									</DropdownItem>,
								)
						}
					</Dropdown>
				</div>
			</div>
			<div className="flex gap-4 overflow-x-auto">
				{
					sortedTimeRanges.map(timeRange =>
						<TimeWindow
							key={ timeRange.id }
							timeRange={ timeRange }
							onEditTimeWindow={ onEditTimeWindow }
							onDeleteTimeWindow={ onDeleteTimeWindow }
						/>
					)
				}
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCopying: (weekday: WeekDay) => didLoadingRecordExist(state, { loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_WEEKDAY, loadableId: weekday }),
});

export default connect(mapStateToProps)(TimeWindowWeekPreview);
