import { Category, CreateCategoryPayload, UpdateCategoryPayload } from "src/app/types/api/category.types";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { ArrayElement, ModalConfig, Nullable } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { canDeleteCategory, canViewCategory } from "src/app/utils/abilities";
import React, { useState } from "react";
import { Room } from "src/app/types/api/room.types";
import { Button } from "flowbite-react";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { customDataTableSearch } from "src/app/utils/helpers";
import UpdateCategoryModal from "src/app/components/Category/UpdateCategoryModal.component";
import CreateCategoryModal from "src/app/components/Category/CreateCategoryModal.component";
import { useSearchParams } from "react-router-dom";
import DeleteCell from "src/app/components/Utils/DeleteCell.component";

type ComponentProps = {
    categories: Category[]
    onCreate: (payload: CreateCategoryPayload) => void
    onDelete: (categoryId: number) => void
    onUpdate: (payload: UpdateCategoryPayload) => void
};

type Props =
    ReturnType<typeof mapStateToProps>
    & ComponentProps

function CategoryListContainer(props: Props) {

    const {
        categories,
        onCreate,
        onDelete,
        onUpdate,
        isDeleting,
    } = props;

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ isCreateModalOpen, toggleCreateModal ] = useState(false);
    const [ updateCategoryModalConfig, setUpdateCategoryModalConfig ] = useState<ModalConfig<Category>>({
        isOpen: false,
        value: null,
    });
    const [ deleteRoomModal, setDeleteRoomModal ] = useState<ModalConfig<Category>>({
        isOpen: false,
        value: null,
    });

    const paramCategoryId = searchParams.get("categoryId") ?? "0";
    const paramCategory = categories.find(({ id }) => id === Number(paramCategoryId));

    const categoryColumns: MUIDataTableColumn[] = [
        {
            name: "Nazwa",
            options: {
                filter: false,
                sort: true,
                customBodyRender: ({ name }: ArrayElement<Props[ "categories" ]>) => name,
                sortCompare: order => (a, b) => {
                    if (order === "asc") {
                        return (a.data.name.toString()).localeCompare(b.data.name.toString());
                    } else {
                        return (b.data.name.toString()).localeCompare(a.data.name.toString());
                    }
                },
            },
        },{
            name: "Akcje",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (category: Category) =>
                    <DeleteCell
                        ability={ canDeleteCategory(category) }
                        onDelete={ e => {
                            e.stopPropagation();
                            setDeleteRoomModal({
                                isOpen: true,
                                value: category,
                            });
                        } }
                    />,
                setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
            },
        },
    ];

    const tableOptions: MUIDataTableOptions = {
        onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
            const clickedCategory: Nullable<ArrayElement<Props[ "categories" ]>> = props.categories[ rowMeta.dataIndex ];
            if (isNotNull(clickedCategory) && canViewCategory(clickedCategory)) {
                setUpdateCategoryModalConfig({ isOpen: true, value: clickedCategory });
            }
        },
        customToolbar: () =>
            <Button
                className="order-first mr-3"
                size="sm"
                color="primary"
                onClick={ () => toggleCreateModal(true) }
            >
                Dodaj kategorie
            </Button>,
        customSearch: (searchQuery: string, currentRow: Room[]) => customDataTableSearch(searchQuery, [
            currentRow[ 0 ]?.name,
        ]),
        setRowProps: (row, dataIndex) => {
            const currentCategory = categories[ dataIndex ];
            if (isNotNull(currentCategory) && currentCategory.id === Number(paramCategoryId)) {
                return {
                    className: "[&>td]:!bg-myPrimary-orange-100 [&>td]hover:!bg-myPrimary-orange-100",
                };
            }

            return {};
        },
    };

    const _handleConfirmDeleteRoom = () => {
        if (isNotNull(deleteRoomModal.value)) {
            onDelete(deleteRoomModal.value.id);
            setDeleteRoomModal(prevState => ({ ...prevState, isOpen: false }));
        }
    };

    const isDeletingRoom = isNotNull(deleteRoomModal.value) && isDeleting(deleteRoomModal.value.id);

    return (
        <>
            <Table
                title="Kategorie"
                columns={ categoryColumns }
                options={ tableOptions }
                usePersist
                data={ categories }
                defaultPage={
                    rowsPerPage => {
                        const chosenCategory = categories.find(category => category.id === Number(paramCategoryId));
                        if (isNull(chosenCategory)) return 0;
                        return Math.floor(categories.indexOf(chosenCategory) / rowsPerPage);
                    }
                }
            />
            <ConfirmModal
                title={ `Usuń ${ isNotNull(deleteRoomModal.value) ? deleteRoomModal.value.name : "kategorie" }` }
                warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
                isOpen={ deleteRoomModal.isOpen }
                confirmWord="kasuj"
                isLoading={ isDeletingRoom }
                handleClose={ () => setDeleteRoomModal({ isOpen: false, value: null }) }
                onConfirm={ _handleConfirmDeleteRoom }
            />
            <CreateCategoryModal
                isOpen={ isCreateModalOpen }
                handleClose={ () => toggleCreateModal(false) }
                onCreate={ onCreate }
            />
            {
                isNotNull(updateCategoryModalConfig.value) &&
                <UpdateCategoryModal
                    isOpen={ updateCategoryModalConfig.isOpen }
                    handleClose={ () => {
                        setUpdateCategoryModalConfig(prevState => ({ ...prevState, isOpen: false }));
                        searchParams.delete("categoryId");
                        setSearchParams(searchParams);
                    } }
                    onUpdate={ onUpdate }
                    category={ updateCategoryModalConfig.value }
                />
            }
            {
                isNotNull(paramCategory) &&
                <UpdateCategoryModal
                    isOpen={ updateCategoryModalConfig.isOpen }
                    handleClose={ () => {
                        setUpdateCategoryModalConfig(prevState => ({ ...prevState, isOpen: false }));
                        searchParams.delete("categoryId");
                        setSearchParams(searchParams);
                    } }
                    onUpdate={ onUpdate }
                    category={ paramCategory as Category }
                />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    isDeleting: (categoryId: number) => didLoadingRecordExist(state, { loadableType: LoadableType.DELETE_CATEGORY, loadableId: categoryId }),
});

export default connect(mapStateToProps)(CategoryListContainer);
