import { useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { fetchCategoriesAsync } from "src/app/store/features/category/category.actions";
import { Nullable, SelectOption } from "src/app/types/util.types";
import { Category } from "src/app/types/api/category.types";
import { FormItem } from "src/app/types/ui/form.types";
import Select, { components, GroupBase, MenuPlacement, OptionProps, ValueContainerProps } from "react-select";
import classNames from "classnames";
import { isArray, isEmptyString, isNotNull } from "src/app/utils/typeguards";
import { PublicBaseSelectProps } from "react-select/base";
import { DataState, ErrorState, LoadingState } from "src/app/types/redux.types";
import { connect } from "react-redux";
import { Label, LabelProps } from "flowbite-react";
import { GridLoader } from "react-spinners";
import { getSelectClassNames, getSelectStyles } from "src/app/utils/ui";

type ComponentProps = {
	className?: string
	label?: string | ReactNode
    labelProps?: LabelProps
	formItem: FormItem<Nullable<Category>>
	onChange: (category: Nullable<Category>) => void
	displayErrorMessage?: boolean
	isSearchable?: boolean
	isClearable?: boolean
	portalEl?: HTMLElement
	menuPlacement?: MenuPlacement
	optionsHeight?: number
	inputHeight?: number
	excludeCategoryIds?: number[]
};

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

const _mapCategoryToOption = (category: Category): SelectOption<Category> => ({
	value: category,
	label: `${ category.id }`,
});

function CategorySelect(props: Props) {

	const {
		className,
		label,
        labelProps,
		formItem,
		onChange,
		displayErrorMessage = true,
		isSearchable = true,
        isClearable = true,
		portalEl,
		menuPlacement = "auto",
		optionsHeight = 240, // for styles
		inputHeight, // for styles
        categories,
		fetchCategories,
		excludeCategoryIds,
	} = props;

	useEffect(() => {
		if (categories.loadingState === LoadingState.NOT_LOADING) {
			fetchCategories();
		}
	}, []);

	const _getSelectOptions = (): SelectOption<Category>[] => {
		if (categories.errorState === ErrorState.PRESENT) return [];

		if (categories.dataState === DataState.PRESENT && categories.loadingState === LoadingState.NOT_LOADING) {
			if (isNotNull(excludeCategoryIds)) {
				return categories.data
					.filter(category => !excludeCategoryIds.includes(category.id))
					.map(_mapCategoryToOption);
			}
			return categories.data.map(_mapCategoryToOption);
		}

		return [];
	};

	const _getNoOptionsMessage = () => {
		const loadingResponse = (
			<>
                <GridLoader size={ 4 } color="#EC5600"/>
				Ładowanie Category
			</>
		);
		if (categories.loadingState === LoadingState.LOADING) {
			return loadingResponse;
		}

		if (categories.errorState === ErrorState.PRESENT) return "Błąd ładowania Category";

		if (categories.dataState === DataState.PRESENT) return "Brak Category";

		return loadingResponse;
	};

	const selectProps: Partial<PublicBaseSelectProps<SelectOption<Category>, false, GroupBase<SelectOption<Category>>>> = {
        styles: getSelectStyles<Category, false>(optionsHeight, inputHeight),
        classNames: getSelectClassNames<Category, false>(formItem, isClearable),
        isClearable: isClearable,
		isSearchable: isSearchable,
		placeholder: "Wybierz...",
		value: isNotNull(formItem.value) ? _mapCategoryToOption(formItem.value) : null,
		onChange: value => onChange(value?.value ?? null),
		components: {
			Option: OptionComponent,
			ValueContainer: ValueContainerComponent,
		},
		menuPortalTarget: portalEl,
		menuPlacement: menuPlacement,
        isMulti: false,
		options: _getSelectOptions(),
        noOptionsMessage: () => (
            <div className="flex items-center gap-2">
                { _getNoOptionsMessage() }
            </div>
        ),
	};

	return (
        <div className={ classNames(className, "flex flex-col gap-y-0.5") }>
            {
                isNotNull(label) &&
                <Label { ...labelProps }>
                    { label }
                </Label>
            }
			<Select { ...selectProps }/>
			{
				(isNotNull(formItem.error) && displayErrorMessage) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ formItem.error }
                </div>
			}
		</div>
	);
}

const OptionComponent = (props: OptionProps<SelectOption<Category>, false>) => {

	const {
		data: {
			value: category,
		},
        isFocused,
	} = props;

	return (
		<components.Option { ...props }>
            <div className="flex items-center gap-2">
                <span className={ classNames({ "dark:text-white": !isFocused }, { "dark:text-black": isFocused }) }>
                    { `${ category.name }` }
                </span>
			</div>
		</components.Option>
	);
};

const ValueContainerComponent = (props: ValueContainerProps<SelectOption<Category>, false>) => {

	const {
		getValue,
		selectProps: {
			inputValue,
		},
	} = props;

	const selectedValues = getValue();

	if (selectedValues.length > 0) {
		const category = selectedValues[ 0 ].value;
		return (
			<components.ValueContainer
				{ ...props }
				className={ classNames(props.className, "flex") }
			>
				{
					isEmptyString(inputValue) &&
                    <div className="flex items-center gap-2">
                        <span className="dark:text-white">
                            { `${ category.name }` }
                        </span>
                    </div>
				}

				{ /* children[ 1 ] = Search input */ }
				{
					isArray(props.children)
						?
						props.children[ 1 ]
						:
						props.children
				}
			</components.ValueContainer>
		);
	} else {
		return (
			<components.ValueContainer { ...props }>
				{ props.children }
			</components.ValueContainer>
		);
	}
};

const mapStateToProps = (state: RootState) => ({
    categories: state.category.categories,
});

const mapDispatchToProps = {
	fetchCategories: fetchCategoriesAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelect);
