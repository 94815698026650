/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { DiscountCode, DetailedDiscountCode } from "src/app/types/api/discountCode.types";
import { CreateDiscountCodePayload, UpdateDiscountCodePayload } from "src/app/types/api/discountCode.types";

export const fetchDiscountCodesAsync = createNetworkAction<undefined, SuccessPayload<DiscountCode[]>>("DISCOUNT_CODE__FETCH_DISCOUNT_CODES");
export const fetchDiscountCodeByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedDiscountCode>, FailurePayloadWithId>("DISCOUNT_CODE__FETCH_DISCOUNT_CODE_BY_ID");
export const createDiscountCodeAsync = createNetworkAction<CreateDiscountCodePayload, SuccessPayload<DetailedDiscountCode>>("DISCOUNT_CODE__CREATE_DISCOUNT_CODE");
export const updateDiscountCodeAsync = createNetworkAction<UpdateDiscountCodePayload, SuccessPayload<DetailedDiscountCode>>("DISCOUNT_CODE__UPDATE_DISCOUNT_CODE");
export const deleteDiscountCodeByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("DISCOUNT_CODE__DELETE_DISCOUNT_CODE");
