/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { DetailedRoom, Room } from "src/app/types/api/room.types";
import { LoadableType } from "src/app/types/ui/loading.types";
import { FormValidator } from "src/app/types/ui/form.types";
import useForm from "src/app/utils/hooks/useForm";
import { createFormField, validateNullableField } from "src/app/utils/forms";
import { Nullable } from "src/app/types/util.types";
import { isNull } from "src/app/utils/typeguards";
import { Button, Modal } from "flowbite-react";
import Select from "src/app/components/Form/Select.component";
import { useEffect } from "react";

type ComponentProps = {
	isOpen: boolean
	room: DetailedRoom
	rooms: Room[]
	handleClose: () => void
	handleSave: (roomId: number) => void
};

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

type CopyTimeRangesFromOtherRoomForm = {
	roomId: Nullable<number>
}

const validator: FormValidator<CopyTimeRangesFromOtherRoomForm> = {
	roomId: (roomId, optional) => validateNullableField("Pokój", roomId, optional),
};

function CopyTimeRangesFromOtherRoomModal(props: Props) {

	const {
		isOpen,
		handleClose,
		handleSave,
		rooms,
		room,
		isCopying,
	} = props;

	const _handleSubmit = (values: CopyTimeRangesFromOtherRoomForm) => {
		if (isNull(values.roomId)) return;

		handleSave(values.roomId);
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		roomId: createFormField(null),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isCopying) {
			resetForm();
		}
	}, [ isOpen, isCopying ]);

	return (
		<Modal
			show={ (isOpen || isCopying) }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isCopying) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Skopiuj harmonogram
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						<Select
							label="Pokój"
							options={
								rooms
									.filter(r => r.id !== room.id)
									.map(room => ({
										value: room.id,
										label: room.name,
									}))
							}
							formItem={ form.roomId }
							onChange={ option => {
								if (isNull(option)) return;

								handleChange("roomId", option.value);
								handleBlur("roomId");
							} }
							isSearchable={ false }
							isClearable={ false }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isCopying }
					>
						Kopiuj
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState, props: ComponentProps) => ({
	isCopying: didLoadingRecordExist(state, { loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_ROOM, loadableId: props.room.id }),
});

export default connect(mapStateToProps)(CopyTimeRangesFromOtherRoomModal);
