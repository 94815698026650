import { CreateDiscountCodePayload, DiscountCode, DiscountCodeType, DiscountCodeValidityType, UpdateDiscountCodePayload } from "src/app/types/api/discountCode.types";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { ModalConfig, Nullable, SortCompare } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { canDeleteDiscountCode, canViewDiscountCode } from "src/app/utils/abilities";
import { discountCodeTypeDictionary } from "src/app/utils/constants/dictionaries";
import { Badge, Button } from "flowbite-react";
import { useState } from "react";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import CreateDiscountCodeModal from "src/app/components/DiscountCode/CreateDiscountCodeModal.component";
import EditDiscountCodeModal from "src/app/components/DiscountCode/EditDiscountCodeModal.component";
import { LocaleFromISO } from "src/app/utils/luxon";
import DeleteCell from "src/app/components/Utils/DeleteCell.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		discountCodes: DiscountCode[]
		onCreate: (payload: CreateDiscountCodePayload) => void
		onUpdate: (payload: UpdateDiscountCodePayload) => void
		onDelete: (discountCodeId: number) => void
	};

function DiscountCodeListContainer(props: Props) {

	const {
		discountCodes,
		onCreate,
		onUpdate,
		onDelete,
		isDeleting,
	} = props;

	const [ isCreateModalOpen, toggleCreateModal ] = useState(false);
	const [ editDiscountCodeModal, setEditDiscountCodeModal ] = useState<ModalConfig<DiscountCode>>({
		isOpen: false,
		value: null,
	});

	const [ deleteDiscountCodeModal, setDeleteDiscountCodeModal ] = useState<ModalConfig<DiscountCode>>({
		isOpen: false,
		value: null,
	});

	const discountCodeColumns: MUIDataTableColumn[] = [
		{
			name: "Kod",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (discountCode: DiscountCode) => discountCode.code,
				sortCompare: order => (a: SortCompare<DiscountCode>, b: SortCompare<DiscountCode>) => {
					if (order === "asc") {
						return a.data.code.localeCompare(b.data.code);
					} else {
						return b.data.code.localeCompare(a.data.code);
					}
				},
			},
		}, {
			name: "Typ",
			options: {
				filter: true,
				filterOptions: {
					names: Object.values(DiscountCodeType).map(type => discountCodeTypeDictionary[ type ]),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const discountCode: DiscountCode = row[ 0 ];
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(discountCodeTypeDictionary[ discountCode.type ].toLowerCase());
					},
				},
				sort: true,
				customBodyRender: (discountCode: DiscountCode) => {
					const type = discountCodeTypeDictionary[ discountCode.type ];
					if (discountCode.type === DiscountCodeType.AMOUNT) {
						return (
							<div className="w-fit">
								<Badge color="warning">{ type }</Badge>
							</div>
						);
					} else {
						return (
							<div className="w-fit">
								<Badge color="indigo">{ type }</Badge>
							</div>
						);
					}
				},
				sortCompare: order => (a: SortCompare<DiscountCode>, b: SortCompare<DiscountCode>) => {
					const typeA = discountCodeTypeDictionary[ a.data.type ];
					const typeB = discountCodeTypeDictionary[ b.data.type ];
					if (order === "asc") {
						return typeA.localeCompare(typeB);
					} else {
						return typeB.localeCompare(typeA);
					}
				},
			},
		}, {
			name: "Wielkość zniżki",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (discountCode: DiscountCode) => {
					if (discountCode.type === DiscountCodeType.AMOUNT) {
						return `${ discountCode.amount } zł`;
					} else {
						return `${ discountCode.percentage }%`;
					}
				},
				sortCompare: order => (a: SortCompare<DiscountCode>, b: SortCompare<DiscountCode>) => {
					const amountA = a.data.type === DiscountCodeType.AMOUNT ? +a.data.amount : a.data.percentage / 100;
					const amountB = b.data.type === DiscountCodeType.AMOUNT ? +b.data.amount : b.data.percentage / 100;
					if (order === "asc") {
						return amountA - amountB;
					} else {
						return amountB - amountA;
					}
				},
			},
		}, {
			name: "Czy aktywny?",
			options: {
				filter: true,
				filterOptions: {
					names: [ "Aktywny", "Nieaktywny" ],
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const discountCode: DiscountCode = row[ 0 ];
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(discountCode.isActive ? "aktywny" : "nieaktywny");
					},
				},
				sort: true,
				customBodyRender: (discountCode: DiscountCode) =>
					<div className="w-fit">
						<Badge color={ discountCode.isActive ? "success" : "failure" }>
							{ discountCode.isActive ? "Aktywny" : "Nieaktywny" }
						</Badge>
					</div>,
				sortCompare: order => (a: SortCompare<DiscountCode>, b: SortCompare<DiscountCode>) => {
					if (order === "asc") {
						return Number(a.data.isActive) - Number(b.data.isActive);
					} else {
						return Number(b.data.isActive) - Number(a.data.isActive);
					}
				},
			},
		}, {
			name: "Wygasa",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (discountCode: DiscountCode) => {
					if (discountCode.validityType === DiscountCodeValidityType.EXPIRES_AT) {
						return LocaleFromISO(discountCode.expiresAt).toFormat("dd/MM/yyyy");
					} else {
						return `${ discountCode.usages ?? 0 }/${ discountCode.maxUsages }`;
					}
				},
				sortCompare: order => (a: SortCompare<DiscountCode>, b: SortCompare<DiscountCode>) => {
					if (order === "asc") {
						return Number(a.data.isActive) - Number(b.data.isActive);
					} else {
						return Number(b.data.isActive) - Number(a.data.isActive);
					}
				},
			},
		},  {
			name: "Data utworzenia",
			options: {
				filter: false,
				sort: false,
				customBodyRender: ({ createdAt }: DiscountCode) => LocaleFromISO(createdAt).toFormat("dd/MM/yyyy"),
				sortCompare: order => (a: SortCompare<DiscountCode>, b: SortCompare<DiscountCode>) => {
					if (order === "asc") {
						return Number(a.data.createdAt) - Number(b.data.createdAt);
					} else {
						return Number(b.data.createdAt) - Number(a.data.createdAt);
					}
				},
			},
		}, {
			name: "Akcje",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (discountCode: DiscountCode) =>
					<DeleteCell
						ability={ canDeleteDiscountCode(discountCode) }
						onDelete={ e =>  {
							e.stopPropagation();
							setDeleteDiscountCodeModal({
								isOpen: true,
								value: discountCode,
							})
						} }
					/>,
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
			const clickedDiscountCode: Nullable<DiscountCode> = props.discountCodes[ rowMeta.dataIndex ];
			if (isNotNull(clickedDiscountCode) && canViewDiscountCode(clickedDiscountCode)) {
				setEditDiscountCodeModal({
					isOpen: true,
					value: clickedDiscountCode,
				});
			}
		},
		customToolbar: () =>
			<Button
				className="order-first mr-3"
				size="sm"
				color="primary"
				onClick={ () => toggleCreateModal(true) }
			>
				Dodaj kod rabatowy
			</Button>,
		customSearch: (searchQuery: string, currentRow: DiscountCode[]) => {
			searchQuery = searchQuery.toLowerCase();
			const row = currentRow[ 0 ];
			const code = row.code;
			const type = discountCodeTypeDictionary[ row.type ];
			const price = row.type === DiscountCodeType.AMOUNT ? `${ row.amount } zł` : `${ row.percentage }%`;

			if (code.toLowerCase().includes(searchQuery)) {
				return true;
			} else if (type.toLowerCase().includes(searchQuery)) {
				return true;
			} else if (price.toLowerCase().includes(searchQuery)) {
				return true;
			}
			return false;
		},
	};

	const _handleConfirmDeleteDiscountCode = () => {
		if (isNotNull(deleteDiscountCodeModal.value)) {
			onDelete(deleteDiscountCodeModal.value.id);
			setDeleteDiscountCodeModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};

	const isDeletingRoom = isNotNull(deleteDiscountCodeModal.value) && isDeleting(deleteDiscountCodeModal.value.id);

	return (
		<>
			<Table
				title="Kody rabatowe"
				columns={ discountCodeColumns }
				options={ tableOptions }
				data={ discountCodes }
			/>
			<ConfirmModal
				title={ `Usuń ${ isNotNull(deleteDiscountCodeModal.value) ? deleteDiscountCodeModal.value.code : "kod rabatowy" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ deleteDiscountCodeModal.isOpen }
				confirmWord="kasuj"
				isLoading={ isDeletingRoom }
				handleClose={ () => setDeleteDiscountCodeModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmDeleteDiscountCode }
			/>
			<CreateDiscountCodeModal
				isOpen={ isCreateModalOpen }
				handleClose={ () => toggleCreateModal(false) }
				onSave={ onCreate }
			/>
			<EditDiscountCodeModal
				isOpen={ editDiscountCodeModal.isOpen }
				handleClose={ () => setEditDiscountCodeModal(prev => ({ ...prev, isOpen: false })) }
				discountCode={ editDiscountCodeModal.value }
				onUpdate={ onUpdate }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isDeleting: (discountCodeId: number) => didLoadingRecordExist(state, { loadableId: discountCodeId, loadableType: LoadableType.DELETE_DISCOUNT_CODE }),
});

export default connect(mapStateToProps)(DiscountCodeListContainer);
