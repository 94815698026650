import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchDiscountCodesAsync } from "src/app/store/features/discountCode/discountCode.actions";
import DiscountCodeListContainer from "src/app/containers/DiscountCode/DiscountCodeList.container";
import { uiCreateDiscountCode, uiDeleteDiscountCode, uiUpdateDiscountCode } from "src/app/store/features/ui/discountCode/ui.discountCode.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function DiscountCodeListView(props: Props) {
	const {
		discountCodes,
		fetchDiscountCodes,
		createDiscountCode,
		updateDiscountCode,
		deleteDiscountCode,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ fetchDiscountCodes }
			state={ discountCodes }
		>
			{
				discountCodes =>
					<DiscountCodeListContainer
						discountCodes={ discountCodes }
						onCreate={ createDiscountCode }
						onUpdate={ updateDiscountCode }
						onDelete={ deleteDiscountCode }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	discountCodes: state.discountCode.discountCodes,
});

const mapDispatchToProps = {
	fetchDiscountCodes: fetchDiscountCodesAsync.request,
	createDiscountCode: uiCreateDiscountCode,
	updateDiscountCode: uiUpdateDiscountCode,
	deleteDiscountCode: uiDeleteDiscountCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCodeListView);
