/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map, mergeMap } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedProduct } from "src/app/types/api/product.types";
import { createProductAsync, deleteProductByIdAsync, fetchAdminProductsAsync, fetchProductByIdAsync, fetchProductsAsync, updateProductAsync } from "src/app/store/features/product/product.actions";
import { isNotNull } from "src/app/utils/typeguards";
import { from, of } from "rxjs";

export const fetchProductEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchProductsAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/products",
				method: "GET",
				withScope: true,
				onSuccess: fetchProductsAsync.success,
				onFailure: fetchProductsAsync.failure,
			}),
		),
	);

export const fetchAdminProductEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchAdminProductsAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/admin/products`,
				method: "GET",
				withScope: true,
				onSuccess: fetchAdminProductsAsync.success,
				onFailure: fetchAdminProductsAsync.failure,
			}),
		),
	);

export const fetchProductByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchProductByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/products/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedProduct>) => fetchProductByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchProductByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const createProductEpic: RootEpic = (action$, _, services) =>
	action$.pipe(
		filter(isActionOf(createProductAsync.request)),
		mergeMap(action =>
			isNotNull(action.payload.avatar)
				?
				from(services.file.parseFile(action.payload.avatar))
					.pipe(
						map(parsedFile =>
							apiAsync.request({
								url: "/admin/products",
								method: "POST",
								data: {
									...action.payload,
									avatar: parsedFile,
								},
								withScope: true,
								onSuccess: createProductAsync.success,
								onFailure: createProductAsync.failure,
							}),
						),
					)
				:
				of(apiAsync.request({
					url: "/admin/products",
					method: "POST",
					data: action.payload,
					withScope: true,
					onSuccess: createProductAsync.success,
					onFailure: createProductAsync.failure,
				})),
		),
	);

export const updateProductEpic: RootEpic = (action$, _, services) =>
	action$.pipe(
		filter(isActionOf(updateProductAsync.request)),
		mergeMap(action =>
			isNotNull(action.payload.avatar)
				?
				from(services.file.parseFile(action.payload.avatar))
					.pipe(
						map(parsedFile =>
							apiAsync.request({
								url: `/admin/products/${ action.payload.id }`,
								method: "PUT",
								data: {
									...action.payload,
									avatar: parsedFile,
								},
								withScope: true,
								onSuccess: updateProductAsync.success,
								onFailure: updateProductAsync.failure,
							}),
						),
					)
				:
				of(apiAsync.request({
					url: `/admin/products/${ action.payload.id }`,
					method: "PUT",
					data: action.payload,
					withScope: true,
					onSuccess: updateProductAsync.success,
					onFailure: updateProductAsync.failure,
				})),
		),
	);

export const deleteProductByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteProductByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/products/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteProductByIdAsync.success,
				onFailure: deleteProductByIdAsync.failure,
			}),
		),
	);
