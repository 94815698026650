import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { deleteObject, deleteStateReducer, handleBasicActions, handleBasicActionsForArray, handleBasicActionsForPagination, handleDeleteItemFromPagination, initialPaginatedStateReducer, initialStateReducer, replaceOrPushObject, replaceStateReducer } from "src/app/utils/redux";
import { ArrayStateReducer, DataState, ErrorState, LoadingState, PaginationStateReducer, StateReducer } from "src/app/types/redux.types";
import { DetailedUser, FetchUsersRequestPayload, LoggedUser, User, UserOrganizationScope, UserTwoFactorAuthentication } from "src/app/types/api/user.types";
import { authMeAsync, confirmTwoFactorAuthenticationAsync, createUserAsync, deleteUserAsync, disableTwoFactorAuthenticationAsync, enableTwoFactorAuthenticationAsync, fetchPaginatedUsersAsync, fetchUserByIdAsync, fetchUsersAsync, fetchUserScopesAsync, forgetSession, loginAsync, logoutAsync, scopeAuthMeAsync, updateUserAsync, updateUserPasswordAsync, updateUserStatusAsync } from "src/app/store/features/user/user.actions";
import moment from "moment";
import { uiChangePaginatedUsersActualPageIndex, uiResetPaginatedUsers, uiSetUserOrganizationScope } from "src/app/store/features/ui/user/ui.user.actions";
import { Nullable } from "src/app/types/util.types";
import { UserScope } from "src/app/types/ui/user.types";
import { uiReservationInit } from "src/app/store/features/ui/reservation/ui.reservation.actions";

const reducer = combineReducers({
	loggedUser: createReducer(initialStateReducer as StateReducer<LoggedUser>)
		.handleAction([ authMeAsync.request, authMeAsync.success, authMeAsync.failure ], handleBasicActions(authMeAsync))
		.handleAction([ loginAsync.success, scopeAuthMeAsync.success ], (_, action) => ({
			dataState: DataState.PRESENT,
			loadingState: LoadingState.NOT_LOADING,
			errorState: ErrorState.NOT_PRESENT,
			fetchedAt: moment(),
			data: action.payload.data,
		}))
		.handleAction([ logoutAsync.success, forgetSession ], () => initialStateReducer),
	userOrganizationScopes: createReducer(initialStateReducer as StateReducer<UserOrganizationScope[]>)
		.handleAction([ fetchUserScopesAsync.request, fetchUserScopesAsync.success, fetchUserScopesAsync.failure ], handleBasicActions(fetchUserScopesAsync))
		.handleAction([ logoutAsync.success, forgetSession ], () => initialStateReducer),
	userScope: createReducer(null as Nullable<UserScope>)
		.handleAction([ uiSetUserOrganizationScope, uiReservationInit ], (_, action) => action.payload)
		.handleAction([ logoutAsync.success, forgetSession ], () => null),
	paginatedUsers: createReducer(initialPaginatedStateReducer as PaginationStateReducer<User[], FetchUsersRequestPayload["sort"], FetchUsersRequestPayload["filters"]>)
		.handleAction([ forgetSession, uiResetPaginatedUsers ], () => initialPaginatedStateReducer)
		.handleAction([ fetchPaginatedUsersAsync.request, fetchPaginatedUsersAsync.success, fetchPaginatedUsersAsync.failure ], handleBasicActionsForPagination(fetchPaginatedUsersAsync))
		.handleAction([ deleteUserAsync.success ], handleDeleteItemFromPagination())
		.handleAction([ uiChangePaginatedUsersActualPageIndex ], (state, action) => ({
			...state,
			meta: {
				...state.meta,
				actualPageIndex: action.payload,
			},
		})),
	users: createReducer(initialStateReducer as StateReducer<User[]>)
		.handleAction([ forgetSession ], () => initialStateReducer)
		.handleAction([ fetchUsersAsync.request, fetchUsersAsync.success, fetchUsersAsync.failure ], handleBasicActions(fetchUsersAsync))
		.handleAction([ createUserAsync.success, updateUserAsync.success ], (state, action) => {
			if (state.dataState === DataState.NOT_PRESENT) return state;

			return {
				...state,
				data: replaceOrPushObject(state.data, action.payload.data, (a, b) => a.id === b.id),
			};
		})
		.handleAction([ deleteUserAsync.success ], (state, action) => {
			if (state.dataState === DataState.NOT_PRESENT) return state;

			return {
				...state,
				data: deleteObject(state.data, a => a.id === action.payload.data.id),
			};
		}),
	singleUsers: createReducer([] as ArrayStateReducer<DetailedUser>)
		.handleAction([ forgetSession ], () => [])
		.handleAction([ fetchUserByIdAsync.request, fetchUserByIdAsync.success, fetchUserByIdAsync.failure ], handleBasicActionsForArray(fetchUserByIdAsync))
		.handleAction([
				createUserAsync.success,
				updateUserAsync.success,
				updateUserPasswordAsync.success,
				updateUserStatusAsync.success,
				disableTwoFactorAuthenticationAsync.success,
				confirmTwoFactorAuthenticationAsync.success,
			], (state, action) =>
				replaceStateReducer(
					state,
					action.payload.data.id,
					stateItem => ({
						id: stateItem.id,
						reducer: {
							dataState: DataState.PRESENT,
							loadingState: LoadingState.NOT_LOADING,
							errorState: ErrorState.NOT_PRESENT,
							fetchedAt: moment(),
							data: {
								...(stateItem.reducer.dataState === DataState.PRESENT ? stateItem.reducer.data : {}),
								...action.payload.data,
							},
						},
					}),
				),
		)
		.handleAction([ deleteUserAsync.success ], (state, action) => deleteStateReducer(state, action.payload.data.id)),
	singleUsersTwoFactorAuthentication: createReducer([] as ArrayStateReducer<UserTwoFactorAuthentication>)
		.handleAction([ forgetSession ], () => [])
		.handleAction(
			[ enableTwoFactorAuthenticationAsync.request, enableTwoFactorAuthenticationAsync.success, enableTwoFactorAuthenticationAsync.failure ],
			handleBasicActionsForArray(enableTwoFactorAuthenticationAsync),
		),
});

export default reducer;
