/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import PurchasingProcessFailedContainer from "src/app/containers/PurchasingProcess/PurchasingProcessFailed.container";

function PurchasingProcessFailedView() {

	return (
		<PurchasingProcessFailedContainer/>
	);
}

export default PurchasingProcessFailedView;
