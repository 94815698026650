import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { ConfirmTwoFactorAuthenticationPayload, CreateUserPayload, DetailedUser, EnableTwoFactorAuthenticationPayload, FetchUsersRequestPayload, ForgotPasswordPayload, LoggedUser, LoginPayload, ResetPasswordPayload, UpdateUserPasswordPayload, UpdateUserPayload, UpdateUserStatusPayload, User, UserOrganizationScope } from "src/app/types/api/user.types";
import { createAction } from "typesafe-actions";
import { FetchPaginatedDataBasicRequest } from "src/app/types/redux.types";

// Auth
export const loginAsync = createNetworkAction<LoginPayload, SuccessPayload<LoggedUser>>("USER__LOGIN");
export const authMeAsync = createNetworkAction<undefined, SuccessPayload<LoggedUser>>("USER__AUTH_ME");
export const scopeAuthMeAsync = createNetworkAction<undefined, SuccessPayload<LoggedUser>>("USER__SCOPE_AUTH_ME");
export const forgotPasswordAsync = createNetworkAction<ForgotPasswordPayload, SuccessPayload<null>>("USER__FORGOT_PASSWORD");
export const resetPasswordAsync = createNetworkAction<ResetPasswordPayload, SuccessPayload<null>>("USER__RESET_PASSWORD");
export const logoutAsync = createNetworkAction<undefined, SuccessPayload<{ message: string }>>("USER__LOGOUT");

export const forgetSession = createAction("USER__FORGET_SESSION")<undefined>();

// Scopes
export const fetchUserScopesAsync = createNetworkAction<undefined, SuccessPayload<UserOrganizationScope[]>>("USER__FETCH_SCOPES");

// User CRUD
export const fetchPaginatedUsersAsync = createNetworkAction<FetchUsersRequestPayload, SuccessPayloadWithId<User[], FetchPaginatedDataBasicRequest>, FailurePayloadWithId>("USERS__FETCH_PAGINATED_USERS");
export const fetchUsersAsync = createNetworkAction<undefined, SuccessPayload<User[]>>("USER__FETCH_USERS");
export const fetchUserByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedUser>, FailurePayloadWithId>("USER__FETCH_USER_BY_ID");

export const createUserAsync = createNetworkAction<CreateUserPayload, SuccessPayload<DetailedUser>>("USER__CREATE_USER");

export const updateUserAsync = createNetworkAction<UpdateUserPayload, SuccessPayload<DetailedUser>>("USER__UPDATE_USER");
export const updateUserStatusAsync = createNetworkAction<UpdateUserStatusPayload, SuccessPayload<DetailedUser>>("USER__UPDATE_USER_STATUS");
export const updateUserPasswordAsync = createNetworkAction<UpdateUserPasswordPayload, SuccessPayload<DetailedUser>>("USER__UPDATE_USER_PASSWORD");

export const deleteUserAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("USER__DELETE_USER");

// User 2FA
export const enableTwoFactorAuthenticationAsync = createNetworkAction<number, SuccessPayloadWithId<EnableTwoFactorAuthenticationPayload>, FailurePayloadWithId>("USER__ENABLE_TWO_FACTOR_AUTHENTICATION");
export const disableTwoFactorAuthenticationAsync = createNetworkAction<number, SuccessPayload<DetailedUser>>("USER__DISABLE_TWO_FACTOR_AUTHENTICATION");
export const confirmTwoFactorAuthenticationAsync = createNetworkAction<ConfirmTwoFactorAuthenticationPayload, SuccessPayload<DetailedUser>>("USER__CONFIRM_TWO_FACTOR_AUTHENTICATION");
