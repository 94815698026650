import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { forgetSession } from "src/app/store/features/user/user.actions";
import { isAuthorized } from "src/app/store/features/user/user.selectors";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

enum RouteAction {
	RESET_PASSWORD = "reset-password",
}

function CheckQueryParamsView(props: Props) {

	const {
		isAuthorized,
		forgetSession,
	} = props;
	const { action = "" } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		switch (action as RouteAction) {
			// Doesn't check url structure - related view should do it
			case RouteAction.RESET_PASSWORD:
				const resetPasswordLink = `/panel/reset-password${ location.search }`;
				if (isAuthorized) forgetSession();
				navigate(resetPasswordLink);
				break;
			default:
				navigate("/panel");
				break;
		}
	}, []);

	return null;
}

const mapStateToProps = (state: RootState) => ({
	isAuthorized: isAuthorized(state),
});

const mapDispatchToProps = {
	forgetSession: forgetSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckQueryParamsView);
