/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DetailedProduct, UpdateProductPayload } from "src/app/types/api/product.types";
import { useState } from "react";
import { Button, Card } from "flowbite-react";
import { canUpdateProduct } from "src/app/utils/abilities";
import { HiPencilAlt } from "react-icons/hi";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import EditProductBasicInformationModal from "src/app/components/Product/EditProductBasicInformationModal.component";
import { isEmptyString } from "src/app/utils/typeguards";
import ModelCell from "src/app/components/Utils/ModelCell.component";

type Props = {
	product: DetailedProduct
	onUpdate: (payload: UpdateProductPayload) => void
};

function ProductInformation(props: Props) {

	const {
		product,
		onUpdate,
	} = props;

	const [ isEditModalVisible, toggleEditModal ] = useState(false);

	return (
		<>
			<div className="grid grid-cols-2 gap-6">
				<div>
					<Card>
						<div className="flex flex-col gap-2.5">
							<div className="flex items-center justify-between pb-2.5 mb-2.5 border-b border-gray-200 dark:border-dark-borderColor">
								<h2 className="text-xl font-bold">Podstawowe informacje</h2>
								<div className="flex gap-1">
									{
										canUpdateProduct(product) &&
                                        <Button
                                            color="transparent"
                                            onClick={ () => toggleEditModal(true) }
                                        >
                                            <HiPencilAlt className="h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
                                        </Button>
									}
								</div>
							</div>
							<LabelValue
								label="Nazwa"
								value={ product.name }
							/>
							<LabelValue
								label="Opis"
								value={ isEmptyString(product.description ?? "") ? "-" : (product.description ?? "") }
							/>
							<LabelValue
								label="Cena"
								value={ `${ product.price } zł` }
							/>
							<LabelValue
								label="Stawka VAT"
								value={ product.vatRate }
							/>
							<LabelValue
								label="Kategorie"
								value={
									product.categories.length > 0 ?
									<div className="flex items-center gap-2 flex-wrap">
										{
											product.categories.map(category =>
												<ModelCell
													key={ category.id }
													model={ category }
													link={ `/categories?categoryId=${ category.id }` }
												/>
											)
										}
									</div> :
									<div>
										Brak
									</div>
								}
							/>
						</div>
					</Card>
				</div>
			</div>
			<EditProductBasicInformationModal
				isOpen={ isEditModalVisible }
				handleClose={ () => toggleEditModal(false) }
				product={ product }
				onUpdate={ onUpdate }
			/>
		</>
	);
}

export default (ProductInformation);
