import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { StateReducer } from "src/app/types/redux.types";
import { DetailedProduct } from "src/app/types/api/product.types";
import { initialStateReducer, mergeTwoStateReducers } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";

const singleProductsSelector = (state: RootState) => state.product.singleProduct;
const productsSelector = (state: RootState) => state.product.products;
const categoriesSelector = (state: RootState) => state.category.categories;

export const getProductById = createSelector(
	[
		singleProductsSelector,
		(_, productId: number) => productId,
	],
	(singleProducts, productId): StateReducer<DetailedProduct> => {
		const product = singleProducts.find(product => product.id === productId);
		if (isNotNull(product)) {
			return product.reducer;
		} else {
			return initialStateReducer as StateReducer<DetailedProduct>;
		}
	},
);

export const getProductsWithCategories = createSelector(
	[
		productsSelector,
		categoriesSelector,
	],
	(products, categories) => mergeTwoStateReducers(
		products,
		categories,
		(products, categories) => ({
			products,
			categories
		})
	)
)