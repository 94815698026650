/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import classNames from "classnames";
import { SimpleRoom } from "src/app/types/api/room.types";
import PlaceholderImage from "src/app/components/PurchasingProcess/util/PlaceholderImage.component";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import Avatar from "src/app/components/Utils/Avatar.component";
import { useEffect, useRef, useState } from "react";
import { Nullable } from "src/app/types/util.types";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";

type ComponentProps = {
	room: SimpleRoom
	className?: string
	onClick?: () => void
	active?: boolean
}

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

function PurchasingProcessRoomPreview(props: Props) {

	const {
		room: {
			image,
			name,
			maxPeople,
		},
		onClick,
		className = "",
		active = false,
		bodyWidth,
	} = props;

	const nameWrapperHeight = 54;
	const maxPeopleMarginRight = 16;

	const maxPeopleRef = useRef<HTMLDivElement>(null);
	const nameWrapperRef = useRef<HTMLDivElement>(null);

	const [ maxTitleWidth, setMaxTitleWidth ] = useState<Nullable<number>>(null);

	useEffect(() => {
		if (isNull(maxPeopleRef.current) || isNull(nameWrapperRef.current)) return;
		const maxPeopleWidth = parseInt(window.getComputedStyle(maxPeopleRef.current).width);
		const maxWrapperWidth = parseInt(window.getComputedStyle(nameWrapperRef.current).width);
		setMaxTitleWidth(maxWrapperWidth - maxPeopleWidth - (maxPeopleMarginRight * 3)); // *3 because margin-left of title, margin-right of max-people and last one to make gap between name and max-people when truncate
	}, [ bodyWidth ]);


	return (
		<div
			onClick={ onClick }
			className={ classNames(
				"h-40 w-96 min-w-[384px] relative flex items-end bg-cover rounded-2xl overflow-hidden",
				"border-4",
				{ "border-transparent": !active },
				{ "border-myPrimary-orange-500": active },
				"md:border-0",
				className) }
		>
			{
				isNotNull(image)
				?
					<Avatar
						img={ image?.full }
						className="absolute h-full w-full top-0 left-0 [&>div]:h-full [&>div]:w-full [&>div>img]:w-full [&>div>img]:h-full [&>div>img]:object-cover"
					/>
					:
					<PlaceholderImage
						className="absolute h-[116px] w-full top-0 left-0"
						style={ { paddingBottom: nameWrapperHeight } }
					/>
			}
			<div
				className="text-myPrimary-gray-500 bg-myPrimary-purple-500 bg-opacity-80 z-10 w-full flex items-center"
				style={ { height: nameWrapperHeight } }
				ref={ nameWrapperRef }
			>
				<div className="flex flex-col w-full ml-4">
					<div className="text-[10px]">
						Pokój
					</div>
					<div
						className={ classNames(
							"font-bold truncate max-w-full",
							"text-base",
							"sm:text-xl",
						) }
						style={ { maxWidth: maxTitleWidth ?? undefined } }
					>
						{ name }
					</div>
				</div>
				<div
					className="flex justify-center items-center gap-1 text-myPrimary-purple-500 z-10 bg-myPrimary-gray-500 rounded px-2"
					style={ { marginRight: maxPeopleMarginRight } }
					ref={ maxPeopleRef }
				>
					<span className="text-[10px] font-semibold">
						max
					</span>
					<span className="font-[900]">
						{ maxPeople }
					</span>
					<span className="text-[10px] font-semibold">
						os.
					</span>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodyWidth: state.ui.layout.bodySize.width,
});

export default connect(mapStateToProps)(PurchasingProcessRoomPreview);

