/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { MdOutlineImageNotSupported } from "react-icons/md";
import classNames from "classnames";

type Props = {
	className?: string
}

function SingleSkeletonRoom({ className }: Props) {
	return (
		<div className={ classNames(
			"h-40 w-96 min-w-[384px] flex items-end relative rounded-2xl animate-pulse border border-gray-200 overflow-hidden",
			className,
		) }>
			{/* no image icon */}
			<MdOutlineImageNotSupported className="text-gray-200 h-8 w-8 absolute top-10 left-1/2 -translate-x-1/2"/>

			{/* bottom content wrapper */}
			<div className="py-6 z-10 w-full bg-gray-300">
				{/* Room capacity circle */}
				<div className="absolute right-[25px] h-12 w-12 z-10 bg-gray-200 rounded-full bottom-[15%]"></div>
			</div>
		</div>
	);
}

export default SingleSkeletonRoom;