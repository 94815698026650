import { CreateRoomPayload, Room } from "src/app/types/api/room.types";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { ModalConfig, Nullable, SortCompare } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { canDeleteRoom, canViewRoom } from "src/app/utils/abilities";
import Avatar from "src/app/components/Utils/Avatar.component";
import { Button } from "flowbite-react";
import React, { useState } from "react";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { Venue } from "src/app/types/api/venue.types";
import CreateRoomModal from "src/app/components/Room/CreateRoomModal.component";
import { DateTime } from "luxon";
import { LocaleFromISO } from "src/app/utils/luxon";
import { customDataTableSearch, getAllVenuesFromModel } from "src/app/utils/helpers";
import DeleteCell from "src/app/components/Utils/DeleteCell.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		rooms: Room[]
		venues: Venue[]
		onCreate: (payload: CreateRoomPayload) => void
		onDelete: (roomId: number) => void
	};

function RoomListContainer(props: Props) {

	const {
		rooms,
		venues,
		onCreate,
		onDelete,
		isDeleting,
	} = props;

	const { navigate } = useUserScopeNavigate();

	const [ isCreateModalOpen, toggleCreateModal ] = useState(false);
	const [ deleteRoomModal, setDeleteRoomModal ] = useState<ModalConfig<Room>>({
		isOpen: false,
		value: null,
	});

	const roomColumns: MUIDataTableColumn[] = [
		{
			name: "Nazwa",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (room: Room) =>
					<div className="flex items-center gap-2">
						<Avatar
							alt={ `${ room.name }-avatar` }
							img={ room.image?.thumb }
							placeholderInitials={ room.name[ 0 ] }
							size="sm"
						/>
						<span>
							{ room.name }
						</span>
					</div>,
				sortCompare: order => (a: SortCompare<Room>, b: SortCompare<Room>) => {
					if (order === "asc") {
						return (a.data.name).localeCompare(b.data.name);
					} else {
						return (b.data.name).localeCompare(a.data.name);
					}
				},
			},
		}, {
			name: "Lokal",
			options: {
				filter: true,
				filterOptions: {
					names: getAllVenuesFromModel(rooms),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const room = row[ 0 ];
						return isNotNull(row) && !filters.map(filterElement => filterElement.toLowerCase()).includes(room.venue.name.toLowerCase());
					},
				},
				sort: true,
				customBodyRender: (room: Room) => room.venue?.name ?? "Brak",
				sortCompare: order => (a: SortCompare<Room>, b: SortCompare<Room>) => {
					if (order === "asc") {
						return (a.data.venue?.name ?? "").localeCompare(b.data.venue?.name ?? "");
					} else {
						return (b.data.venue?.name ?? "").localeCompare(a.data.venue?.name ?? "");
					}
				},
			},
		}, {
			name: "Ilość osób",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (room: Room) => room.maxPeople,
				sortCompare: order => (a: SortCompare<Room>, b: SortCompare<Room>) => {
					if (order === "asc") {
						return a.data.maxPeople - b.data.maxPeople;
					} else {
						return b.data.maxPeople - a.data.maxPeople;
					}
				},
			},
		}, {
			name: "Utworzono",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (room: Room) => LocaleFromISO(room.createdAt).toLocaleString(DateTime.DATE_SHORT),
				sortCompare: order => (a: SortCompare<Room>, b: SortCompare<Room>) => {
					if (order === "asc") {
						return LocaleFromISO(a.data.createdAt).valueOf() - LocaleFromISO(b.data.createdAt).valueOf();
					} else {
						return LocaleFromISO(b.data.createdAt).valueOf() - LocaleFromISO(a.data.createdAt).valueOf();
					}
				},
			},
		}, {
			name: "Akcje",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (room: Room) =>
					<DeleteCell
						ability={ canDeleteRoom(room) }
						onDelete={ e => {
							e.stopPropagation();
							setDeleteRoomModal({
								isOpen: true,
								value: room,
							});
						} }
					/>,
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
			const clickedRoom: Nullable<Room> = props.rooms[ rowMeta.dataIndex ];
			if (isNotNull(clickedRoom) && canViewRoom(clickedRoom)) {
				navigate(`/rooms/${ clickedRoom.id }`);
			}
		},
		customToolbar: () =>
			<Button
				className="order-first mr-3"
				size="sm"
				color="primary"
				onClick={ () => toggleCreateModal(true) }
			>
				Dodaj pokój
			</Button>,
		customSearch: (searchQuery: string, currentRow: Room[]) => customDataTableSearch(searchQuery, [
			currentRow[ 0 ]?.name,
			currentRow[ 0 ]?.venue?.name ?? "",
			currentRow[ 0 ]?.maxPeople.toString(),
			LocaleFromISO(currentRow[ 0 ]?.createdAt).toLocaleString(DateTime.DATE_SHORT),
		])
	};

	const _handleConfirmDeleteRoom = () => {
		if (isNotNull(deleteRoomModal.value)) {
			onDelete(deleteRoomModal.value.id);
			setDeleteRoomModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};

	const isDeletingRoom = isNotNull(deleteRoomModal.value) && isDeleting(deleteRoomModal.value.id);

	return (
		<>
			<Table
				title="Pokoje"
				columns={ roomColumns }
				options={ tableOptions }
				data={ rooms }
			/>
			<ConfirmModal
				title={ `Usuń ${ isNotNull(deleteRoomModal.value) ? deleteRoomModal.value.name : "pokój" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ deleteRoomModal.isOpen }
				confirmWord="kasuj"
				isLoading={ isDeletingRoom }
				handleClose={ () => setDeleteRoomModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmDeleteRoom }
			/>
			<CreateRoomModal
				isOpen={ isCreateModalOpen }
				handleClose={ () => toggleCreateModal(false) }
				vanues={ venues }
				onCreate={ onCreate }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isDeleting: (roomId: number) => didLoadingRecordExist(state, { loadableType: LoadableType.DELETE_ROOM, loadableId: roomId }),
});

export default connect(mapStateToProps)(RoomListContainer);
