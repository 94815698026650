/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { MouseEvent } from "react";
import { Badge } from "flowbite-react";
import { flowbiteColorsToHexDictionary, reservationStatusColorDictionary, reservationStatusDictionary } from "src/app/utils/constants/dictionaries";
import classNames from "classnames";
import { ReservationStatus } from "src/app/types/api/reservation.types";
import { HiChevronDown } from "react-icons/hi";
import { PulseLoader } from "react-spinners";

type Props = {
	status: ReservationStatus
	isProcessing?: boolean
	onClick?: (e?: MouseEvent<HTMLElement>) => void
	className?: string
} & ({
	mode: "normal"
} | {
	mode: "dropdown"
	isOpen: boolean
})

function ReservationStatusBadge(props: Props) {

	const {
		status,
		isProcessing = false,
		mode,
		onClick,
		className,
	} = props;

	const _onClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		if (onClick) {
			onClick(e);
		}
	}

	return (
		<div className="relative max-w-max">
			{
				isProcessing &&
                <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                    <PulseLoader size={ 7 } color={ flowbiteColorsToHexDictionary[ reservationStatusColorDictionary[ status ] ] }/>
                </div>
			}
			<Badge
				onClick={ _onClick }
				className={ classNames(
					"max-w-max",
					{ "blur-[1px]": isProcessing },
					className,
				) }
				color={ reservationStatusColorDictionary[ status ] }
			>
				<div className="flex items-center gap-2">
					{ reservationStatusDictionary[ status ] }
					{
						mode === "dropdown" &&
                        <HiChevronDown className={ classNames("h-4 w-4", { "rotate-180": props.isOpen }) }/>
					}
				</div>
			</Badge>
		</div>
	);
}

export default ReservationStatusBadge;