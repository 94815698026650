/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedCategory } from "src/app/types/api/category.types";
import { createCategoryAsync, deleteCategoryByIdAsync, fetchAdminCategoriesAsync, fetchCategoriesAsync, fetchCategoryByIdAsync, updateCategoryAsync } from "src/app/store/features/category/category.actions";

export const fetchCategoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchCategoriesAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/categories",
				method: "GET",
				withScope: true,
				onSuccess: fetchCategoriesAsync.success,
				onFailure: fetchCategoriesAsync.failure,
			}),
		),
	);

export const fetchAdminCategoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchAdminCategoriesAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/admin/categories",
				method: "GET",
				withScope: true,
				onSuccess: fetchAdminCategoriesAsync.success,
				onFailure: fetchAdminCategoriesAsync.failure,
			}),
		),
	);

export const fetchCategoryByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchCategoryByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/categories/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedCategory>) => fetchCategoryByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchCategoryByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const createCategoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createCategoryAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/admin/categories",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createCategoryAsync.success,
				onFailure: createCategoryAsync.failure,
			}),
		),
	);

export const updateCategoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateCategoryAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/categories/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateCategoryAsync.success,
				onFailure: updateCategoryAsync.failure,
			}),
		),
	);

export const deleteCategoryByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteCategoryByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/categories/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteCategoryByIdAsync.success,
				onFailure: deleteCategoryByIdAsync.failure,
			}),
		),
	);
