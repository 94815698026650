import { CreateProductPayload, Product } from "src/app/types/api/product.types";
import { ModalConfig } from "src/app/types/util.types";
import { isNotNull } from "src/app/utils/typeguards";
import { canDeleteProduct } from "src/app/utils/abilities";
import { Button } from "flowbite-react";
import { useState } from "react";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import CreateProductModal from "src/app/components/Product/CreateProductModal.component";
import ProductListComponent from "src/app/components/Product/ProductList.component";
import DeleteCell from "src/app/components/Utils/DeleteCell.component";

type ComponentProps =  {
	products: Product[]
	onCreate: (payload: CreateProductPayload) => void
	onDelete: (productId: number) => void
};


type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps

function ProductListContainer(props: Props) {
	const {
		products,
		onCreate,
		onDelete,
		isDeleting,
	} = props;

	const [ isCreateModalOpen, toggleCreateModal ] = useState(false);
	const [ deleteProductModal, setDeleteProductModal ] = useState<ModalConfig<Product>>({
		isOpen: false,
		value: null,
	});

	const _handleConfirmDeleteProduct = () => {
		if (isNotNull(deleteProductModal.value)) {
			onDelete(deleteProductModal.value.id);
			setDeleteProductModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};

	const isDeletingProduct = isNotNull(deleteProductModal.value) && isDeleting(deleteProductModal.value.id);

	return (
		<>
			<ProductListComponent
				products={ products }
				actionsCell={ product =>
					<DeleteCell
						onDelete={ () => setDeleteProductModal({ isOpen: true, value: product }) }
						ability={ canDeleteProduct(product) }
					/>
				}
				customToolbar={
					<Button
						className="order-first mr-3"
						size="sm"
						color="primary"
						onClick={ () => toggleCreateModal(true) }
					>
						Dodaj produkt
					</Button>
			}
			/>
			<ConfirmModal
				title={ `Usuń ${ isNotNull(deleteProductModal.value) ? deleteProductModal.value.name : "produkt" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ deleteProductModal.isOpen }
				confirmWord="kasuj"
				isLoading={ isDeletingProduct }
				handleClose={ () => setDeleteProductModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmDeleteProduct }
			/>
			<CreateProductModal
				isOpen={ isCreateModalOpen }
				handleClose={ () => toggleCreateModal(false) }
				onCreate={ onCreate }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isDeleting: (productId: number) => didLoadingRecordExist(state, { loadableType: LoadableType.DELETE_PRODUCT, loadableId: productId }),
});

export default connect(mapStateToProps)(ProductListContainer);
