export type LoadingRecord = {
	loadableId?: string | number,
	loadableType: LoadableType
}

export enum LoadableType {
	LOGIN = "LOGIN",
	AUTH_ME = "AUTH_ME",
	FORGOT_PASSWORD = "FORGOT_PASSWORD",
	RESET_PASSWORD = "RESET_PASSWORD",

	FETCH_USER_SCOPES = "FETCH_USER_SCOPES",
	APP_DASHBOARD_LOADING = "APP_DASHBOARD_LOADING",

	CREATE_USER = "CREATE_USER",
	UPDATE_USER = "UPDATE_USER",
	UPDATE_USER_STATUS = "UPDATE_USER_STATUS",
	UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD",
	DELETE_USER = "DELETE_USER",

	DISPLAY_USER_TWO_FA_MODAL = "DISPLAY_USER_TWO_FA_MODAL",
	CONFIRM_USER_TWO_FA = "CONFIRM_USER_TWO_FA",
	DISABLE_USER_TWO_FA = "DISABLE_USER_TWO_FA",

	/* Companies */
	CREATE_COMPANY = "CREATE_COMPANY",
	UPDATE_COMPANY = "UPDATE_COMPANY",
	DELETE_COMPANY = "DELETE_COMPANY",

	/* Availabilities */
	INITIAL_LOAD_FETCH_AVAILABILITIES = "INITIAL_LOAD_FETCH_AVAILABILITIES",
	FETCH_AVAILABILITIES = "FETCH_AVAILABILITIES",

	/* Venues */
	CREATE_VENUE = "CREATE_VENUE",
	UPDATE_VENUE = "UPDATE_VENUE",
	DELETE_VENUE = "DELETE_VENUE",

	/* Organizations */
	CREATE_ORGANIZATION = "CREATE_ORGANIZATION",
	UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
	DELETE_ORGANIZATION = "DELETE_ORGANIZATION",

	/* Rooms */
	CREATE_ROOM = "CREATE_ROOM",
	UPDATE_ROOM = "UPDATE_ROOM",
	COPY_TIME_RANGE_FROM_OTHER_ROOM = "COPY_TIME_RANGE_FROM_OTHER_ROOM",
	COPY_TIME_RANGE_FROM_OTHER_WEEKDAY = "COPY_TIME_RANGE_FROM_OTHER_WEEKDAY",
	ADD_TIME_WINDOW_TO_WEEKDAY = "ADD_TIME_WINDOW_TO_WEEKDAY",
	EDIT_TIME_WINDOW = "EDIT_TIME_WINDOW",
	DELETE_TIME_WINDOW = "DELETE_TIME_WINDOW",
	DELETE_ROOM = "DELETE_ROOM",
	FETCH_ADMIN_ROOM_AVAILABILITIES = "FETCH_ADMIN_ROOM_AVAILABILITIES",

	/* Products */
	CREATE_PRODUCT = "CREATE_PRODUCT",
	UPDATE_PRODUCT = "UPDATE_PRODUCT",
	DELETE_PRODUCT = "DELETE_PRODUCT",

	/* Discount codes */
	CREATE_DISCOUNT_CODE = "CREATE_DISCOUNT_CODE",
	UPDATE_DISCOUNT_CODE = "UPDATE_DISCOUNT_CODE",
	DELETE_DISCOUNT_CODE = "DELETE_DISCOUNT_CODE",

	/* Reservations */
	CREATE_RESERVATION = "CREATE_RESERVATION",
	UPDATE_RESERVATION = "UPDATE_RESERVATION",
	DELETE_RESERVATION = "DELETE_RESERVATION",
	CALCULATE_ROOM_RESERVATION_PRICE = "CALCULATE_ROOM_RESERVATION_PRICE",
	UPDATE_RESERVATION_STATUS = "UPDATE_RESERVATION_STATUS",
	SENDING_INVOICE = "SENDING_INVOICE",
	GENERATE_INVOICE = "GENERATE_INVOICE",

	/* Product */
	FETCH_PRODUCTS = "FETCH_PRODUCTS",

	/* Location */
	FETCH_VENUES = "FETCH_VENUES",
	FETCH_ADMIN_VENUE_AVAILABILITIES = "FETCH_ADMIN_VENUE_AVAILABILITIES",

	/* Reservation */
	RESERVATION_INIT = "RESERVATION_INIT",
	CALCULATE_RESERVATION_PRICE = "CALCULATE_RESERVATION_PRICE",
	RESERVATION_DISCOUNT_CODE = "RESERVATION_DISCOUNT_CODE",
	FETCH_RESERVATION_BY_ID = "FETCH_RESERVATION_BY_ID",

	/* Categories */
	FETCH_CATEGORIES = "FETCH_CATEGORIES",
	CREATE_CATEGORY = "CREATE_CATEGORY",
	UPDATE_CATEGORY = "UPDATE_CATEGORY",
	DELETE_CATEGORY = "DELETE_CATEGORY",
	// Loadable types - don't remove
}
