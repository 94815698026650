import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchAdminProductsAsync } from "src/app/store/features/product/product.actions";
import ProductListContainer from "src/app/containers/Product/ProductList.container";
import { uiCreateProduct, uiDeleteProduct } from "src/app/store/features/ui/product/ui.product.actions";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps;

function ProductListView(props: Props) {

	const {
		adminProducts,
		fetchProducts,
		createProduct,
		deleteProduct,
	} = props;

	return (
		<CachedThenFreshStrategy
			request={ fetchProducts }
			state={ adminProducts }
		>
			{
				products =>
					<ProductListContainer
						products={ products }
						onCreate={ createProduct }
						onDelete={ deleteProduct }
					/>
			}
		</CachedThenFreshStrategy>
	);
}

const mapStateToProps = (state: RootState) => ({
	adminProducts: state.product.adminProducts,
});

const mapDispatchToProps = {
	fetchProducts: fetchAdminProductsAsync.request,
	createProduct: uiCreateProduct,
	deleteProduct: uiDeleteProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListView);
