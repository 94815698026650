/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CreateVenuePayload } from "src/app/types/api/venue.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import { isEmptyString } from "src/app/utils/typeguards";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		handleClose: () => void
		onCreate: (payload: CreateVenuePayload) => void
	};

type CreateVenueForm = {
	name: string
	country: string
	city: string
	street: string
	houseNumber: string
	flatNumber: string
	postCode: string
}

const validator: FormValidator<CreateVenueForm> = {
	name: (name, optional) => validateField("Nazwa", name, optional),
	country: (country, optional) => validateField("Kraj", country, optional),
	city: (city, optional) => validateField("Miasto", city, optional),
	street: (street, optional) => validateField("Ulica", street, optional),
	houseNumber: (houseNumber, optional) => validateField("Nr domu", houseNumber, optional),
	flatNumber: (flatNumber, optional) => validateField("Nr mieszkania", flatNumber, optional),
	postCode: (postCode, optional) => validateField("Kod pocztowy", postCode, optional),
};

function CreateVenueModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onCreate,
		isCreating,
	} = props;

	const _handleSubmit = (values: CreateVenueForm) => {
		onCreate({
			...values,
			country: isEmptyString(values.country) ? undefined : values.country,
			flatNumber: isEmptyString(values.flatNumber) ? undefined : values.flatNumber,
		});
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		name: createFormField(""),
		country: createFormField("", { optional: true }),
		city: createFormField(""),
		street: createFormField(""),
		houseNumber: createFormField(""),
		flatNumber: createFormField("", { optional: true }),
		postCode: createFormField(""),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isCreating) {
			resetForm();
		}
	}, [ isOpen, isCreating ]);

	return (
		<Modal
			show={ isOpen || isCreating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Dodaj nowy lokal
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div>
						<Input
							className="mb-4"
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.country }
							label="Kraj"
							name="country"
							inputProps={ {
								type: "text",
								placeholder: "Polska",
								onChange: (e) => handleChange("country", e.target.value),
								onBlur: () => handleBlur("country"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.city }
							label="Miasto"
							name="city"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("city", e.target.value),
								onBlur: () => handleBlur("city"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.street }
							label="Ulica"
							name="street"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("street", e.target.value),
								onBlur: () => handleBlur("street"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.houseNumber }
							label="Nr domu"
							name="houseNumber"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("houseNumber", e.target.value),
								onBlur: () => handleBlur("houseNumber"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.flatNumber }
							label="Nr mieszkania"
							name="flatNumber"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("flatNumber", e.target.value),
								onBlur: () => handleBlur("flatNumber"),
							} }
						/>
						<Input
							className="mb-4"
							formItem={ form.postCode }
							label="Kod pocztowy"
							name="postCode"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("postCode", e.target.value),
								onBlur: () => handleBlur("postCode"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isCreating }
					>
						Stwórz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_VENUE }),
});

export default connect(mapStateToProps)(CreateVenueModal);
