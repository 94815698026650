import { createSelector } from "reselect";
import { RootState } from "src/app/store/root.reducer";
import { StateReducer } from "src/app/types/redux.types";
import { DetailedRoom, Room } from "src/app/types/api/room.types";
import { initialStateReducer, mergeStateReducers, mergeTwoStateReducers } from "src/app/utils/redux";
import { isNotNull } from "src/app/utils/typeguards";
import { Venue } from "src/app/types/api/venue.types";
import { Product } from "src/app/types/api/product.types";
import { DiscountCode } from "src/app/types/api/discountCode.types";

const adminRoomsSelector = (state: RootState) => state.room.adminRooms;
const singleRoomsSelector = (state: RootState) => state.room.singleRoom;
const adminVenuesSelector = (state: RootState) => state.venue.adminVenues;
const productsSelector = (state: RootState) => state.product.products;
const discountCodesSelector = (state: RootState) => state.discountCode.discountCodes;

export const getRoomById = createSelector(
	[
		singleRoomsSelector,
		(_, roomId: number) => roomId,
	],
	(singleRooms, roomId): StateReducer<DetailedRoom> => {
		const room = singleRooms.find(room => room.id === roomId);
		if (isNotNull(room)) {
			return room.reducer;
		} else {
			return initialStateReducer as StateReducer<DetailedRoom>;
		}
	},
);

export const getAdminRoomWithVenuesWithRoomsWithProductsWithDiscountCodes = createSelector(
	[
		getRoomById,
		adminVenuesSelector,
		adminRoomsSelector,
		productsSelector,
		discountCodesSelector,
	],
	(room, venues, rooms, products, discountCodes) => mergeStateReducers<{
		room: DetailedRoom
		venues: Venue[]
		rooms: Room[]
		products: Product[]
		discountCodes: DiscountCode[]
	}>(
		[ room, venues, rooms, products, discountCodes ],
		(room, venues, rooms, products, discountCodes) => ({
			room,
			venues,
			rooms,
			products,
			discountCodes,
		})
	)
);

export const getRoomsWithVenues = createSelector(
	[
		adminRoomsSelector,
		adminVenuesSelector,
	],
	(rooms, venues) =>
		mergeTwoStateReducers(
			rooms,
			venues,
			(rooms, venues) => ({
				rooms,
				venues,
			}),
		),
);
