import { RouteObject } from "react-router/dist/lib/context";
import RoomListView from "src/app/views/Room/RoomList.view";
import RoomProfileView from "src/app/views/Room/RoomProfile.view";
import RoomCreateView from "src/app/views/Room/RoomCreate.view";

const roomRoutes: RouteObject = {
	path: "rooms",
	children: [
		{
			index: true,
			element: <RoomListView/>,
		}, {
			path: "create",
			element: <RoomCreateView/>,
		}, {
			path: ":roomId",
			element: <RoomProfileView/>,
		},
	],
};

export default roomRoutes;
