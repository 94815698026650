import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import camelcaseKeys from "camelcase-keys";
import { baseUrl, isDevelopmentEnvironment } from "src/app/utils/constants/constants";

export const abortController = new AbortController();

export const request = (config: AxiosRequestConfig) =>
	axios.request({
		...config,
		data: config.data,
		withCredentials: true,
		url: `${ baseUrl }${ config.url }`,
		// url: `http://localhost:3003${ config.url }`,
		// signal: abortController.signal,
	}).then((response: AxiosResponse<SuccessPayload<any>>) => {
		const camelizeResponse = camelcaseKeys(response, { deep: true });
		isDevelopmentEnvironment && console.log("SUCCESS", camelizeResponse);
		return Promise.resolve(camelizeResponse);
	}).catch((error: AxiosError<FailurePayload>) => {
		const camelizeError: AxiosError<FailurePayload> = camelcaseKeys(error);
		isDevelopmentEnvironment && console.log("ERROR", camelizeError.response);
		if (camelizeError.response) return Promise.resolve(camelizeError.response);
		return Promise.reject(camelizeError);
	});
