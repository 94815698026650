/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from "react";
import AvailablityButton from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Availability/AvailabilityButton.component";
import classNames from "classnames";
import moment from "moment";
import { FormHookReturnType } from "src/app/types/ui/form.types";
import { availabilityReservedOpenOrPast, getAvailabilityItToLocalize, getCurrentDateRoomAvailabilities, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { isNull } from "src/app/utils/typeguards";
import { SimpleRoom } from "src/app/types/api/room.types";
import { AvailabilityState, FormAvailability } from "src/app/types/api/reservation.types";

type Props = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	availability: FormAvailability
	room: SimpleRoom
	idPrefix: "mobile" | "desktop"
	className?: string
}

function AvailabilityComponent(props: Props) {

	const {
		availability: {
			localId,
			startTime,
			endTime,
			price,
			state,
		},
		form: {
			form: {
				dateRoomAvailabilities,
				date,
				venue,
			},
			handleChange,
		},
		room: {
			id: roomId,
			maxPeople,
		},
		idPrefix,
		className,
	} = props;

	const currentAvailabilities = getCurrentDateRoomAvailabilities(dateRoomAvailabilities.value, date.value)
		?.find(roomAvailability => roomAvailability.roomId === roomId)?.availabilities;

	//get localIds of every element which is in block one neighbour on each side
	const getBlockIds = (availabilities: FormAvailability[]): number[] => {
		const newBlockIds = [ ...availabilities ].reduce<number[]>((prev, current) => {
			if (current.state !== AvailabilityState.RESERVING) return prev;
			return [ ...prev, current.localId + 1 ];
		}, []);
		newBlockIds.unshift(newBlockIds[ 0 ] - 1)
		newBlockIds.push(newBlockIds[ newBlockIds.length - 1 ] + 1)
		return newBlockIds;
	}


	const getAvailabilities = (availabilities: FormAvailability[]): FormAvailability[] => {
		const newAvailabilities = [ ...availabilities ];
		const oldBlockIds = getBlockIds(availabilities)

		//if clicked availability is not in pre render block we set state of every availability which is not RESERVED to OPEN
		if (!oldBlockIds.includes(localId + 1)) {
			for (let i = 0; i < newAvailabilities.length; i++) {
				if (newAvailabilities[ i ].state !== AvailabilityState.RESERVED) {
					newAvailabilities[ i ].state = availabilityReservedOpenOrPast(newAvailabilities[ i ]);
				}
			}
		}

		//change only state of clicked availability
		for (let i = 0; i < availabilities.length; i++) {
			if (availabilities[ i ].localId !== localId) continue;
			if (availabilities[ i ].state === AvailabilityState.OPEN || availabilities[ i ].state === AvailabilityState.EXTENDABLE) {
				availabilities[ i ].state = AvailabilityState.RESERVING;
			} else if (availabilities[ i ].state === AvailabilityState.RESERVING) {
				newAvailabilities[ i ].state = availabilityReservedOpenOrPast(availabilities[ i ]);
			}
		}

		const blockIds = getBlockIds(availabilities)

		//setting state of last first and last element of block to EXPANDABLE
		for (let i = 0; i < newAvailabilities.length; i++) {
			newAvailabilities[ i ].localId += 1;
			if (!blockIds.includes(newAvailabilities[ i ].localId) && newAvailabilities[ i ].state !== AvailabilityState.RESERVED) { //outside block which aren't reserved (can be PAST)
				newAvailabilities[ i ].state = availabilityReservedOpenOrPast(newAvailabilities[ i ]);
			} else if (blockIds.includes(newAvailabilities[ i ].localId)) {
				if (newAvailabilities[ blockIds[ 0 ] - 1 ] !== undefined) {
					if (
						newAvailabilities[ blockIds[ 0 ] - 1 ].state !== AvailabilityState.RESERVED &&
						newAvailabilities[ blockIds[ 0 ] - 1 ].state !== AvailabilityState.PAST
					) {
						newAvailabilities[ blockIds[ 0 ] - 1 ].state = AvailabilityState.EXTENDABLE;
					}
				}
				if (newAvailabilities[ blockIds[ blockIds.length - 1 ] - 1 ] !== undefined) {
					if (
						newAvailabilities[ blockIds[ blockIds.length - 1 ] - 1 ].state !== AvailabilityState.RESERVED &&
						newAvailabilities[ blockIds[ blockIds.length - 1 ] - 1 ].state !== AvailabilityState.RESERVED
					) {
						newAvailabilities[ blockIds[ blockIds.length - 1 ] - 1 ].state = AvailabilityState.EXTENDABLE;
					}
				}
			}
		}

		//set every availability localId to initial, in above function we operated on abstract local id which was one number greater than normal
		return newAvailabilities.map(availability => ({ ...availability, localId: availability.localId - 1 }));
	}

	const onButtonClick = () => {
		if (isNull(getCurrentDateRoomAvailabilities(dateRoomAvailabilities.value, date.value))) return;
		handleChange("dateRoomAvailabilities", dateRoomAvailabilities.value.map(dateRoomAvailability => {
			//Change reserving availabilities from other date to RESERVED or OPEN
			if (dateRoomAvailability.date !== date.value) return {
				...dateRoomAvailability,
				roomAvailabilities: dateRoomAvailability.roomAvailabilities.map(roomAvailability => ({
					...roomAvailability,
					availabilities: roomAvailability.availabilities.map(availability => ({
						...availability,
						state: availabilityReservedOpenOrPast(availability),
					}))
				}))
			}

			//Change current date roomAvailabilities, only one block available so if there is a block i other room just delete it
			return {
				...dateRoomAvailability,
				roomAvailabilities: getCurrentDateRoomAvailabilities(dateRoomAvailabilities.value, date.value)?.map(roomAvailability => {
					if (roomAvailability.roomId !== roomId) return {
						...roomAvailability,
						availabilities: roomAvailability.availabilities.map(availability => {
							if (availability.state === AvailabilityState.RESERVED) return availability;
							return {
								...availability,
								state: availabilityReservedOpenOrPast(availability),
							}
						})
					};
					return {
						...roomAvailability,
						availabilities: getAvailabilities(roomAvailability.availabilities),
					}
				}) ?? []
			};
		}));
	}

	const shouldDisplayButton = (): boolean => {
		if (state === AvailabilityState.RESERVED) return false;
		else if (state === AvailabilityState.OPEN || state === AvailabilityState.EXTENDABLE) return true;
		else if (state === AvailabilityState.RESERVING) {
			if (isNull(currentAvailabilities) || getBlockIds(currentAvailabilities).length < 5) return true;
			const blockIds = getBlockIds(currentAvailabilities).slice(2).slice(0, -2);
			if (!blockIds.includes(localId + 1)) return true;
		}
		return false;
	}

	return (
		<div
			id={ getAvailabilityItToLocalize(idPrefix, venue.value?.id, roomId, localId) }
			className={ classNames(
				"h-40 w-40 min-w-[160px] flex flex-col items-center justify-center rounded-2xl border-[1px] transition-all duration-200",
				{ "border-myPrimary-orange-500": state === AvailabilityState.OPEN },
				{ "bg-myPrimary-gray-200": state === AvailabilityState.RESERVING },
				{ "bg-myPrimary-gray-100": state === AvailabilityState.RESERVED },
				{ "bg-myPrimary-gray-50": state === AvailabilityState.EXTENDABLE },
				className,
			) }
		>
			{
				state === AvailabilityState.RESERVED
					?
					<span className="text-base text-myPrimary-gray-500 font-bold">
						Rezerwacja
					</span>
					:
					<>
						<span className={ classNames(
							"text-xs text-mySecondary-purple-500",
							{ "text-gray-400": state === AvailabilityState.PAST },
						) }>
							{ `${ moment(startTime).format("HH:mm") } - ${ moment(endTime).format("HH:mm") }` }
						</span>
						<span className={ classNames(
							"text-xl font-extrabold transition-all duration-200",
							{ "text-myPrimary-orange-500": state === AvailabilityState.EXTENDABLE },
							{ "text-mySecondary-purple-500": state === AvailabilityState.RESERVING },
							{ "text-gray-400": state === AvailabilityState.PAST },
						) }>
							{ `${ price } zł` }
						</span>
						<span className={ classNames(
							"text-[10px] text-mySecondary-purple-500 mt-[-1px]",
							{ "opacity-0": state === AvailabilityState.EXTENDABLE },
						{ "text-gray-400": state === AvailabilityState.PAST },
						) }>
							{ `(${ Math.round(price / maxPeople) }zł / os.)` }
						</span>
						<AvailablityButton
							state={ state }
							className="mt-2"
							onButtonClick={ () => shouldDisplayButton() && onButtonClick() }
							disabled={ !shouldDisplayButton() }
						/>
					</>
			}
		</div>
	);
}

export default AvailabilityComponent;
