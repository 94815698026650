import { MUIDataTableColumn } from "mui-datatables";
import Table from "src/app/components/Utils/Table.component";
import { PaginationTableConfiguration } from "src/app/hoc/caching/PaginationStrategy.hoc";

type Props = {
	tests: any[]
	tableConfiguration: PaginationTableConfiguration
	isLoading: boolean
};

function PaginationContainer(props: Props) {

	const {
		tests,
		tableConfiguration,
		isLoading,
	} = props;

	const columns: MUIDataTableColumn[] = [
		/*{
			name: "Title of test".toUpperCase(),
			options: {
				filter: true,
				filterOptions: {
					names: tests.map(test => test.user.id.toString()).filter((userId, i, arr) => arr.indexOf(userId) === i),
					renderValue: (userId: string) => {
						const user = tests.map(test => test.user).find(user => user.id.toString() === userId);
						if (isNull(user)) return "Unknown user";
						return `${ user.name } ${ user.surname }`;
					},
				},
				customFilterListOptions: {
					render: (userId: string) => {
						const user = tests.map(test => test.user).find(user => user.id.toString() === userId);
						if (isNull(user)) return "Unknown user";
						return `${ user.name } ${ user.surname }`;
					},
				},
				sort: true,
				customBodyRender: (test: any) => test.name,
				sortCompare: order => (a, b) => {
					if (order === "asc") {
						return a.data.name.toLowerCase().localeCompare(b.data.name.toLowerCase());
					}
					return b.data.name.toLowerCase().localeCompare(a.data.name.toLowerCase());
				},
			},
		},*/
	];

	return (
		<Table
			title="Tytuł"
			columns={ columns }
			data={ tests }
			options={ tableConfiguration.tableOptions }
			filters={ tableConfiguration.filters }
			cellHeight={ 71 }
			isLoading={ isLoading }
		/>
	);
}

export default (PaginationContainer);
