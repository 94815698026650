import { lazy } from "react";
import { RouteObject } from "react-router/dist/lib/context";

const LoginView = lazy(() => import("src/app/views/Auth/Login.view"));
const ForgotPasswordView = lazy(() => import("src/app/views/Auth/ForgotPassword.view"));
const ForgotPasswordMessageView = lazy(() => import("src/app/views/Auth/ForgotPasswordMessage.view"));
const ResetPasswordView = lazy(() => import("src/app/views/Auth/ResetPassword.view"));
const LoginSecondStepView = lazy(() => import("src/app/views/Auth/LoginSecondStep.view"));

const authRoutes: RouteObject[] = [
	{
		path: "/panel/login",
		element: <LoginView/>,
	},
	{
		path: "/panel/login/2fa",
		element: <LoginSecondStepView/>,
	},
	{
		path: "/panel/forgot-password",
		element: <ForgotPasswordView/>,
	},
	{
		path: "/panel/forgot-password/message",
		element: <ForgotPasswordMessageView/>,
	},
	{
		path: "/panel/reset-password",
		element: <ResetPasswordView/>,
	},
];

export default authRoutes;
