import { FormItem } from "src/app/types/ui/form.types";
import ReactSelect, { MenuPlacement } from "react-select";
import { isNotNull } from "src/app/utils/typeguards";
import { useState } from "react";
import classNames from "classnames";
import { Nullable, SelectOption } from "src/app/types/util.types";
import { Label, LabelProps } from "flowbite-react";
import { getSelectClassNames, getSelectStyles } from "src/app/utils/ui";
import { PulseLoader } from "react-spinners";

type Props<T> =
	{
		className?: string
		label?: string | ReactNode
		labelProps?: LabelProps
		options: SelectOption<T>[]
		formItem: FormItem<T>
		onChange: (option: Nullable<SelectOption<T>>) => void
		displayErrorMessage?: boolean
		isSearchable?: boolean
		isClearable?: boolean
		isLoading?: boolean
		portalEl?: HTMLElement
		menuPlacement?: MenuPlacement
		optionsHeight?: number
		inputHeight?: number
		placeholder?: string
	}
	& (T extends object ? { compareValue: (a: T, b: T) => boolean } : { compareValue?: never })

function Select<T>(props: Props<T>) {

	const {
		className,
		label,
		labelProps,
		options,
		formItem,
		onChange,
		displayErrorMessage = true,
		isSearchable = true,
		isClearable = true,
		isLoading = false,
		compareValue,
		portalEl,
		menuPlacement = "auto",
		optionsHeight = 240,
		inputHeight = 42,
		placeholder = "Wybierz opcję...",
	} = props;

	const [ inputValue, onInputValueChange ] = useState("");

	const _handleInputChange = (value: string) => {
		if (isSearchable) {
			onInputValueChange(value);
		}
	};

	return (
		<div className={ classNames(className, "flex flex-col gap-y-0.5") }>
			{
				isNotNull(label) &&
                <Label { ...labelProps }>
					{ label }
                </Label>
			}
			<div className="relative">
				<ReactSelect
					styles={ getSelectStyles<T, false>(optionsHeight, inputHeight) }
					classNames={ getSelectClassNames<T, false>(formItem, isClearable) }
					options={ options }
					value={ options.find(option => compareValue?.(option.value, formItem.value) ?? option.value === formItem.value) ?? null }
					isMulti={ false }
					menuPortalTarget={ portalEl }
					onChange={ onChange }
					isOptionDisabled={ option => isNotNull(option.disabled) && option.disabled }
					inputValue={ inputValue }
					onInputChange={ _handleInputChange }
					isDisabled={ formItem.disabled || isLoading }
					isSearchable={ isSearchable }
					isClearable={ isClearable }
					menuPlacement={ menuPlacement }
					placeholder={ placeholder }
					// menuIsOpen={ true }
				/>
				{
					isLoading &&
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <PulseLoader size={ 7 } color="#EC5600"/>
                    </div>
				}
			</div>
			{
				(isNotNull(formItem.error) && displayErrorMessage) &&
                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
					{ formItem.error }
                </div>
			}
		</div>
	);
}

export default Select;
