/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { FormHookReturnType } from "src/app/types/ui/form.types";
import { getAvailabilityItToLocalize, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { Nullable } from "src/app/types/util.types";
import { createFormField } from "src/app/utils/forms";
import { HiCalendar } from "react-icons/hi";
import { Button } from "flowbite-react";
import { DateTime } from "luxon";
import DatePicker from "src/app/components/Form/DatePicker.component";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import useReservingData from "src/app/utils/hooks/useReservingData";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { getAvailabilityId } from "src/app/utils/helpers";
import { addLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LocaleFromISO } from "src/app/utils/luxon";
import { MAX_FUTURE_MONTHS } from "src/app/utils/constants/constants";

type ComponentProps = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	fetchAvailabilities: (venueId: number, date: string) => void
	actualHeaderHeight: number
	windowScrollValue: number
	setChooseDateHeight: (height: number) => void
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function PurchasingProcessChooseDate(props: Props) {

	const {
		form: {
			form: {
				date,
				dateRoomAvailabilities,
				venue,
			},
			handleChange,
			handleBlur,
		},
		fetchAvailabilities,
		actualHeaderHeight,
		windowScrollValue,
		setChooseDateHeight,
		bodySize,
		addLoadingRecord,
	} = props;

	const reservingData = useReservingData();

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isNull(ref.current)) return;
		setChooseDateHeight(parseInt(window.getComputedStyle(ref.current).height));
	}, [ bodySize ]);

	const dt = DateTime.fromISO(date.value);

	const dateFormItem = createFormField(dt.toJSDate(), {
		disabled: date.disabled,
		optional: date.optional,
		success: date.success,
	});

	const resetGridScrollPosition = () => {
		const availabilitiesWrapper = document.getElementById("availabilities-wrapper");
		if (isNull(availabilitiesWrapper)) return;
		availabilitiesWrapper.scroll(0, 0);
	};

	const setPreviousDay = () => {
		const minusDate = dt.minus({ days: 1 }).toISODate();
		if (isNull(minusDate)) return;
		resetGridScrollPosition();
		handleChange("date", minusDate);
	};

	const setNextDay = () => {
		const plusDate = dt.plus({ days: 1 }).toISODate();
		if (isNull(plusDate)) return;
		resetGridScrollPosition();
		handleChange("date", plusDate);
	};

	const isToday = date.value === DateTime.now().toISODate();
	const disableNextArrow = LocaleFromISO(DateTime.fromISO(date.value).toISO()).hasSame(DateTime.now().plus({ months: MAX_FUTURE_MONTHS }), "day");

	const _fetchAvailabilities = (date: Nullable<string>) => {
		if (isNull(venue.value) || isNull(date)) return;
		fetchAvailabilities(venue.value.id, date);
	};

	const onPrevArrowClick = () => {
		if (isToday) return;
		const newDate = dt.minus({ days: 1 }).toISODate();
		setPreviousDay();
		const storeDateRoomAvailability = dateRoomAvailabilities.value.find(dateRoomAvailability => dateRoomAvailability.date === newDate);
		_fetchAvailabilities(newDate);
		if (isNotNull(storeDateRoomAvailability)) {
			handleChange("dateRoomAvailabilities", dateRoomAvailabilities.value.map(dateRoomAvailability => {
				if (dateRoomAvailability.date !== newDate) return dateRoomAvailability;
				return storeDateRoomAvailability;
			}));
		} else {
			const loadingRecord: LoadingRecord = { loadableId: getAvailabilityId(venue.value?.id ?? 0, newDate ?? ""), loadableType: LoadableType.FETCH_AVAILABILITIES };
			addLoadingRecord(loadingRecord);
		}
	};

	const onNextArrowClick = () => {
		if (disableNextArrow) return;
		const newDate = dt.plus({ days: 1 }).toISODate();
		setNextDay();
		const storeDateRoomAvailability = dateRoomAvailabilities.value.find(dateRoomAvailability => dateRoomAvailability.date === newDate);
		_fetchAvailabilities(newDate);
		if (isNotNull(storeDateRoomAvailability)) {
			handleChange("dateRoomAvailabilities", dateRoomAvailabilities.value.map(dateRoomAvailability => {
				if (dateRoomAvailability.date !== newDate) return dateRoomAvailability;
				return storeDateRoomAvailability;
			}));
		} else {
			const loadingRecord: LoadingRecord = { loadableId: getAvailabilityId(venue.value?.id ?? 0, newDate ?? ""), loadableType: LoadableType.FETCH_AVAILABILITIES };
			addLoadingRecord(loadingRecord);
		}
	};

	const onDatePickerChange = (datePickerDate: Date) => {
		const newDate = DateTime.fromJSDate(datePickerDate).toISODate();
		if (isNull(newDate)) return;
		handleChange("date", newDate);
		resetGridScrollPosition();
		const storeDateRoomAvailability = dateRoomAvailabilities.value.find(dateRoomAvailability => dateRoomAvailability.date === newDate);
		_fetchAvailabilities(newDate);
		if (isNotNull(storeDateRoomAvailability)) {
			handleChange("dateRoomAvailabilities", dateRoomAvailabilities.value.map(dateRoomAvailability => {
				if (dateRoomAvailability.date !== newDate) return dateRoomAvailability;
				return storeDateRoomAvailability;
			}));
		} else {
			const loadingRecord: LoadingRecord = { loadableId: getAvailabilityId(venue.value?.id ?? 0, newDate ?? ""), loadableType: LoadableType.FETCH_AVAILABILITIES };
			addLoadingRecord(loadingRecord);
		}
	};

	const localizeReservation = () => {
		if (isNull(reservingData)) return;
		const roomId = reservingData.room.id;
		const date = reservingData.date;
		const middleAvailabilityLocalIdInTheBlock = reservingData.availabilities[ Math.floor(reservingData.availabilities.length / 2) ].localId;

		if (LocaleFromISO(date).startOf("day").valueOf() < DateTime.now().startOf("day").valueOf()) return;
		handleChange("date", date);
		_fetchAvailabilities(date);
		const mobileAvailability = document.getElementById(getAvailabilityItToLocalize("mobile", venue.value?.id, roomId, middleAvailabilityLocalIdInTheBlock));
		const desktopAvailability = document.getElementById(getAvailabilityItToLocalize("desktop", venue.value?.id, roomId, middleAvailabilityLocalIdInTheBlock));
		if (isNotNull(mobileAvailability)) {
			mobileAvailability.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		}
		if (isNotNull(desktopAvailability)) {
			desktopAvailability.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		}
	};

	const getReservingDataString = () => {
		const ReturnSpan = ({ variant }: { variant: "empty" | "chosen" }) => (
			<div className="flex items-center justify-center gap-1 flex-wrap">
				<div className="font-[900]">Wybrany termin:</div>
				<div
					className={ classNames({ "font-semibold underline cursor-pointer": variant === "chosen" }) }
					onClick={ localizeReservation }
				>
					{ variant === "empty" ? "brak" : `${ roomName } ${ date } ${ startTime } - ${ endTime }` }
				</div>
			</div>
		);
		if (isNull(reservingData)) return <ReturnSpan variant="empty"/>;
		const date = DateTime.fromISO(reservingData.startTime).toLocaleString(DateTime.DATE_SHORT);
		const startTime = DateTime.fromISO(reservingData.startTime).toLocaleString(DateTime.TIME_24_SIMPLE);
		const endTime = DateTime.fromISO(reservingData.endTime).toLocaleString(DateTime.TIME_24_SIMPLE);
		const roomName = reservingData.room.name;
		return <ReturnSpan variant="chosen"/>;
	};

	return (
		<div
			ref={ ref }
			id="choose-date"
			className={ classNames(
				"flex flex-col gap-2 py-4 z-20 bg-gray-50 w-[calc(100vw-32px)]",
				{ "fixed top-0 left-4 shadow": windowScrollValue >= actualHeaderHeight },
			) }
		>
			<div className="flex items-center justify-center gap-4 flex-wrap w-full">
				<Button
					onClick={ () => !isToday && onDatePickerChange(DateTime.now().toJSDate()) }
					size="xs"
					color={ isToday ? "orange-full" : "orange-outline" }
				>
					Dziś
				</Button>
				<DatePicker
					formItem={ dateFormItem }
					onChange={ onDatePickerChange }
					onBlur={ () => handleBlur("date") }
					showTimeInput={ false }
					closeOnChange={ true }
					minDate={ new Date() }
					maxDate={ DateTime.now().plus({ months: MAX_FUTURE_MONTHS }).toJSDate() }
					customInput={
						<Button
							color="orange-outline"
							size="xs"
						>
							<HiCalendar className="text-base mr-1"/>
							<span>data</span>
						</Button>
					}
				/>
				<div className="flex items-center gap-4">
					<SlArrowLeft
						onClick={ onPrevArrowClick }
						className={ classNames(
							"text-lg text-myPrimary-orange-500 cursor-pointer",
							{ "!text-gray-400 !cursor-default": isToday },
						) }
					/>
					<SlArrowRight
						onClick={ onNextArrowClick }
						className={ classNames(
							"text-lg text-myPrimary-orange-500 cursor-pointer",
							{ "!text-gray-400 !cursor-default": disableNextArrow },
						) }
					/>
				</div>
				<span className="text-myPrimary-orange-500 text-sm">
					{ DateTime.fromISO(date.value).setLocale("pl").toLocaleString(DateTime.DATE_HUGE) }
				</span>
			</div>
			<div className="flex justify-center items-center">
				{ getReservingDataString() }
			</div>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	bodySize: state.ui.layout.bodySize,
});

const mapDispatchToProps = {
	addLoadingRecord: addLoadingRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingProcessChooseDate);
