/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiAddTimeWindowToWeekday, uiCopyTimeRangeFromOtherRoom, uiCopyTimeRangeFromOtherWeekday, uiCreateRoom, uiDeleteRoom, uiDeleteTimeWindow, uiEditTimeWindow, uiUpdateRoom } from "src/app/store/features/ui/room/ui.room.actions";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { copyRoomTimeRangesFromOtherRoomAsync, createRoomAsync, deleteRoomByIdAsync, updateRoomAsync, updateRoomTimeRangesAsync } from "src/app/store/features/room/room.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { scopedPush } from "src/app/store/features/misc/misc.actions";

export const uiCreateRoomEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateRoom)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_ROOM };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createRoomAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createRoomAsync.success, action) || isActionOf(createRoomAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createRoomAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie utworzono pokój!" })),
								of(scopedPush(`/rooms/${ responseAction.payload.data.id }`)),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateRoomEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateRoom)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_ROOM };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateRoomAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateRoomAsync.success, action) || isActionOf(updateRoomAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateRoomAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaktualizowano pokój!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiCopyTimeRangeFromOtherRoomEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCopyTimeRangeFromOtherRoom)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_ROOM })),
				of(copyRoomTimeRangesFromOtherRoomAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(copyRoomTimeRangesFromOtherRoomAsync.success, action) || isActionOf(copyRoomTimeRangesFromOtherRoomAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(copyRoomTimeRangesFromOtherRoomAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_ROOM })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Harmonogram został poprawnie skopiowany" })),
							);
						}

						return of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_ROOM }));
					}),
				),
			),
		),
	);

export const uiCopyTimeRangeFromOtherWeekdayEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCopyTimeRangeFromOtherWeekday)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.weekDay, loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_WEEKDAY })),
				of(updateRoomTimeRangesAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateRoomTimeRangesAsync.success, action) || isActionOf(updateRoomTimeRangesAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateRoomTimeRangesAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.weekDay, loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_WEEKDAY })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Okno czasowe zostało poprawnie skopiowane" })),
							);
						}

						return of(removeLoadingRecord({ loadableId: action.payload.weekDay, loadableType: LoadableType.COPY_TIME_RANGE_FROM_OTHER_WEEKDAY }));
					}),
				),
			),
		),
	);

export const uiAddTimeWindowToWeekdayEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiAddTimeWindowToWeekday)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.ADD_TIME_WINDOW_TO_WEEKDAY })),
				of(updateRoomTimeRangesAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateRoomTimeRangesAsync.success, action) || isActionOf(updateRoomTimeRangesAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateRoomTimeRangesAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.ADD_TIME_WINDOW_TO_WEEKDAY })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Poprawnie dodano okno czasowe" })),
							);
						}

						return of(removeLoadingRecord({ loadableId: action.payload.id, loadableType: LoadableType.ADD_TIME_WINDOW_TO_WEEKDAY }));
					}),
				),
			),
		),
	);

export const uiEditTimeWindowEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiEditTimeWindow)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.timeRangeId, loadableType: LoadableType.EDIT_TIME_WINDOW })),
				of(updateRoomTimeRangesAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateRoomTimeRangesAsync.success, action) || isActionOf(updateRoomTimeRangesAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateRoomTimeRangesAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.timeRangeId, loadableType: LoadableType.EDIT_TIME_WINDOW })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Poprawnie zaktualizowano okno czasowe" })),
							);
						}

						return of(removeLoadingRecord({ loadableId: action.payload.timeRangeId, loadableType: LoadableType.EDIT_TIME_WINDOW }));
					}),
				),
			),
		),
	);

export const uiDeleteTimeWindowEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteTimeWindow)),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.timeRangeId, loadableType: LoadableType.DELETE_TIME_WINDOW })),
				of(updateRoomTimeRangesAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateRoomTimeRangesAsync.success, action) || isActionOf(updateRoomTimeRangesAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateRoomTimeRangesAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord({ loadableId: action.payload.timeRangeId, loadableType: LoadableType.DELETE_TIME_WINDOW })),
								of(displayToast({ type: ToastType.SUCCESS, content: "Poprawnie usunięto okno czasowe" })),
							);
						}

						return of(removeLoadingRecord({ loadableId: action.payload.timeRangeId, loadableType: LoadableType.DELETE_TIME_WINDOW }));
					}),
				),
			),
		),
	);

export const uiDeleteRoomEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteRoom)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_ROOM };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteRoomByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteRoomByIdAsync.success, action) || isActionOf(deleteRoomByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteRoomByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto pokój!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);
