/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button, Card } from "flowbite-react";
import LabelValue from "src/app/components/Utils/LabelValue.component";
import { canUpdateVenue } from "src/app/utils/abilities";
import { useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import { DetailedVenue, UpdateVenuePayload } from "src/app/types/api/venue.types";
import { getAddressString, getGoogleMapsLink } from "src/app/utils/helpers";
import UpdateVenueModal from "src/app/components/Venue/UpdateVenueModal.component";
import { isNotNull } from "src/app/utils/typeguards";
import ModelCell from "src/app/components/Utils/ModelCell.component";
import { RiDoorOpenFill } from "react-icons/ri";

type Props = {
	venue: DetailedVenue
	onUpdate: (payload: UpdateVenuePayload) => void
};

function VenueProfileOverview(props: Props) {

	const {
		venue: {
			name,
			street,
			houseNumber,
			flatNumber,
			postCode,
			city,
			country,
			rooms,
		},
		onUpdate,
	} = props;

	const [ isUpdateModalOpen, toggleUpdateModalOpen ] = useState(false);

	return (
		<>
			<div className="grid grid-cols-2 gap-6">
				<div>
					<Card>
						<div className="flex flex-col gap-2.5">
							<div className="flex items-center justify-between pb-2.5 mb-2.5 border-b border-gray-200 dark:border-dark-borderColor">
								<h2 className="text-xl font-bold">Podstawowe informacje</h2>
								<div className="flex gap-1">
									{
										canUpdateVenue(props.venue) &&
                                        <Button
                                            color="transparent"
                                            onClick={ () => toggleUpdateModalOpen(true) }
                                        >
                                            <HiPencilAlt className="h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
                                        </Button>
									}
								</div>
							</div>
							<LabelValue
								label="Nazwa"
								value={ name }
							/>
							<LabelValue
								label="Adres"
								value={ getAddressString({ postCode, city, country, flatNumber, houseNumber, street }) }
								link={ isNotNull(city) ? getGoogleMapsLink({ postCode, city, country, flatNumber, houseNumber, street }) : undefined }
							/>
							<LabelValue
								label="Pokoje"
								value={
									(isNotNull(rooms) && rooms.length > 0) ?
									<div className="flex flex-wrap gap-2">
										{
											rooms.map(room =>
												<ModelCell
													key={ room.id }
													model={ room }
													link={ `/rooms/${ room.id }` }
													image={ room.image }
													fallbackImage={ RiDoorOpenFill }
												/>
											)
										}
									</div> :
										"Brak pokoi"
								}
							/>
						</div>
					</Card>
				</div>
			</div>
			<UpdateVenueModal
				isOpen={ isUpdateModalOpen }
				handleClose={ () => toggleUpdateModalOpen(false) }
				venue={ props.venue }
				onUpdate={ onUpdate }
			/>
		</>
	);
}

export default (VenueProfileOverview);
