import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { getDiscountCodeById } from "src/app/store/features/discountCode/discountCode.selectors";
import { fetchDiscountCodeByIdAsync } from "src/app/store/features/discountCode/discountCode.actions";
import DiscountCodeProfileContainer from "src/app/containers/DiscountCode/DiscountCodeProfile.container";
import { useParams } from "react-router-dom";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function DiscountCodeProfileView(props: Props) {
    const {
        getDiscountCode,
        fetchDiscountCodeById,
    } = props;

    const { discountCodeId = "0" } = useParams();

    return (
        <ForceFreshStrategy
            request={ () => fetchDiscountCodeById(+discountCodeId) }
            state={ getDiscountCode(+discountCodeId) }
        >
            {
                discountCode =>
                    <DiscountCodeProfileContainer
                        discountCode={ discountCode }
                    />
            }
        </ForceFreshStrategy>
    );
}

const mapStateToProps = (state: RootState) => ({
    getDiscountCode: (id: number) => getDiscountCodeById(state, id),
});

const mapDispatchToProps = {
    fetchDiscountCodeById: fetchDiscountCodeByIdAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCodeProfileView);