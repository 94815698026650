import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { uiCreateDiscountCode } from "src/app/store/features/ui/discountCode/ui.discountCode.actions";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function DiscountCodeCreateView(props: Props) {
    const {
        createDiscountCode,
    } = props;

    return (
        <div></div>
    );
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
    createDiscountCode: uiCreateDiscountCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCodeCreateView);