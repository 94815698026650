import { RootEpic } from "src/app/store/root.epic";
import { filter, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { authMeAsync, confirmTwoFactorAuthenticationAsync, createUserAsync, deleteUserAsync, disableTwoFactorAuthenticationAsync, enableTwoFactorAuthenticationAsync, fetchPaginatedUsersAsync, fetchUserByIdAsync, fetchUsersAsync, fetchUserScopesAsync, forgotPasswordAsync, loginAsync, logoutAsync, resetPasswordAsync, scopeAuthMeAsync, updateUserAsync, updateUserPasswordAsync, updateUserStatusAsync } from "src/app/store/features/user/user.actions";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedUser, EnableTwoFactorAuthenticationPayload, User } from "src/app/types/api/user.types";
import { isNotNull } from "src/app/utils/typeguards";
import { from, of } from "rxjs";

export const loginEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(loginAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/auth/login",
				method: "POST",
				data: action.payload,
				withScope: false,
				onSuccess: loginAsync.success,
				onFailure: loginAsync.failure,
			}),
		),
	);

export const authMeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(authMeAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/auth/me",
				method: "GET",
				withScope: false,
				onSuccess: authMeAsync.success,
				onFailure: authMeAsync.failure,
			}),
		),
	);

export const scopeAuthMeEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(scopeAuthMeAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/users/me",
				method: "GET",
				withScope: true,
				onSuccess: scopeAuthMeAsync.success,
				onFailure: scopeAuthMeAsync.failure,
			}),
		),
	);

export const forgotPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(forgotPasswordAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/auth/forgot-password",
				method: "POST",
				data: action.payload,
				withScope: false,
				onSuccess: forgotPasswordAsync.success,
				onFailure: forgotPasswordAsync.failure,
			}),
		),
	);

export const resetPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(resetPasswordAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/auth/reset-password",
				method: "POST",
				data: action.payload,
				withScope: false,
				onSuccess: resetPasswordAsync.success,
				onFailure: resetPasswordAsync.failure,
			}),
		),
	);

export const logoutEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(logoutAsync.request)),
		map(_ =>
			apiAsync.request({
				url: "/auth/logout",
				method: "POST",
				withScope: false,
				onSuccess: logoutAsync.success,
				onFailure: logoutAsync.failure,
			}),
		),
	);

export const fetchUserScopesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserScopesAsync.request)),
		map(_ =>
			apiAsync.request({
				url: "/scopes",
				method: "GET",
				withScope: false,
				onSuccess: fetchUserScopesAsync.success,
				onFailure: fetchUserScopesAsync.failure,
			}),
		),
	);

export const fetchPaginatedUsersEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchPaginatedUsersAsync.request)),
		map(action => {
			const pageSize = action.payload.pageSize;
			const pageIndex = action.payload.pageIndex;

			// ToDo: Use new URLSearchParams
			const sortParam = isNotNull(action.payload.sort) ? `&sort=${ action.payload.sort }` : "";
			const ownerUserIdParams = action.payload.filters.user_id.reduce((prev, next) => `${ prev }&filter[user_id][]=${ next }`, "");
			const statusParams = action.payload.filters.status.reduce((prev, next) => `${ prev }&filter[status][]=${ next }`, "");
			const search = isNotNull(action.payload.search) ? `&search=${ action.payload.search }` : "";

			return apiAsync.request({
				url: `/users?pageSize=${ pageSize }&pageIndex=${ pageIndex }${ search }${ sortParam }${ ownerUserIdParams }${ statusParams }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<User[]>) => fetchPaginatedUsersAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchPaginatedUsersAsync.failure({ ...payload, id: action.payload.pageIndex }),
			});
		}),
	);

export const fetchUsersEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUsersAsync.request)),
		map(_ =>
			apiAsync.request({
				url: "/users",
				method: "GET",
				withScope: true,
				onSuccess: fetchUsersAsync.success,
				onFailure: fetchUsersAsync.failure,
			}),
		),
	);

export const fetchUserByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchUserByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/users/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedUser>) => fetchUserByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchUserByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const createUserEpic: RootEpic = (action$, _, services) =>
	action$.pipe(
		filter(isActionOf(createUserAsync.request)),
		mergeMap(action =>
			isNotNull(action.payload.avatar)
				?
				from(services.file.parseFile(action.payload.avatar))
					.pipe(
						map(parsedFile =>
							apiAsync.request({
								url: "/users",
								method: "POST",
								data: {
									...action.payload,
									avatar: parsedFile,
								},
								withScope: true,
								onSuccess: createUserAsync.success,
								onFailure: createUserAsync.failure,
							}),
						),
					)
				:
				of(apiAsync.request({
					url: "/users",
					method: "POST",
					data: action.payload,
					withScope: true,
					onSuccess: createUserAsync.success,
					onFailure: createUserAsync.failure,
				})),
		),
	);

export const updateUserEpic: RootEpic = (action$, _, services) =>
	action$.pipe(
		filter(isActionOf(updateUserAsync.request)),
		mergeMap(action =>
			isNotNull(action.payload.avatar)
				?
				from(services.file.parseFile(action.payload.avatar))
					.pipe(
						map(parsedFile =>
							apiAsync.request({
								url: `/users/${ action.payload.id }`,
								method: "PUT",
								data: {
									...action.payload,
									avatar: parsedFile,
								},
								withScope: true,
								onSuccess: updateUserAsync.success,
								onFailure: updateUserAsync.failure,
							}),
						),
					)
				:
				of(apiAsync.request({
					url: `/users/${ action.payload.id }`,
					method: "PUT",
					data: action.payload,
					withScope: true,
					onSuccess: updateUserAsync.success,
					onFailure: updateUserAsync.failure,
				})),
		),
	);

export const updateUserStatusEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateUserStatusAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/users/${ action.payload.id }/status`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateUserStatusAsync.success,
				onFailure: updateUserStatusAsync.failure,
			}),
		),
	);

export const updateUserPasswordEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateUserPasswordAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/users/${ action.payload.id }/password`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateUserPasswordAsync.success,
				onFailure: updateUserPasswordAsync.failure,
			}),
		),
	);

export const deleteUserByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteUserAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/users/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteUserAsync.success,
				onFailure: deleteUserAsync.failure,
			}),
		),
	);

export const enableTwoFactorAuthenticationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(enableTwoFactorAuthenticationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/users/${ action.payload }/2fa/enable`,
				method: "POST",
				withScope: true,
				onSuccess: (payload: SuccessPayload<EnableTwoFactorAuthenticationPayload>) => enableTwoFactorAuthenticationAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => enableTwoFactorAuthenticationAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const disableTwoFactorAuthenticationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(disableTwoFactorAuthenticationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/users/${ action.payload }/2fa/disable`,
				method: "POST",
				withScope: true,
				onSuccess: disableTwoFactorAuthenticationAsync.success,
				onFailure: disableTwoFactorAuthenticationAsync.failure,
			}),
		),
	);

export const confirmTwoFactorAuthenticationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(confirmTwoFactorAuthenticationAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/users/${ action.payload.id }/2fa/confirm`,
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: confirmTwoFactorAuthenticationAsync.success,
				onFailure: confirmTwoFactorAuthenticationAsync.failure,
			}),
		),
	);
