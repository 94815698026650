import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import CachedThenFreshStrategy from "src/app/hoc/caching/CachedThenFreshStrategy.hoc";
import { fetchAdminCategoriesAsync } from "src/app/store/features/category/category.actions";
import CategoryListContainer from "src/app/containers/Category/CategoryList.container";
import { uiCreateCategory, uiDeleteCategory, uiUpdateCategory } from "src/app/store/features/ui/category/ui.category.actions";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function CategoryListView(props: Props) {
    const {
        adminCategories,
        fetchAdminCategories,
        deleteCategory,
        createCategory,
        updateCategory,
    } = props;

    return (
        <CachedThenFreshStrategy
            request={ fetchAdminCategories }
            state={ adminCategories }
        >
            {
                adminCategories =>
                    <CategoryListContainer
                        categories={ adminCategories }
                        onCreate={ createCategory }
                        onDelete={ deleteCategory }
                        onUpdate={ updateCategory }
                    />
            }
        </CachedThenFreshStrategy>
    );
}

const mapStateToProps = (state: RootState) => ({
    adminCategories: state.category.adminCategories,
});

const mapDispatchToProps = {
    fetchAdminCategories: fetchAdminCategoriesAsync.request,
    deleteCategory: uiDeleteCategory,
    createCategory: uiCreateCategory,
    updateCategory: uiUpdateCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListView);