/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DateTime } from "luxon";
import { Button } from "flowbite-react";
import classNames from "classnames";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import { RoomTimeRange } from "src/app/types/api/room.types";
import { RootState } from "src/app/store/root.reducer";
import { ModalConfig } from "src/app/types/util.types";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { useState } from "react";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { isNotNull } from "src/app/utils/typeguards";
import { LocaleFromISO } from "src/app/utils/luxon";

type ComponentProps = {
	timeRange: RoomTimeRange
	onEditTimeWindow: (timeWindow: RoomTimeRange) => void
	onDeleteTimeWindow: (timeWindowId: number) => void
}

type Props =
	ReturnType<typeof mapStateToProps> &
	ComponentProps;

function TimeWindow(props: Props) {

	const {
		timeRange,
		onEditTimeWindow,
		onDeleteTimeWindow,
		isEditing,
		isDeleting,
	} = props;

	const [ deleteTimeRangeModal, setDeleteTimeRangeModal ] = useState<ModalConfig<RoomTimeRange>>({ isOpen: false });

	return (
		<>
			<div className="p-3 flex flex-col items-center justify-between rounded-lg w-40 h-40 bg-myPrimary-gray-300 max-w-max">
				<p className="text-center text-base font-semibold whitespace-nowrap">{ `${ LocaleFromISO(timeRange.startHour).toFormat("HH:mm") } - ${ DateTime.fromISO(timeRange.endHour).toFormat("HH:mm") }` }</p>
				<p className="text-center text-2xl font-medium whitespace-nowrap">{ `${ timeRange.price } zł` }</p>
				<div className="flex justify-end items-center gap-1">
					<Button
						color="transparent"
						className={
							classNames(
								"[&>span]:px-2 [&>span]:py-2",
								{ "[&>span>svg]:hidden [&>span>span]:mr-0 [&>span>span]:h-4 [&>span>span]:w-4 [&>span>span_svg]:block [&>span>span_svg]:h-4 [&>span>span_svg]:w-4": isEditing },
							)
						}
						onClick={ () => onEditTimeWindow(timeRange) }
						isProcessing={ isEditing }
					>
						<HiPencilAlt className="h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
					</Button>
					<Button
						color="transparent"
						className={
							classNames(
								"[&>span]:px-2 [&>span]:py-2",
								{ "[&>span>svg]:hidden [&>span>span]:mr-0 [&>span>span]:h-4 [&>span>span]:w-4 [&>span>span_svg]:block [&>span>span_svg]:h-4 [&>span>span_svg]:w-4": isDeleting },
							)
						}
						onClick={ () => setDeleteTimeRangeModal({ isOpen: true, value: timeRange }) }
						isProcessing={ isDeleting }
					>
						<HiTrash className="h-4 w-4 text-gray-600 dark:text-dark-textGray"/>
					</Button>
				</div>
			</div>
			<ConfirmModal
				title={ `Usuń okno ${ isNotNull(deleteTimeRangeModal.value) ? `od ${ LocaleFromISO(deleteTimeRangeModal.value.startHour).toFormat("HH:mm") } do ${ LocaleFromISO(deleteTimeRangeModal.value.endHour).toFormat("HH:mm") }` : "pokój" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ deleteTimeRangeModal.isOpen }
				handleClose={ () => setDeleteTimeRangeModal({ isOpen: false, value: null }) }
				onConfirm={ () => {
					isNotNull(deleteTimeRangeModal.value) && onDeleteTimeWindow(deleteTimeRangeModal.value.id);
					setDeleteTimeRangeModal({ isOpen: false });
				} }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState, { timeRange }: ComponentProps) => ({
	isEditing: didLoadingRecordExist(state, { loadableType: LoadableType.EDIT_TIME_WINDOW, loadableId: timeRange.id }),
	isDeleting: didLoadingRecordExist(state, { loadableType: LoadableType.DELETE_TIME_WINDOW, loadableId: timeRange.id }),
});

export default connect(mapStateToProps)(TimeWindow);