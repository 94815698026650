import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import ForceFreshStrategy from "src/app/hoc/caching/ForceFreshStrategy.hoc";
import { getVenueWithProductsWithDiscountCodesWithRoomsWithVenues } from "src/app/store/features/venue/venue.selectors";
import { fetchAdminVenuesAsync, fetchVenueByIdAsync } from "src/app/store/features/venue/venue.actions";
import VenueProfileContainer from "src/app/containers/Venue/VenueProfile.container";
import { useParams } from "react-router-dom";
import { fetchAdminProductsAsync } from "src/app/store/features/product/product.actions";
import { fetchDiscountCodesAsync } from "src/app/store/features/discountCode/discountCode.actions";
import { uiUpdateVenue } from "src/app/store/features/ui/venue/ui.venue.actions";
import { uiCalculateAdminRoomReservationPrice, uiCreateAdminReservation, uiUpdateReservation } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { fetchAdminRoomsAsync } from "src/app/store/features/room/room.actions";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function VenueProfileView(props: Props) {
    const {
        getVenueWithProductsAndDiscountCodes,
        fetchVenueById,
        fetchProducts,
        fetchDiscountCodes,
        fetchRooms,
        fetchVenues,
        updateVenue,
        updateReservation,
        createReservation,
        calculateReservationPrice,
    } = props;

    const { venueId = "0" } = useParams();

    const getRequest = () => {
        fetchVenueById(+venueId);
        fetchProducts();
        fetchDiscountCodes();
        fetchRooms();
        fetchVenues();
    }

    return (
        <ForceFreshStrategy
            request={ getRequest }
            state={ getVenueWithProductsAndDiscountCodes(+venueId) }
        >
            {
                ({ venue, products, discountCodes, rooms, venues }) =>
                    <VenueProfileContainer
                        venue={ venue }
                        products={ products }
                        discountCodes={ discountCodes }
                        rooms={ rooms }
                        venues={ venues }
                        updateVenue={ updateVenue }
                        updateReservation={ updateReservation }
                        createReservation={ createReservation }
                        calculateReservationPrice={ calculateReservationPrice }
                    />
            }
        </ForceFreshStrategy>
    );
}

const mapStateToProps = (state: RootState) => ({
    getVenueWithProductsAndDiscountCodes: (id: number) => getVenueWithProductsWithDiscountCodesWithRoomsWithVenues(state, id),
});

const mapDispatchToProps = {
    fetchVenueById: fetchVenueByIdAsync.request,
    fetchProducts: fetchAdminProductsAsync.request,
    fetchDiscountCodes: fetchDiscountCodesAsync.request,
    fetchRooms: fetchAdminRoomsAsync.request,
    fetchVenues: fetchAdminVenuesAsync.request,
    updateVenue: uiUpdateVenue,
    updateReservation: uiUpdateReservation,
    createReservation: uiCreateAdminReservation,
    calculateReservationPrice: uiCalculateAdminRoomReservationPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueProfileView);
