/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createNetworkAction } from "src/app/utils/redux";
import { FailurePayloadWithId, SuccessPayload, SuccessPayloadWithId } from "src/app/types/api/api.types";
import { AdminAvailability, CalculateReservationPricePayload, CreateReservationPayload, DetailedReservation, FetchRoomAvailabilitiesPayload, Reservation, ReservationPrice, UpdateReservationPayload, UpdateReservationStatusPayload } from "src/app/types/api/reservation.types";

export const fetchReservationsAsync = createNetworkAction<undefined, SuccessPayload<Reservation[]>>("RESERVATION__FETCH_RESERVATIONS");
export const fetchAdminReservationsByDateAsync = createNetworkAction<string, SuccessPayloadWithId<Reservation[]>, FailurePayloadWithId>("RESERVATION__FETCH_ADMIN_RESERVATIONS_BY_DATE");
export const fetchReservationByIdAsync = createNetworkAction<number, SuccessPayloadWithId<DetailedReservation>, FailurePayloadWithId>("RESERVATION__FETCH_RESERVATION_BY_ID");
export const fetchRoomAvailabilitiesAsync = createNetworkAction<FetchRoomAvailabilitiesPayload, SuccessPayloadWithId<AdminAvailability[]>, FailurePayloadWithId>("RESERVATION__FETCH_ROOM_AVAILABILITY");
export const calculateAdminRoomReservationPriceAsync = createNetworkAction<CalculateReservationPricePayload, SuccessPayload<ReservationPrice>>("RESERVATION__CALCULATE_ADMIN_ROOM_RESERVATION_PRICE");
export const calculateRoomReservationPriceAsync = createNetworkAction<CalculateReservationPricePayload, SuccessPayload<ReservationPrice>>("RESERVATION__CALCULATE_ROOM_RESERVATION_PRICE");
export const createReservationAsync = createNetworkAction<CreateReservationPayload, SuccessPayload<DetailedReservation>>("RESERVATION__CREATE_RESERVATION");
export const createAdminReservationAsync = createNetworkAction<CreateReservationPayload, SuccessPayload<DetailedReservation>>("RESERVATION__CREATE_ADMIN_RESERVATION");
export const updateReservationAsync = createNetworkAction<UpdateReservationPayload, SuccessPayload<DetailedReservation>>("RESERVATION__UPDATE_RESERVATION");
export const deleteReservationByIdAsync = createNetworkAction<number, SuccessPayload<{ id: number }>>("RESERVATION__DELETE_RESERVATION");
export const fetchAdminRoomAvailabilitiesAsync = createNetworkAction<FetchRoomAvailabilitiesPayload, SuccessPayloadWithId<AdminAvailability[]>, FailurePayloadWithId>("RESERVATION__FETCH_ADMIN_ROOM_AVAILABILITY");
export const updateReservationStatusAsync = createNetworkAction<UpdateReservationStatusPayload, SuccessPayload<DetailedReservation>>("RESERVATION__UPDATE_RESERVATION_STATUS");
export const sendReservationInvoiceAsync = createNetworkAction<number, SuccessPayload<DetailedReservation>>("RESERVATION__SEND_RESERVATION_INVOICE");
export const generateReservationInvoiceAsync = createNetworkAction<number, SuccessPayload<DetailedReservation>>("RESERVATION__GENERATE_RESERVATION_INVOICE");
