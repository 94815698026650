/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { filter, map } from "rxjs/operators";
import { apiAsync } from "src/app/store/features/api/api.actions";
import { isActionOf } from "typesafe-actions";
import { RootEpic } from "src/app/store/root.epic";
import { FailurePayload, SuccessPayload } from "src/app/types/api/api.types";
import { DetailedVenue } from "src/app/types/api/venue.types";
import { createVenueAsync, deleteVenueByIdAsync, fetchAdminVenueAvailabilitiesAsync, fetchAdminVenuesAsync, fetchVenueAvailabilitiesAsync, fetchVenueByIdAsync, fetchVenuesAsync, updateVenueAsync } from "src/app/store/features/venue/venue.actions";
import { DateTime } from "luxon";
import { getAvailabilityId } from "src/app/utils/helpers";
import { VenueAvailabilities } from "src/app/types/api/reservation.types";

export const fetchVenuesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchVenuesAsync.request)),
		map(() =>
			apiAsync.request({
				url: "/venues",
				method: "GET",
				withScope: true,
				onSuccess: fetchVenuesAsync.success,
				onFailure: fetchVenuesAsync.failure,
			}),
		),
	);

export const fetchAdminVenuesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchAdminVenuesAsync.request)),
		map(() =>
			apiAsync.request({
				url: `/admin/venues`,
				method: "GET",
				withScope: true,
				onSuccess: fetchAdminVenuesAsync.success,
				onFailure: fetchAdminVenuesAsync.failure,
			}),
		),
	);

export const fetchVenueByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchVenueByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/venues/${ action.payload }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<DetailedVenue>) => fetchVenueByIdAsync.success({ ...payload, id: action.payload }),
				onFailure: (payload: FailurePayload) => fetchVenueByIdAsync.failure({ ...payload, id: action.payload }),
			}),
		),
	);

export const createVenueEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(createVenueAsync.request)),
		map(action =>
			apiAsync.request({
				url: "/admin/venues",
				method: "POST",
				data: action.payload,
				withScope: true,
				onSuccess: createVenueAsync.success,
				onFailure: createVenueAsync.failure,
			}),
		),
	);

export const updateVenueEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(updateVenueAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/venues/${ action.payload.id }`,
				method: "PUT",
				data: action.payload,
				withScope: true,
				onSuccess: updateVenueAsync.success,
				onFailure: updateVenueAsync.failure,
			}),
		),
	);

export const deleteVenueByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(deleteVenueByIdAsync.request)),
		map(action =>
			apiAsync.request({
				url: `/admin/venues/${ action.payload }`,
				method: "DELETE",
				withScope: true,
				onSuccess: deleteVenueByIdAsync.success,
				onFailure: deleteVenueByIdAsync.failure,
			}),
		),
	);

export const fetchVenueAvailabilitiesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchVenueAvailabilitiesAsync.request)),
		map(({ payload: { date, venueId } }) =>
			apiAsync.request({
				url: `/venues/${ venueId }/availabilities/${ DateTime.fromISO(date).toFormat("yyyy-LL-dd") }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<VenueAvailabilities[]>) => fetchVenueAvailabilitiesAsync.success({ ...payload, id: getAvailabilityId(venueId, date) }),
				onFailure: (payload: FailurePayload) => fetchVenueAvailabilitiesAsync.failure({ ...payload, id: getAvailabilityId(venueId, date) }),
			}),
		),
	);

export const fetchAdminVenueAvailabilities: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(fetchAdminVenueAvailabilitiesAsync.request)),
		map(({ payload: { date, venueId, } }) =>
			apiAsync.request({
				url: `/admin/venues/${ venueId }/availabilities/${ DateTime.fromISO(date).toFormat("yyyy-LL-dd") }`,
				method: "GET",
				withScope: true,
				onSuccess: (payload: SuccessPayload<VenueAvailabilities[]>) => fetchAdminVenueAvailabilitiesAsync.success({ ...payload, id: getAvailabilityId(venueId, date) }),
				onFailure: (payload: FailurePayload) => fetchAdminVenueAvailabilitiesAsync.failure({ ...payload, id: getAvailabilityId(venueId, date) }),
			}),
		),
	);
