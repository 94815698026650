/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CreateProductPayload } from "src/app/types/api/product.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { Nullable } from "src/app/types/util.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validateNumberField, validatePhoto } from "src/app/utils/forms";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import Avatar from "src/app/components/Utils/Avatar.component";
import { isNotNull } from "src/app/utils/typeguards";
import { HiX } from "react-icons/hi";
import FileInput from "src/app/components/Form/FileInput.component";
import Input from "src/app/components/Form/Input.component";
import { Category } from "src/app/types/api/category.types";
import CategoryMultiSelect from "src/app/components/Form/CategoryMultiSelect.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		handleClose: () => void
		onCreate: (payload: CreateProductPayload) => void
	};

type CreateProductForm = {
	avatar: Nullable<File>
	name: string
	description: string
	price: string
	vatRate: string
	categories: Category[]
}

const validator: FormValidator<CreateProductForm> = {
	avatar: (avatar, optional) => validatePhoto("Awatar", avatar, optional),
	name: (name, optional) => validateField("Nazwa", name, optional),
	description: (description, optional) => validateField("Opis", description, optional),
	price: (price, optional) => validateNumberField("Cena", price, optional),
	vatRate: (vatRate, optional) => validateNumberField("Stawka VAT", vatRate, optional, 0, 100),
	categories: () => null,
};

function CreateProductModal(props: Props) {

	const {
		isOpen,
		handleClose,
		onCreate,
		isCreating,
	} = props;

	const _handleSubmit = (values: CreateProductForm) => {
		onCreate({
			...values,
			vatRate: +values.vatRate,
		});
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		avatar: createFormField(null, { optional: true }),
		name: createFormField(""),
		description: createFormField("", { optional: true }),
		price: createFormField(""),
		vatRate: createFormField(""),
		categories: createFormField([]),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isCreating) {
			resetForm();
		}
	}, [ isOpen, isCreating ]);

	return (
		<Modal
			show={ isOpen || isCreating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Dodaj nowy produkt
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						<div className="flex flex-col sm:flex-row gap-4 items-end">
							<div className="relative">
								<Avatar
									img={ isNotNull(form.avatar.value) ? URL.createObjectURL(form.avatar.value) : undefined }
									size="lg"
								/>
								{
									isNotNull(form.avatar.value) &&
									<HiX
										className="cursor-pointer text-white absolute -top-2.5 -right-2.5 p-1 rounded-full w-6 h-6 bg-red-500 transition duration-25 ease-in-out hover:bg-red-600 hover:text-gray-100"
										onClick={ () => handleChange("avatar", null) }
									/>
								}
							</div>
							<FileInput
								label="Awatar"
								buttonText="Prześlij awatar"
								name="avatar"
								className="[&>div]:py-4 flex-grow"
								onChange={ file => {
									handleChange("avatar", file);
									handleBlur("avatar");
								} }
								options={ {
									accept: {
										"image/jpeg": [],
										"image/png": [],
									},
								} }
							/>
						</div>
						{
							isNotNull(form.avatar.error) &&
							<div className="text-sm text-red-600 dark:text-red-500 font-medium">
								{ form.avatar.error }
							</div>
						}
						<Input
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							formItem={ form.description }
							label="Opis"
							name="description"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("description", e.target.value),
								onBlur: () => handleBlur("description"),
							} }
						/>
						<Input
							formItem={ form.price }
							label="Cena"
							name="price"
							inputProps={ {
								type: "number",
								rightIcon: () => <span className="pr-5">zł</span>,
								onChange: (e) => handleChange("price", e.target.value),
								onBlur: () => handleBlur("price"),
							} }
						/>
						<Input
							formItem={ form.vatRate }
							label="Stawka VAT"
							name="vatRate"
							inputProps={ {
								type: "number",
								rightIcon: () => <span className="pr-5">%</span>,
								onChange: (e) => handleChange("vatRate", e.target.value),
								onBlur: () => handleBlur("vatRate"),
							} }
						/>
						<CategoryMultiSelect
							label="Kategorie"
							formItem={ form.categories }
							onChange={ categories => handleChange("categories", categories) }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isCreating }
					>
						Stwórz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_PRODUCT }),
});

export default connect(mapStateToProps)(CreateProductModal);
