import { CreateVenuePayload, Venue } from "src/app/types/api/venue.types";
import Table from "src/app/components/Utils/Table.component";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { ModalConfig, Nullable, SortCompare } from "src/app/types/util.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { canDeleteVenue, canViewVenue } from "src/app/utils/abilities";
import useUserScopeNavigate from "src/app/utils/hooks/useUserScopeNavigate";
import { Button } from "flowbite-react";
import React, { useState } from "react";
import CreateVenueModal from "src/app/components/Venue/CreateVenueModal.component";
import ConfirmModal from "src/app/components/Utils/ConfirmModal.component";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { customDataTableSearch, getAllInnerRoomsFromModel } from "src/app/utils/helpers";
import { Room } from "src/app/types/api/room.types";
import ModelListPopover from "src/app/components/Utils/ModelListPopover.component";
import { RiDoorOpenFill } from "react-icons/ri";
import DeleteCell from "src/app/components/Utils/DeleteCell.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		venues: Venue[]
		onCreate: (payload: CreateVenuePayload) => void
		onDelete: (venueId: number) => void
	};

function VenueListContainer(props: Props) {

	const {
		venues,
		onCreate,
		onDelete,
		isDeleting,
	} = props;

	const { navigate } = useUserScopeNavigate();

	const [ isCreateModalOpen, toggleCreateModal ] = useState(false);
	const [ deleteVenueModal, setDeleteVenueModal ] = useState<ModalConfig<Venue>>({
		isOpen: false,
		value: null,
	});

	const venueColumns: MUIDataTableColumn[] = [
		{
			name: "Nazwa",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (venue: Venue) => venue.name,
				sortCompare: order => (a: SortCompare<Venue>, b: SortCompare<Venue>) => {
					if (order === "asc") {
						return (a.data.name).localeCompare(b.data.name);
					} else {
						return (b.data.name).localeCompare(a.data.name);
					}
				},
			},
		}, {
			name: "Pokoje",
			options: {
				filter: true,
				sort: true,
				filterOptions: {
					names: getAllInnerRoomsFromModel(venues),
					logic: (item, filters, row) => {
						if (isNull(row) || isNull(row[ 0 ])) return false;
						const roomNames: string[] = (row[ 0 ].rooms as Room[]).map(room => room.name.toLowerCase());
						return isNotNull(row) && !filters.some(filterElement => roomNames.some(roomName => filterElement.toLowerCase() === roomName));
					},
				},
				customBodyRender: ({ rooms }: Venue) => {
					return (
						isNotNull(rooms) ?
							<ModelListPopover
								list={ rooms }
								count={ 1 }
								getLink={ id => `/rooms/${ id }` }
								fallbackImage={ RiDoorOpenFill }
							/> :
							"Brak"
					)
				},
				sortCompare: order => (a, b) => {
					if (order === "asc") {
						return a.data.rooms.length.toString().localeCompare(b.data.rooms.length.toString());
					} else {
						return b.data.rooms.length.toString().localeCompare(a.data.rooms.length.toString());
					}
				},
			},
		}, {
			name: "Akcje",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (venue: Venue) =>
					<DeleteCell
						ability={ canDeleteVenue(venue) }
						onDelete={ e => {
							e.stopPropagation();
							setDeleteVenueModal({
								isOpen: true,
								value: venue,
							});
						} }
					/>,
				setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		onRowClick: (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
			const clickedVenue: Nullable<Venue> = props.venues[ rowMeta.dataIndex ];
			if (isNotNull(clickedVenue) && canViewVenue(clickedVenue)) {
				navigate(`/venues/${ clickedVenue.id }`);
			}
		},
		customToolbar: () =>
			<Button
				className="order-first mr-3"
				size="sm"
				color="primary"
				onClick={ () => toggleCreateModal(true) }
			>
				Dodaj lokal
			</Button>,
		customSearch: (searchQuery: string, currentRow: Venue[]) => customDataTableSearch(
			searchQuery,
			[
				currentRow[ 0 ]?.name,
				currentRow[ 0 ]?.rooms?.map(room => room.name).join("-"),
			]
		),
	};

	const _handleConfirmDeleteVenue = () => {
		if (isNotNull(deleteVenueModal.value)) {
			onDelete(deleteVenueModal.value.id);
			setDeleteVenueModal(prevState => ({ ...prevState, isOpen: false }));
		}
	};

	const isDeletingVenue = isNotNull(deleteVenueModal.value) && isDeleting(deleteVenueModal.value.id);

	return (
		<>
			<Table
				title="Lokale"
				columns={ venueColumns }
				options={ tableOptions }
				data={ venues }
			/>
			<CreateVenueModal
				isOpen={ isCreateModalOpen }
				handleClose={ () => toggleCreateModal(false) }
				onCreate={ onCreate }
			/>
			<ConfirmModal
				title={ `Usuń ${ isNotNull(deleteVenueModal.value) ? deleteVenueModal.value.name : "pokój" }` }
				warning="Czy jesteś pewień? Ta operacja jest nieodwracalna"
				isOpen={ deleteVenueModal.isOpen }
				confirmWord="kasuj"
				isLoading={ isDeletingVenue }
				handleClose={ () => setDeleteVenueModal({ isOpen: false, value: null }) }
				onConfirm={ _handleConfirmDeleteVenue }
			/>
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	isDeleting: (venueId: number) => didLoadingRecordExist(state, { loadableType: LoadableType.DELETE_VENUE, loadableId: venueId }),
});

export default connect(mapStateToProps)(VenueListContainer);
