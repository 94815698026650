/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import CartItemProduct from "src/app/components/PurchasingProcess/util/Cart/CartItemProduct.component";
import { Cart, CartItemType } from "src/app/types/api/reservation.types";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Nullable } from "src/app/types/util.types";
import { isNull } from "src/app/utils/typeguards";
import CartItemSession from "src/app/components/PurchasingProcess/util/Cart/CartItemSession.component";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { breakpoints } from "src/app/utils/constants/constants";

type ComponentProps = {
	title: string
	cart: Cart
	handleProductChange: (productId: number, number: Nullable<number>) => void
	className?: string
}

type Props =
	ReturnType<typeof mapStateToProps>
	& ComponentProps;

function ReservationCart(props: PropsWithChildren<Props>) {

	const {
		title,
		cart,
		handleProductChange,
		children,
		className = "",
		bodyWidth,
	} = props;

	const sortedCart = cart.sort((a, b) => {
		if (a.type === CartItemType.SESSION && b.type !== CartItemType.SESSION) {
			return -1;
		} else if (a.type !== CartItemType.SESSION && b.type === CartItemType.SESSION) {
			return 1;
		} else {
			return 0;
		}
	});

	const headerRef = useRef<HTMLDivElement>(null);
	const childrenWrapperRef = useRef<HTMLDivElement>(null);

	const [ maxProductListHeight, setMaxProductListHeight ] = useState<Nullable<number>>(null);

	useEffect(() => {
		if (isNull(headerRef.current) || isNull(childrenWrapperRef.current)) return;
		const {
			height: headerHeight,
			marginBottom: headerMarginBottom,
			borderBottomWidth: headerBorderBottomWidth,
			paddingBottom: headerPaddingBottom,
		} = window.getComputedStyle(headerRef.current);
		const { height: childrenWrapperHeight } = window.getComputedStyle(childrenWrapperRef.current);
		const layoutHeaderHeight = 208;
		setMaxProductListHeight(window.innerHeight - layoutHeaderHeight - 30 - (parseInt(headerHeight) + parseInt(headerMarginBottom) + parseInt(headerBorderBottomWidth) + parseInt(headerPaddingBottom)) - parseInt(childrenWrapperHeight));
	}, []);

	return (
		<div className={ classNames("bg-white min-w-2/5 w-2/5 flex flex-col p-4 rounded-xl justify-between bg-parrot-background bg-no-repeat bg-center bg-1/2", className) }>
			<div className="flex flex-col">
				<div
					ref={ headerRef }
					className="flex justify-between border-b-[1px] border-b-gray-200 mb-3 pb-1"
				>
					<div className={ classNames(
						"uppercase font-medium",
						"text-base",
						"sm:text-lg",
					) }>
						{ title }
					</div>
					<div>
						{/* todo: Add cart-logo */ }
					</div>
				</div>
				<div
					style={ { maxHeight: bodyWidth > breakpoints.LG ? maxProductListHeight ?? "unset" : "unset" } }
					className="flex flex-col overflow-y-auto"
				>
					{
						sortedCart.map((cartItem, index) =>
							cartItem.type === CartItemType.SESSION ?
								<CartItemSession
									key={ index }
									cartItem={ cartItem }
									className={ classNames({ "border-t-0": index !== 0 }) }
								/>
								:
								<CartItemProduct
									key={ index }
									cartItem={ cartItem }
									className={ classNames({ "border-t-0": index !== 0 }) }
									handleProductChange={ handleProductChange }
								/>,
						)
					}
				</div>
			</div>
			<div ref={ childrenWrapperRef }>
				{ children }
			</div>
		</div>

	);
}

const mapStateToProps = (state: RootState) => ({
	bodyWidth: state.ui.layout.bodySize.width,
});

export default connect(mapStateToProps)(ReservationCart);

