/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import SingleSkeletonRoom from "src/app/components/PurchasingProcess/ChooseAvailabilities(1st-step)/Skeleton/SingleSkeletonRoom.component";

type Props = {
	roomsNumber: number
}

function SkeletonRooms(props: Props) {

	const {
		roomsNumber,
	} = props;

	return (
		<>
			{
				new Array(roomsNumber).fill("").map((_, index) =>
					<SingleSkeletonRoom key={ index }/>
				)
			}
		</>
	);
}

export default SkeletonRooms;