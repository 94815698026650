/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { CreateRoomPayload } from "src/app/types/api/room.types";
import { RootState } from "src/app/store/root.reducer";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { connect } from "react-redux";
import { Nullable } from "src/app/types/util.types";
import { FormValidator } from "src/app/types/ui/form.types";
import { createFormField, validateField, validateNullableField, validateNumberField, validatePhoto } from "src/app/utils/forms";
import { isEmptyString, isNotNull, isNull } from "src/app/utils/typeguards";
import useForm from "src/app/utils/hooks/useForm";
import { useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import Input from "src/app/components/Form/Input.component";
import Avatar from "src/app/components/Utils/Avatar.component";
import { HiX } from "react-icons/hi";
import FileInput from "src/app/components/Form/FileInput.component";
import { Venue } from "src/app/types/api/venue.types";
import Select from "src/app/components/Form/Select.component";

type Props =
	ReturnType<typeof mapStateToProps>
	& {
		isOpen: boolean
		handleClose: () => void
		vanues: Venue[]
		onCreate: (payload: CreateRoomPayload) => void
	};

type CreateRoomForm = {
	avatar: Nullable<File>
	venueId: Nullable<number>
	name: string
	description: string
	location: string
	maxPeople: string
}

const validator: FormValidator<CreateRoomForm> = {
	avatar: (avatar, optional) => validatePhoto("Awatar", avatar, optional),
	venueId: (venueId, optional) => validateNullableField("Lokal", venueId, optional),
	name: (name, optional) => validateField("Nazwa", name, optional),
	description: (description, optional) => validateField("Opis", description, optional),
	location: (location, optional) => validateField("Lokacja", location, optional),
	maxPeople: (maxPeople, optional) => validateNumberField("Ilość osób", maxPeople, optional, 1),
};

function CreateRoomModal(props: Props) {

	const {
		isOpen,
		handleClose,
		vanues,
		onCreate,
		isCreating,
	} = props;

	const _handleSubmit = (values: CreateRoomForm) => {

		const {
			venueId,
			description,
			maxPeople,
			...rest
		} = values;

		if (isNull(venueId)) return;

		onCreate({
			...rest,
			venueId,
			description: isEmptyString(description) ? undefined : description,
			maxPeople: +maxPeople,
		});
		handleClose();
	};

	const {
		form,
		handleChange,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useForm({
		avatar: createFormField(null, { optional: true }),
		venueId: createFormField(null),
		name: createFormField(""),
		description: createFormField("", { optional: true }),
		location: createFormField(""),
		maxPeople: createFormField(""),
	}, validator, _handleSubmit);

	useEffect(() => {
		if (!isOpen && !isCreating) {
			resetForm();
		}
	}, [ isOpen, isCreating ]);

	return (
		<Modal
			show={ isOpen || isCreating }
			onClose={ handleClose }
			size="xl"
			root={ document.body }
			key={ (isOpen || isCreating) ? "open" : "hidden" } // AutoFocus on input work with this
		>
			<Modal.Header>
				Dodaj nowy pokój
			</Modal.Header>
			<form onSubmit={ handleSubmit }>
				<Modal.Body className="!overflow-visible">
					<div className="space-y-3">
						<>
							<div className="flex flex-col sm:flex-row gap-4 items-end">
								<div className="relative">
									<Avatar
										img={ isNotNull(form.avatar.value) ? URL.createObjectURL(form.avatar.value) : undefined }
										size="lg"
									/>
									{
										isNotNull(form.avatar.value) &&
                                        <HiX
                                            className="cursor-pointer text-white absolute -top-2.5 -right-2.5 p-1 rounded-full w-6 h-6 bg-red-500 transition duration-25 ease-in-out hover:bg-red-600 hover:text-gray-100"
                                            onClick={ () => handleChange("avatar", null) }
                                        />
									}
								</div>
								<FileInput
									label="Awatar"
									buttonText="Prześlij awatar"
									name="avatar"
									className="[&>div]:py-4 flex-grow"
									onChange={ file => {
										handleChange("avatar", file);
										handleBlur("avatar");
									} }
									options={ {
										accept: {
											"image/jpeg": [],
											"image/png": [],
										},
									} }
								/>
							</div>
							{
								isNotNull(form.avatar.error) &&
                                <div className="text-sm text-red-600 dark:text-red-500 font-medium">
									{ form.avatar.error }
                                </div>
							}
						</>
						<Select
							label="Lokal"
							options={ vanues.map(venue => ({ value: venue.id, label: venue.name })) }
							formItem={ form.venueId }
							onChange={ option => {
								if (isNull(option)) return;
								handleChange("venueId", option.value);
								handleBlur("venueId");
							} }
							isSearchable={ false }
							isClearable={ false }
						/>
						<Input
							formItem={ form.name }
							label="Nazwa"
							name="name"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("name", e.target.value),
								onBlur: () => handleBlur("name"),
							} }
						/>
						<Input
							formItem={ form.description }
							label="Opis"
							name="description"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("description", e.target.value),
								onBlur: () => handleBlur("description"),
							} }
						/>
						<Input
							formItem={ form.location }
							label="Lokacja"
							name="location"
							inputProps={ {
								type: "text",
								onChange: (e) => handleChange("location", e.target.value),
								onBlur: () => handleBlur("location"),
							} }
						/>
						<Input
							formItem={ form.maxPeople }
							label="Ilość osób"
							name="maxPeople"
							inputProps={ {
								type: "number",
								onChange: (e) => handleChange("maxPeople", e.target.value),
								onBlur: () => handleBlur("maxPeople"),
							} }
						/>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-between border-none pt-0">
					<Button onClick={ handleClose } color="gray-outline">
						Anuluj
					</Button>
					<Button
						type="submit"
						isProcessing={ isCreating }
					>
						Stwórz
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCreating: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_ROOM }),
});

export default connect(mapStateToProps)(CreateRoomModal);
