/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import { uiCreateCategory, uiDeleteCategory, uiUpdateCategory } from "src/app/store/features/ui/category/ui.category.actions";
import { filter, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { createCategoryAsync, deleteCategoryByIdAsync, updateCategoryAsync } from "src/app/store/features/category/category.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";

export const uiCreateCategoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateCategory)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_CATEGORY };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createCategoryAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createCategoryAsync.success, action) || isActionOf(createCategoryAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createCategoryAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie utworzono kategorie" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateCategoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateCategory)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_CATEGORY };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateCategoryAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateCategoryAsync.success, action) || isActionOf(updateCategoryAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateCategoryAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie zaaktualizowano kategorie" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteCategoryEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteCategory)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_CATEGORY };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteCategoryByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteCategoryByIdAsync.success, action) || isActionOf(deleteCategoryByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteCategoryByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto kategorie" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);
