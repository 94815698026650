/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CreateCategoryPayload, UpdateCategoryPayload } from "src/app/types/api/category.types";

export const uiCreateCategory = createAction("UI_CATEGORY__CREATE_CATEGORY")<CreateCategoryPayload>();
export const uiUpdateCategory = createAction("UI_CATEGORY__UPDATE_CATEGORY")<UpdateCategoryPayload>();
export const uiDeleteCategory = createAction("UI_CATEGORY__DELETE_CATEGORY")<number>();