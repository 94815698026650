/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { DateTime } from "luxon";
import { Nullable } from "src/app/types/util.types";

export const LocaleFromISO = (iso: Nullable<string>) => DateTime.fromISO(iso ?? "").setZone("Europe/Warsaw").setLocale("pl");
