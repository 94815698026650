/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { createAction } from "typesafe-actions";
import { CreateProductPayload, UpdateProductPayload } from "src/app/types/api/product.types";

export const uiCreateProduct = createAction("UI_PRODUCT__CREATE_PRODUCT")<CreateProductPayload>();
export const uiUpdateProduct = createAction("UI_PRODUCT__UPDATE_PRODUCT")<UpdateProductPayload>();
export const uiDeleteProduct = createAction("UI_PRODUCT__DELETE_PRODUCT")<number>();