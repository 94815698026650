import { DayTime, EnumDictionary, Nullable, PaymentMethod, WeekDay } from "src/app/types/util.types";
import { ErrorCodeName } from "src/app/types/api/api.types";
import { PermissionGroup, UserPermission, UserRole, UserStatus } from "src/app/types/api/user.types";
import { FlowbiteColors } from "flowbite-react";
import { PurchasingProcessFormStep, ReservationStatus } from "src/app/types/api/reservation.types";
import { ProductCategory } from "src/app/types/api/product.types";
import { DiscountCodeType, DiscountCodeValidityType } from "src/app/types/api/discountCode.types";
import { isNotNull } from "src/app/utils/typeguards";
import { DAY_START_HOUR } from "src/app/utils/constants/constants";

export const errorCodeNameDictionary: EnumDictionary<ErrorCodeName, string> = {
	[ ErrorCodeName.GENERIC ]: "Błąd serwera",
	[ ErrorCodeName.GENERIC_NOT_FOUND ]: "Nie znaleziono",
	[ ErrorCodeName.GENERIC_NOT_SUPPORTED ]: "Brak wsparcia zapytania",
	[ ErrorCodeName.GENERIC_NOT_IMPLEMENTED ]: "Nie zaimplementowano zapytania",
	[ ErrorCodeName.GENERIC_THROTTLE_TOO_MANY_REQUESTS ]: "Zbyt wiele żądań",
	[ ErrorCodeName.UNAUTHORIZED_SIGNATURE_INVALID ]: "Niepoprawna sygnatura",
	[ ErrorCodeName.GENERIC_METHOD_NOT_ALLOWED ]: "Metoda nie dozwolona",
	[ ErrorCodeName.GENERIC_HEALTHCHECK_FAILED ]: "Healthcheck nie powiódł się",
	[ ErrorCodeName.GENERIC_UNAUTHENTICATED ]: "Brak autentykacji",
	[ ErrorCodeName.UNAUTHENTICATED_INVALID_CREDENTIALS ]: "Nie poprawne dane logowania",
	[ ErrorCodeName.UNAUTHENTICATED_MISSING_2FA_OTP ]: "Brakuje kodu 2FA",
	[ ErrorCodeName.UNAUTHENTICATED_INVALID_2FA_OTP ]: "Niepoprawny kod 2FA",
	[ ErrorCodeName.UNAUTHENTICATED_RESTRICTED_ACCESS ]: "Ograniczony dostęp",
	[ ErrorCodeName.UNAUTHENTICATED_USER_EMAIL_NOT_CONFIRMED ]: "Użytkownik nie posiada potwierdzonego emaila",
	[ ErrorCodeName.GENERIC_VALIDATION_FAIL ]: "Błąd walidacji",
	[ ErrorCodeName.GENERIC_UNAUTHORIZED ]: "Brak autoryzacji",
	[ ErrorCodeName.GENERIC_INVALID_OPERATION ]: "Niepoprawna operacja",
	[ ErrorCodeName.ROOM_TIME_RANGES_UNAVAILABLE ]: "Ten termin nie jest już dłużej dostępny",
};

export const userPermissionsDictionary: EnumDictionary<UserPermission, string> = {
	[ UserPermission.APP_ADMINISTRATION ]: "App administration",
	[ UserPermission.OTHER_MANAGER_PERFORM ]: "Other manager perform",

	[ UserPermission.USER_CREATE ]: "Create user",
	[ UserPermission.USER_VIEW ]: "View user",
	[ UserPermission.USER_LIST ]: "List users",
	[ UserPermission.USER_UPDATE ]: "Update user",
	[ UserPermission.USER_MANAGE ]: "Manage user",
	[ UserPermission.USER_MANAGE_ADMIN ]: "Admin manage user",
	[ UserPermission.USER_DELETE ]: "Delete user",

	[ UserPermission.USER_SCOPE_ALL ]: "Operate on all users",
	[ UserPermission.USER_SCOPE_COMPANY ]: "Operate on all users inside company",
	[ UserPermission.USER_PREVIEW_SCOPE_ALL ]: "View all users",
	[ UserPermission.USER_PREVIEW_SCOPE_COMPANY ]: "View all users from company",

	[ UserPermission.COMPANY_CREATE ]: "Create company",
	[ UserPermission.COMPANY_VIEW ]: "View company",
	[ UserPermission.COMPANY_LIST ]: "List companies",
	[ UserPermission.COMPANY_UPDATE ]: "Update company",
	[ UserPermission.COMPANY_DELETE ]: "Delete company",

	[ UserPermission.COMPANY_SCOPE_ALL ]: "Operate on all companies",
	[ UserPermission.COMPANY_PREVIEW_SCOPE_ALL ]: "View all companies",

	[ UserPermission.MODEL_CREATE ]: "Create model",
	[ UserPermission.MODEL_VIEW ]: "View model",
	[ UserPermission.MODEL_LIST ]: "List models",
	[ UserPermission.MODEL_UPDATE ]: "Update model",
	[ UserPermission.MODEL_DELETE ]: "Delete model",

	[ UserPermission.MODEL_SCOPE_ALL ]: "Operate on all models",
	[ UserPermission.MODEL_SCOPE_COMPANY ]: "Operate on all models inside company",
	[ UserPermission.MODEL_PREVIEW_SCOPE_ALL ]: "View all models",
	[ UserPermission.MODEL_PREVIEW_SCOPE_COMPANY ]: "View all models from company",
};

export const userPermissionGroupDictionary: EnumDictionary<PermissionGroup, string> = {
	[ PermissionGroup.USERS ]: "Users",
	[ PermissionGroup.COMPANIES ]: "Companies",
	[ PermissionGroup.MODELS ]: "Models",
	[ PermissionGroup.OTHERS ]: "Others",
};

export const userStatusColorDictionary: EnumDictionary<UserStatus, string> = {
	[ UserStatus.ACTIVE ]: "success",
	[ UserStatus.ARCHIVED ]: "gray",
	[ UserStatus.SUSPENDED ]: "warning",
	[ UserStatus.INACTIVE ]: "teal",
	[ UserStatus.DELETED ]: "failure",
};

export const userStatusDictionary: EnumDictionary<UserStatus, string> = {
	[ UserStatus.ACTIVE ]: "Aktywny",
	[ UserStatus.ARCHIVED ]: "Archiwum",
	[ UserStatus.SUSPENDED ]: "Zawieszony",
	[ UserStatus.INACTIVE ]: "Nieakatywny",
	[ UserStatus.DELETED ]: "Usunięty",
};

export const actionUserStatusDictionary: EnumDictionary<UserStatus, string> = {
	[ UserStatus.ACTIVE ]: "Aktywuj",
	[ UserStatus.ARCHIVED ]: "Archiwizuj",
	[ UserStatus.SUSPENDED ]: "Zawieś",
	[ UserStatus.INACTIVE ]: "Dezaktywuj",
	[ UserStatus.DELETED ]: "Usuń",
};

export const userPermissionGroupAggregationDictionary: EnumDictionary<PermissionGroup, UserPermission[]> = {
	[ PermissionGroup.USERS ]: [
		UserPermission.USER_CREATE,
		UserPermission.USER_VIEW,
		UserPermission.USER_LIST,
		UserPermission.USER_UPDATE,
		UserPermission.USER_MANAGE,
		UserPermission.USER_MANAGE_ADMIN,
		UserPermission.USER_DELETE,
		UserPermission.USER_SCOPE_ALL,
		UserPermission.USER_SCOPE_COMPANY,
		UserPermission.USER_PREVIEW_SCOPE_ALL,
		UserPermission.USER_PREVIEW_SCOPE_COMPANY,
	],
	[ PermissionGroup.COMPANIES ]: [
		UserPermission.COMPANY_CREATE,
		UserPermission.COMPANY_VIEW,
		UserPermission.COMPANY_LIST,
		UserPermission.COMPANY_UPDATE,
		UserPermission.COMPANY_DELETE,
		UserPermission.COMPANY_SCOPE_ALL,
		UserPermission.COMPANY_PREVIEW_SCOPE_ALL,
	],
	[ PermissionGroup.MODELS ]: [
		UserPermission.MODEL_CREATE,
		UserPermission.MODEL_VIEW,
		UserPermission.MODEL_LIST,
		UserPermission.MODEL_UPDATE,
		UserPermission.MODEL_DELETE,
		UserPermission.MODEL_SCOPE_ALL,
		UserPermission.MODEL_SCOPE_COMPANY,
		UserPermission.MODEL_PREVIEW_SCOPE_ALL,
		UserPermission.MODEL_PREVIEW_SCOPE_COMPANY,
	],
	[ PermissionGroup.OTHERS ]: [
		UserPermission.APP_ADMINISTRATION,
		UserPermission.OTHER_MANAGER_PERFORM,
	],
};

export const weekDayDictionary: EnumDictionary<WeekDay, string> = {
	[ WeekDay.MONDAY ]: "Poniedziałek",
	[ WeekDay.TUESDAY ]: "Wtorek",
	[ WeekDay.WEDNESDAY ]: "Środa",
	[ WeekDay.THURSDAY ]: "Czwartek",
	[ WeekDay.FRIDAY ]: "Piątek",
	[ WeekDay.SATURDAY ]: "Sobota",
	[ WeekDay.SUNDAY ]: "Niedziela",
};

export const userRoleDictionary: EnumDictionary<UserRole, string> = {
	[ UserRole.SUPER_ADMIN ]: "Super Admin",
	[ UserRole.ORGANIZATION_ADMIN ]: "Administrator Organizacji",
};

export const userRoleColorDictionary: EnumDictionary<UserRole, keyof FlowbiteColors> = {
	[ UserRole.SUPER_ADMIN ]: "red",
	[ UserRole.ORGANIZATION_ADMIN ]: "gray",
};

export const dayTimeDictionary: EnumDictionary<DayTime, keyof FlowbiteColors> = {
	[ DayTime.MORNING ]: "Poranek",
	[ DayTime.AFTERNOON ]: "Popołudnie",
	[ DayTime.EVENING ]: "Wieczór",
};

// Purchasing process
export const purchasingProcessFormStepDictionary: EnumDictionary<PurchasingProcessFormStep, string> = {
	[ PurchasingProcessFormStep.AVAILABILITIES ]: "pokoje",
	[ PurchasingProcessFormStep.ADDITIONS ]: "dodatki",
	[ PurchasingProcessFormStep.PAYMENT ]: "płatność",
	[ PurchasingProcessFormStep.CONFIRMATION ]: "potwierdzenie",
	[ PurchasingProcessFormStep.FAILED ]: "niepowodzenie",
	[ PurchasingProcessFormStep.PROCESSING ]: "przetwarzanie",
};

export const purchasingProcessFormStepRouteDictionary = (urlVenueId: Nullable<number>): EnumDictionary<PurchasingProcessFormStep, string> => ({
	[ PurchasingProcessFormStep.AVAILABILITIES ]: `${ isNotNull(urlVenueId) ? `/${ urlVenueId }` : "" }`,
	[ PurchasingProcessFormStep.ADDITIONS ]: `${ isNotNull(urlVenueId) ? `/${ urlVenueId }` : "" }/additions`,
	[ PurchasingProcessFormStep.PAYMENT ]: `${ isNotNull(urlVenueId) ? `/${ urlVenueId }` : "" }/payment`,
	[ PurchasingProcessFormStep.CONFIRMATION ]: `${ isNotNull(urlVenueId) ? `/${ urlVenueId }` : "" }/confirmation`,
	[ PurchasingProcessFormStep.FAILED ]: `${ isNotNull(urlVenueId) ? `/${ urlVenueId }` : "" }/failed`,
	[ PurchasingProcessFormStep.PROCESSING ]: `${ isNotNull(urlVenueId) ? `/${ urlVenueId }` : "" }/processing`,
});

export const purchasingProcessFormStepAvailableRoutesDictionary: EnumDictionary<PurchasingProcessFormStep, PurchasingProcessFormStep[]> = {
	[ PurchasingProcessFormStep.AVAILABILITIES ]: [],
	[ PurchasingProcessFormStep.ADDITIONS ]: [ PurchasingProcessFormStep.AVAILABILITIES, PurchasingProcessFormStep.ADDITIONS ],
	[ PurchasingProcessFormStep.PAYMENT ]: [ PurchasingProcessFormStep.AVAILABILITIES, PurchasingProcessFormStep.ADDITIONS, PurchasingProcessFormStep.PAYMENT ],
	[ PurchasingProcessFormStep.CONFIRMATION ]: [ PurchasingProcessFormStep.AVAILABILITIES ],
	[ PurchasingProcessFormStep.FAILED ]: [ PurchasingProcessFormStep.AVAILABILITIES ],
	[ PurchasingProcessFormStep.PROCESSING ]: [ PurchasingProcessFormStep.AVAILABILITIES ],
};

export const discountCodeTypeDictionary: EnumDictionary<DiscountCodeType, string> = {
	[ DiscountCodeType.AMOUNT ]: "Kwotowy",
	[ DiscountCodeType.PERCENTAGE ]: "Procentowy",
};

export const discountCodeTypeCharDictionary: EnumDictionary<DiscountCodeType, string> = {
	[ DiscountCodeType.AMOUNT ]: "zł",
	[ DiscountCodeType.PERCENTAGE ]: "%",
};

export const discountCodeValidityTypeDictionary: EnumDictionary<DiscountCodeValidityType, string> = {
	[ DiscountCodeValidityType.EXPIRES_AT ]: "Data wygaśnięcia",
	[ DiscountCodeValidityType.MAX_USAGES ]: "Ilość użyć",
};

export const productCategoryDictionary: EnumDictionary<ProductCategory, string> = {
	[ ProductCategory.SEASONAL ]: "sezonowe",
	[ ProductCategory.MENU ]: "zestawy",
	[ ProductCategory.ALCOHOL ]: "alkohol",
	[ ProductCategory.SNACKS ]: "przekąski",
	[ ProductCategory.PARTY ]: "party",
	[ ProductCategory.OTHERS ]: "inne",
};

export const reservationStatusDictionary: EnumDictionary<ReservationStatus, string> = {
	[ ReservationStatus.NEW ]: "Nowa",
	[ ReservationStatus.PAID ]: "Opłacona",
	[ ReservationStatus.UNPAID ]: "Nieopłacona",
	[ ReservationStatus.CANCELLED ]: "Anulowana",
	[ ReservationStatus.ENDED ]: "Zakończona",
};

export const reservationStatusColorDictionary: EnumDictionary<ReservationStatus, string> = {
	[ ReservationStatus.NEW ]: "teal",
	[ ReservationStatus.PAID ]: "success",
	[ ReservationStatus.UNPAID ]: "failure",
	[ ReservationStatus.CANCELLED ]: "dark",
	[ ReservationStatus.ENDED ]: "gray",
};

export const flowbiteColorsToHexDictionary: EnumDictionary<string, string> = {
	"dark": "#4b5563",
	"blue": "#1c64f2",
	"red": "#e02424",
	"failure": "#e02424",
	"green": "#057a55",
	"yellow": "#e3a008",
	"warning": "#e3a008",
	"indigo": "#5850ec",
	"purple": "#7e3af2",
	"cyan": "#0891b2",
	"gray": "#6b7280",
	"lime": "#65a30d",
	"success": "#65a30d",
	"pink": "#e74694",
	"teal": "#047481",
};

export const paymentMethodDictionary: EnumDictionary<PaymentMethod, string> = {
	[ PaymentMethod.CASH ]: "Gotówka",
	[ PaymentMethod.TRANSFER ]: "Przelew",
};

export const dayHourToStartAndEndHourString: EnumDictionary<typeof DAY_START_HOUR, { start: string, end: string }> = {
	[ 0 ]: {
		start: "0:00",
		end: "23:59",
	},
	[ 1 ]: {
		start: "1:00",
		end: "00:59",
	},
	[ 2 ]: {
		start: "2:00",
		end: "1:59",
	},
	[ 3 ]: {
		start: "3:00",
		end: "2:59",
	},
	[ 4 ]: {
		start: "4:00",
		end: "3:59",
	},
	[ 5 ]: {
		start: "5:00",
		end: "4:59",
	},
	[ 6 ]: {
		start: "6:00",
		end: "5:59",
	},
	[ 7 ]: {
		start: "7:00",
		end: "6:59",
	},
	[ 8 ]: {
		start: "8:00",
		end: "7:59",
	},
	[ 9 ]: {
		start: "9:00",
		end: "8:59",
	},
};
