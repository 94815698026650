/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Button } from "flowbite-react";
import { createContext, Dispatch, MouseEvent, PropsWithChildren, SetStateAction, useContext, useRef, useState } from "react";
import useOutsideAlerter from "src/app/utils/hooks/useOutsideAlerter";
import { ButtonProps } from "flowbite-react/lib/esm/components/Button/Button";

type Props =
	ButtonProps
	& {
		label: ReactNode
	};

type Context = {
	isDropdownOpen: boolean
	toggleDropdown: Dispatch<SetStateAction<boolean>>
}

const DropdownContext = createContext<Context>({
	isDropdownOpen: false,
	toggleDropdown: () => undefined,
});

function Dropdown(props: PropsWithChildren<Props>) {

	const {
		label,
		children,
		...buttonProps
	} = props;

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, () => toggleDropdown(false));

	const [ isDropdownOpen, toggleDropdown ] = useState(false);

	return (
		<DropdownContext.Provider
			value={ {
				isDropdownOpen,
				toggleDropdown,
			} }
		>
			<div className="relative" ref={ wrapperRef }>
				<Button
					{ ...buttonProps }
					onClick={ (e: MouseEvent<HTMLButtonElement>) => {
						toggleDropdown(prevState => !prevState);
						buttonProps?.onClick?.(e);
					} }
				>
					{ label }
				</Button>
				{
					isDropdownOpen &&
                    <div className="absolute top-[calc(100%+8px)] w-full z-10 rounded-xl divide-y divide-gray-100 shadow transition-opacity duration-100 border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white">
                        <ul>
							{ children }
                        </ul>
                    </div>
				}
			</div>
		</DropdownContext.Provider>
	);
}

type DropdownItemProps = {
	onClick: () => void
}

export function DropdownItem(props: PropsWithChildren<DropdownItemProps>) {
	const {
		onClick,
		children,
	} = props;

	const dropdownContext = useContext(DropdownContext);

	return (
		<li
			className="flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
			onClick={ () => {
				onClick();
				dropdownContext.toggleDropdown(false);
			} }
		>
			{ children }
		</li>
	);
}

export default (Dropdown);
