import { combineReducers } from "redux";
import { createFormReducer } from "src/app/utils/redux";
import { loginFormInitialState, loginFormValidator } from "src/app/utils/constants/login.form";
import { loginFormActions, purchasingProcessFormActions, roomReservationFormActions, updateReservationFormActions } from "src/app/store/features/form/form.actions";
import { purchasingProcessFormInitialState, purchasingProcessFormValidator, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { createRoomReservationFormInitialState, createRoomReservationFormValidator, updateRoomReservationFormInitialState, updateRoomReservationFormValidator } from "src/app/utils/constants/roomReservation.form";
import { createReducer } from "typesafe-actions";
import { ReducerForm } from "src/app/types/ui/form.types";
import { applicationStart } from "src/app/store/features/misc/misc.actions";

const reducer = combineReducers({
	login: createFormReducer(loginFormInitialState, loginFormActions, loginFormValidator),
	purchasingProcess: createReducer({ form: purchasingProcessFormInitialState, validator: purchasingProcessFormValidator, comparator: {} } as unknown as ReducerForm<PurchasingProcessReducerForm>, {
		...createFormReducer<PurchasingProcessReducerForm>(purchasingProcessFormInitialState, purchasingProcessFormActions, purchasingProcessFormValidator).handlers,
	})
		.handleAction([ applicationStart ], (state) => {
			if (
				Object.keys(purchasingProcessFormInitialState).some(key => !Object.keys(state.form).includes(key)) ||
				Object.keys(purchasingProcessFormInitialState.paymentForm.value).some(key => !Object.keys(state.form.paymentForm.value).includes(key))
			) {
				return {
					...state,
					form: purchasingProcessFormInitialState,
					validator: purchasingProcessFormValidator,
				};
			}
			return {
				...state,
				validator: purchasingProcessFormValidator,
			};
		}),
	roomReservation: createFormReducer(createRoomReservationFormInitialState, roomReservationFormActions, createRoomReservationFormValidator),
	updateRoomReservation: createFormReducer(updateRoomReservationFormInitialState, updateReservationFormActions, updateRoomReservationFormValidator),
});

export default reducer;
