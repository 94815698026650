/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Nullable } from "src/app/types/util.types";
import { Organization, SimpleOrganization } from "src/app/types/api/organization.types";
import { SimpleRoom } from "src/app/types/api/room.types";

export enum VenueAbility {
	VIEW = "venue.view",
	UPDATE = "venue.update",
	DELETE = "venue.delete",
}

export type SimpleVenue = {
	id: number
	name: string

	street: Nullable<string>
	houseNumber: Nullable<string>
	flatNumber: Nullable<string>
	postCode: Nullable<string>
	city: Nullable<string>
	country: Nullable<string>
	rooms: Nullable<SimpleRoom[]>

	organizationId: string
	organization: Nullable<SimpleOrganization>

	createdAt: string
	updatedAt: string
	meta: {
		abilities: VenueAbility[]
	}
}

export type Venue =
	SimpleVenue
	& {}

export type DetailedVenue =
	Venue
	& {
		organization: Organization
	}

export type CreateVenuePayload = {
	name: string

	country?: string
	city: string
	street: string
	houseNumber: string
	flatNumber?: string
	postCode: string
}

export type UpdateVenuePayload = {
	id: number
	name?: string

	country?: string
	city?: string
	street?: string
	houseNumber?: string
	flatNumber?: string
	postCode?: string
}
