import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { uiCreateProduct } from "src/app/store/features/ui/product/ui.product.actions";

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

function ProductCreateView(props: Props) {
    const {
        createProduct,
    } = props;

    return (
        <div></div>
    );
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
    createProduct: uiCreateProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCreateView);